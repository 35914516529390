/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import {
  Toast, Badge, Dropdown, ToastContainer, Button,
} from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { onMessageListener } from '../../firebaseInit';
import LoginContext from '../login/login.context';
import NotificationLatest from './notificationLatest';
import { GET_USERS_NOTIFICATIONS, GET_UNREAD_NOTIFICATION_COUNT } from './gql';
import NotificationClient from '../../NotificationClient';

const NotificationBadge = ({ isMobile }) => {
  const navigate = useNavigate();
  const { userUid } = useContext(LoginContext);

  const [count, setCount] = useState(0);
  const [newMessage, setNewMessage] = useState([]);
  const [showLatestNotif, setShowLatestNotif] = useState(false);

  // const [saveRegistrationToken] = useMutation(SAVE_REGISTRATION_TOKEN, {
  //   client: NotificationClient,
  // });

  const { data: dataCount } = useQuery(GET_UNREAD_NOTIFICATION_COUNT, {
    client: NotificationClient,
    variables: {
      userUid,
    },
  });

  useEffect(() => {
    const notifCount = _.has(dataCount, 'getUnreadNotificationCount') ? dataCount.getUnreadNotificationCount : 0;
    setCount(notifCount);
  }, [dataCount])


  const { loading, data } = useQuery(GET_USERS_NOTIFICATIONS, {
    client: NotificationClient,
    variables: {
      userUid,
      offset: 0,
      limit: 5,
    },
  });

  useEffect(() => {
    const result = _.has(data, 'getUserNotifications') ? data.getUserNotifications : null;
    const notifications = _.has(result, 'rows') ? result.rows : [];

    setNewMessage(notifications);
  }, [loading]);


  const isValidJSON = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return false;
    }
  };

  onMessageListener()
    .then((payload) => {
      const notificationData = _.has(payload, 'data') ? payload.data : null;
      const notification = _.has(payload, 'notification') ? payload.notification : null;
      const title = _.has(notification, 'title') ? notification.title : null;
      const body = _.has(notification, 'body') ? notification.body : null;
      const otherData = _.has(notificationData, 'data') ? notificationData.data : null;

      setCount(count + 1);
      setNewMessage([...newMessage, {
        title,
        body,
        data: isValidJSON(otherData),
        show: true,
      }]);
    });

  const handleClick = ({ urlPath, urlState }) => {
    if (urlPath) {
      navigate(urlPath, { state: urlState });
    }
  };

  return (
    <>
      {!isMobile
        ? (
          <>
            <Dropdown
              show={showLatestNotif}
              className="m-auto"
              onToggle={(isOpen) => {
                if (!isOpen) {
                  setShowLatestNotif(false);
                }
              }}
            >
              <Badge
                pill
                bg="primary"
                onClick={() => setShowLatestNotif(!showLatestNotif)}
                onBlur={() => setShowLatestNotif(false)}
              >
                {count}
                {' '}
                <i className="fa fa-bell" />
              </Badge>
              {showLatestNotif && (
                <NotificationLatest
                  show={showLatestNotif}
                  setShow={setShowLatestNotif}
                  data={newMessage}
                />
              )}
            </Dropdown>

            <ToastContainer
              style={{
                zIndex: 1000, right: 0,
              }}
            >
              {newMessage && newMessage.length > 0 && newMessage.map((item, index) => (
                <Toast
                  key={`notif-toast-${index}`}
                  show={_.has(item, 'show') ? item.show : false}
                  onClick={() => {
                    const otherData = _.has(item, 'data') ? item.data : null;
                    const urlPath = _.has(otherData, 'urlPath') ? otherData.urlPath : null;
                    const urlState = _.has(otherData, 'urlState') ? otherData.urlState : null;

                    handleClick({ urlPath, urlState });
                  }}
                  onClose={() => {
                    setNewMessage(newMessage.filter((i, k) => k !== index));
                  }}
                >
                  <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">{_.has(item, 'title') ? item.title : null}</strong>
                    {/* <small>11 mins ago</small> */}
                  </Toast.Header>
                  <Toast.Body>{_.has(item, 'body') ? item.body : null}</Toast.Body>
                </Toast>
              ))}
            </ToastContainer>
          </>
        )
        : (
          // <a
          //   href="javascript:void(0)"
          //   onClick={() => navigate('/notification')}
          // >
          //   Notifications
          //   {' '}
          //   <Badge
          //     pill
          //     bg="danger"
          //   >
          //     {count}
          //   </Badge>
          // </a>
          <Button
            variant='link'
            onClick={() => navigate('/notification')}
          >
            Notifications
            {' '}
            <Badge
              pill
              bg="danger"
            >
              {count}
            </Badge>
          </Button>
        )}
    </>
  );
}

export default NotificationBadge