/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-mui';
import client, { cache } from './CoreClient';
import LoginProvider from './pages/login/loginProvider';

// Templates
import Template from './pages/app';

// Pages
import LandingPage from './pages/landingPage';
import TuteeController, {
  RegisterStudent,
  StudentDashboard,
  StudentProfile,
  StudentProfileSettings,
  StudentSessions,
  UploadStudentId,
  StudentIssueAndResolution,
  StudentTicketList,
  StudentAnalytics,
} from './pages/tutee';
import Login from './pages/login';
import EmailVerification from './pages/login/emailVerification';
import ForgotPassword from './pages/login/forgot';
import TutorController, {
  MyProfile,
  RegisterTutor,
  Timeslots,
  TutorDashboard,
  TutorSessions,
  CompleteProfile,
  ProfileSettings,
  QualificationSettings,
  TutorIssueAndResolution,
  TutorTicketList,
  TutorAnalytics,
} from './pages/tutor';
import SuperadminController, { SuperadminDashboard, UserManagement } from './pages/superadmin';
import MarketingAdminController, {
  MarketingAdminDashboard,
  ManagementSettings,
  MarketingUserManagement,
  TutorRecruitment,
  ScheduleAndClassManagement,
  CommissionManagement,
  Vouchers,
  VoucherView,
  MarketingIssueAndResolution,
  MarketingTicketList,
  MarketingTicketCategory,
  MarketingAttendance,
  SystemVouchers,
  MarketingAnalytics,
} from './pages/marketingAdmin';
// import PartnerMerchantController, { PartnerMerchantDashboard } from './pages/partnerMerchant';
import Search from './pages/search';
import Profile from './pages/profile';
import Booking from './pages/booking';
import Reschedule from './pages/reschedule';
import PaymentsController, {
  CashIn,
  CashInCharge,
  CashInChargeCC,
  ChargeCC,
  ChargeCC3D,
  Checkout,
  Invoice,
  Wallet,
} from './pages/payments';
import Failed from './pages/payments/failed';
import Charge from './pages/payments/charge';
import Session from './pages/session';
import { UploaderProvider } from './modules/uploader';
import Notification from './pages/notification';
import ResetPassword from './pages/login/resetPassword';
import StudentCalendar from './pages/tutee/calendar';
import TutorCalendar from './pages/tutor/calendar';
import AuditTrail from './pages/auditTrail';
import StudentRatings from './pages/tutee/ratings';
import { TicketView, TicketForm } from './pages/issueAndResolution';
import SupportController, {
  SupportDashboard,
  SupportIssueAndResolution,
  SupportTicketList,
} from './pages/support';
import Payroll from './pages/marketingAdmin/payroll';
import ManagePayroll from './pages/marketingAdmin/payroll/managePayroll';
import ViewPayroll from './pages/marketingAdmin/payroll/viewPayroll';
import ViewPayslipPage from './pages/payroll/payslip/view';
import PayslipPage from './pages/payroll';
import UserProfile from './pages/userProfile';
import Faqs from './pages/faqs';
import PaymentHistories from './pages/marketingAdmin/payments'
import Terms from './pages/app/terms'
import PrivacyPolicy from './pages/app/policy'

const CAN_OFFLINE = JSON.parse(process.env.REACT_APP_CAN_OFFLINE);
const CACHE_FIRST = JSON.parse(process.env.REACT_APP_CACHE_FIRST);
// const SYSTEM_MAINTENANCE = JSON.parse(process.env.REACT_APP_SYSTEM_MAINTENANCE) || false;

export const waitOnCache = () => {
  if (!CAN_OFFLINE && !CACHE_FIRST) { return null; }

  const storage = window.localStorage;
  return persistCache({ cache, storage });
};

// optional configuration
const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 1000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

export default function App() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <BrowserRouter>
      <ApolloProvider client={client}>
        <LoginProvider>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <UploaderProvider>
              <Routes>
                <Route path="/" element={<Template />}>
                  <Route index element={<LandingPage />} />

                  <Route path="/student" element={<TuteeController />}>
                    <Route index element={<StudentDashboard />} />
                    <Route path="sessions" element={<StudentSessions />} />
                    <Route path="my-profile" element={<StudentProfile />} />
                    <Route path="profile-settings" element={<StudentProfileSettings />} />
                    <Route path="calendar" element={<StudentCalendar />} />
                    <Route path="ratings" element={<StudentRatings />} />
                    <Route path="issue-and-resolution" element={<StudentIssueAndResolution />}>
                      <Route index element={<StudentTicketList />} />
                      <Route path="ticket-form" element={<TicketForm />} />
                      <Route path="view" element={<TicketView />} />
                    </Route>
                    <Route path="analytics" element={<StudentAnalytics />} />
                  </Route>

                  <Route path="/tutor" element={<TutorController />}>
                    <Route index element={<TutorDashboard />} />
                    <Route path="my-profile" element={<MyProfile />} />
                    <Route path="timeslots" element={<Timeslots />} />
                    <Route path="sessions" element={<TutorSessions />} />
                    <Route path="complete" element={<CompleteProfile />} />
                    <Route path="profile-settings" element={<ProfileSettings />} />
                    <Route path="qualification-settings" element={<QualificationSettings />} />
                    <Route path="calendar" element={<TutorCalendar />} />
                    <Route path="issue-and-resolution" element={<TutorIssueAndResolution />}>
                      <Route index element={<TutorTicketList />} />
                      <Route path="ticket-form" element={<TicketForm />} />
                      <Route path="view" element={<TicketView />} />
                    </Route>
                    <Route path="payslip" element={<PayslipPage />} />
                    <Route path="analytics" element={<TutorAnalytics />} />
                  </Route>

                  <Route path="/search" element={<Search />} />
                  <Route path="/booking" element={<Booking />} />
                  <Route path="/booking/reschedule" element={<Reschedule />} />
                  <Route path="/notification" element={<Notification />} />

                  <Route path="/superadmin" element={<SuperadminController />}>
                    <Route index element={<SuperadminDashboard />} />
                    <Route path="user-management" element={<UserManagement />} />
                    <Route path="my-profile" element={<Profile />} />
                    <Route path="audit-trail" element={<AuditTrail />} />
                  </Route>

                  <Route path="/marketing-admin" element={<MarketingAdminController />}>
                    <Route index element={<MarketingAdminDashboard />} />
                    <Route path="my-profile" element={<Profile />} />

                    <Route path="management-settings" element={<ManagementSettings />}>
                      <Route index path="user-management" element={<MarketingUserManagement />} />
                      <Route path="tutor-recruitment" element={<TutorRecruitment />} />
                      <Route path="schedule-and-class-management" element={<ScheduleAndClassManagement />} />
                      <Route path="commission-management" element={<CommissionManagement />} />
                      <Route path="vouchers" element={<Vouchers />} />
                      <Route path="vouchers/view" element={<VoucherView />} />
                      <Route path="system-vouchers" element={<SystemVouchers />} />
                    </Route>
                    <Route path="audit-trail" element={<AuditTrail />} />
                    <Route path="issue-and-resolution" element={<MarketingIssueAndResolution />}>
                      <Route index element={<MarketingTicketList />} />
                      <Route path="settings" element={<MarketingTicketCategory />} />
                      <Route path="view" element={<TicketView />} />
                    </Route>
                    <Route path="payroll" element={<Payroll />} />
                    <Route path="payroll/manage" element={<ManagePayroll />} />
                    <Route path="payroll/manage/view" element={<ViewPayroll />} />
                    <Route path="payslip/view" element={<ViewPayslipPage />} />
                    <Route path="attendance" element={<MarketingAttendance />} />
                    <Route path="analytics" element={<MarketingAnalytics />} />
                    <Route path="payments" element={<PaymentHistories />} />
                  </Route>

                  {/* <Route path="/partner-merchant" element={<PartnerMerchantController />}>
                    <Route index element={<PartnerMerchantDashboard />} />
                    <Route path="my-profile" element={<Profile />} />
                  </Route> */}

                  <Route path="/support" element={<SupportController />}>
                    <Route index element={<SupportDashboard />} />
                    <Route path="my-profile" element={<Profile />} />
                    <Route path="issue-and-resolution" element={<SupportIssueAndResolution />}>
                      <Route index element={<SupportTicketList />} />
                      <Route path="view" element={<TicketView />} />
                    </Route>
                  </Route>

                  <Route path="/payments" element={<PaymentsController />}>
                    <Route index element={<Wallet />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="cashin" element={<CashIn />} />
                    <Route path="cashin/charge" element={<CashInCharge />} />
                    <Route path="cashin/charge-cc" element={<CashInChargeCC />} />
                    <Route path="failed" element={<Failed />} />
                    <Route path="charge" element={<Charge />} />
                    <Route path="invoice" element={<Invoice />} />
                    <Route path="charge-cc" element={<ChargeCC />} />
                    <Route path="charge-cc3d" element={<ChargeCC3D />} />
                  </Route>

                  <Route path="/session" element={<Session />} />
                  <Route path="/profile/:userUid" element={<UserProfile />} />

                  <Route path="/faqs" element={<Faqs />} />

                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                </Route>

                <Route path="/register" element={<RegisterStudent />} />
                <Route path="/register-tutor" element={<RegisterTutor />} />
                <Route path="/login" element={<Login />} />
                <Route path="/email-verification" element={<EmailVerification />} />
                <Route path="/upload-id" element={<UploadStudentId />} />
                <Route path="/forgot" element={<ForgotPassword />} />
                <Route path="/reset/:uid" element={<ResetPassword />} />

              </Routes>
            </UploaderProvider>
          </AlertProvider>
        </LoginProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
