import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { UPDATE_INTEREST_STATUS } from './gql';
import LoginContext from '../../../login/login.context';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';
import ModalConfirm from '../../../../components/modalConfirm';

export default function updateStatusModal({ status, uid, title, onError, onSuccess }) {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();
  const [saving, setSaving] = useState(null)

  const [updateStatus] = useMutation(UPDATE_INTEREST_STATUS, {
    refetchQueries: ['GetPaginatedInterest'],
  });

  const onOk = () => {
    setSaving(true)
    updateStatus({
      variables: {
        uid,
        updatedBy: userUid,
      },
    }).then(() => {
      setSaving(false)
      if(onSuccess) {
        onSuccess()
      }
      doInsertAuditTrail({
        action: 'UPDATE',
        module: 'Interest',
        changes: `${userFullName} ${status === 'PUBLISHED' ? 'Unpublish' : 'Publish'} ${title} on ${moment().format('lll')}`,
      });
    })
    .catch(err => {
      setSaving(false)
      if(onError) {
        onError({
          title: `Failed to ${status === 'PUBLISHED' ? 'Unpublish' : 'Publish'} ${title}`,
          message: err.toString()
        })
      }
    })
  };

  return (
    <ModalConfirm
      onOk={() => onOk()}
      message={(
        <span>
          Are you sure you want to
          {' '}
          {status === 'PUBLISHED' ? 'unpublish' : 'publish'}
          {' '}
          <b>{ title }</b>
          ?
        </span>
        )}
    >
      {({ onClick }) => (
        <Button
          disabled={saving}
          variant={status === 'PUBLISHED' ? 'secondary' : 'primary'}
          onClick={onClick}
        >
          {status === 'PUBLISHED' ? 'Unpublish' : 'Publish'}

        </Button>
      )}
    </ModalConfirm>
  );
}
