export default {
  navigation: [
    {
      roleCode: 'DEFAULT',
      links: [
        {
          label: 'Home',
          path: '/',
        },
        // {
        //   label: 'Mentor',
        //   path: '/tutor',
        //   sub: [
        //     {
        //       label: 'Mentor Dashboard',
        //       path: '/tutor/dashboard',
        //     }, {
        //       label: 'Bookings',
        //       path: '/tutor/bookings',
        //     }, {
        //       label: 'Schedule',
        //       path: '/tutor/schedule',
        //     }, {
        //       label: 'Mentee List',
        //       path: '/tutor/menteeList',
        //     },
        //   ],
        // },
      ],
    },
    {
      roleCode: 'TUTEE',
      links: [
        {
          label: 'Home',
          path: '/',
        }, {
          label: 'Dashboard',
          path: '/student',
        },
      ],
    },
    {
      roleCode: 'TUTOR',
      links: [
        {
          label: 'Home',
          path: '/',
        }, {
          label: 'Dashboard',
          path: '/tutor',
        },
      ],
    },
    {
      roleCode: 'SUPERADMIN',
      links: [
        {
          label: 'Home',
          path: '/',
        }, {
          label: 'Dashboard',
          path: '/superadmin',
        },
      ],
    },
    {
      roleCode: 'MARKETING_ADMIN',
      links: [
        {
          label: 'Home',
          path: '/',
        }, {
          label: 'Dashboard',
          path: '/marketing-admin',
        },
      ],
    },
    {
      roleCode: 'SUPPORT',
      links: [
        {
          label: 'Home',
          path: '/',
        }, {
          label: 'Dashboard',
          path: '/support',
        },
      ],
    },
  ],
  sideNav: [
    {
      roleCode: 'TUTEE',
      links: [
        {
          label: 'Dashboard',
          path: '/student',
          icon: 'fa-home',
        },
        {
          label: 'My Sessions',
          path: '/student/sessions',
          icon: 'fa-calendar',
        },
        {
          label: 'Calendar',
          path: '/student/calendar',
          icon: 'fa-calendar',
        },
        {
          label: 'Wallet',
          path: '/payments',
          icon: 'fa-wallet',
        },
        {
          label: 'Ratings & Feedback',
          path: '/student/ratings',
          icon: 'fa-star',
        },
        {
          label: 'Issue and Resolution',
          path: '/student/issue-and-resolution',
          icon: 'fa-file-alt',
        },
        {
          label: 'Analytics',
          path: '/student/analytics',
          icon: 'fa-chart-bar',
        },
      ],
    },
    {
      roleCode: 'TUTOR',
      links: [
        {
          label: 'Dashboard',
          path: '/tutor',
          icon: 'fa-home',
        },
        {
          label: 'My Sessions',
          path: '/tutor/sessions',
          icon: 'fa-calendar',
        },
        {
          label: 'Calendar',
          path: '/tutor/calendar',
          icon: 'fa-calendar',
        },
        {
          label: 'My Profile',
          path: '/tutor/my-profile',
          icon: 'fa-user-cog',
        },
        {
          label: 'Wallet',
          path: '/payments',
          icon: 'fa-wallet',
        },
        {
          label: 'Payslip',
          path: '/tutor/payslip',
          icon: 'fa-money-check',
        },
        {
          label: 'Issue and Resolution',
          path: '/tutor/issue-and-resolution',
          icon: 'fa-file-alt',
        },
        {
          label: 'Analytics',
          path: '/tutor/analytics',
          icon: 'fa-chart-bar',
        },
      ],
    },
    {
      roleCode: 'SUPERADMIN',
      links: [
        {
          label: 'Dashboard',
          path: '/superadmin',
          icon: 'fa-home',
        },
        {
          label: 'My Profile',
          path: '/superadmin/my-profile',
          icon: 'fa-user-cog',
        },
        {
          label: 'User Management',
          path: '/superadmin/user-management',
          icon: 'fa-user-cog',
        },
        {
          label: 'Audit Trail',
          path: '/superadmin/audit-trail',
          icon: 'fa-list-alt',
        },
      ],
    },
    {
      roleCode: 'MARKETING_ADMIN',
      links: [
        {
          label: 'Dashboard',
          path: '/marketing-admin',
          icon: 'fa-home',
        },
        {
          label: 'My Profile',
          path: '/marketing-admin/my-profile',
          icon: 'fa-user-cog',
        },
        {
          label: 'Management Settings',
          path: '/marketing-admin/management-settings/user-management',
          icon: 'fa-cogs',
          sub: [
            {
              label: 'User Management',
              path: '/marketing-admin/management-settings/user-management',
              icon: 'fa-user-cog',
            },
            {
              label: 'Tutor Recruitment',
              path: '/marketing-admin/management-settings/tutor-recruitment',
              icon: 'fa-user-cog',
            },
            {
              label: 'Schedule And Class Management',
              path: '/marketing-admin/management-settings/schedule-and-class-management',
              icon: 'fa-user-cog',
            },
            {
              label: 'Learnlive Fees Management',
              path: '/marketing-admin/management-settings/commission-management',
              icon: 'fa-user-cog',
            },
            {
              label: 'Voucher Code',
              path: '/marketing-admin/management-settings/vouchers',
              icon: 'fa-user-cog',
            },
            {
              label: 'System Voucher',
              path: '/marketing-admin/management-settings/system-vouchers',
              icon: 'fa-user-cog',
            },
          ],
        },
        {
          label: 'Payroll',
          path: '/marketing-admin/payroll',
          icon: 'fa-money-check',
        },
        {
          label: 'Audit Trail',
          path: '/marketing-admin/audit-trail',
          icon: 'fa-list-alt',
        },
        {
          label: 'Payment History',
          path: '/marketing-admin/payments',
          icon: 'fa-money-bill',
        },
        {
          label: 'Issue and Resolution',
          path: '/marketing-admin/issue-and-resolution',
          icon: 'fa-file-alt',
        },
        {
          label: 'Attendance',
          path: '/marketing-admin/attendance',
          icon: 'fa-file-alt',
        },
        {
          label: 'Analytics',
          path: '/marketing-admin/analytics',
          icon: 'fa-chart-bar',
        },
      ],
    },
    {
      roleCode: 'PARTNER_MERCHANT',
      links: [
        {
          label: 'Dashboard',
          path: '/partner-merchant',
          icon: 'fa-home',
        },
        {
          label: 'My Profile',
          path: '/partner-merchant/my-profile',
          icon: 'fa-user-cog',
        },
      ],
    },
    {
      roleCode: 'SUPPORT',
      links: [
        {
          label: 'Dashboard',
          path: '/support',
          icon: 'fa-home',
        },
        {
          label: 'My Profile',
          path: '/support/my-profile',
          icon: 'fa-user-cog',
        },
        {
          label: 'Issue and Resolution',
          path: '/support/issue-and-resolution',
          icon: 'fa-file-alt',
        },
      ],
    },
  ],
};
