/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import {
  Modal, Form, Button, Row, Col,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AlertError } from '../../components'

export default function editProfileModal({
  visible, onCancel, loading, onSubmit, initialValues, error, onCloseError, saving
}) {
  const firstName = _.has(initialValues, 'firstName') ? initialValues.firstName : null;
  const lastName = _.has(initialValues, 'lastName') ? initialValues.lastName : null;
  const middleInitial = _.has(initialValues, 'middleInitial') ? initialValues.middleInitial : null;

  const birthDate = _.has(initialValues, 'birthDate') ? initialValues.birthDate : null;
  const mobile = _.has(initialValues, 'mobile') ? initialValues.mobile : null;
  const gender = _.has(initialValues, 'gender') ? initialValues.gender : null;

  const address = _.has(initialValues, 'address') ? initialValues.address : null;
  const address1 = _.has(address, 'address1') ? address.address1 : null;
  const address2 = _.has(address, 'address2') ? address.address2 : null;
  const city = _.has(address, 'city') ? address.city : null;
  const postalCode = _.has(address, 'postalCode') ? address.postalCode : null;
  const countryCode = _.has(address, 'countryCode') ? address.countryCode : null;

  const {
    handleSubmit, formState, control,
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      middleInitial,
      mobile,
      gender,
      'address.address1': address1,
      'address.address2': address2,
      'address.city': city,
      'address.postalCode': postalCode,
      'address.countryCode': countryCode,
      birthDate: birthDate && new Date(birthDate),
    },
  });
  const { errors } = formState;

  return (
    <Modal
      show={visible}
      onHide={() => onCancel()}
    >
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Header>
          <Modal.Title>
            Edit Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <AlertError
            title='Profile update failed'
            error={error}
            onClose={() => onCloseError()}
          />}

          <Row>
            <Col lg={5}>
              <Form.Group className="form-group" controlId="createUser.controlInput1">
                <Form.Label>First Name</Form.Label>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: 'First name is required.' }}
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      isInvalid={!!_.has(errors, 'firstName')}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'firstName') ? errors.firstName.message : 'Invalid first name.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={2}>
              <Form.Group className="form-group" controlId="registerTutor.controlInput2">
                <Form.Label>MI</Form.Label>
                <Controller
                  name="middleInitial"
                  control={control}
                  rules={{ maxLength: { value: 3, message: 'Max length is 3.' } }}
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      isInvalid={!!_.has(errors, 'middleInitial')}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'middleInitial') ? errors.middleInitial.message : 'Invalid middle initial.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={5}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">Last Name</Form.Label>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: 'Last name is required.' }}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      isInvalid={!!_.has(errors, 'lastName')}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'lastName') ? errors.lastName.message : 'Invalid last name.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">Date of Birth</Form.Label>
                <Controller
                  name="birthDate"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <DatePicker
                      customInput={(
                        <Form.Control
                          isInvalid={!!_.has(errors, 'birthDate')}
                        />
                      )}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="MMMM d, yyyy"
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">Mobile</Form.Label>
                <Controller
                  name="mobile"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">Gender</Form.Label>
                <Controller
                  name="gender"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Select
                      autoFocus
                      {...field}
                    >
                      <option value="">Select Gender</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">
                  Address 1
                  <span className="text-muted">(Unit, House #, Street)</span>
                </Form.Label>
                <Controller
                  name="address.address1"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">
                  Address 2
                  <span className="text-muted">(Brgy, District, Subd)</span>
                </Form.Label>
                <Controller
                  name="address.address2"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">City</Form.Label>
                <Controller
                  name="address.city"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">Postal Code</Form.Label>
                <Controller
                  name="address.postalCode"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={12}>
              <Form.Group className="form-group" controlId="createUser.controlInput3">
                <Form.Label className="form-control-label">Country</Form.Label>
                <Controller
                  name="address.countryCode"
                  control={control}
                  autoFocus
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => onCancel()} disabled={saving}>Cancel</Button>
          {' '}
          <Button type="submit" variant="primary" disabled={saving}>{!saving ? 'Save Changes' : 'Saving'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
