/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { LoadingSpinner } from '../../components';
import loginContext from '../login/login.context';
import { confirmPayrollReleaseMutation } from './gql';
import PaymentClient from '../../PaymentClient';

export default function Index(payload) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userUid } = useContext(loginContext);
  const { uid } = payload;

  const onClose = () => {
    setVisible(false);
  };

  const onShow = () => {
    setVisible(true);
  };

  const [confirmRelease] = useMutation(confirmPayrollReleaseMutation, {
    client: PaymentClient,
    onCompleted() {
      onClose();
    },
    onError() {
      setLoading(false);
    },
  });

  const onSubmit = useCallback(() => {
    async function doSubmit() {
      setLoading(true);

      const variables = { tutorPayrollUid: uid, updatedBy: userUid };
      await confirmRelease({ variables });

      setLoading(false);
    }

    doSubmit();
  }, [uid, userUid, confirmRelease]);

  return (
    <>
      <Button variant="link" block onClick={onShow}>
        <FontAwesomeIcon icon={solid('circle-check')} />
        {' '}
        Confirm
      </Button>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payroll Release</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">You are about to confirm the relase of your payroll. Are you sure to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={loading}>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('circle-check')} />
                  {' '}
                  Confirm
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
