import { gql } from '@apollo/client';

export const GET_PAGINATED_TICKETS = gql`
query GetPaginatedTickets($offset: Int!, $limit: Int!, $filter: TicketFilter, $searchText: String) {
    getPaginatedTickets(offset: $offset, limit: $limit, filter: $filter, searchText: $searchText) {
      count
      rows {
        id
        uid
        ticketNumber
        appCode
        subject
        details
        priority
        status
        createdByName
        categoryUid
        createdAt
        createdBy
        updatedAt
        updatedBy
        otherDetails
        category {
          id
          uid
          name
          description
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
      }
      }
    }
  }
`;

export const CREATE_TICKET_CATEGORY = gql`
mutation CreateTicketCategory($name: String!, $description: String $userUid: String!, $appCode: String ) {
    createTicketCategory(name: $name, description: $description userUid: $userUid, appCode: $appCode ) {
      id
      uid
      name
      description
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const GET_ALL_TICKET_CATEGORY = gql`
query GetAllTicketCategory($appCode: String!) {
  getAllTicketCategory(appCode: $appCode) {
    id
    uid
    name
    description
    status
    isDefault
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const GET_PAGINATED_TICKET_CATEGORY = gql`
query GetPaginatedTicketCategory($appCode: String!, $offset: Int!, $limit: Int!) {
  getPaginatedTicketCategory(appCode: $appCode, offset: $offset, limit: $limit) {
    count
    rows {
      id
      uid
      name
      description
      status
      isDefault
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
`;

export const GET_TICKET = gql`
query GetTicket($uid: String!) {
  getTicket(uid: $uid) {
    id
    uid
    ticketNumber
    appCode
    subject
    details
    priority
    status
    createdByName
    categoryUid
    createdAt
    createdBy
    updatedAt
    updatedBy
    otherDetails
    category {
      id
      uid
      name
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    files {
      id
      uid
      storage
      ticketUid
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    ticketThread {
      id
      uid
      ticketUid
      lastMessageUid
      createdByName
      createdAt
      createdBy
      updatedAt
      updatedBy
      lastMessage {
        id
        uid
        ticketThreadUid
        message
        createdByName
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
}
`;

export const CREATE_TICKET = gql`
mutation CreateTicket($appCode: String!
    $subject: String!
    $categoryUid: String
    $details: String
    $userUid: String!
    $userFullname: String!
    $priority: String
    $attachments: [JSON]
    $otherDetails: JSON
    ) {
        createTicket(appCode: $appCode
            subject: $subject
            categoryUid: $categoryUid
            details: $details
            userUid: $userUid
            userFullname: $userFullname
            priority: $priority
            attachments: $attachments
            otherDetails: $otherDetails
            ) {
                id
                uid
                ticketNumber
                appCode
                subject
                details
                priority
                status
                createdByName
                categoryUid
                otherDetails
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
    }
`;

export const GET_PAGINATED_TICKET_THREAD_MESSAGES = gql`
query GetPaginatedTicketThreadMessages($ticketThreadUid: String!, $offset: Int, $limit: Int, $searchText: String) {
  getPaginatedTicketThreadMessages(ticketThreadUid: $ticketThreadUid, offset: $offset, limit: $limit, searchText: $searchText) {
    count
    rows {
      id
      uid
      ticketThreadUid
      message
      createdByName
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
`;

export const INSERT_TICKET_THREAD_MESSAGE = gql`
mutation InsertTicketThreadMessage($ticketUid: String!, $message: String!, $userUid: String!, $userFullname: String!) {
  insertTicketThreadMessage(ticketUid: $ticketUid, message: $message, userUid: $userUid, userFullname: $userFullname) {
    id
    uid
    ticketThreadUid
    message
    createdByName
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const CLOSE_TICKET = gql`
mutation CloseTicket($ticketUid: String!, $userUid: String!) {
  closeTicket(ticketUid: $ticketUid, userUid: $userUid) {
    id
    uid
    ticketNumber
    appCode
    subject
    details
    priority
    status
    createdByName
    categoryUid
    createdAt
    createdBy
    updatedAt
    updatedBy
    category {
      id
      uid
      name
      description
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    files {
      id
      uid
      ticketUid
      storage
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    ticketThread {
      id
      uid
      ticketUid
      lastMessageUid
      createdByName
      createdAt
      createdBy
      updatedAt
      updatedBy
      lastMessage {
        id
        uid
        ticketThreadUid
        message
        createdByName
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
}
`;

export const UPDATE_TICKET_CATEGORY_STATUS = gql`
mutation UpdateTicketCategoryStatus($categoryUid: String!, $status: CategoryStatus! $userUid: String!) {
  updateTicketCategoryStatus(categoryUid: $categoryUid, status: $status userUid: $userUid) {
    id
    uid
    name
    description
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const DELETE_TICKET_CATEGORY = gql`
mutation DeleteTicketCategory($categoryUid: String!, $userUid: String!) {
  deleteTicketCategory(categoryUid: $categoryUid, userUid: $userUid)
}
`;

export const UPDATE_TICKET_CATEGORY = gql`
mutation UpdateTicketCategory($categoryUid: String!, $name: String!, $userUid: String!, $description: String) {
  updateTicketCategory(categoryUid: $categoryUid, name: $name, userUid: $userUid, description: $description) {
    id
    uid
    name
    description
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;
