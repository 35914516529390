/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getUserWalletQuery = gql`
  query GetUserWallet($userUid: ID!) {
    getUserWallet(userUid: $userUid) {
      id
      uid
      userUid
      balance
      document
      soas {
        id
        uid
        walletUid
        action
        amount
        currentBalance
        previousBalance
        document

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
        address
        __typename
      }
      __typename
    }
  }
`;

export const getVoucherCodeDetailsQuery = gql`
  query GetVoucherCodeDetails($code: String!) {
    getVoucherCodeDetails(code: $code) {
      ... on Voucher {
        id
        uid
        code
        status
        startDate
        endDate
        requirement
        amount
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
        totalUsed
        __typename
      }
      ... on SystemVoucher {
        id
        uid
        code
        amount
        recepientUserUid
        status
        type
        startDate
        endDate
        description
        emailSent
        document
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
    }
  }
`;

export const IS_VOUCHER_REDEEMED = gql`
query isVoucherRedeemed($code: String!, $userUid: String!) {
  isVoucherRedeemed(code: $code, userUid: $userUid)
}
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

export const addPaymentMutation = gql`
  mutation AddPayment(
    $sourceId: String!
    $amount: Float!
    $paymentMethod: String!
    $voucher: String
    $document: JSON!
    $serviceDetails: JSON!
    $createdBy: String!
  ) {
    addPayment(
      sourceId: $sourceId
      amount: $amount
      paymentMethod: $paymentMethod
      voucher: $voucher
      document: $document
      serviceDetails: $serviceDetails
      createdBy: $createdBy
    ) {
      id
      uid
      sourceId
      amount
      paymentMethod
      paymentId
      status
      document
      serviceDetails

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const completePaymentMutation = gql`
  mutation CompletePayment(
    $sourceId: String!
    $paymentId: String!
    $status: String!
    $document: JSON!
    $updatedBy: String!
      
  ) {
    completePayment(
      sourceId: $sourceId
      paymentId: $paymentId
      status: $status
      document: $document
      updatedBy: $updatedBy
    ) {
      id
      uid
      sourceId
      amount
      paymentMethod
      paymentId
      status
      document
      serviceDetails

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const enrollToSessionMutation = gql`
  mutation EnrollToSession(
    $tuteeUid: String!
    $timeslotUid: String!
    $startDate: String!
    $endDate: String!
    $paymentUid: String!
    $createdBy: String!
  ) {
    enrollToSession(
      tuteeUid: $tuteeUid
      timeslotUid: $timeslotUid
      startDate: $startDate
      paymentUid: $paymentUid
      endDate: $endDate
      createdBy: $createdBy
    ) {
      id
      uid
      tuteeUid
      sessionUid
      paymentUid
      attendanceStatus

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const chargeWalletMutation = gql`
  mutation ChargeWallet(
    $userUid: ID!
    $amount: Float!
    $document: JSON!
  ) {
    chargeWallet(
      userUid: $userUid
      amount: $amount
      document: $document
    ) {
      id
      uid
      userUid
      balance
      chargedUid
      document
      soas {
        id
        uid
        walletUid
        action
        document

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

/**
 * End of Mutation
 */
