import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import Promise from 'bluebird';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CustomTable } from '../../../components';
import { paginatedPayrollsQuery, getUserQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import RegistryClient from '../../../RegistryClient';

function getUser(uid) {
  return RegistryClient.query({
    query: getUserQuery,
    variables: { uid },
  }).then(({ data }) => {
    const user = _.has(data, 'getUser') ? data.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;

    return `${firstName} ${lastName}`;
  });
}

export default function Index() {
  const [payrolls, setPayrolls] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { loading: loadingPayrolls, data: payrollResult } = useQuery(paginatedPayrollsQuery, {
    client: PaymentClient,
    variables: { page, pageSize },
    pollInterval: 2000,
  });

  useEffect(() => {
    setLoading(loadingPayrolls);
  }, [loadingPayrolls]);

  useEffect(() => {
    async function doParse() {
      setLoading(true);
      const result = _.has(payrollResult, 'getPaginatedPayrolls') ? payrollResult.getPaginatedPayrolls : null;
      const iCount = _.has(result, 'count') ? result.count : 0;
      const rows = _.has(result, 'rows') ? result.rows : [];

      // map generatedBy
      const iPayrolls = await Promise.mapSeries(rows, async (row) => {
        const payroll = { ...row };
        const uid = _.has(row, 'createdBy') ? row.createdBy : null;
        const user = await getUser(uid);

        Object.assign(payroll, { generatedBy: user });

        return payroll;
      });

      setCount(iCount);
      setPayrolls(iPayrolls);
      setLoading(false);
    }

    if (payrollResult) {
      doParse();
    }
  }, [payrollResult, setCount, setPayrolls]);

  const columns = useMemo(() => [
    {
      title: 'Payroll Type',
      dataKey: 'type',
    },
    {
      title: 'Coverage Dates',
      dataKey: 'startDate',
      render: (value, row) => {
        const startDate = _.has(row, 'startDate') ? moment(row.startDate).format('ll') : null;
        const endDate = _.has(row, 'endDate') ? moment(row.endDate).format('ll') : null;

        return `${startDate} to ${endDate}`;
      },
    },
    // { title: 'Pay Date', dataKey: 'payDate' },
    { title: 'Generate Status', dataKey: 'status', center: true },
    {
      title: 'Generated Date',
      dataKey: 'createdAt',
      render: (value) => {
        const generated = moment(value).format('lll');

        return generated;
      },
    },
    {
      title: 'Generated By',
      dataKey: 'generatedBy',
    },
    {
      title: '',
      dataKey: 'uid',
      render: (uid) => <ViewPayroll uid={uid} />,
    },
  ]);

  const onPageChange = useCallback((current) => {
    setPage(current);
  }, [setPage]);

  return (
    <CustomTable
      columns={columns}
      dataValues={payrolls}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      totals={count}
      loading={loading}
    />
  );
}

function ViewPayroll(payload) {
  const navigate = useNavigate();
  const { uid } = payload;

  const onView = useCallback(() => {
    navigate('manage', { state: { uid } });
  });

  return (
    <Button variant="link" onClick={onView}>
      <FontAwesomeIcon icon={solid('gears')} />
      {' '}
      Manage
    </Button>
  );
}
