import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, Cell, Brush, YAxis,
} from 'recharts';
import { GET_TUTOR_AVERAGE_RATING } from './gql';
import { LoadingSpinner } from '../../../components';

export default function AverageRatingBarChart() {
  const { data, loading } = useQuery(GET_TUTOR_AVERAGE_RATING);

  const result = _.has(data, 'getTutorAverageRating') ? data.getTutorAverageRating : null;
  let maxNumber = 0
  const parsedData = result && result.length > 0 ? result.map((item) => {
    const others = _.has(item, 'others') ? item.others : null;
    const firstName = _.has(others, 'firstName') ? others.firstName : null;
    const lastName = _.has(others, 'lastName') ? others.lastName : null;
    const rating = _.has(item, 'rating') ? parseFloat(item.rating) : 0;

    if(rating > maxNumber) {
      maxNumber = rating
    }

    return {
      name: `${firstName} ${lastName}`,
      rating,
    };
  }) : [];

  return (
    <div style={{ width: '100%', height: 400 }} className="d-flex m-auto">
      { !loading
        ? (
          <ResponsiveContainer width="100%" height={400} >
            <BarChart width={150} height={40} data={parsedData} margin={{ top: 50 }}>
              <Tooltip />
              <XAxis dataKey="name" />
              <YAxis dataKey={"rating"} domain={[0, maxNumber]} hide/>
              <Bar barSize={40} dataKey="rating" name="Average Rating" fill="#8884d8" label={{ position: 'top' }}>
                {parsedData.map((entry, index) => {
                  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                  // eslint-disable-next-line react/no-array-index-key
                  return <Cell key={`cell-${index}`} fill={`#${randomColor}`} />;
                })}
              </Bar>
              <Brush dataKey="name" height={30} stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        )
        : <div className="m-auto"><LoadingSpinner /></div>}
    </div>
  );
}
