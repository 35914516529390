import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Row, Col, Alert, Card, Button,
} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useAlert } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import MyProfileContext from './myprofile.context';
import AddTutorialProfile from './addTutorialModal';
import {
  deleteTutorialProfileMutation, getTutorialProfilesQuery, getTutorQuery, getUserQuery,
} from './gql';
import { LoginContext } from '../../login';
import RegistryClient from '../../../RegistryClient';
import HideTutorialProfile from './hideTutorialProfile';
import ShowTutorialProfile from './showTutorialProfile';
import { CustomPlaceHolder } from '../../../components';

const CardTitle = styledComponents(Card.Title)`
  &.card-title {
    font-size: 21px;
    display: inline-block;
    margin: 0 16px 16px 0;
  }
`;

const CardSubtitle = styledComponents(Card.Subtitle)`
  font-size: 26px;
  display: inline-block;
  margin: 0 0 16px 0;
`;

export default function Index() {
  const { tutorials = [], onAddTutorialProfile } = useContext(MyProfileContext);
  const { userUid, instanceUid } = useContext(LoginContext);
  const [canAdd, setCanAdd] = useState(false);
  const [tutorStatus, setTutorStatus] = useState(null);
  const [canHide, setCanHide] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data: userResult, loading: userLoading } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !userUid,
    variables: { uid: userUid },
  });

  const { data: getTutorResult, loading: tutorLoading } = useQuery(getTutorQuery, {
    skip: !instanceUid,
    variables: { uid: instanceUid },
  });

  useEffect(() => {
    if (userLoading || tutorLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [userLoading, tutorLoading])

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const status = _.has(user, 'status') ? user.status : null;

    if (status === 'ACTIVE') {
      setCanAdd(true);
    } else {
      setCanAdd(false);
    }
  }, [userResult]);

  useEffect(() => {
    const tutorResult = _.has(getTutorResult, 'getTutor') ? getTutorResult.getTutor : null;
    const iStatus = _.has(tutorResult, 'status') ? tutorResult.status : null;

    setTutorStatus(iStatus);
  }, [getTutorResult]);

  useEffect(() => {
    if (tutorials && tutorials.length && tutorStatus === 'ACTIVE') {
      setCanHide(true);
      setCanShow(false);
    }

    if (tutorStatus === 'INACTIVE') {
      setCanHide(false);
      setCanShow(true);
    }
  }, [tutorials, tutorStatus]);

  return (
    <CustomPlaceHolder loading={loading}>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={{ span: 8 }}>
                  <CardTitle className="text-muted">Tutorial Status</CardTitle>
                  {
                    tutorStatus === 'ACTIVE' ? (
                      <CardSubtitle className="text-success">
                        <FontAwesomeIcon icon={solid('check-circle')} />
                        {' '}
                        ACTIVE
                      </CardSubtitle>
                    ) : (
                      <>
                        <CardSubtitle className="text-danger">
                          <FontAwesomeIcon icon={solid('xmark-circle')} />
                          {' '}
                          INACTIVE
                        </CardSubtitle>
                        <Card.Subtitle className="text-warning">
                          Your tutorial profile status is inactive.
                          You are not searchable by the students.
                        </Card.Subtitle>
                      </>
                    )
                  }
                </Col>
                <Col lg={{ span: 4 }} align="right">
                  {canHide && <HideTutorialProfile />}
                  {canShow && (<ShowTutorialProfile />)}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3" lg={12}>
          {
            canAdd
              ? <AddTutorialProfile onSubmit={onAddTutorialProfile} />
              : <InactiveAccount />
          }
        </Col>
      </Row>

      <div className="row">
        {tutorials.map((i) => <TutorialProfileItem {...i} />)}
      </div>
    </CustomPlaceHolder>
  );
}

function TutorialProfileItem(payload) {
  const navigate = useNavigate();
  const { instanceUid, userUid } = useContext(LoginContext);

  const uid = _.has(payload, 'uid') ? payload.uid : null;
  const interest = _.has(payload, 'interest') ? payload.interest : null;
  const sessionType = _.has(payload, 'sessionType') ? payload.sessionType : null;
  const timeslots = _.has(payload, 'timeslots') ? payload.timeslots : [];
  const createdAt = _.has(payload, 'createdAt') ? payload.createdAt : null;
  const price = _.has(payload, 'price') ? `Price ${parseFloat(payload.price).toFixed(2)}` : null;
  const type = sessionType === 'SINGLE' ? 'One on One Session' : 'Group Session';
  const [parsedTimeslots, setParsedTimeslots] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    const parsedSlot = _.map(timeslots, (i) => {
      const day = _.has(i, 'day') ? i.day : null;

      switch (day) {
        case 'SUNDAY': return { ...i, key: 'a' };
        case 'MONDAY': return { ...i, key: 'b' };
        case 'TUESDAY': return { ...i, key: 'c' };
        case 'WEDNESDAY': return { ...i, key: 'd' };
        case 'THURSDAY': return { ...i, key: 'e' };
        case 'FRIDAY': return { ...i, key: 'f' };
        default: return { ...i, key: 'g' };
      }
    });

    const sortedSlots = _.sortBy(parsedSlot, ['key', 'start']);
    setParsedTimeslots(sortedSlots);
  }, [timeslots]);

  const onEdit = useCallback(() => {
    navigate('/tutor/timeslots', { state: { ...payload } });
  });

  const [mutateDelete] = useMutation(deleteTutorialProfileMutation, {
    onCompleted: () => {
      alert.success('Deleted successfully!', {
        title: 'Delete Tutorial Profile',
      });
    },
    refetchQueries: [
      {
        query: getTutorialProfilesQuery,
        variables: { tutorUid: instanceUid },
      },
    ],
  });

  const onDelete = useCallback(() => {
    const variables = { uid, deletedBy: userUid };

    mutateDelete({ variables });
  }, [uid]);

  return (
    <div className="col-12 col-md-6 col-xl-4 d-flex">
      <div className="course-box blog grid-blog" style={{ width: '100%' }}>
        <div className="course-content">
          <span className="course-title">
            {interest}
            <span className="date">
              Created on
              {createdAt && moment(createdAt).format('ll')}
            </span>
            <span className="date">{price}</span>
          </span>

          {parsedTimeslots.map((i) => <SlotItem {...i} />)}

          <p className="h5 mt-3">{type}</p>
          <div className="row">
            <div className="col">
              {/* <a
                href="javascript:void(0);"
                className="text-success"
                onClick={onEdit}
              >
                <i className="far fa-edit" />
                {' '}
                Edit
              </a> */}
              <Button className="text-success" variant="link" onClick={onEdit}>
                <i className="far fa-edit" /> Edit
              </Button>
            </div>
            <div className="col text-end">
              {/* <a
                className="text-danger"
                href="javascript:void(0);"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt" />
                {' '}
                Delete
              </a> */}
              <Button className="text-danger" variant="link" onClick={onDelete}>
                <i className="far fa-trash-alt" /> Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SlotItem(payload) {
  const day = _.has(payload, 'day') ? payload.day : null;
  const start = _.has(payload, 'start') ? moment(payload.start, 'HH:mm:ss').format('LT') : null;
  const end = _.has(payload, 'end') ? moment(payload.end, 'HH:mm:ss').format('LT') : null;

  if (!day)
    return ''

  return (
    <p className="h6">
      {day}
      {' '}
      <small className="text-muted">
        {start}
        {' '}
        -
        {' '}
        {end}
      </small>
    </p>
  );
}

function InactiveAccount() {
  return (
    <Alert variant="danger">
      <Alert.Heading>
        <ExclamationDiv><FontAwesomeIcon icon={solid('circle-exclamation')} /></ExclamationDiv>
        Inactive Account
      </Alert.Heading>
      <p>Please complete the recruitment process to crete a tutorial profile.</p>
      <p>First try to complete your details form the "Qualification" tab and send for review.</p>
    </Alert>
  );
}

const ExclamationDiv = styledComponents.div`
  font-size: 21px;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 18px;
`;
