/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */
export const searchTutorsQuery = gql`
  query SearchTutors(
    $location: String
    $search: String
    $interest: String
    $gradeLevel: String
    $availability: String
    $sessionType: String
    $priceRange: [Int!]
    $ratings: Int
    $offset: Int!
    $limit: Int
  ) {
    searchTutors(
      location: $location
      search: $search
      interest: $interest
      gradeLevel: $gradeLevel
      availability: $availability
      sessionType: $sessionType
      priceRange: $priceRange
      ratings: $ratings
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        id
        uid
        userUid
        status
        priceRange
        others
        tutorials {
          interest
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
        address
        __typename
      }
      __typename
    }
  }
`;

export const elasticSearchQuery = gql`
  query ElasticSearch($type: String!, $offset: Int!, $filters: [JSON!]) {
    elasticSearch(type: $type, offset: $offset, filters: $filters) {
      count
      rows {
        id
        uid
        type
        referenceUid
        document
      }
    }
  }
`;

export const getTutorQuery = gql`
  query GetTutor($uid: ID!) {
    getTutor(uid: $uid) {
      id
      uid
      userUid
      status
      priceRange
      __typename
    }
  }
`;

export const getInterestQuery = gql`
  query GetInterest($uid: ID!) {
    getInterest(uid: $uid) {
      id
      uid
      title
      description
      status
    }
  }
`;
/**
 * Queries End
 */

/**
 * Mutations
 */

/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
