import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button,
  Col, Form, Modal,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import RegistryClient from '../../../../RegistryClient';
import { saveFileMutation } from './gql';
import { LoginContext } from '../../../login';
import { getUserQuery } from '../gql';

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-image`;

const upload = (file, fileName, userUid) => new Promise((resolve, reject) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('userUid', userUid);
  formData.append('fileName', fileName);

  axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

export default function Index(payload) {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, setValue, watch,
  } = formPayload;
  const { errors } = formState;
  const { onCompleted } = payload

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateFileSave, { loading }] = useMutation(saveFileMutation, {
    client: RegistryClient,
    awaitRefetchQueries: true,
    // update percentage progress accross the system
    refetchQueries: [
      {
        query: getUserQuery,
        client: RegistryClient,
        variables: { uid: userUid },
      }
    ],
    onCompleted: () => {
      onCompleted()
      setVisible(false);
    }
  });

  const watchCert = watch('vaccination');

  useEffect(() => {
    if (watchCert) {
      const { type } = watchCert;
      const splitType = type && type.split('/');
      const ext = splitType.length && _.last(splitType);
      const fileName = `vaccination.${ext}`;

      upload(watchCert, fileName, userUid).then((result) => {
        const { data } = result;

        mutateFileSave({
          variables: {
            userUid,
            fileCategory: 'VACCINATION',
            storage: { ...data },
          },
        });
      });
    }
  }, [watchCert]);

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={regular('edit')} />
      </Button>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group as={Col} sm={12} controlId="reg.certification">
              <Form.Label>Vaccination Card</Form.Label>
              <Controller
                name="nbi"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    isInvalid={!!_.has(errors, 'vaccination')}
                    type="file"
                    onChange={(e) => {
                      const fileList = e.target.files;

                      setValue('vaccination', fileList[0]);
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'vaccination') ? errors.vaccination.message : 'Invalid Vaccination.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            {loading ? 'Uploading...' : 'Close'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
