import React, {
  createContext, useMemo, useState,
} from 'react';
import axios from 'axios';
import Promise from 'bluebird';
import _ from 'lodash';

const SEC_KEY = process.env.REACT_APP_SEC_KEY || null;
export const PaymentContext = createContext();

const getAbsoluteUrl = (function Url() {
  let a;
  return function Absolute(url) {
    if (!a) a = document.createElement('a');
    a.href = url;
    return a.href;
  };
}());

const base64Key = Buffer.from(SEC_KEY).toString('base64');
const uri = getAbsoluteUrl('/');
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Basic ${base64Key}`,
};

const errorHandler = (error) => {
  if (error.response) {
    // Request made and server responded
    return error.response.data;
  } if (error.request) {
    // The request was made but no response was received
    return error.request;
  }
  // Something happened in setting up the request that triggered an Error
  return error.message;
};

const fetch = ({ options }) => new Promise((resolve, reject) => {
  axios.request(options)
    .then((response) => {
      const data = _.has(response, 'data') ? response.data : null;

      resolve(data);
    })
    .catch((err) => reject(errorHandler(err)));
});

const createGcashSource = (totalAmount, redirect = {}) => new Promise((resolve, reject) => {
  const redirectUrl = redirect || {};

  if (!redirect) {
    Object.assign(redirectUrl, {
      success: `${uri}payments/charge`,
      failed: `${uri}payments/failed`,
    });
  }

  const options = {
    method: 'POST',
    url: 'https://api.paymongo.com/v1/sources',
    headers,
    data: {
      data: {
        attributes: {
          amount: totalAmount,
          redirect: redirectUrl,
          billing: {
            // address: {
            //   line1: '71 Mulawinan St.',
            //   line2: 'Lawang Bato',
            //   postal_code: '1447',
            //   city: 'Valenzuela',
            //   country: 'PH',
            //   state: 'NCR',
            // },
            // name: 'Joseph Samonte',
            // phone: '09955549647',
            // email: 'jsamonte@vibalgroup.com',
          },
          type: 'gcash',
          currency: 'PHP',
        },
      },
    },
  };

  fetch({ options }).then(resolve).catch(reject);
});

const getSource = (sourceId) => new Promise((resolve, reject) => {
  try {
    if (!sourceId) { throw new Error('Invalid sourceId, please retry booking again!'); }

    const options = {
      method: 'GET',
      url: `https://api.paymongo.com/v1/sources/${sourceId}`,
      headers,
    };

    fetch({ options }).then(resolve).catch(reject);
  } catch (err) {
    reject(err);
  }
});

const chargePayment = (sourceId, amount) => new Promise((resolve, reject) => {
  const options = {
    method: 'POST',
    url: 'https://api.paymongo.com/v1/payments',
    headers,
    data: {
      data: {
        attributes: {
          currency: 'PHP',
          amount,
          source: { id: sourceId, type: 'source' },
          statement_descriptor: 'LearnLive Tutorial Market',
        },
      },
    },
  };

  fetch({ options }).then(resolve).catch(reject);
});

const createPaymentIntent = (payload) => new Promise((resolve, reject) => {
  const { amount = 0, description, statementDescriptor } = payload;

  const options = {
    method: 'POST',
    url: 'https://api.paymongo.com/v1/payment_intents',
    headers,
    data: {
      data: {
        attributes: {
          amount,
          payment_method_allowed: ['card', 'paymaya'],
          payment_method_options: { card: { request_three_d_secure: 'any' } },
          currency: 'PHP',
          capture_type: 'automatic',
          description,
          statement_descriptor: statementDescriptor,
        },
      },
    },
  };

  fetch({ options }).then(resolve).catch(reject);
});

const createPaymentMethod = (payload) => new Promise((resolve, reject) => {
  const { type = 'card', details } = payload;
  const {
    cardNumber, expMonth, expYear, cvc,
  } = details;

  const options = {
    method: 'POST',
    url: 'https://api.paymongo.com/v1/payment_methods',
    headers,
    data: {
      data: {
        attributes: {
          type,
          details: {
            card_number: cardNumber,
            exp_month: expMonth && parseInt(expMonth, 10),
            exp_year: expYear && parseInt(expYear, 10),
            cvc,
          },
          // billing,
          // billing: {
          //   name: 'Joseph Samonte',
          //   email: 'jsamonte@vibalgroup.com',
          //   phone: 'pi_ZBV1h9oJmna8aYPzbxtqF9q4_client_NoJhhaYWpQhLR1cpRmvGqd5d',
          // },
        },
      },
    },
  };

  fetch({ options }).then(resolve).catch(reject);
});

const attachPayment = (payload) => new Promise((resolve, reject) => {
  const {
    intentId,
    paymentMethodId,
    clientKey,
    returnUrl = `${uri}payments/charge-cc3d`,
  } = payload;

  const options = {
    method: 'POST',
    url: `https://api.paymongo.com/v1/payment_intents/${intentId}/attach`,
    headers,
    data: {
      data: {
        attributes: {
          payment_method: paymentMethodId,
          client_key: clientKey,
          return_url: returnUrl,
        },
      },
    },
  };

  fetch({ options }).then(resolve).catch(reject);
});

const getPaymentIntent = (payload) => new Promise((resolve, reject) => {
  const { paymentIntentId, clientKey } = payload;

  const options = {
    method: 'GET',
    url: `https://api.paymongo.com/v1/payment_intents/${paymentIntentId}?client_key=${clientKey}`,
    headers,
  };

  fetch({ options }).then(resolve).catch(reject);
});

const handelCreatePaymentSource = (payload) => new Promise((resolve, reject) => {
  try {
    const method = _.has(payload, 'method') ? payload.method : null;
    const totalAmount = _.has(payload, 'totalAmount') ? payload.totalAmount : null;
    const redirect = _.has(payload, 'redirect') ? payload.redirect : null;
    const convertedAmount = parseFloat(totalAmount) * 100;

    if (!totalAmount || totalAmount < 1) { throw new Error('Invalid totalAmount value!'); }

    switch (method) {
      case 'GCASH': resolve(createGcashSource(convertedAmount, redirect));
        break;
      default:
        throw new Error('Please provide a valid payment value! { method: [ GCASH | CC ] }');
    }
  } catch (err) {
    reject(err);
  }
});

export default function Index({ children }) {
  const [payError] = useState(null);
  const [source, setSource] = useState(null);

  const contextPayload = useMemo(() => ({
    createGcashSource: handelCreatePaymentSource,
    getSource,
    payError,
    source,
    setSource,
    createPaymentIntent,
    createPaymentMethod,
    attachPayment,
    getPaymentIntent,
    getAbsoluteUrl,
    chargePayment,
  }), [
    payError,
    source,
    handelCreatePaymentSource,
    getSource,
    setSource,
    createPaymentIntent,
    createPaymentMethod,
    attachPayment,
    getPaymentIntent,
    getAbsoluteUrl,
    chargePayment,
  ]);

  return (
    <PaymentContext.Provider value={contextPayload}>
      {children}
    </PaymentContext.Provider>
  );
}
