/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */
export const getPaymentQuery = gql`
  query GetPayment($uid: ID!) {
    getPayment(uid: $uid) {
      id
      uid
      amount
      paymentMethod
      sourceId
      paymentId
      status
      document
      serviceDetails
      voucher
      voucherDiscount

      createdAt
      createdBy
      updatedAt
    updatedBy
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getTimeslotQuery = gql`
  query GetTimeslot($uid: ID!) {
    getTimeslot(uid: $uid) {
      id
      uid
      day
      start
      end
      status
      tutorialProfile {
        id
        uid
        interest
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
/**
 * End of Query
 */

/**
 * Mutation
 */

/**
 * End of Mutation
 */
