/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import styledComponents from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingSpinner } from '../../../components';
import loginContext from '../../login/login.context';
import { createPayrollMutation, getCommissionQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { useAlert } from 'react-alert';

export default function Index() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userUid } = useContext(loginContext);
  const [commissionRate, setCommissionRate] = useState(null);
  const alert = useAlert()

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const {
    handleSubmit, formState, control, reset,
  } = useForm();
  const { errors } = formState;

  const onClose = () => {
    setVisible(false);
    reset();
  };

  const onShow = () => {
    setVisible(true);
  };

  const {
    loading: loadingCommission,
    data: commissionResult,
  } = useQuery(getCommissionQuery, {
    variables: { roleCode: 'TUTOR' },
  });

  useEffect(() => {
    setLoading(loadingCommission);
  }, [loadingCommission]);

  useEffect(() => {
    const commission = _.has(commissionResult, 'getDefaultCommission') ? commissionResult.getDefaultCommission : null;
    const rate = _.has(commission, 'percentage') ? commission.percentage : null;

    setCommissionRate(rate);
  }, [commissionResult]);

  const [createPayroll] = useMutation(createPayrollMutation, {
    client: PaymentClient,
    refetchQueries: ['GetPaginatedPayrolls'],
    onCompleted() {
      onClose();
    },
    onError(err) {
      const errorMessage = _.has(err, 'message') ? err.message : null

      alert.error(errorMessage, { timeout: 3000 })
      setLoading(false);
    },
  });

  const onSubmit = useCallback((values) => {
    async function doSubmit() {
      setLoading(true);
      const type = _.has(values, 'type') ? values.type : null;
      const dates = _.has(values, 'dates') ? values.dates : [];
      const startDate = dates[0];
      const endDate = dates[1];
      const variables = {
        type,
        startDate,
        endDate,
        commissionRate,
        createdBy: userUid,
      };

      await createPayroll({ variables }).then(() => {
        // audit trail
        doInsertAuditTrail({
          module: 'Payroll',
          changes: `${userFullName} generated payroll for ${startDate} - ${endDate}`,
          action: 'CREATE',
        });
      })


      setLoading(false);
    }

    doSubmit();
  }, [commissionRate, userUid, userFullName]);

  const validateType = (value) => {
    if (value === 'Select payroll type') {
      return 'Payroll type is required!'
    }

    return true
  }

  const validateDates = (values) => {
    let valid = true

    _.map(values, value => {
      if (!value)
        valid = false
    })

    if (!valid) {
      return 'Invalid date range'
    }

    return true
  }

  const triggerSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <Button variant="secondary" onClick={onShow}>
        <FontAwesomeIcon icon={solid('file-invoice')} />
        {' '}
        Create Payroll
      </Button>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Payroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">
            Current Commission Rate is
            {' '}
            <span className="text-info">
              {commissionRate}
              %
            </span>
          </p>
          <Form noValidate>
            <Form.Group className="form-group" controlId="payroll.type">
              <Form.Label>Payroll Type</Form.Label>
              <Controller
                name="type"
                control={control}
                rules={{
                  required: 'Payroll type is required!',
                  validate: validateType
                }}
                render={({ field }) => (
                  <Form.Control
                    autoFocus
                    isInvalid={!!_.has(errors, 'type')}
                    as={SelectType}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'type') ? errors.type.message : 'Invalid type.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" controlId="rate.dates">
              <Form.Label>Date Range</Form.Label>
              <Controller
                name="dates"
                control={control}
                rules={{
                  required: 'Daterange is required!',
                  validate: validateDates
                }}
                render={({ field }) => (
                  <Form.Control
                    isInvalid={!!_.has(errors, 'dates')}
                    as={DateRange}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'dates') ? errors.dates.message : 'Invalid dates.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>
          <Button variant="primary" onClick={triggerSubmit} disabled={loading}>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('circle-check')} />
                  {' '}
                  Generate
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const DateWrapper = styledComponents.div`
  border: unset;
  padding: 6px 0;
`;

function DateRange(props) {
  const className = _.has(props, 'className') ? props.className : null;
  const value = _.has(props, 'value') ? props.value : [];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (value) {
      const start = value[0];
      const end = value[1];

      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [value]);

  return (
    <DateWrapper className={`${className}`}>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        selectsRange
        selectsDisabledDaysInRange
        inline
        {...props}
      />
    </DateWrapper>
  );
}

function SelectType(props) {
  return (
    <Form.Select aria-label="Select payroll type" {...props}>
      <option>Select payroll type</option>
      <option value="FREELANCE">Freelance</option>
      <option value="PARTNER">Partner Merchant</option>
    </Form.Select>
  );
}
