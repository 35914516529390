import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
// import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { DatePicker } from '../../../components';
import { GET_TOTAL_PROFILE_VIEWS } from './qql';
import AuditTrailClient from '../../../AuditTrailClient';
import { LoadingSpinner } from '../../../components';

export default function TotalProfileView({ userUid, appCode }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { loading, data } = useQuery(GET_TOTAL_PROFILE_VIEWS, {
    client: AuditTrailClient,
    variables: {
      userUid,
      appCode,
      dateRange: {
        startDate,
        endDate,
      },
    },
  });

  const result = _.has(data, 'getTotalProfileViews') ? data.getTotalProfileViews : 0;

  const yellow = 'rgba(249, 206, 35, 0.1)';
  return (
    <div className="card" style={{ backgroundColor: yellow, height: '100%' }}>
      <div className="card-header" style={{ backgroundColor: yellow }}>
        <div className="d-flex justify-content-between align-items-center gap-2">
          <h5 className="card-title">Profile Views</h5>
          <div>
            <DatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable
            />

          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="dash-board-list yellow ">
          <div
            className="dash-widget"
            style={{
              height: '100%',
              background: 'none',
              boxShadow: 'none',
              marginBottom: 0,
            }}
          >
            <div className="circle-bar">
              <div className="icon-col">
                <i className="fas fa-eye" />
              </div>
            </div>
            <div className="dash-widget-info">
              <h3>
                {!loading ? result : <LoadingSpinner text='' />}
              </h3>
              <h6>Total</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
