import React, {
  useContext, useState, useEffect,
} from 'react';
import { useMutation } from '@apollo/client';
import {
  Row, Col, Button, Badge,
} from 'react-bootstrap';
import _ from 'lodash';
import ModalImage from 'react-modal-image';
import moment from 'moment';
import TicketingClient from '../../../TicketingClient';
import { CLOSE_TICKET } from '../gql';
import LoginContext from '../../login/login.context';
import { FileReaderModal, ModalConfirm, AlertError } from '../../../components';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import UploaderContext from '../../../modules/uploader/uploader.context';

export default function Index({ data }) {
  const { userUid } = useContext(LoginContext);
  const { getFileLink } = useContext(UploaderContext);

  const [attachments, setAttachments] = useState([]);
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)

  const { doInsertAuditTrail } = useCreateAuditTrail();

  const [closeTicket] = useMutation(CLOSE_TICKET, {
    client: TicketingClient,
    refetchQueries: ['GetTicket'],
  });

  const ticketUid = _.has(data, 'uid') ? data.uid : null;
  const ticketNumber = _.has(data, 'ticketNumber') ? data.ticketNumber : null;
  const subject = _.has(data, 'subject') ? data.subject : null;
  const status = _.has(data, 'status') ? data.status : null;
  const createdByName = _.has(data, 'createdByName') ? data.createdByName : null;
  const createdBy = _.has(data, 'createdBy') ? data.createdBy : null;
  const createdAt = _.has(data, 'createdAt') ? data.createdAt : null;
  const details = _.has(data, 'details') ? data.details : null;
  const category = _.has(data, 'category') ? data.category : null;
  const files = _.has(data, 'files') ? data.files : [];
  // const ticketThread = _.has(data, 'ticketThread') ? data.ticketThread : null;
  // const ticketThreadUid = _.has(ticketThread, 'uid') ? ticketThread.uid : null;
  const otherDetails = _.has(data, 'otherDetails') ? data.otherDetails : null;
  const payslipControlNumber = _.has(otherDetails, 'payslipControlNumber') ? otherDetails.payslipControlNumber : null;
  // const userRole = _.has(otherDetails, 'userRole') ? otherDetails.userRole : null;

  const getFilesUri = (iFiles) => (iFiles && iFiles.length > 0
    ? Promise.all(iFiles.map(async (file) => {
      const storage = _.has(file, 'storage') ? file.storage : null;
      const path = _.has(storage, 'path') ? storage.path : null;

      const fileUrl = path ? await getFileLink({ filePath: path }).then((uri) => {
        if (uri) {
          return uri;
        }
      }) : null;

      return { ...file, fileUrl };
    })) : iFiles);

  useEffect(() => {
    async function callFunction() {
      const attachmentsResults = await getFilesUri(files);
      setAttachments(attachmentsResults);
    }

    if (files.length > 0) {
      callFunction();
    }

    return () => {
      setAttachments([]);
    };
  }, [files]);

  const handleCloseTicket = () => {
    setSaving(true)
    closeTicket({
      variables: {
        ticketUid,
        userUid,
      },
    }).then(() => {
      setSaving(false)
      setError(null)
      doInsertAuditTrail({
        action: 'CLOSE',
        changes: `Close ticket #${ticketNumber} issue.`,
        module: 'Issues and Resolution',
      });
    }).catch(err => {
      setSaving(false)
      setError({
        title: 'Failed to close ticket',
        message: err.toString()
      })
    })
  };

  return (
    <div>
      <Row className="justify-content-between gap-2">
        {error && (
          <AlertError
            error={error.message}
            title={error.title}
            onClose={() => setError(null)}
          />
        )}

        <Col lg={9}>
          <Row>
            <Col lg={12}>
              <h4 className="card-title">
                <span className="text-muted">
                  Ticket Number:
                </span>
                {' '}
                #
                {ticketNumber}
              </h4>
            </Col>

            <Col lg={12}>
              <p>
                <span className="text-muted">Category: </span>
                {category ? category.name : ''}
              </p>
            </Col>

            {category && category.name && _.lowerCase(category.name) === 'payslip'
              && (
                <Col lg={12}>
                  <p>
                    <span className="text-muted">Payslip Control Number: </span>
                    {payslipControlNumber}
                  </p>
                </Col>
              )}

            <Col lg={12}>
              <p>
                <span className="text-muted">Subject: </span>
                {subject}
              </p>
            </Col>
            <Col lg={12}>
              <p>
                <span className="text-muted">Details: </span>
                {details}

              </p>
            </Col>
            {createdBy !== userUid && (
              <Col lg={12}>
                <p>
                  <span className="text-muted">Requestor Name: </span>
                  {createdByName}

                </p>
              </Col>
            )}

            <Col lg={12}>
              <p>
                <span className="text-muted">Date Submitted: </span>
                {moment(createdAt).isValid() && moment().format('LLL')}

              </p>
            </Col>
          </Row>
          <Row className="d-flex gap-2">
            <p className="text-muted">Attachments</p>
            {attachments && attachments.length > 0 && attachments.map((file) => {
              const storage = _.has(file, 'storage') ? file.storage : null;
              const fileId = _.has(file, 'id') ? file.id : null;
              const fileUrl = _.has(file, 'fileUrl') ? file.fileUrl : null;
              const mimetype = _.has(storage, 'mimetype') ? storage.mimetype : null;
              const originalname = _.has(storage, 'originalname') ? storage.originalname : null;

              return (
                <Col lg={3} key={`file-${fileId}`}                >
                  {mimetype === 'application/pdf'
                    ? (
                      <div
                        style={{
                          margin: 'auto',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                          border: 'solid #7575',
                          padding: 10,
                          borderRadius: 10,
                        }}
                      >
                        <FileReaderModal
                          title={originalname}
                          filePath={fileUrl}
                          fileType={mimetype}
                          buttonViewText={<>
                            <i className="fas fa-file-pdf" />
                            <p>{originalname}</p>
                          </>}
                        />
                      </div>
                    )
                    : (
                      <ModalImage
                        small={fileUrl}
                        large={fileUrl}
                        alt="Hello World!"
                      />
                    )}
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col lg={2}>
          <div className="d-grid">

            {userUid !== createdBy ? (
              <ModalConfirm
                message="Are you sure you want to close this ticket?"
                onOk={handleCloseTicket}
              >
                {({ onClick }) => {
                  if (status === 'OPEN') {
                    return (
                      <Button
                        disabled={saving}
                        variant="primary"
                        onClick={() => onClick()}
                        block
                      >
                        {!saving ? 'Close Ticket' : 'Closing Ticket...'}
                      </Button>
                    );
                  }
                  return (
                    <Badge bg="secondary">
                      {status}
                    </Badge>
                  );
                }}
              </ModalConfirm>
            ) : (
              <Badge bg={status === 'OPEN' ? 'success' : 'secondary'}>
                {status}
              </Badge>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
