import React, { useContext, useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { DashboardTemplate } from '../../../template/components';
import LoginContext from '../../login/login.context';
import TotalClassHours from './TotalClassHours';
import TotalSpendBarChart from './TotalSpendBarChart';
import TotalReviews from './TotalReviews';
import RescheduleRate from './RescheduleRate';
import CancellationRate from './CancellationRate';
import styledComponents from 'styled-components';

const CustomNav = styledComponents(Nav)`
  .nav-link.active {
    color: #FE9445;
  }
`

export default function Index() {
  const [current, setCurrent] = useState('rates')

  return (
    <DashboardTemplate>
      <Row>
        <Col lg={12} className="mb-3">
          <CustomNav
            activeKey={current}
            onSelect={(selectedKey) => setCurrent(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link eventKey="/rates">Rates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="yearlySpendings">Yearly Spendings</Nav.Link>
            </Nav.Item>
          </CustomNav>
        </Col>
      </Row>

      <LoadContent content={current} />
    </DashboardTemplate>
  );
}

const LoadContent = (payload) => {
  const { instanceUid, userUid } = useContext(LoginContext);
  const { content } = payload

  switch (content) {
    case 'yearlySpendings':
      return (
        <Row className="mb-4">
          <Col lg={12}>
            <TotalSpendBarChart userUid={userUid} />
          </Col>
        </Row>
      )
    default:
      return (
        <>
          <Row className="mb-4">
            <Col lg={6} className="dash-board-list pink">
              <CancellationRate instanceUid={instanceUid} userUid={userUid} />
            </Col>
            <Col lg={6} className="dash-board-list blue">
              <RescheduleRate instanceUid={instanceUid} userUid={userUid} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={6} className="dash-board-list blue">
              <TotalReviews userUid={userUid}/>
            </Col>
            <Col lg={6} className="dash-board-list yellow">
              <TotalClassHours instanceUid={instanceUid} />
            </Col>
          </Row>
        </>
      )
  }
}
