import moment from 'moment';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Card, Spinner } from 'react-bootstrap';
import { CustomTable, Ratings } from '../../../components';
import { LoginContext } from '../../login';
import { getStudentRatingsQuery } from './gql';
import ViewModal from './viewModal';

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState([]);
  const { instanceUid } = useContext(LoginContext);

  const { loading: loadingRatings, data: ratingsResult } = useQuery(getStudentRatingsQuery, {
    skip: !instanceUid,
    variables: { tuteeUid: instanceUid },
  });

  useEffect(() => {
    setLoading(loadingRatings);
  }, [loadingRatings]);

  useEffect(() => {
    if (ratingsResult) {
      const result = _.has(ratingsResult, 'getStudentRatings') ? ratingsResult.getStudentRatings : [];

      setRatings(result);
    }
  }, [ratingsResult]);

  const columns = useMemo(() => [
    {
      title: 'Tutor',
      dataKey: 'session',
      render: (session) => {
        const timeslot = _.has(session, 'timeslot') ? session.timeslot : null;
        const profile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
        const tutor = _.has(profile, 'tutor') ? profile.tutor : null;
        const tutorOthers = _.has(tutor, 'others') ? tutor.others : null;
        const firstName = _.has(tutorOthers, 'firstName') ? _.startCase(tutorOthers.firstName) : null;
        const lastName = _.has(tutorOthers, 'lastName') ? _.startCase(tutorOthers.lastName) : null;
        const tutorName = `${firstName} ${lastName}`;

        return tutorName;
      },
    },
    {
      title: 'Session',
      dataKey: 'session',
      render: (session) => {
        const timeslot = _.has(session, 'timeslot') ? session.timeslot : null;
        const profile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
        const interest = _.has(profile, 'interest') ? profile.interest : null;

        return interest;
      },
    },
    {
      title: 'Date',
      dataKey: 'createdAt',
      render: (value) => value && moment(value).format('lll'),
    },
    {
      title: 'Rating',
      dataKey: 'ratings',
      center: true,
      render: (rating) => <Ratings value={rating} readonly />,
    },
    {
      title: '',
      dataKey: 'session',
      render: (session, row) => {
        const rating = _.has(row, 'ratings') ? row.ratings : null;
        const feedback = _.has(row, 'feedback') ? row.feedback : null;

        const timeslot = _.has(session, 'timeslot') ? session.timeslot : null;
        const profile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
        const interest = _.has(profile, 'interest') ? profile.interest : null;

        return <ViewModal rating={rating} feedback={feedback} interest={interest} />;
      },
    },
  ]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Ratings
          {' '}
          {
            loading && (
              <Spinner
                animation="border"
                role="status"
                as="div"
                aria-hidden="true"
                size="sm"
                style={{ color: '#FE9445' }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )
          }
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <CustomTable
          columns={columns}
          dataValues={ratings}
        />
      </Card.Body>
    </Card>
  );
}
