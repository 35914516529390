/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { useMutation } from '@apollo/client';
import { addDays } from 'date-fns';
import moment from 'moment';
import useNotification from '../../notification/useNotification';
import { UPDATE_COMMISSIONS } from './gql';
import LoginContext from '../../login/login.context';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { AlertError } from '../../../components'
import useTutorNotification from '../../notification/useTutorNotification';

export default function commissionModal({
  userUid, roleCode, uid, firstName, lastName, initialValues
}) {
  const { userUid: currentUserUid } = useContext(LoginContext);
  const { sendNotification } = useNotification({ userUid: currentUserUid });
  const { sendNotification: sendTutorsNotification } = useTutorNotification({ userUid: currentUserUid });

  const { doInsertAuditTrail } = useCreateAuditTrail();

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const effectivityDate = _.has(initialValues, 'effectivityDate') ? initialValues.effectivityDate : null
  const percentage = _.has(initialValues, 'percentage') ? initialValues.percentage : null


  const {
    handleSubmit, control, formState, reset
  } = useForm({
    defaultValues: {
      percentageRate: percentage,
      effectivityDate: effectivityDate ? new Date(effectivityDate) : null
    }
  });
  const { errors } = formState;

  const [updateCommission] = useMutation(UPDATE_COMMISSIONS);

  const onSubmit = (data, e) => {
    setSaving(true)
    setError(null)
    e.preventDefault();
    updateCommission({
      variables: {
        ...data,
        uid,
        userUid,
        roleCode,
        percentage: parseFloat(data.percentageRate),
        updatedBy: currentUserUid,
      },
    }).then(() => {

      if (roleCode === 'TUTOR') {
        // all tutors
        doInsertAuditTrail({
          action: 'UPDATE',
          changes: `Updated learnlive fees of ${roleCode} from ${initialValues.percentage}% to ${data.percentageRate}% effective on ${moment(data.effectivityDate).format('LL')}`,
          module: 'Learnlive Fees',
        });

        sendTutorsNotification({
          title: 'Learnlive Fees',
          message: `LearnLive Fees has been changed from ${initialValues.percentage}% to ${data.percentageRate}% effective on ${moment(data.effectivityDate).format('LL')}`
        })
      } else if (roleCode === 'PARTNER_MERCHANT') {
        // per user

        doInsertAuditTrail({
          action: 'UPDATE',
          changes: `Updated learnlive fees of ${firstName} ${lastName} from ${initialValues.percentage}% to ${data.percentageRate}% effective on ${moment(data.effectivityDate).format('LL')}`,
          module: 'Learnlive Fees',
        });

        sendNotification({
          userUids: [userUid],
          title: 'Learnlive Fees',
          message: `LearnLive Fees has been changed from ${initialValues.percentage}% to ${data.percentageRate}% effective on ${moment(data.effectivityDate).format('LL')}`,
        });
      }

      reset()
      setSaving(false)
      setError(null)
      setShow(false);
    }).catch(err => {
      setSaving(false)
      setError(err.toString())
    })
  };

  const onClose = () => {
    setShow(false)
    reset()
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(true)}
      >
        Edit
      </Button>

      {show && (
        <Modal show={show} onHide={() => onClose()}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                Update Commission Rate
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {firstName && lastName && <h5 className='mb-4'>{firstName} {lastName}</h5>}
              {error && <AlertError
                title='Update failed'
                error={error}
                onClose={() => setError(null)}
              />}
              <Form.Group>
                <Form.Label>Percentage rate</Form.Label>
                <Controller
                  name="percentageRate"
                  control={control}
                  rules={{
                    required: 'Percentage rate is required.',
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      autoFocus
                      isInvalid={!!_.has(errors, 'percentageRate')}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'percentageRate') ? errors.percentageRate.message : 'Invalid Percentage rate.'}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Effectivity Date</Form.Label>
                <Controller
                  name="effectivityDate"
                  control={control}
                  rules={{ required: 'Effectivity Date is required.' }}
                  render={({ field }) => (
                    <DatePicker
                      customInput={(
                        <Form.Control
                          isInvalid={!!_.has(errors, 'effectivityDate')}
                        />
                      )}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="MMMM d, yyyy"
                      minDate={addDays(new Date(), 14)}
                      {...field}
                    />
                  )}
                />
                {/* Fix error message */}
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'effectivityDate') ? errors.effectivityDate.message : 'Invalid Effectivity Date.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={saving} onClick={() => setShow(false)}>Cancel</Button>
              <Button variant="primary" disabled={saving} type="submit">{saving ? 'Saving...' : 'Save'}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
}
