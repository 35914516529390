/* eslint-disable react/prop-types */
import { useLazyQuery, useQuery } from '@apollo/client';
import React, {
  useContext, useEffect, useState, useMemo, useCallback,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import styledComponents from 'styled-components';
import Promise from 'bluebird'
import {
  CustomTable, DynamicAvatar, SelectInterest,
} from '../../../components';
import { getUserQuery, getAttendanceQuery, getStudentEnrolledSessionQuery, getStudentSessionRestrictionsQuery } from './gql';
import SessionsContext, { SessionRowContext } from './sessions.context';
import RegistryClient from '../../../RegistryClient';
import JoinSession from './joinSession';
import EndSession from './endSession';
import { LoginContext } from '../../login';
import CancelBooking from './cancelBooking';
import RateSession from './rateSession';
import RateSessionContext from '../../../modules/rateSession/rateSession.context';
import RateSessionModal from '../../../modules/rateSession';
import ViewPayment from './viewPayment';

const StyledRow = styledComponents(Row)`
  table {
    font-size: 12px;

    .btn-link {
      font-size: 12px;
    }
  }
`;

export default function Index() {
  const {
    sessionList,
    setFilterInterest,
    setFilterTutor,
    setFilterFavorite,
    loadingTable,
    rateSessionUid,
    setRateSessionUid,
    rateInterest,
    setFilterStatus
  } = useContext(SessionsContext);
  const { instanceUid } = useContext(LoginContext)
  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(false)

  const onFilter = useCallback((values) => {
    const filterInterest = _.has(values, 'interest') ? values.interest : null
    const filterTutor = _.has(values, 'tutor') ? values.tutor : null
    const filterFavorite = _.has(values, 'favorite') ? values.favorite : null
    const filterStatus = _.has(values, 'status') ? values.status : null

    setFilterInterest(filterInterest);
    setFilterTutor(filterTutor);
    setFilterFavorite(filterFavorite)
    setFilterStatus(filterStatus)
  });

  const [getRestrictions] = useLazyQuery(getStudentSessionRestrictionsQuery)

  useEffect(() => {
    async function doFecth() {
      setLoading(true)
      const list = await Promise.mapSeries(sessionList, async i => {
        const sessionUid = _.has(i, 'uid') ? i.uid : null
        const restrictions = await getRestrictions({
          variables: { sessionUid, tuteeUid: instanceUid }
        }).then(({ data }) => _.has(data, 'getStudentSessionRestrictions') ? data.getStudentSessionRestrictions : null)
        const enrolledStatus = _.has(restrictions, 'enrolledStatus') ? restrictions.enrolledStatus : null

        if (enrolledStatus !== 'CANCELLED')
          return { ...i }

        return { ...i, status: enrolledStatus }
      })

      setDataList(list)
      setLoading(false)
    }

    doFecth()
  }, [sessionList])

  const columns = useMemo(() => [
    {
      title: 'TUTOR',
      dataKey: 'uid',
      render: (uid, row) => <RenderTutor row={row} />,
    },
    {
      title: 'SCHEDULED DATE',
      dataKey: 'startDate',
      render: (startDate) => {
        const date = startDate && moment(startDate).format('ll');

        return date;
      },
    },
    {
      title: 'TIME',
      dataKey: 'startDate',
      center: true,
      render: (startDate, row) => {
        const endDate = _.has(row, 'endDate') ? row.endDate : null;
        const startTime = moment(startDate).format('hh:mm:ss a');
        const endTime = moment(endDate).format('hh:mm:ss a');
        const timeRange = `${startTime} - ${endTime}`;

        return timeRange;
      },
    },
    {
      title: 'INTEREST',
      dataKey: 'timeslot',
      render: (timeslot) => {
        const tutorialProfile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
        const interest = _.has(tutorialProfile, 'interest') ? tutorialProfile.interest : null;

        return interest;
      },
    },
    {
      title: 'STATUS',
      dataKey: 'status',
      render: (uid, row) => <RenderStatus row={row} />,
    },
    {
      title: 'ACTION',
      dataKey: 'uid',
      render: (uid, row) => <RenderAction row={row} />,
    },
  ]);

  const rateContextPayload = useMemo(() => ({
    sessionUid: rateSessionUid,
    setRateSessionUid,
    interest: rateInterest,
  }), [
    rateSessionUid,
    setRateSessionUid,
    rateInterest,
  ]);

  return (
    <>
      <h3 className="pb-3">Booking Summary</h3>
      <StyledRow>
        <Col>
          <Card>
            <Card.Header>
              <Filters onChange={onFilter} />
            </Card.Header>
            <Card.Body>
              <CustomTable
                columns={columns}
                dataValues={dataList}
                loading={loadingTable || loading}
              />

              <RateSessionContext.Provider value={rateContextPayload}>
                <RateSessionModal />
              </RateSessionContext.Provider>
            </Card.Body>
          </Card>
        </Col>
      </StyledRow>
    </>
  );
}

function RenderTutor({ row }) {
  const [fullName, setFullName] = useState(null);
  const [tutorUid, setTutorUid] = useState(null);
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const { tutorList, setTutorList } = useContext(SessionsContext);

  const { data: userTutorResult } = useQuery(getUserQuery, {
    skip: !tutorUserUid,
    client: RegistryClient,
    variables: { uid: tutorUserUid },
  });

  useEffect(() => {
    const timeslot = _.has(row, 'timeslot') ? row.timeslot : null;
    const createdBy = _.has(timeslot, 'createdBy') ? timeslot.createdBy : null;
    const tutorialProfile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
    const iTutorUid = _.has(tutorialProfile, 'tutorUid') ? tutorialProfile.tutorUid : null;

    setTutorUid(iTutorUid);
    setTutorUserUid(createdBy);
  }, [row]);

  useEffect(() => {
    const user = _.has(userTutorResult, 'getUser') ? userTutorResult.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const middleInitial = _.has(profile, 'middleInitial') ? profile.middleInitial : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const tutorName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;
    const found = _.find(tutorList, { uid: tutorUid });

    if (!found && tutorUid) {
      const newList = [
        ...tutorList, {
          uid: tutorUid,
          tutorName,
        },
      ];

      setTutorList(newList);
    }

    setFullName(tutorName);
  }, [userTutorResult]);

  return (
    <Link to={`/profile/${tutorUserUid}`}>
      <h2 className="table-avatar">
        <DynamicAvatar
          className="m-1"
          userUid={tutorUserUid}
          roleCode="TUTOR"
          size="small"
          noClick
        />
        <span className="m-1">
          {fullName}
        </span>
      </h2>
    </Link>
  );
}

function RenderStatus({ row }) {
  const sessionStatus = _.has(row, 'status') ? row.status : null

  if (sessionStatus === 'CANCELLED' || sessionStatus === 'COMPLETED')
    return sessionStatus

  const enrollees = _.has(row, 'enrollees') ? row.enrollees : []
  const enrollee = enrollees[0]
  let enrolleeStatus = _.has(enrollee, 'status') ? enrollee.status : null
  let status = null

  if (enrolleeStatus === 'ACTIVE')
    enrolleeStatus = 'UPCOMING'

  switch (enrolleeStatus) {
    case 'ACTIVE':
      status = 'UPCOMING'
      break;
    default:
      status = enrolleeStatus
  }

  return status
}

function RenderAction({ row }) {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [interest, setInterest] = useState(null);
  const [link, setLink] = useState(null);
  const [tutorUid, setTutorUid] = useState(null);
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const [status, setStatus] = useState(null);
  const [sessionUid, setSessionUid] = useState(null);
  const [sessionType, setSessionType] = useState(null);
  const [canJoin, setCanJoin] = useState(false);
  const { instanceUid, userUid } = useContext(LoginContext);
  const [attendanceUid, setAttendanceUid] = useState(null);
  const [attendanceStatus, setAttendanceStatus] = useState(null);
  const [studentFullname, setStudentFullname] = useState(null);
  const { tutorList, setTutorList } = useContext(SessionsContext);
  const [canCancel, setCanCancel] = useState(false);
  const [canRate, setCanRate] = useState(false);
  const [canResched, setCanResched] = useState(false);
  const [paymentUid, setPaymentUid] = useState(null)
  const [canEnd, setCanEnd] = useState(false)

  const { data: userTutorResult } = useQuery(getUserQuery, {
    skip: !tutorUserUid,
    client: RegistryClient,
    variables: { uid: tutorUserUid },
  });

  const { data: attendanceResult } = useQuery(getAttendanceQuery, {
    skip: !instanceUid || !sessionUid,
    variables: { sessionUid, instanceUid },
  });

  const { data: userStudentResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  const { data: enrolledSession } = useQuery(getStudentEnrolledSessionQuery, {
    skip: !sessionUid || !instanceUid,
    variables: { sessionUid, tuteeUid: instanceUid },
  });

  useEffect(() => {
    const uid = _.has(row, 'uid') ? row.uid : null;
    const timeslot = _.has(row, 'timeslot') ? row.timeslot : null;
    const createdBy = _.has(timeslot, 'createdBy') ? timeslot.createdBy : null;
    const tutorialProfile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
    const profileInterest = _.has(tutorialProfile, 'interest') ? tutorialProfile.interest : null;
    const iTutorUid = _.has(tutorialProfile, 'tutorUid') ? tutorialProfile.tutorUid : null;
    const startDate = _.has(row, 'startDate') ? row.startDate : null;
    const endDate = _.has(row, 'endDate') ? row.endDate : null;
    const startTime = moment(startDate).format('hh:mm a');
    const endTime = moment(endDate).format('hh:mm a');
    const timeRange = `${startTime} - ${endTime}`;
    const sessionStatus = _.has(row, 'status') ? row.status : null;
    const url = _.has(row, 'link') ? row.link : null;
    const type = _.has(tutorialProfile, 'sessionType') ? tutorialProfile.sessionType : null;
    const allowJoin = _.has(row, 'canJoin') ? row.canJoin : null;
    const iCanCancel = _.has(row, 'canCancel') ? row.canCancel : null;
    const iCanRate = _.has(row, 'canRate') ? row.canRate : null;
    const iCanResched = _.has(row, 'canResched') ? row.canResched : null;

    setTutorUid(iTutorUid);
    setSessionUid(uid);
    setTutorUserUid(createdBy);
    setInterest(profileInterest);
    setScheduleDate(startDate && moment(startDate).format('MMM Do, YY'));
    setScheduleTime(timeRange);
    setStatus(sessionStatus);
    setLink(url);
    setSessionType(type);

    if (sessionStatus === 'CANCELLED') {
      setCanJoin(false);
      setCanCancel(false);
      setCanRate(false);
      setCanResched(false);
    } else {
      setCanJoin(allowJoin);
      setCanCancel(iCanCancel);
      setCanRate(iCanRate);
      setCanResched(iCanResched);
    }
  }, [row]);

  useEffect(() => {
    const user = _.has(userTutorResult, 'getUser') ? userTutorResult.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const middleInitial = _.has(profile, 'middleInitial') ? profile.middleInitial : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const tutorName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;
    const found = _.find(tutorList, { uid: tutorUid })

    if (!found && tutorUid) {
      const newList = [
        ...tutorList, {
          uid: tutorUid,
          tutorName,
        },
      ];

      setTutorList(newList);
    }

    setFullName(tutorName);
  }, [userTutorResult]);

  useEffect(() => {
    const attendance = _.has(attendanceResult, 'getAttendance') ? attendanceResult.getAttendance : null;
    const uid = _.has(attendance, 'uid') ? attendance.uid : null;
    const attStatus = _.has(attendance, 'status') ? attendance.status : null;

    if (status !== 'CANCELLED') {
      setAttendanceStatus(attStatus)
    }

    setAttendanceUid(uid)
  }, [attendanceResult, status])

  useEffect(() => {
    const user = _.has(userStudentResult, 'getUser') ? userStudentResult.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const middleInitial = _.has(profile, 'middleInitial') ? profile.middleInitial : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const tutorName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;

    setStudentFullname(tutorName);
  }, [userStudentResult]);

  useEffect(() => {
    const enrolled = _.has(enrolledSession, 'getStudentEnrolledSession') ? enrolledSession.getStudentEnrolledSession : null;
    const uid = _.has(enrolled, 'paymentUid') ? enrolled.paymentUid : null;

    setPaymentUid(uid);
  }, [enrolledSession])

  useEffect(() => {
    if (interest === 'Outdoor' && attendanceStatus === 'ONGOING') {
      console.log({ attendanceStatus, status, interest, sessionUid })
      console.log({ canEnd: attendanceStatus === 'ONGOING' })
    }

    if (attendanceStatus === 'ONGOING')
      setCanEnd(true)
    else
      setCanEnd(false)
  }, [attendanceStatus, status, interest, sessionUid])

  const contextPayload = useMemo(() => ({
    studentFullname,
    tutorUid,
    tutorUserUid,
    sessionUid,
    interest,
    link,
    sessionType,
    canJoin,
    attendanceUid,
    scheduleDate,
    scheduleTime,
    fullName,
    attendanceStatus,
    status,
    paymentUid,
  }), [
    tutorUid,
    tutorUserUid,
    sessionUid,
    interest,
    link,
    sessionType,
    canJoin,
    attendanceUid,
    scheduleDate,
    scheduleTime,
    fullName,
    attendanceStatus,
    status,
    paymentUid,
  ]);

  return (
    <SessionRowContext.Provider value={contextPayload}>
      <Button
        className="text-info"
        variant="link"
        onClick={() => { navigate('/session', { state: { sessionUid } }); }}
      >
        <FontAwesomeIcon icon={solid('eye')} />
        {' '}
        View
      </Button>

      <ViewPayment />

      {canJoin && !attendanceStatus ? <JoinSession /> : ''}

      {/* {attendanceStatus === 'ONGOING' && <EndSession />} */}

      {canEnd && <EndSession />}

      {canResched && <RescheduleAction />}

      {canCancel && <CancelBooking />}

      {canRate && <RateSession onRated={() => { setCanRate(false); }} />}
    </SessionRowContext.Provider>
  );
}

function Filters(payload) {
  const { onChange } = payload;
  const formInstance = useForm();
  const { control, watch } = formInstance;
  const { tutorList } = useContext(SessionsContext);

  const watchInterest = watch('interest');
  const watchTutor = watch('tutor');
  const watchFavorite = watch('favorite')
  const watchStatus = watch('status')

  useMemo(() => {
    if (watchInterest || watchTutor || watchFavorite || watchFavorite === false || watchStatus) {
      onChange({
        interest: watchInterest === 'Filter Interest' ? null : watchInterest,
        tutor: watchTutor === 'Filter Tutor' ? null : watchTutor,
        favorite: watchFavorite || false,
        status: watchStatus === 'Filter Status' ? null : watchStatus
      });
    }
  }, [
    watchInterest,
    watchTutor,
    watchFavorite,
    watchStatus
  ]);

  return (
    <Form noValidate>
      <Row>
        <Col lg={{ span: 4 }}>
          <SelectInterest label="" placeholder="Filter Interest" {...formInstance} />
        </Col>
        <Col lg={{ span: 4 }}>
          <Form.Group as={Col} sm={12} controlId="select.tutor">
            <Controller
              name="tutor"
              control={control}
              render={({ field }) => (
                <Form.Select
                  {...field}
                >
                  <option value={undefined}>Filter Tutor</option>
                  {
                    tutorList.map((i) => {
                      const uid = _.has(i, 'uid') ? i.uid : null;
                      const title = _.has(i, 'tutorName') ? i.tutorName : null;

                      return <option value={uid}>{title}</option>;
                    })
                  }
                </Form.Select>
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={{ span: 4 }}>
          <Form.Group as={Col} sm={12} controlId="select.status">
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Form.Select
                  {...field}
                >
                  <option value={undefined}>Filter Status</option>
                  <option value={'COMPLETED'}>COMPLETED</option>
                  <option value={'UPCOMING'}>UPCOMING</option>
                  <option value={'CANCELLED'}>CANCELLED</option>
                  <option value={'RESCHEDULED'}>RESCHEDULED</option>
                </Form.Select>
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={{ span: 4 }}>
          <Form.Group as={Col} sm={12} controlId="select.favorite">
            <Controller
              name="favorite"
              control={control}
              render={({ field }) => (
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Favorite Tutor"
                  {...field}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

function RescheduleAction() {
  const navigate = useNavigate();
  const { sessionUid } = useContext(SessionRowContext);

  const redirectTo = useCallback(() => {
    navigate('/student/calendar', { state: { sessionUid } })
  });

  return (
    <Button
      variant="link"
      onClick={redirectTo}
    >
      <FontAwesomeIcon icon={solid('calendar-xmark')} />
      {' '}
      Reschedule
    </Button>
  );
}
