import { gql } from '@apollo/client';

export const GET_AVAILABLE_VOUCHERS = gql`
query GetAvailableVouchers($offset: Int, $limit: Int, $searchText: String $userUid: String) {
    getAvailableVouchers(offset: $offset, limit: $limit, searchText: $searchText, userUid: $userUid) {
      count
      rows {
        id
        uid
        code
        status
        startDate
        endDate
        requirement
        amount
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
        totalUsed
        __typename
      }
    }
  }  
`;
