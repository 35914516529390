import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { LoadingSpinner, AlertError } from '../../../components';
import TicketDetails from '../ticketDetails';
import TicketingClient from '../../../TicketingClient';
import TicketResponses from '../ticketResponses';
import ReplyTicket from '../replyTicket';
import { GET_TICKET } from '../gql';
import LoginContext from '../../login/login.context';
import useMarketingNotification from '../../notification/useMarketingNotification';
import useSupportNotification from '../../notification/useSupportNotification';
import useNotification from '../../notification/useNotification';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [dataResult, setDataResult] = useState([]);
  const [ticketThreadUid, setTicketThreadUid] = useState(null);
  const [replyError, setReplyError] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [createdByName, setCreatedByName] = useState(null);
  const [status, setStatus] = useState(null);
  const [ticketNumber, setTicketNumber] = useState(null);

  const locationState = _.has(location, 'state') ? location.state : null;
  const ticketUid = _.has(locationState, 'ticketUid') ? locationState.ticketUid : null;

  const { doInsertAuditTrail } = useCreateAuditTrail();
  const { sendNotification } = useNotification({ userUid });
  const { sendNotification: sendMarketingNotification } = useMarketingNotification({ userUid });
  const { sendNotification: sendSupportNotification } = useSupportNotification({ userUid });

  const {
    data, loading, error,
  } = useQuery(GET_TICKET, {
    client: TicketingClient,
    fetchPolicy: 'network-only',
    variables: {
      uid: ticketUid,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const result = _.has(data, 'getTicket') ? data.getTicket : null;
      const rticketThread = _.has(result, 'ticketThread') ? result.ticketThread : null;
      const rticketThreadUid = _.has(rticketThread, 'uid') ? rticketThread.uid : null;
      const rCreatedBy = _.has(result, 'createdBy') ? result.createdBy : null;
      const rCreatedByName = _.has(result, 'createdByName') ? result.createdByName : null;
      const rStatus = _.has(result, 'status') ? result.status : null;
      const rTicketNumber = _.has(result, 'ticketNumber') ? result.ticketNumber : null;

      setDataResult(result);
      setTicketThreadUid(rticketThreadUid);
      setCreatedBy(rCreatedBy);
      setCreatedByName(rCreatedByName);
      setStatus(rStatus);
      setTicketNumber(rTicketNumber);
    }
  }, [data, loading]);

  const handleSubmit = (message) => {
    const otherDetails = _.has(dataResult, 'otherDetails') ? dataResult.otherDetails : null;
    const userRole = _.has(otherDetails, 'userRole') ? otherDetails.userRole : null;

    // insert audit trail
    doInsertAuditTrail({
      action: 'RESPOND',
      changes: `Responded to ticket #${ticketNumber}`,
      module: 'Issues and Resolution',
    });

    // send notif
    if (userUid === createdBy) {
      sendMarketingNotification({
        title: 'Ticket Response',
        message: `${createdByName} has logged a response on Ticket #${ticketNumber}`,
        data: {
          urlPath: '/marketing-admin/issue-and-resolution/view',
          urlState: { ticketUid },
        },
      });

      sendSupportNotification({
        title: 'Ticket Response',
        message: `${createdByName} has logged a response on Ticket #${ticketNumber}`,
        data: {
          urlPath: '/support/issue-and-resolution/view',
          urlState: { ticketUid },
        },
      });
    } else {
      let rolePath = '';
      switch (userRole) {
        case 'TUTOR':
          rolePath = '/tutor';
          break;
        case 'TUTEE':
          rolePath = '/student';
          break;
        case 'PARTNER_MERCHANT':
          rolePath = '/partner-merchant';
          break;
        default:
          rolePath = '';
      }

      sendNotification({
        userUids: [createdBy],
        message: `An agent has logged a response on Ticket #${ticketNumber}`,
        title: 'Ticket Response',
        data: {
          urlPath: `${rolePath}/issue-and-resolution/view`,
          urlState: { ticketUid },
        },
      });
    }

    if (ticketThreadUid) {
      setNewMessage(message);
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <Button variant="link" onClick={() => navigate(-1)}>
          <i className="fa fa-arrow-circle-left" />
          {' '}
          Back to Ticket List
        </Button>
      </Col>
      <Col lg={12}>
        <div className="card">
          <div className="card-body">

            {error && <div className="d-flex justify-content-center"><p>{error.message}</p></div>}

            {loading && (
              <div className="d-flex justify-content-center">
                <LoadingSpinner loading={loading} />
              </div>
            )}

            {!error && !loading && dataResult && (
              <TicketDetails
                data={dataResult}
                ticketUid={ticketUid}
              />
            )}
          </div>
        </div>
      </Col>

      <Col lg={12}>
        <div className="card">
          <div className="card-header">

            <h4 className="card-title">
              <span className="text-muted">
                Ticket Response
              </span>
            </h4>
          </div>
          <div className="card-body">
            {replyError && (
            <AlertError
              error={replyError}
              title="Failed to reply"
              onClose={() => setReplyError(null)}
            />
            ) }

            { !loading && ticketThreadUid
              ? (
                <TicketResponses
                  ticketThreadUid={ticketThreadUid}
                  ticketUid={ticketUid}
                  userUid={userUid}
                  newMessage={newMessage}
                />
              )
              : <div className="d-flex m-auto">No response yet</div>}
            <div style={{ marginTop: 10 }}>

              { userUid === createdBy && ticketThreadUid && status === 'OPEN' && (
              <ReplyTicket
                onSubmit={handleSubmit}
                ticketUid={ticketUid}
                onError={(err) => setReplyError(err)}
              />
              )}
              { userUid !== createdBy && status === 'OPEN' && (
              <ReplyTicket
                onSubmit={handleSubmit}
                ticketUid={ticketUid}
              />
              )}
            </div>
          </div>
        </div>
      </Col>

    </Row>
  );
}
