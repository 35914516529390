import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button,
  Col, Form, Modal,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import RegistryClient from '../../../../RegistryClient';
import { saveFileMutation } from './gql';
import { LoginContext } from '../../../login';
import { getUserQuery } from '../gql';

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-video`;
const FILE_CATEGORY = 'DEMO_TEACHING';

const upload = (file, fileName, userUid) => new Promise((resolve, reject) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('userUid', userUid);
  formData.append('fileName', fileName);

  axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

export default function Index(payload) {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, setValue, reset, handleSubmit, setError,
  } = formPayload;
  const { errors } = formState;
  const { onCompleted } = payload

  const handleClose = useCallback(() => {
    setVisible(false);
    reset();
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateFileSave] = useMutation(saveFileMutation, {
    client: RegistryClient,
    awaitRefetchQueries: true,
    // update percentage progress accross the system
    refetchQueries: [
      {
        query: getUserQuery,
        client: RegistryClient,
        variables: { uid: userUid },
      }
    ],
    onCompleted: () => {
      onCompleted()
      handleClose();
    },
    onError: (err) => {
      setError('demo', { message: err.toString() }, { shouldFocus: true });
    },
  });

  const uploadFile = useCallback((value) => {
    async function doSubmit() {
      const { demo } = value;
      setLoading(true)

      const { name } = demo;
      const splitType = name && name.split('.');
      const ext = splitType.length && _.last(splitType);
      const fileName = `demo-teaching-video.${ext}`;

      await upload(demo, fileName, userUid).then((result) => {
        const { data } = result;

        mutateFileSave({
          variables: {
            userUid,
            fileCategory: FILE_CATEGORY,
            storage: { ...data },
          },
        });
      }).catch((err) => {
        const response = _.has(err, 'response') ? err.response : null
        const data = _.has(response, 'data') ? response.data : null
        const message = data || err.toString()

        setError('demo', { message }, { shouldFocus: true });
      });

      setLoading(false)
    }

    doSubmit();
  });

  const triggerUpload = useCallback(() => {
    handleSubmit(uploadFile)();
  });

  const validateFile = useCallback((file) => {
    const { size, type } = file;
    const maxMB = 500;
    const oneMB = 1024 * 1024;
    const allowFileType = 'video/mp4';
    const uploadedFileSize = size / oneMB;

    if (uploadedFileSize > maxMB) {
      return `Invalid file size, max ${maxMB}MB`;
    }

    if (_.lowerCase(type) > allowFileType) {
      return `Invalid file type, please upload ${allowFileType} file`;
    }

    return true;
  });

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={regular('edit')} />
      </Button>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Video File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Col} sm={12} controlId="reg.demo">
              <Form.Label>Demo Teaching Video</Form.Label>
              <Controller
                name="demo"
                control={control}
                rules={{
                  required: 'Video file is required.',
                  validate: { validateFile },
                }}
                render={() => (
                  <Form.Control
                    isInvalid={!!_.has(errors, 'demo')}
                    type="file"
                    onChange={(e) => {
                      const fileList = e.target.files;
                      setValue('demo', fileList[0]);
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'demo') ? errors.demo.message : 'Invalid demo teaching.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={triggerUpload} disabled={loading}>
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
