/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Card, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Ratings } from '../../../components';
import { SessionRowContext } from './sessions.context';
import { addReviewMutation, isFavoriteTutorQuery } from './gql';
import { LoginContext } from '../../login';

export default function Index(payload) {
  const [view, setView] = useState(false);
  const { userUid, instanceUid } = useContext(LoginContext);
  const {
    handleSubmit, formState, control, reset, setValue, watch
  } = useForm();
  const { errors } = formState;
  const { onRated } = payload;

  const {
    sessionUid,
    interest,
    tutorUid
  } = useContext(SessionRowContext);

  const { data: isFavResult } = useQuery(isFavoriteTutorQuery, {
    skip: !tutorUid || !instanceUid,
    variables: { tutorUid, tuteeUid: instanceUid }
  })

  useEffect(() => {
    const favorite = _.has(isFavResult, 'isFavoriteTutor') ? isFavResult.isFavoriteTutor : false

    setValue('favorite', favorite)
  }, [isFavResult])

  const handleShow = useCallback(() => {
    setView(true);
  }, [sessionUid]);

  const handleClose = useCallback(() => {
    setView(false);
    reset();
  }, [sessionUid]);

  const [mutateReview, { loading }] = useMutation(addReviewMutation, {
    onCompleted: () => {
      if (onRated) { onRated(); }

      handleClose();
    },
  });

  const submitForm = useCallback((props) => {
    mutateReview({ variables: { ...props, sessionUid, userUid } });
  }, [sessionUid]);

  const onRate = useCallback(() => {
    handleSubmit(submitForm)();
  });

  const watchFavorite = watch('favorite')

  return (
    <>
      <Button
        variant="link"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={solid('star')} />
        {' '}
        Rate
      </Button>

      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rate Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>
                Interest
                {' '}
                <small className="text-muted">{interest}</small>
              </Card.Title>
              <Card.Subtitle>Please rate your experience on your tutor.</Card.Subtitle>
              <br />
              <Form noValidate onSubmit={handleSubmit(submitForm)}>
                <Form.Group className="form-group" controlId="rate.review">
                  <Form.Label>Review</Form.Label>
                  <Controller
                    name="review"
                    control={control}
                    rules={{ required: 'Review is required.' }}
                    render={({ field }) => (
                      <Form.Control
                        autoFocus
                        isInvalid={!!_.has(errors, 'review')}
                        as="textarea"
                        rows={4}
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'review') ? errors.review.message : 'Invalid review.'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="rate.ratings">
                  <Form.Label className="form-control-label">Ratings</Form.Label>
                  <Controller
                    name="ratings"
                    control={control}
                    rules={{ required: 'Ratings is required.' }}
                    render={({ field }) => (
                      <Form.Control
                        isInvalid={!!_.has(errors, 'ratings')}
                        {...field}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        as={(item) => <Ratings {...item} size="xl" />}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'ratings') ? errors.ratings.message : 'Invalid ratings.'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="rate.favorite">
                  <Form.Label className="form-control-label">Favorite</Form.Label>
                  <Controller
                    name="favorite"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="checkbox"
                        label="Mark tutor as favorite"
                        isInvalid={!!_.has(errors, 'favorite')}
                        checked={watchFavorite}
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'favorite') ? errors.ratings.message : 'Invalid favorite.'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={onRate}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Join'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
