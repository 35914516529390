/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Button, Card, Col, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { Gcash, CreditCard } from './paymongo/index';
import CashinContext from './cashin.context';
import { getUserWalletQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import { LoginContext } from '../../login';
import { LoadingSpinner } from '../../../components';

const HeaderCard = styledComponents(Card)`
  .wallet-header__balance {
    font-size: 36px;
    margin: 0 16px 16px 0;
    display: inline-flex;
    vertical-align: middle;
    color: #00cc52;
    text-shadow: 0px 0px aliceblue;

    i.fa {
      margin-right: 8px;
      margin-top: 3px;
    }
  }

  .wallet-header__balance--low {
    color: #ffbc34;
  }

  .wallet-header__balance--zero {
    color: red;
  }

  .wallet-header__label {
    display: inline-flex;
    vertical-align: middle;
    margin-bottom: 16px;
  }

  .card-title {
    margin-bottom: 0px;
  }

  .card-text {
    text-shadow: 0px 0px aliceblue;
  }

  .wallet-loading {
    font-size: 21px;
    vertical-align: middle;
    color: #fd7e14;
    display: block;
  }
`;

const PaymentList = styledComponents.div`
  margin-bottom: 16px;
`;

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function Index() {
  const [payMethod, setPayMethod] = useState('GCASH')
  const [canConfirm, setCanConfirm] = useState(false)
  const [balance, setBalance] = useState(0)
  const [amount, setAmount] = useState(0)
  const navigate = useNavigate()
  const { userUid } = useContext(LoginContext)
  const [loading, setLoading] = useState(false)

  const [fetchWallet] = useLazyQuery(getUserWalletQuery, {
    client: PaymentClient,
  });

  useEffect(() => {
    async function doFetchWallet() {
      setLoading(true)

      await fetchWallet({ variables: { userUid } }).then(({ data }) => {
        const wallet = _.has(data, 'getUserWallet') ? data.getUserWallet : null;
        const remaining = _.has(wallet, 'balance') ? parseFloat(wallet.balance) : 0;

        setBalance(remaining.toFixed(2));
      })

      setLoading(false)
    }

    if (userUid) {
      doFetchWallet()
    }
  }, [userUid]);

  const {
    formState, control, watch,
  } = useForm({ mode: 'onBlur' });
  const { errors, isValid } = formState;

  useEffect(() => {
    setCanConfirm(isValid);
  }, [isValid]);

  const watchAmount = watch('amount');

  useEffect(() => {
    if (isValid) { setAmount(parseInt(watchAmount, 10)); } else { setAmount(0); }
  }, [isValid, watchAmount]);

  useEffect(() => {
    setBalance(parseFloat(0).toFixed(2));
  }, []);

  const validateAmount = (value) => {
    var reg = /^\d*\.?\d*$/
    const valid = reg.test(value)

    if (!valid) {
      return 'Invalid number format'
    }

    if (value < 100) {
      return 'Minimum amount of 100'
    }

    return true
  }

  const contextPayload = useMemo(() => ({
    balance,
    canConfirm,
    amount,
    loading
  }), [
    balance,
    canConfirm,
    amount,
    loading
  ]);

  return (
    <CashinContext.Provider value={contextPayload}>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0);" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cash In</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Cash In</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div className="card">
                <div className="card-body">

                  <div className="payment-widget">
                    <h4 className="card-title">Top up Value</h4>
                    <Form noValidate>
                      <Form.Group className="form-group card-label" as={Col} controlId="cashin.amount" lg={6}>
                        <Form.Label>Amount</Form.Label>
                        <Controller
                          name="amount"
                          control={control}
                          rules={{
                            required: 'Amount is required.',
                            // pattern: { value: /^\d+$/, message: 'Invalid format, please input a whole number' },
                            validate: validateAmount
                          }}
                          render={({ field }) => (
                            <Form.Control
                              autoFocus
                              isInvalid={!!_.has(errors, 'amount')}
                              {...field}
                            />
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {_.has(errors, 'amount') ? errors.amount.message : 'Invalid amount.'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="payment-widget">
                    <h4 className="card-title">Payment Method</h4>

                    <PaymentList className="payment-list">
                      <label className="payment-radio credit-card-option">
                        <input
                          type="radio"
                          name="radio"
                          checked={payMethod === 'GCASH'}
                          onClick={() => setPayMethod('GCASH')}
                        />
                        <span className="checkmark" />
                        GCASH
                      </label>
                      {payMethod === 'GCASH' && <Gcash />}
                    </PaymentList>

                    <PaymentList className="payment-list">
                      <label className="payment-radio credit-card-option">
                        <input
                          type="radio"
                          name="radio"
                          checked={payMethod === 'CREDIT_CARD'}
                          onClick={() => setPayMethod('CREDIT_CARD')}
                        />
                        <span className="checkmark" />
                        Credit Card
                      </label>
                      {payMethod === 'CREDIT_CARD' && <CreditCard />}
                    </PaymentList>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 theiaStickySidebar">
              <div className="card booking-card">
                <div className="card-header">
                  <h4 className="card-title">Wallet</h4>
                </div>

                <div className="card-body">
                  <HeaderCard className="wallet-header">
                    <Card.Body>
                      <Card.Title>
                        <span className="wallet-header__label text-secondary">Remaining Balance</span>
                        {' '}
                        <RenderBalance />
                      </Card.Title>
                      <Card.Text><RenderBalanceText /></Card.Text>
                    </Card.Body>
                  </HeaderCard>
                  <div className="d-grid gap-2">
                    <Button
                      variant="secondary"
                      size="lg"
                      onClick={() => navigate('/payments')}
                    >
                      <i className="fa fa-wallet" aria-hidden="true" />
                      {' '}
                      View Wallet
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CashinContext.Provider>
  );
}

function RenderBalance() {
  const { loading, balance } = useContext(CashinContext)

  if (loading) return <span className='wallet-loading'><LoadingSpinner /></span>

  if (balance < 1) {
    return (
      <span className="wallet-header__balance wallet-header__balance--zero">
        <i className="fa fa-wallet" aria-hidden="true" />
        {' '}
        ₱
        {balance}
      </span>
    );
  } if (balance < 200) {
    return (
      <span className="wallet-header__balance wallet-header__balance--low">
        <i className="fa fa-wallet" aria-hidden="true" />
        {' '}
        ₱
        {balance}
      </span>
    );
  }

  return (
    <span className="wallet-header__balance">
      <i className="fa fa-wallet" aria-hidden="true" />
      {' '}
      ₱
      {balance}
    </span>
  );
}

function RenderBalanceText() {
  const { loading, balance } = useContext(CashinContext)

  if (loading) return ''

  if (balance < 1) {
    return (
      <p className="text-danger">
        <FontAwesomeIcon icon={solid('exclamation-circle')} />
        {' '}
        You have zero balance remaining, please cash in.
      </p>
    );
  } if (balance < 200) {
    return (
      <p className="text-warning">
        <FontAwesomeIcon icon={solid('exclamation-circle')} />
        {' '}
        You have low balance remaining, please cash in.
      </p>
    );
  }

  return '';
}
