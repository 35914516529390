import React, {
  useCallback, useMemo, useState,
} from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import ls from 'local-storage';
import { useNavigate } from 'react-router-dom';
import Context from './login.context';
import auth from '../../authEmulatorConnect';

export default function Index({ children }) {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(ls.get('userId'));
  const [userUid, setUserUid] = useState(ls.get('userUid'));
  const [roleCode, setRoleCode] = useState(ls.get('roleCode'));
  const [instanceUid, setInstanceUid] = useState(ls.get('instanceUid'));
  const [tokenManager, setTokenManager] = useState(ls.get('tokenManage'));
  const [email, setEmail] = useState(null);
  const [emailVerified, setEmailVerified] = useState(true);
  const [emailSent, setEmailSent] = useState(ls.get('emailSent'));
  const [notifToken, setNotifToken] = useState(null)

  const clearLogin = () => {
    setUserId(null)
    setUserUid(null);
    setRoleCode(null);
    setTokenManager(null);
    setInstanceUid(null);
    setNotifToken(null)

    return true;
  };

  const logout = useCallback(() => {
    // User is signed out
    signOut(auth).then(() => {
      // Sign-out successful.
      ls.clear();
      clearLogin();

      navigate('/', { replace: true });
    });
  }, [userUid]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const {
        uid,
        email: iEmail,
        emailVerified: iEmailVerified,
        stsTokenManager,
      } = user;
      setUserUid(uid);
      setTokenManager(stsTokenManager);
      setEmail(iEmail);
      setEmailVerified(iEmailVerified);
    } else if (userUid) {
      logout();
    }
  });

  const contextPayload = useMemo(() => ({
    userId, setUserId,
    userUid, setUserUid,
    tokenManager,
    setTokenManager,
    roleCode,
    setRoleCode,
    clearLogin,
    instanceUid, setInstanceUid,
    email, emailVerified,
    logout,
    emailSent, setEmailSent,
    notifToken, setNotifToken
  }), [
    userId, setUserId,
    userUid, setUserUid,
    tokenManager, setTokenManager,
    roleCode, setRoleCode,
    clearLogin,
    instanceUid, setInstanceUid,
    email, emailVerified,
    logout,
    emailSent, setEmailSent,
    notifToken, setNotifToken
  ]);

  return (
    <Context.Provider value={contextPayload}>
      {children}
    </Context.Provider>
  );
}

Index.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
