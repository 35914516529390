import moment from 'moment';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import SessionContext from './sessions.context';
import StudentList from './studentList';
import LeaveSession from './leaveSession';
import JoinSession from './joinSession';
import EndSession from './endSession';
import { LoginContext } from '../login';
import { DynamicAvatar, TutorRating } from '../../components';
import RateSession from './rateSession';
import { getMySessionRatinQuery } from './gql';
import SharedNotes from './sharedNotes';
import PrivateNotes from './privateNotes';
import SessionProof from './sessionProof';
import RateSessionContext from '../../modules/rateSession/rateSession.context';
import RateSessionModal from '../../modules/rateSession';

const COMPLETED = 'COMPLETED';
const ONGOING = 'ONGOING';

export default function Index() {
  const navigate = useNavigate();
  const [canLeave, setCanLeave] = useState(false);
  const [canJoin, setCanJoin] = useState(false);
  const [canEnd, setCanEnd] = useState(false);
  const [canRate, setCanRate] = useState(false);
  const [canViewPrivate, setCanViewPrivate] = useState(false);
  const {
    sessionUid,
    tutorName,
    sessionStatus,
    interest,
    sessionType,
    startDate,
    endDate,
    attendanceStatus,
    location,
    tutorUid,
    tutorUserUid,
    tutorRoleCode,
  } = useContext(SessionContext);
  const [rateSessionUid, setRateSessionUid] = useState(null)

  const { roleCode, userUid } = useContext(LoginContext);

  const onBack = () => { navigate(-1); };

  const { refetch, data: mySessionResult } = useQuery(getMySessionRatinQuery, {
    skip: !userUid || !sessionUid,
    variables: { userUid, sessionUid },
  });

  useEffect(() => {
    if (roleCode === 'TUTEE') {
      const myRating = _.has(mySessionResult, 'getMySessionRating') ? mySessionResult.getMySessionRating : null;

      if (sessionStatus === COMPLETED && attendanceStatus === COMPLETED && !myRating) {
        setCanRate(true);
      } else {
        setCanRate(false);
      }

      setCanViewPrivate(false);
    } else {
      setCanViewPrivate(true);
    }
  }, [sessionStatus, attendanceStatus, roleCode, mySessionResult]);

  useEffect(() => {
    if (roleCode === 'TUTEE') {
      if (attendanceStatus === ONGOING) {
        setCanLeave(true);
      } else {
        setCanLeave(false);
      }

      if (!attendanceStatus) { setCanJoin(true); } else { setCanJoin(false); }
    }

    if (roleCode === 'TUTOR') {
      if (sessionStatus === ONGOING) {
        setCanEnd(true);
      } else {
        setCanEnd(false);
      }
    }
  }, [sessionStatus, attendanceStatus, roleCode]);

  const onRated = useCallback(() => {
    refetch();
  }, [refetch]);

  const rateContextPayload = useMemo(() => ({
    sessionUid: rateSessionUid,
    setRateSessionUid,
    interest,
  }), [
    rateSessionUid,
    setRateSessionUid,
    interest,
  ]);

  return (
    <>
      <div className="settings-back mb-3">
        <Button variant="link" onClick={onBack}>
          <i className="fas fa-long-arrow-alt-left" />
          {' '}
          <span>Back</span>
        </Button>
      </div>

      <div className="row">
        <div className="col-sm-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="mentor-widget">
                <div className="user-info-left align-items-center">
                  <div className="mentor-img d-flex flex-wrap justify-content-center">
                    <DynamicAvatar
                      userUid={tutorUserUid}
                      roleCode={tutorRoleCode}
                      size="large"
                      noClick
                    />

                    <TutorRating tutorUid={tutorUid} />
                    <div className="mentor-details m-0">
                      <p className="user-location m-0">
                        <i className="fas fa-map-marker-alt" />
                        {' '}
                        {location}
                      </p>
                    </div>
                  </div>
                  <div className="user-info-cont">
                    <h4 className="usr-name">{tutorName}</h4>
                    <p className="mentor-type">{interest}</p>
                  </div>
                </div>
                <div className="user-info-right d-flex align-items-end flex-wrap">
                  <div className="hireme-btn text-center">
                    <span className="hire-rate">
                      <p className="text-muted">Session Status</p>
                      {sessionStatus}
                    </span>

                    <RateSessionContext.Provider value={rateContextPayload}>
                      {canLeave && <LeaveSession />}
                      <RateSessionModal />
                    </RateSessionContext.Provider>

                    {canJoin && <JoinSession />}
                    {canEnd && <EndSession />}
                    {canRate && <RateSession onRated={onRated} tutorUid={tutorUid} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <Card.Title>Attendees</Card.Title>
              <StudentList />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{interest}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{sessionType}</Card.Subtitle>
              <Card.Text>
                <span className="text-muted">Schedule</span>
                {' '}
                {startDate && moment(startDate).format('ll')}
                {' '}
                {startDate && moment(startDate).format('h:mm a')}
                {' '}
                -
                {' '}
                {endDate && moment(endDate).format('h:mm a')}
              </Card.Text>
              <Card.Text>
                <span className="text-muted">Attendance</span>
                {' '}
                {attendanceStatus}
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Proof of Session</Card.Title>
              <SessionProof />
            </Card.Body>
          </Card>

          {
            canViewPrivate && (
              <Card>
                <Card.Body>
                  <Card.Title>Private Notes</Card.Title>
                  <PrivateNotes />
                </Card.Body>
              </Card>
            )
          }

          <Card>
            <Card.Body>
              <Card.Title>Shared Notes</Card.Title>
              <SharedNotes />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
}
