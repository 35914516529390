import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { DashboardTemplate } from '../../template/components';
import AuditTrailClient from '../../AuditTrailClient';
import { GET_PAGINATED_AUDIT_TRAIL } from './gql';
import { CustomTable, DatePicker } from '../../components';

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function Index() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchText, setSearchText] = useState(null);

  const { data, loading } = useQuery(GET_PAGINATED_AUDIT_TRAIL, {
    client: AuditTrailClient,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      filter: {
        appCode: APPCODE,
        dateRange: startDate && endDate ? {
          startDate : startDate ? moment(startDate).format('YYYY-MM-DD'): null,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        } : null,
      },
    },
  });

  const result = _.has(data, 'getPaginatedAuditTrail') ? data.getPaginatedAuditTrail : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Date Modified',
      dataKey: 'createdAt',
      render: (text) => moment(text).isValid() && moment(text).format('LLL'),
    },
    {
      title: 'Module',
      dataKey: 'module',
    },
    {
      title: 'Action',
      dataKey: 'action',
    },
    {
      title: 'Changes',
      dataKey: 'changes',
    },
    {
      title: 'Modified By',
      dataKey: 'userFullName',
    },
  ];

  const debounceSearch = _.debounce((value) => setSearchText(value), 500) 

  const onSearch = (value) => {
    setPage(1);
    debounceSearch(value)
  };

  return (
    <DashboardTemplate>
      <Row>
        <Col lg={12}>
          <h3 className="page-title mb-4">Audit Trail</h3>
        </Col>
        <Col lg={12}>
          <Row className="justify-content-end mb-4">
            <Col lg={3}>
              <form className="search-form custom-search-form">
                <div className="input-group">
                  <input
                    type="text"
                    onChange={(e) => onSearch(e.target.value)}
                    placeholder="Search here"
                    className="form-control"
                  />
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fa fa-search" />

                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Col>
        <Col lg={12}>
          <Row className="pb-4">
            <Col lg={3} style={{ zIndex:"999" }}>
              <DatePicker
                placeholderText="Select Dates"
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12}>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <CustomTable
                  loading={loading}
                  columns={columns}
                  page={page}
                  pageSize={pageSize}
                  totals={rowCount}
                  dataValues={dataResult}
                  onPageChange={(nPage) => setPage(nPage)}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
