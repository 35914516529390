/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from '../../../components';
import loginContext from '../../login/login.context';
import { generatePayslipMutation } from './gql';
import PaymentClient from '../../../PaymentClient';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';

export default function Index() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userUid } = useContext(loginContext);
  const { state } = useLocation();
  const payrollUid = _.has(state, 'uid') ? state.uid : null;

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const onClose = () => {
    setVisible(false);
  };

  const onShow = () => {
    setVisible(true);
  };

  const [generatePayslip] = useMutation(generatePayslipMutation, {
    client: PaymentClient,
    refetchQueries: () => ['getPayrollQuery'],
    onCompleted() {
      onClose();
    },
    onError() {
      setLoading(false);
    },
  });

  const onSubmit = useCallback(() => {
    async function doSubmit() {
      setLoading(true);

      await generatePayslip({ variables: { payrollUid } }).then((result => {
        const data = _.has(result, 'data') ? result.data : null
        const dataResult = _.has(data, 'generatePayslips') ? data.generatePayslips : null
        const startDate = _.has(dataResult, 'startDate') ? moment(dataResult.startDate).format('LL') : null
        const endDate = _.has(dataResult, 'endDate') ? moment(dataResult.endDate).format('LL') : null
        const status = _.has(dataResult, 'status') ? dataResult.status : null

        if (status === 'PAYSLIP_GENERATED') {
          // audit trail
          doInsertAuditTrail({
            module: 'Payroll',
            changes: `${userFullName} generated payslip for ${startDate} - ${endDate}`,
            action: 'CREATE',
          });
        }
      }))


      setLoading(false);
    }

    doSubmit();
  }, [userUid, generatePayslip]);

  return (
    <>
      <div className="d-grid gap-2">
        <Button variant="secondary" block onClick={onShow}>
          <FontAwesomeIcon icon={solid('file-circle-plus')} />
          {' '}
          Generate Payslip
        </Button>
      </div>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Payslip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">
            <FontAwesomeIcon icon={solid('exclamation-circle')} />
            {' '}
            You are about to generate a payslip.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Cancel
          </Button>
          <Button variant="secondary" onClick={onSubmit} disabled={loading}>
            {
              loading ? (<LoadingSpinner />) : (
                <>
                  <FontAwesomeIcon icon={solid('circle-check')} />
                  {' '}
                  Confirm
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
