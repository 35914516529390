import moment from 'moment';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { CustomTable } from '../../../components';
import ViewModal from './viewModal';
import { getUserWalletQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import WalletContext, { RowContext } from './wallet.context';

const PAYMENT = 'PAYMENT';

export default function Index() {
  const [payments, setPayments] = useState([]);
  const [paymentUid, setPaymentUid] = useState(null);
  const { walletUserUid } = useContext(WalletContext);
  const formPayload = useForm();
  const {
    formState, control, watch,
  } = formPayload;
  const { errors } = formState;
  const watchSearch = watch('paymentUid');

  useEffect(() => {
    setPaymentUid(watchSearch);
  }, [watchSearch]);

  const { loading, data: walletResult, refetch: refetchWallet } = useQuery(getUserWalletQuery, {
    client: PaymentClient,
    variables: {
      userUid: walletUserUid,
      action: PAYMENT,
      paymentUid,
    },
    pollInterval: 10000,
  });

  useEffect(() => {
    const wallet = _.has(walletResult, 'getUserWallet') ? walletResult.getUserWallet : null;
    const soas = _.has(wallet, 'soas') ? wallet.soas : [];

    setPayments(soas);
  }, [walletResult]);

  const columns = useMemo(() => [
    {
      title: 'Payment UID',
      dataKey: 'document',
      render: (document) => {
        const uid = _.has(document, 'paymentUid') ? document.paymentUid : null;

        return uid;
      },
    },
    {
      title: 'Transaction Type',
      dataKey: 'action',
      center: true,
      render: (transactionType, row) => {
        const { document } = row;
        const uid = _.has(document, 'paymentUid') ? document.paymentUid : null;
        const userUid = _.has(row, 'createdBy') ? row.createdBy : null;
        const paymentStatus = _.has(document, 'paymentStatus') ? document.paymentStatus : null;

        return (
          <ViewModal
            transactionType={transactionType}
            paymentUid={uid}
            userUid={userUid}
            paymentStatus={paymentStatus}
          />
        );
      },
    },
    { title: 'Current', dataKey: 'currentBalance' },
    { title: 'Previous', dataKey: 'previousBalance' },
    {
      title: 'Amount',
      dataKey: 'amount',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return value && <span className="text-info">{amount}</span>;
      },
    },
    {
      title: 'Date',
      dataKey: 'createdAt',
      render: (value) => value && moment(value).format('lll'),
    },
  ]);

  const contextPayload = useMemo(() => ({
    refetchWallet,
  }), [refetchWallet]);

  return (
    <RowContext.Provider value={contextPayload}>
      <Form className="mb-3">
        <Row>
          <Form.Group as={Col} xs={{ span: 6 }} controlId="history.paymentUid">
            <Form.Label>Payment UID</Form.Label>
            <Controller
              name="paymentUid"
              control={control}
              render={({ field }) => (
                <Form.Control
                  isInvalid={!!_.has(errors, 'paymentUid')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'paymentUid') ? errors.paymentUid.message : 'Payment UID.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Form>
      <CustomTable
        columns={columns}
        dataValues={payments}
        loading={loading}
        emptyText={(
          <h4 style={{ color: '#757575' }}>
            <FontAwesomeIcon icon={solid('face-tired')} />
            {' '}
            Empty Result! Please select a subscriber.
          </h4>
        )}
      />
    </RowContext.Provider>
  );
}
