/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */
export const getStudentRatingsQuery = gql`
  query GetStudentRatings($tuteeUid: ID!) {
    getStudentRatings(tuteeUid: $tuteeUid) {
      id
      uid
      sessionUid
      tuteeUid
      feedback
      ratings
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      session {
        id
        uid
        timeslot {
          id
          uid
          tutorialProfile {
            id
            uid
            interest
            tutor {
              id
              uid
              others
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getStudentFinalRatingQuery = gql`
  query GetStudentFinalRating($tuteeUid: ID!) {
    getStudentFinalRating(tuteeUid: $tuteeUid)
  }
`;
/**
 * End of Query
 */

/**
 * Mutation
 */

/**
 * End of Mutation
 */
