import React, { useState } from 'react';
import {
  Button, Row, Col, Tab, Nav,
} from 'react-bootstrap';
// import _ from 'lodash';
import CommissionList from './commissionList';
import DefaultCommissionModal from './defaultCommissionModal';
import UserList from './userList'

export default function Index() {
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('PARTNER_MERCHANT');

  return (
    <div>
      <h3 className="page-title mb-4">Learnlive Fees Management</h3>
      <Row className="justify-content-end mb-4 px-2">
        <Col lg={3} align='right' className='d-grid'>
          <Button
            onClick={() => setShowCommissionModal(true)}
            type="button"
          >
            Set Commission
          </Button>
        </Col>
      </Row>
      <Tab.Container defaultActiveKey={selectedRole} fill>
        <Row>
          <Col lg={12}>
            <Nav
              variant="pills"
              className="flex-row nav nav-tabs nav-tabs-solid bg-transparent"
              onSelect={(selectedKey) => setSelectedRole([selectedKey])}

            >
              <Nav.Item>
                <Nav.Link eventKey="PARTNER_MERCHANT">Partner Merchant</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="TUTOR">Freelance Tutors</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={12}>
            <Tab.Content>
              <Tab.Pane eventKey="PARTNER_MERCHANT">
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body">
                      <UserList
                        selectedRole={selectedRole}
                      />
                    </div>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="TUTOR">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <CommissionList roleCode="TUTOR" type="DEFAULT" />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      {showCommissionModal && (
        <DefaultCommissionModal
          show={showCommissionModal}
          onHide={() => setShowCommissionModal(false)}
        />
      )}
    </div>
  );
}
