import { useMutation, useQuery } from '@apollo/client';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import _ from 'lodash';
import TutorialProfile from './tutorialProfile';
import MyProfile from './personalProfile';
import { DashboardTemplate } from '../../../template/components';
import MyProfileContext from './myprofile.context';
import { LoginContext } from '../../login';
import { addTutorialProfileQuery, getTutorialProfilesQuery } from './gql';
import Qualifications from './qualifications';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';

export { default as MyProfileContext } from './myprofile.context';
export {
  default as Qualifications,
  Settings as QualificationSettings,
} from './qualifications';

export default function Index() {
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState('myProfile');
  const [tutorials, setTutorials] = useState([]);
  const { instanceUid, userUid } = useContext(LoginContext);
  const navigate = useNavigate();

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  useEffect(() => {
    switch (hash) {
      case '#tutorials': setActiveTab('tutorialProfile');
        break;
      case '#qualifications': setActiveTab('qualifications');
        break;
      default: setActiveTab('myProfile');
    }
  }, [setActiveTab]);

  const onSelectTab = useCallback((tab) => {
    setActiveTab(tab);
  }, setActiveTab);

  /**
   * Retrieve tutorials
   */
  const { data, refetch } = useQuery(getTutorialProfilesQuery, {
    skip: !instanceUid,
    variables: { tutorUid: instanceUid },
  });

  useEffect(() => {
    const tutorialResults = _.has(data, 'getTutorialProfiles') ? data.getTutorialProfiles : [];

    setTutorials(tutorialResults || []);
  }, [data]);
  /**
  * End of Retrieve tutorials
  */

  const [mutateAddTP, { loading: loadingAddTP }] = useMutation(addTutorialProfileQuery, {
    onCompleted: (result) => {
      const { addTutorialProfile: data } = result;
      // insert audit trail
      doInsertAuditTrail({
        action: 'CREATE',
        changes: `${userFullName} added ${data.interest} to his/her tutorial profile`,
        module: 'Tutorial Profile',
      });

      refetch()

      navigate('/tutor/timeslots', { state: { ...data } });
    },
  });

  const onAddTutorialProfile = useCallback((payload) => {
    const { price, ...etc } = payload;
    const variables = {
      ...etc, price: parseFloat(price), createdBy: userUid, instanceUid,
    };

    mutateAddTP({ variables });
  });

  const contextPayload = useMemo(() => ({
    tutorials,
    onAddTutorialProfile,
    loadingAddTP,
  }), [
    tutorials,
    onAddTutorialProfile,
    loadingAddTP,
  ]);

  return (
    <MyProfileContext.Provider value={contextPayload}>
      <DashboardTemplate>
        <Nav
          className="nav nav-tabs nav-justified mb-3"
          variant="pills"
          activeKey={activeTab}
          onSelect={onSelectTab}
        >
          <Nav.Item>
            <Nav.Link href="#profile" eventKey="myProfile">Personal Profile</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#qualifications" eventKey="qualifications">Qualifications</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#tutorials" eventKey="tutorialProfile">Tutorial Profiles</Nav.Link>
          </Nav.Item>
        </Nav>

        {activeTab === 'myProfile' && (<MyProfile />)}
        {activeTab === 'qualifications' && (<Qualifications />)}
        {activeTab === 'tutorialProfile' && (<TutorialProfile />)}
      </DashboardTemplate>
    </MyProfileContext.Provider>
  );
}
