import React, { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash'

export default ({ onSubmit }) => {
  const [show, setShow] = useState(false);
  const { handleSubmit, formState, control, reset } = useForm();
  const { errors } = formState;

  const handleClose = () => {
    setShow(false)
    reset()
  };
  const handleShow = () => setShow(true);

  const submitForm = useCallback((data) => {
    onSubmit({ ...data })
  });

  const triggerSubmit = () => {
    handleSubmit(submitForm)();
  };

  return (
    <>
      <Button className='edit-link' variant="link" onClick={handleShow}>
        <i className="fa fa-plus-circle" /> Add Tutorial Profile
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Tutorial Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group className="form-group" controlId="slot.title">
              <Form.Label>Interest</Form.Label>
              <Controller
                name="interest"
                control={control}
                rules={{ required: 'Interest is required.' }}
                render={({ field }) => (
                  <Form.Select
                    autoFocus
                    isInvalid={!!_.has(errors, 'interest')}
                    {...field}
                  >
                    <option>Select Interest</option>
                    <option value="Swimming">Swimming</option>
                    <option value="Dancing">Dancing</option>
                    <option value="Cooking">Cooking</option>
                    <option value="DIY Home Improvement">DIY Home Improvement</option>
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'interest') ? errors.interest.message : 'Invalid interest.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" controlId="slot.title">
              <Form.Label>Session Type</Form.Label>
              <Controller
                name="sessionType"
                control={control}
                rules={{ required: 'Session type is required.' }}
                render={({ field }) => (
                  <Form.Select
                    autoFocus
                    isInvalid={!!_.has(errors, 'sessionType')}
                    {...field}
                  >
                    <option>Select Session Type</option>
                    <option value="SINGLE">One on One</option>
                    <option value="GROUP">Group</option>
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'sessionType') ? errors.sessionType.message : 'Invalid session tpye.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" controlId="slot.title">
              <Form.Label>Price</Form.Label>
              <Controller
                name="price"
                control={control}
                rules={{
                  required: 'Price is required.',
                  pattern: {
                    value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                    message: 'Invalid price input.'
                  }
                }}
                render={({ field }) => (
                  <Form.Control
                    autoFocus
                    isInvalid={!!_.has(errors, 'price')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'price') ? errors.price.message : 'Invalid price.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={triggerSubmit} >Create</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}