import { useMutation, useQuery } from '@apollo/client';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import client from '../../../RegistryClient';
import { registerUserMutation } from '../gql';
import RegistrationContext from './registration.context';
import RegistrationForm from './registrationForm';
import { countRemainingSystemVoucherQuery, createRegistrationVoucherMutation } from './gql';
import PaymentClient from '../../../PaymentClient';
import { LoginContext } from '../../login';

const ROLE_CODE = 'TUTEE';
const VOUCHER_TYPE_REGISTRATION = 'REGISTRATION';
const VOUCHER_LIMIT = 100;

export default function Index() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [voucherCount, setVoucherCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { userUid: loginUserUid } = useContext(LoginContext);

  useEffect(() => {
    if (loginUserUid) {
      navigate('/');
    }
  }, [loginUserUid]);

  // check if valid for registration voucher
  const {
    data: voucherRemainingResult,
    loading: countLoading,
  } = useQuery(countRemainingSystemVoucherQuery, {
    client: PaymentClient,
    variables: { type: VOUCHER_TYPE_REGISTRATION },
  });

  useEffect(() => {
    const count = _.has(voucherRemainingResult, 'countRemainingSystemVoucher') ? voucherRemainingResult.countRemainingSystemVoucher : null;

    setVoucherCount(count);
  }, [voucherRemainingResult]);

  const [
    mutateVoucher,
    { loading: createVoucherLoading },
  ] = useMutation(createRegistrationVoucherMutation, {
    client: PaymentClient,
    onError: (err) => {
      setError(JSON.stringify(err));
    },
  });

  const [register, { loading: regLoading }] = useMutation(registerUserMutation, {
    client,
    onCompleted: async (result) => {
      const { registerUser: data } = result;

      if (data) {
        const userUid = _.has(data, 'uid') && data.uid;
        const email = _.has(data, 'email') && data.email;
        const userProfile = _.has(data, 'userProfile') && data.userProfile;
        const firstName = _.has(userProfile, 'firstName') && userProfile.firstName;
        // const props = {
        //   userUid,
        //   status: _.has(data, 'status') && data.status,
        //   others: {
        //     email: _.has(data, 'email') && data.email,
        //     firstName: _.lowerCase(firstName),
        //     middleInitial: _.has(userProfile, 'middleInitial') && _.lowerCase(userProfile.middleInitial),
        //     lastName: _.has(userProfile, 'lastName') && _.lowerCase(userProfile.lastName),
        //   },
        // };

        if (voucherCount < VOUCHER_LIMIT) {
          const voucherPayload = {
            createdBy: userUid,
            recepientEmail: email,
            recepientUserUid: userUid,
            recepientName: firstName,
          };

          mutateVoucher({ variables: voucherPayload });
        }

        /**
         * @todo: Disable this if pubsub and http endpoint is avaible in Google.
         * Billing problem in Google as of now. This should be run in background through pubsub
         */
        // pushToLearnliveCore({ ...props });

        navigate('/upload-id', { state: { userUid } });
      }
    },
    onError: (err) => {
      console.log({ err })
      const message = _.has(err, 'message') ? err.message : err.toString()
      setError(message);
    },
  });

  useEffect(() => {
    setLoading(countLoading || regLoading || createVoucherLoading);
  }, [countLoading, regLoading, createVoucherLoading]);

  const onSubmit = useCallback((data) => {
    const {
      address1, address2, city, postalCode, countryCode, password,
      gradeLevel, interest, ...etc
    } = data;

    const address = {
      address1, address2, city, postalCode, countryCode,
    };

    const others = { gradeLevel, interest };

    register({
      variables: {
        ...etc, password, roleCode: ROLE_CODE, address, others,
      },
    });
  }, [register]);

  const regContextPayload = useMemo(() => ({
    onSubmit,
    error,
    setError,
    loading,
  }), [
    onSubmit,
    error, setError,
    loading,
  ]);

  return (
    <RegistrationContext.Provider value={regContextPayload}>
      <RegistrationForm />
    </RegistrationContext.Provider>
  );
}
