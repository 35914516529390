/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Button, Modal, Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
// import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getPaymentQuery } from './gql';
import PaymentClient from '../../../PaymentClient';

export default function Index(payload) {
  const { paymentUid } = payload;
  const [visible, setVisible] = useState(false);
  const [referenceUid, setReferenceUid] = useState(false);
  const [dateTime, setDateTime] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [amount, setAmount] = useState(0);

  const { data: paymentResult } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid },
  });

  useEffect(() => {
    if (paymentResult) {
      const iPayment = _.has(paymentResult, 'getPayment') ? paymentResult.getPayment : null;
      const iPaymentMethod = _.has(iPayment, 'paymentMethod') ? _.toUpper(iPayment.paymentMethod) : null;
      const iAmount = _.has(iPayment, 'amount') ? iPayment.amount : null;
      const createdAt = _.has(iPayment, 'createdAt') ? moment(iPayment.createdAt).format('lll') : null;

      setReferenceUid(paymentUid);
      setPaymentMethod(iPaymentMethod);
      setAmount(iAmount);
      setDateTime(createdAt);
    }
  }, [paymentResult]);

  const onShow = useCallback(() => {
    setVisible(true);
  });

  const onClose = useCallback(() => {
    setVisible(false);
  });

  return (
    <>
      <Button variant="link" onClick={onShow}>CASHIN</Button>
      <Modal
        show={visible}
        onHide={onClose}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('barcode')} />
                {' '}
                Reference #
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{referenceUid}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('cash-register')} />
                {' '}
                Payment
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{paymentMethod}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('calendar-day')} />
                {' '}
                Date
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{dateTime}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Total Amount
              </p>
            </Col>
            <Col lg={8}>
              <h3 className="text-info">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small>{amount && amount.toFixed(2)}</small>
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
