import { useQuery } from '@apollo/client';
import React from 'react';
import { LoadingSpinner } from '../../../components'
import { GET_TOTAL_REVIEWS } from './gql';
import _ from 'lodash'

export default function TotalReviews({ userUid }) {
  const { loading, data } = useQuery(GET_TOTAL_REVIEWS, {
    variables: {
      userUid
    }
  })
  const result = _.has(data, 'getStudentReviewCount') ? data.getStudentReviewCount : 0;

  return (
    <div className="dash-widget" style={{ height: '100%' }}>
      <div className="circle-bar">
        <div className="icon-col">
          <i className="fas fa-file" />
        </div>
      </div>
      <div className="dash-widget-info">
        <h3>
          {!loading ? result : <LoadingSpinner text=''/>}
        </h3>
        <div>
          <h5>Total Reviews</h5>
        </div>
      </div>
    </div>
  );
}
