import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GET_PAGINATED_TICKET_THREAD_MESSAGES } from '../gql';
import TicketingClient from '../../../TicketingClient';
import { LoadingSpinner } from '../../../components';

export default function Index({
  ticketThreadUid, ticketUid, userUid, newMessage,
}) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(5);
  const [dataList, setDataList] = useState([]);
  const [totalRow, setTotalRow] = useState(0);

  const { data, loading, startPolling, stopPolling } = useQuery(GET_PAGINATED_TICKET_THREAD_MESSAGES, {
    client: TicketingClient,
    fetchPolicy: 'cache-and-network',
    variables: {
      ticketThreadUid,
      offset: 0,
      limit: pageSize,
    },
  });

  const [fetchMessages] = useLazyQuery(GET_PAGINATED_TICKET_THREAD_MESSAGES, {
    client: TicketingClient,
    fetchPolicy: 'cache-and-network',
    onCompleted: (iData) => {
      const result = _.has(iData, 'getPaginatedTicketThreadMessages') ? iData.getPaginatedTicketThreadMessages : null;
      const messages = _.has(result, 'rows') ? result.rows : [];
      const rowCount = _.has(result, 'count') ? result.count : 0;

      setDataList([...dataList, ...messages])
      setTotalRow(rowCount)
    }
  })

  useEffect(() => {
    startPolling(1000)

    return () => {
      stopPolling()
    }
  }, [])


  useEffect(() => {
    const result = _.has(data, 'getPaginatedTicketThreadMessages') ? data.getPaginatedTicketThreadMessages : null;
    const messages = _.has(result, 'rows') ? result.rows : [];
    const rowCount = _.has(result, 'count') ? result.count : 0;

    const newDataList = dataList.length > 0 ? dataList.slice(5) : dataList
    setDataList([...messages, ...newDataList])
    setTotalRow(rowCount);

    return () => {
      setDataList([]);
      setTotalRow(0);
    };
  }, [loading, data, ticketThreadUid]);

  return (
    <div style={{ padding: 10 }}>
      <Row
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          // style={{ background: '#f5f5f6' }}
          background: '#f5f5f6',
        }}
      >
        <InfiniteScroll
          inverse
          scrollableTarget="scrollableDiv"
          dataLength={dataList.length}
          next={() => {
            setTimeout(() => {
              const newPage = page + 1
              setPage(newPage);
              fetchMessages({
                variables: {
                  ticketThreadUid,
                  offset: pageSize ? pageSize * (newPage - 1) : 0,
                  limit: pageSize,
                }
              })
            }, 1500);
          }}
          hasMore={dataList.length < totalRow}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            overflowX: 'hidden',

          }}
          loader={<div className="text-center"><LoadingSpinner /></div>}
          endMessage={dataList.length === totalRow
            && <div className="flex justify-center py-2 text-gray-400 text-italic">-- No more message -- </div>}
        >

          {dataList.length > 0 && dataList.map((item) => {
            const id = _.has(item, 'id') ? item.id : null;
            const createdBy = _.has(item, 'createdBy') ? item.createdBy : null;
            const createdByName = _.has(item, 'createdByName') ? item.createdByName : null;
            const createdAt = _.has(item, 'createdAt') ? item.createdAt : null;
            const message = _.has(item, 'message') ? item.message : null;

            return (
              <Col lg={12} className="pb-4" key={`message-${id}`}>
                <Row
                  className={`${createdBy !== userUid ? 'justify-content-start' : 'justify-content-end'}`}
                >
                  <Col lg={8} xs={10}>
                    <div className="px-2 text-xs font-thin chat-recipient">{createdByName}</div>
                    <div
                      className={`chat-box d-grid px-2 pb-2 rounded ${createdBy !== userUid ? 'bg-white' : 'bg-primary-light'}`}
                      style={{
                        backgroundColor: '#fff',
                      }}
                    >
                      <div className="p-2 chat-message">
                        <span>{message}</span>
                      </div>
                      <div
                        className="flex justify-start px-2 text-xs chat-info"
                      >
                        <div className="chat-time text-muted">

                          {moment(createdAt).format('llll')}
                        </div>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </InfiniteScroll>
      </Row>
    </div>
  );
}
