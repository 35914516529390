import { useMutation } from '@apollo/client';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import RegisterTutor from './registerForm';
import RegistryClient from '../../../RegistryClient';
import { registerUserMutation } from './gql';
import RegistrationContext from './registration.context';
import { LoginContext } from '../../login';

const ROLE_CODE = 'TUTOR';

export default function Index() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { userUid } = useContext(LoginContext);

  useEffect(() => {
    if (userUid) {
      navigate('/');
    }
  }, [userUid]);

  const [resgister, { loading }] = useMutation(registerUserMutation, {
    client: RegistryClient,
    onCompleted: async (result) => {
      const { registerUser: data } = result;

      if (data) {
        // const userProfile = _.has(data, 'userProfile') && data.userProfile;
        // const firstName = _.has(userProfile, 'firstName') && _.lowerCase(userProfile.firstName);
        // const middleInitial = _.has(userProfile, 'middleInitial') && _.lowerCase(userProfile.middleInitial);
        // const lastName = _.has(userProfile, 'lastName') && _.lowerCase(userProfile.lastName);
        // const email = _.has(data, 'email') && data.email;
        // const props = {
        //   userUid: _.has(data, 'uid') && data.uid,
        //   status: _.has(data, 'status') && data.status,
        //   others: {
        //     email,
        //     firstName,
        //     middleInitial,
        //     lastName,
        //   },
        // };
      }

      navigate('/login');
    },
    onError: (err) => {
      const message = _.has(err, 'message') ? err.message : err.toString()
      setError(message);
    },
  });

  const onSubmit = useCallback((data) => {
    const {
      address1, address2, city, postalCode, countryCode, password, ...etc
    } = data;

    const address = {
      address1, address2, city, postalCode, countryCode,
    };

    resgister({
      variables: {
        ...etc, password, roleCode: ROLE_CODE, address,
      },
    });
  }, [resgister]);

  const regContextPayload = useMemo(() => ({
    onSubmit,
    error,
    setError,
    loading,
  }), [
    onSubmit,
    error, setError,
    loading,
  ]);

  return (
    <RegistrationContext.Provider value={regContextPayload}>
      <RegisterTutor />
    </RegistrationContext.Provider>
  );
}
