import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import TutorsTable from './tutorsTable';
import { GET_ALL_WANTATTENDVIBALCERT, GET_PAGINATED_TUTORS } from './gql';
import { Pagination } from '../../../components';
import RegistryClient from '../../../RegistryClient';

export default function tutorsList({ searchText }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedRating, setSelectedRating] = useState(null);
  const [showWantAttendVibalCert, setShowWantAttendVibalCert] = useState(false);
  const [tutorUserUids, setTutorUserUids] = useState([]);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  const [getAllWantAttendVibalCert] = useLazyQuery(GET_ALL_WANTATTENDVIBALCERT, {
    client: RegistryClient,
    onCompleted: (results) => {
      const userUids = _.has(results, 'getAllWantAttendVibalCert') ? results.getAllWantAttendVibalCert : [];
      setTutorUserUids(userUids);
    },
  });

  const { data, loading } = useQuery(GET_PAGINATED_TUTORS, {
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      ratings: selectedRating ? parseInt(selectedRating, 10) : null,
      tutorUserUids,
    },
  });

  const result = _.has(data, 'getPaginatedTutors') ? data.getPaginatedTutors : null;
  const rows = _.has(result, 'rows') ? result.rows : [];
  const total = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Name',
      key: 'fullname',
    },
    {
      title: 'Last Activity',
      key: 'lastActivity',
    },
    {
      title: 'Date & Time',
      key: 'dateTime',
    },
    {
      title: 'Overall Ratings and Feedback List',
      key: 'lastTutor',
      style: { width: '10%' },
    },
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'uid',
    },
  ];

  const onPageChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    if (showWantAttendVibalCert) {
      getAllWantAttendVibalCert();
    } else {
      setTutorUserUids([]);
    }
  }, [showWantAttendVibalCert]);

  return (
    <div className="card">
      <div className="card-header">
        <Row className="gap-2">
          <Col lg={12}>
            <div className="row d-flex gap-2">
              <div className="col-md-3">
                <select
                  className="form-select"
                  aria-label="select status"
                  onChange={(e) => setSelectedRating(e.target.value)}
                  placeholder="Select Rating"
                >
                  <option value="" selected>Select Ratings</option>
                  <option value={5}>5 stars</option>
                  <option value={4}>4 stars</option>
                  <option value={3}>3 stars</option>
                  <option value={2}>2 stars</option>
                  <option value={1}>1 star</option>
                </select>
              </div>
              <div className="col-md-4">
                <Form.Group className="form-group">

                  <Form.Check
                    className="form-check form-check-xs form-checkbox"
                    label="Interested in Vibal Certification"
                    onChange={() => {
                      setShowWantAttendVibalCert(!showWantAttendVibalCert);
                    }}
                    checked={showWantAttendVibalCert}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-body">
        <Row>
          <Col xs={12}>
            <TutorsTable
              columns={columns}
              dataSource={rows}
              loading={loading}
            />
          </Col>
          {!loading && rows.length > 0 && (
          <Col lg={12}>
            <Pagination
              totals={total}
              page={page}
              pageSize={pageSize}
              onChange={onPageChange}
            />
          </Col>
          )}
        </Row>
      </div>
    </div>
  );
}
