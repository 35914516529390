import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, Cell, Brush, YAxis
} from 'recharts';
import { GET_TUTORS_STUDENTS_COUNT } from './gql';
import { LoadingSpinner } from '../../../components';

export default function tutorsStudentBarChart() {
  const { data, loading } = useQuery(GET_TUTORS_STUDENTS_COUNT);

  const result = _.has(data, 'getTutorsStudentsCount') ? data.getTutorsStudentsCount : null;
  let maxNumber = 0
  const parsedData = result && result.length > 0 ? result.map((item) => {
    const tutor = _.has(item, 'tutor') ? item.tutor : null;
    const others = _.has(tutor, 'others') ? tutor.others : null;
    const firstName = _.has(others, 'firstName') ? others.firstName : null;
    const lastName = _.has(others, 'lastName') ? others.lastName : null;
    const studentCounts = _.has(item, 'studentCounts') ? item.studentCounts : 0;

    if(studentCounts > maxNumber) {
      maxNumber = studentCounts
    }

    return {
      name: `${firstName} ${lastName}`,
      studentCounts,
    };
  }) : [];

  return (
    <div style={{ width: '100%', height: 400 }} className="d-flex m-auto">
      { !loading
        ? (
          <ResponsiveContainer width="100%"  height={400}>
            <BarChart width={150} height={40} data={parsedData}>
              <Tooltip />
              <XAxis dataKey="name" />
              <YAxis dataKey="studentCounts" domain={[0, maxNumber]} hide/>
              <Bar barSize={40} dataKey="studentCounts" name="Number of students" fill="#82ca9d">

                {parsedData.map((entry, index) => {
                  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                  // eslint-disable-next-line react/no-array-index-key
                  return <Cell key={`cell-${index}`} fill={`#${randomColor}`} />;
                })}
              </Bar>
              <Brush dataKey="name" height={30} stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        )
        : <div className="m-auto"><LoadingSpinner /></div>}
    </div>
  );
}
