import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import _ from 'lodash';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { GET_ALL_TUTOR_ATTENDANCE } from './gql';
import { DashboardTemplate } from '../../../template/components';
import { CustomTable, DatePicker } from '../../../components';
import AttendanceStatus from './attendanceStatus';

export default function Index() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStatus, setSelectedStatus] = useState(null);

  const { data, loading } = useQuery(GET_ALL_TUTOR_ATTENDANCE, {
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      dateRange: startDate && endDate ? {
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      } : null,
      status: selectedStatus,
    },
  });

  const result = _.has(data, 'getAllTutorsAttendance') ? data.getAllTutorsAttendance : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const debounceSearch = _.debounce((value) => setSearchText(value), 500)

  const onSearch = (value) => {
    setPage(1);
    debounceSearch(value)
  };

  const onChangeStatus = (value) => {
    setPage(1)
    setSelectedStatus(value)
  }

  const columns = [
    {
      title: 'Tutor Name',
      dataKey: 'createdBy',
      render: (text, row) => {
        const tutorDetails = _.has(row, 'tutorDetails') ? row.tutorDetails : null;
        const others = _.has(tutorDetails, 'others') ? tutorDetails.others : null;
        const firstName = _.has(others, 'firstName') ? others.firstName : null;
        const lastName = _.has(others, 'lastName') ? others.lastName : null;

        return (
          <span>
            {firstName}
            {' '}
            {lastName}
          </span>
        );
      },
    },
    {
      title: 'Interest',
      dataKey: 'interest',
      render: (text, row) => {
        const session = _.has(row, 'session') ? row.session : null;
        const interest = _.has(session, 'interest') ? session.interest : null;

        return interest
      },
    },
    {
      title: 'Date',
      dataKey: 'date',
      render: (text, row) => {
        const session = _.has(row, 'session') ? row.session : null;
        const sessionStartDate = _.has(session, 'startDate') ? session.startDate : null;

        return sessionStartDate && moment(sessionStartDate).format('ll');
      },
    },
    {
      title: 'Time',
      dataKey: 'time',
      render: (text, row) => {
        const session = _.has(row, 'session') ? row.session : null;
        const sessionStartDate = _.has(session, 'startDate') ? session.startDate : null;
        const sessionEndDate = _.has(session, 'endDate') ? session.endDate : null;
        const startTime = moment(sessionStartDate).format('hh:mm:ss a');
        const endTime = moment(sessionEndDate).format('hh:mm:ss a');
        const timeRange = `${startTime} - ${endTime}`;

        return timeRange;
      },
    },
    {
      title: 'Class Duration',
      dataKey: 'createdAt',
      render: (text, row) => {
        const session = _.has(row, 'session') ? row.session : null;
        const sessionStartDate = _.has(session, 'startDate') ? session.startDate : null;
        const sessionEndDate = _.has(session, 'endDate') ? session.endDate : null;
        const a = moment(sessionStartDate);
        const b = moment(sessionEndDate);
        const duration = b.diff(a, 'minutes');
        return (
          <span>
            {duration}
            {' '}
            minutes
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataKey: 'uid',
      render: (text, row) => {
        // const attendanceStartDate = row.startedAt;
        const { createdAt } = row;
        const session = _.has(row, 'session') ? row.session : null;
        const sessionStartDate = _.has(session, 'startDate') ? session.startDate : null;
        const a = moment(sessionStartDate);
        const b = moment(createdAt);
        const duration = b.diff(a, 'minutes');
        const status = duration > 13 ? 'LATE' : 'PRESENT';
        const xStatus = duration > 14 ? 'NO_SHOW' : status;
        // const status = duration > 13 ? 'Late' : 'Present';
        // const xStatus = duration > 14 ? 'No Show' : status;
        return (
          <AttendanceStatus status={xStatus} />

          // <span>
          //   {xStatus}
          //   {/* /
          //   {duration}
          //   {' '}
          //   /
          //   {' '}
          //   {row.status} */}
          // </span>
        );
      },
    },
  ];

  return (
    <DashboardTemplate>
      <Row className="gap-4">
        <Col lg={12}>
          <h3 className="page-title">Tutors Attendance</h3>
        </Col>
        <Col lg={12}>
          <Row className="justify-content-end">
            <Col lg={3}>
              <form className="search-form custom-search-form">
                <div className="input-group">
                  <input
                    type="text"
                    onChange={(e) => onSearch(e.target.value)}
                    placeholder="Search here"
                    className="form-control"
                  />
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fa fa-search" />

                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Col>
        <Col lg={12}>
          <Row className="gap-2">
            <Col lg={3} style={{ zIndex: "999" }}>
              <DatePicker
                placeholderText="Select Dates"
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setPage(1)
                  setDateRange(update);
                }}
                isClearable
              />
            </Col>
            <Col lg={3}>
              <select
                className="form-control form-select"
                aria-label="select status"
                onChange={(e) => onChangeStatus(e.target.value)}
                placeholder="Select Rating"
              >
                <option value="" selected>Select Status</option>
                <option value="PRESENT">PRESENT</option>
                <option value="LATE">LATE</option>
                <option value="NO_SHOW">NO SHOW</option>
              </select>
            </Col>
          </Row>
        </Col>

        <Col lg={12}>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <CustomTable
                  loading={loading}
                  columns={columns}
                  page={page}
                  pageSize={pageSize}
                  totals={rowCount}
                  dataValues={dataResult}
                  onPageChange={(nPage) => setPage(nPage)}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
