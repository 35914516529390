import React, {
  useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import Timeslots from '../../../template/timeslots';
import { DashboardTemplate } from '../../../template/components';
import slotContext from './timeslots.context';

export const TimeslotsContext = slotContext;

export default function Index() {
  const location = useLocation();
  const [showAddTimeslot, setShowAddTimeslot] = useState(null);
  const [addTimeslot, setAddTimeslot] = useState(null);
  const state = _.has(location, 'state') ? location.state : null;

  const contextPayload = useMemo(() => ({
    showAddTimeslot,
    setShowAddTimeslot,
    addTimeslot,
    setAddTimeslot,
    state,
  }), [
    showAddTimeslot, setShowAddTimeslot,
    addTimeslot, setAddTimeslot,
    state,
  ]);

  return (
    <TimeslotsContext.Provider value={contextPayload}>
      <DashboardTemplate>
        <Timeslots />
      </DashboardTemplate>
    </TimeslotsContext.Provider>
  );
}
