import moment from 'moment';
import React, { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CustomTable } from '../../../components';
import WalletContext from './wallet.context';
import ViewModal from './viewModal';
import ViewConfirmedModal from './viewConfirmedModal';
import ViewCashinModal from './viewCashinModal';

const REFUND = 'REFUND';
const PAYMENT = 'PAYMENT';
const CONFIRMED = 'CONFIRMED';
const PAYROLLED = 'PAYROLLED';
const CASHIN = 'CASHIN';

export default function Index() {
  const { transactions = [] } = useContext(WalletContext);
  const columns = useMemo(() => [
    {
      title: 'Transaction Type',
      dataKey: 'action',
      center: true,
      render: (transactionType, row) => {
        const { document } = row;
        const paymentUid = _.has(document, 'paymentUid') ? document.paymentUid : null;
        const tutorPayrollUid = _.has(document, 'tutorPayrollUid') ? document.tutorPayrollUid : null;

        switch (transactionType) {
          case REFUND:
          case PAYMENT:
            return <ViewModal transactionType={transactionType} paymentUid={paymentUid} />;

          case CONFIRMED:
            return <ViewConfirmedModal transactionType={transactionType} paymentUid={paymentUid} />;

          case PAYROLLED:
            return <ViewPayroll tutorPayrollUid={tutorPayrollUid} />;

          case CASHIN:
            return <ViewCashinModal paymentUid={paymentUid} />;

          default: return transactionType;
        }
      },
    },
    { title: 'Current', dataKey: 'currentBalance' },
    { title: 'Previous', dataKey: 'previousBalance' },
    {
      title: 'Amount',
      dataKey: 'amount',
      render: (value, row) => {
        const action = _.has(row, 'action') ? row.action : null;
        let amount = parseFloat(value).toFixed(2);
        let className = 'text-success';

        if (action === 'PAYMENT' || action === 'PAYROLLED') {
          amount = `-${amount}`;
          className = 'text-danger';
        } else {
          amount = `+${amount}`;
        }

        return value && <span className={className}>{amount}</span>;
      },
    },
    {
      title: 'Date',
      dataKey: 'createdAt',
      render: (value) => value && moment(value).format('lll'),
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={transactions}
    />
  );
}

function ViewPayroll(payload) {
  const navigate = useNavigate();
  const { tutorPayrollUid } = payload;

  const redirect = useCallback(() => {
    navigate('/tutor/payslip', { state: { tutorPayrollUid } });
  }, [tutorPayrollUid]);

  return <Button variant="link" onClick={redirect}>PAYROLLED</Button>;
}
