import { gql } from '@apollo/client';

export const UPDATE_USER_STATUS = gql`
mutation UpdateUserStatus($uid: String!, $status: String! $updatedBy: String!) {
    updateUserStatus(uid: $uid, status: $status updatedBy: $updatedBy) {
      id
      uid
      username
      email
      roleCode
      agreedPolicyTerm
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const GET_LAST_ACTIVITY = gql`
query GetLastActivity($userUid: String!, $appCode: String!, $module: String) {
  getLastActivity(userUid: $userUid, appCode: $appCode, module: $module) {
    id
    uid
    action
    appCode
    module
    details
    changes
    userFullName
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const GET_TUTOR_RATING = gql`
query getTutorRating(
  $tutorUid: String!
) {
  getTutorRating(tutorUid: $tutorUid)
}
`;

export const GET_USER_TUTOR_DETAILS = gql`
query getUserTutorDetails($userUid: ID!) {
  getUserTutorDetails(userUid: $userUid) {
    id
    uid
    userUid
    status
    others
    priceRange
    rating

    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const GET_TUTOR_REVIEW_COUNT = gql`
query getTutorReviewCount($tutorUid: String!) {
  getTutorReviewCount(tutorUid: $tutorUid)
}
`;

export const GET_PAGINATED_TUTORS = gql`
query getPaginatedTutors($offset: Int! $limit: Int $searchText: String $ratings: Int $tutorUserUids: [String]) {
  getPaginatedTutors(offset: $offset limit: $limit searchText: $searchText ratings: $ratings tutorUserUids: $tutorUserUids) {
    count
    rows {
      id
      uid
      userUid
      status
      others
      priceRange
      rating

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
`;

export const sendSystemEmailMutation = gql`
  mutation SendSystemEmail($to: String!, $subject: String!, $text: String, $html: String) {
    sendSystemEmail(to: $to, subject: $subject, text: $text, html: $html)
  }
`;

export const GET_ALL_WANTATTENDVIBALCERT = gql`
query getAllWantAttendVibalCert {
  getAllWantAttendVibalCert
}
`;

export const GET_USER = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
      }
      __typename
    }
  }
`