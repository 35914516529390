import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { GET_USER } from '../../profile/gql';
import RegistryClient from '../../../RegistryClient';

export default function userFullName({ userUid }) {
  const { data } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    client: RegistryClient,
    variables: {
      uid: userUid,
    },
  });

  const result = _.has(data, 'getUser') ? data.getUser : null;
  const userProfile = _.has(result, 'userProfile') ? result.userProfile : null;
  const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
  const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;

  return (
    <span>
      {firstName}
      {' '}
      {lastName}
    </span>
  );
}
