import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import TuteesTable from './tuteesTable';
import { Pagination } from '../../../components';
import { GET_ALL_USERS } from '../../superadmin/userManagement/gql';
import client from '../../../RegistryClient';

export default function tuteesList({ searchText, selectedRole }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  const { loading, data } = useQuery(GET_ALL_USERS, {
    client,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      filter: {
        roleCodes: selectedRole,
        status: selectedStatus,
      },
      exceptRoles: ['SUPERADMIN', 'MARKETING_ADMIN'],
    },
  });

  const result = _.has(data, 'getAllUsers') ? data.getAllUsers : null;
  const rows = _.has(result, 'rows') ? result.rows : [];
  // const rows = [];
  const total = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Name',
      key: 'fullname',
    },
    {
      title: 'Last Activity',
      key: 'lastActivity',
    },
    {
      title: 'Date & Time',
      key: 'dateTime',
    },
    {
      title: 'Last Tutor',
      key: 'lastTutor',
    },
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'uid',
    },
  ];

  const onPageChange = (value) => {
    setPage(value);
  };

  return (
    <div className="card">
      <div className="card-header">
        <Row className="gap-4">
          <Col lg={12}>
            <div className="row">
              <div className="col-md-3">
                <select
                  className="form-select"
                  aria-label="select status"
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  placeholder="Select Rating"
                >
                  <option value="" selected>Select Status</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-body">
        <Row className="gap-4">

          <Col xs={12}>
            <TuteesTable
              columns={columns}
              dataSource={rows}
              loading={loading}
            />
          </Col>

          {!loading && rows.length > 0 && (
            <Col lg={12}>
              <Pagination
                totals={total}
                page={page}
                pageSize={pageSize}
                onChange={onPageChange}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}
