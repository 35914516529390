/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ls from 'local-storage';
import axios from 'axios';
import _ from 'lodash';

const SEC_KEY = process.env.REACT_APP_SEC_KEY || null;
const base64Key = Buffer.from(SEC_KEY).toString('base64');

const retrieveSource = (sourceId) => new Promise((resolve, reject) => {
  try {
    if (!sourceId) { throw new Error('Invalid sourceId, please retry booking again!'); }

    const options = {
      method: 'GET',
      url: `https://api.paymongo.com/v1/sources/${sourceId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${base64Key}`,
      },
    };

    axios
      .request(options)
      .then((response) => { resolve(response.data); })
      .catch(reject);
  } catch (err) {
    reject(err);
  }
});

export default function Index() {
  const navigate = useNavigate();
  const [sourceId, setSourceId] = useState(null);
  const [source, setSource] = useState(null);
  const [payType, setPayType] = useState(null);
  const [payAmount, setPayAmount] = useState(null);

  /**
   * @note log this failed transaction
   */

  useEffect(() => {
    const sId = ls.get('sourceId');

    async function doRetrieveSource() {
      return retrieveSource(sId)
        .then((result) => setSource(result));
    }
    doRetrieveSource();
    setSourceId(sId);
  }, [setSourceId, setSource]);

  useEffect(() => {
    setPayType();
    const data = _.has(source, 'data') ? source.data : null;
    const attributes = _.has(data, 'attributes') ? data.attributes : null;
    const amount = _.has(attributes, 'amount') ? attributes.amount : null;
    const type = _.has(attributes, 'type') ? _.toUpper(attributes.type) : null;
    const converted = parseFloat(amount) / 100;

    setPayType(type);
    setPayAmount(converted);
  }, [source, setPayType]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Booking</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Booking</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i style={{ background: 'red', borderColor: 'red' }}><FontAwesomeIcon icon={solid('xmark')} /></i>
                    <h3>Appointment booking failed!</h3>
                    <p className="text-danger">Payment confirmation failed!</p>
                    <p className="lead">
                      SourceId
                      {' '}
                      <small className="text-muted">{sourceId}</small>
                    </p>

                    <p className="lead">
                      Payment Method
                      {' '}
                      <small className="text-muted">{payType}</small>
                    </p>

                    <p className="lead">
                      Amount
                      {' '}
                      <small className="text-muted">{payAmount}</small>
                    </p>

                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary view-inv-btn"
                      onClick={() => navigate('/search')}
                    >
                      Book Again
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
