/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getPaymentQuery, getTimeslotSessionQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import { DynamicAvatar } from '../../../components';
import { SessionRowContext } from './sessions.context';

export default function Index() {
  const { paymentUid } = useContext(SessionRowContext);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [referenceUid, setReferenceUid] = useState(false);
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const [tutor, setTutor] = useState(null);
  const [interest, setInterest] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0)
  const [timeslotUid, setTimeslotUid] = useState(null)

  const { data: paymentResult } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid },
  });

  useEffect(() => {
    if (paymentResult) {
      const iPayment = _.has(paymentResult, 'getPayment') ? paymentResult.getPayment : null;
      const iService = _.has(iPayment, 'serviceDetails') ? iPayment.serviceDetails : null;
      const iAmount = _.has(iPayment, 'amount') ? iPayment.amount : null;
      const iTutor = _.has(iService, 'tutor') ? iService.tutor : null;
      const iUserUid = _.has(iTutor, 'userUid') ? iTutor.userUid : null;
      const iFirstName = _.has(iTutor, 'firstName') ? iTutor.firstName : null;
      const iLastName = _.has(iTutor, 'lastName') ? iTutor.lastName : null;
      const iFullName = `${iFirstName} ${iLastName}`;
      const iStartDate = _.has(iService, 'startDate') ? moment(iService.startDate) : null;
      const iEndDate = _.has(iService, 'endDate') ? moment(iService.endDate) : null;
      const iDateTime = iStartDate && `${iStartDate.format('ll')} ${iStartDate.format('hh:mm:ss a')} - ${iEndDate.format('hh:mm:ss a')}`;
      const voucherDiscount = _.has(iPayment, 'voucherDiscount') ? iPayment.voucherDiscount : null
      const iTimeslotUid = _.has(iService, 'timeSlotUid') ? iService.timeSlotUid : null

      setReferenceUid(paymentUid);
      setTutorUserUid(iUserUid);
      setTutor(iFullName);
      setDateTime(iDateTime);
      setInterest('Swimming Lesson');
      setSubTotal(iAmount)
      setTimeslotUid(iTimeslotUid)

      if (voucherDiscount) {
        const iSubtotal = iAmount + voucherDiscount;
        setSubTotal(iSubtotal);
        setDiscount(voucherDiscount - (2 * (voucherDiscount)));
      }
    }
  }, [paymentResult])

  const [fetchSession] = useLazyQuery(getTimeslotSessionQuery)

  useEffect(() => {
    async function doFetch() {
      const session = await fetchSession({ variables: { timeslotUid } })
        .then((result) => {
          const data = _.has(result, 'data') ? result.data : null;
          const details = _.has(data, 'getTimeslotSession') ? data.getTimeslotSession : null;

          return details;
        });

      const iInterest = _.has(session, 'interest') ? session.interest : null;

      setInterest(iInterest)
    }

    if (timeslotUid) { doFetch(); }
  }, [timeslotUid])

  useEffect(() => {
    // add discount since it's in negative value
    const totals = subTotal + discount;

    setGrossTotal(totals);
  }, [subTotal]);

  const onShow = useCallback(() => {
    setVisible(true);
  });

  const onClose = useCallback(() => {
    setVisible(false);
  });

  return (
    <>
      <Button variant="link" className="text-info" onClick={onShow}>
        <FontAwesomeIcon icon={solid('eye')} />
        {' '}
        Payment
      </Button>
      <Modal
        show={visible}
        onHide={onClose}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('person-chalkboard')} />
                {' '}
                Tutor
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <DynamicAvatar userUid={tutorUserUid} size="small" />
                {' '}
                <small className="text-muted" style={{ display: 'inline-flex' }}>{tutor}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('barcode')} />
                {' '}
                Payment UID
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{referenceUid}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('calendar-day')} />
                {' '}
                Schedule
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{dateTime}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('chalkboard-user')} />
                {' '}
                Interest
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{interest}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Subtotal
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small className="text-muted">{subTotal && subTotal.toFixed(2)}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Voucher
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small className="text-muted">{discount && discount.toFixed(2)}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Total Amount
              </p>
            </Col>
            <Col lg={8}>
              <h3 className="text-info">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small>{grossTotal && grossTotal.toFixed(2)}</small>
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>

          <Button
            variant="link"
            onClick={() => {
              navigate('/payments/invoice', { state: { paymentUid } });
            }}
          >
            View Invoice
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
