import React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardTemplate } from '../../../template/components';

export default function Index() {
  return (
    <DashboardTemplate>
      <Outlet />
    </DashboardTemplate>
  );
}
