import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Form, Modal,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import RegistryClient from '../../../../RegistryClient';
import { getUserQualificationQuery, saveQualificationsMutation } from './gql';
import { LoginContext } from '../../../login';
import { getUserQuery } from '../gql';

export default function Index() {
  const { userId, userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, handleSubmit, setValue,
  } = formPayload;
  const { errors } = formState;

  const { data: qualificationResult } = useQuery(getUserQualificationQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { userUid },
  });

  useEffect(() => {
    const result = _.has(qualificationResult, 'getUserQualification') ? qualificationResult.getUserQualification : null;
    const iTin = _.has(result, 'tin') ? result.tin : null;
    const iWantAttendVibalCert = _.has(result, 'wantAttendVibalCert') ? result.wantAttendVibalCert : null;

    setValue('tin', iTin);
    setValue('wantAttendVibalCert', iWantAttendVibalCert);
  }, [qualificationResult]);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateSave, { loading }] = useMutation(saveQualificationsMutation, {
    client: RegistryClient,
    awaitRefetchQueries: true,
    // update percentage progress accross the system
    refetchQueries: [
      {
        query: getUserQuery,
        client: RegistryClient,
        variables: { uid: userUid },
      }
    ],
    onCompleted: () => {
      handleClose();
    },
    update(cache, { data: { saveQualifications: qualification } }) {
      cache.modify({
        id: `User:${userId}`,
        fields: {
          qualification: () => qualification,
        },
      });
    },
  });

  const onSubmit = useCallback((data) => {
    mutateSave({
      variables: {
        userUid,
        ...data,
      },
    });
  }, [userUid, mutateSave]);

  const triggerSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit]);

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={regular('edit')} />
      </Button>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Other Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="tin">
              <Form.Label>TIN #</Form.Label>
              <Controller
                name="tin"
                control={control}
                rules={{ required: 'Field is required.' }}
                render={({ field }) => (
                  <Form.Control
                    isInvalid={!!_.has(errors, 'tin')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'tin') ? errors.tin.message : 'Invalid TIN.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="wantAttendVibalCert">
              <Controller
                name="wantAttendVibalCert"
                control={control}
                render={({ field }) => (
                  <Form.Check
                    type="checkbox"
                    isInvalid={!!_.has(errors, 'wantAttendVibalCert')}
                    label="Do you want to attend Vibal certification?"
                    checked={field.value}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'wantAttendVibalCert') ? errors.wantAttendVibalCert.message : 'Invalid attend vibal cert.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={triggerSubmit} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
