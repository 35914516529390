import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import LoginContext from '../../login/login.context';
import PaymentClient from '../../../PaymentClient';
import { UPDATE_VOUCHER_STATUS } from './gql';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import ModalConfirm from '../../../components/modalConfirm';

const PUBLISHED = 'PUBLISHED';

export default function publishModal({ data, onError, onSuccess }) {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();

  const uid = _.has(data, 'uid') ? data.uid : null;

  const [updateStatus, { loading }] = useMutation(UPDATE_VOUCHER_STATUS, {
    client: PaymentClient,
  });

  const handleOk = () => {
    updateStatus({
      variables: {
        uid,
        status: PUBLISHED,
        updatedBy: userUid,
      },
    }).then(() => {
      if(onSuccess) {
        onSuccess()
      }
      // audit trail
      doInsertAuditTrail({
        action: 'PUBLISH',
        changes: `Published ${data.code} voucher`,
        module: 'Voucher Code',
      });
    }).catch(err => {
      if(onError) {
        onError({
          title: 'Failed to publish voucher',
          message: err.toString()
        })
      }
    })
  };

  return (
    <ModalConfirm
      onOk={() => handleOk()}
      message={(
        <p>
          Are you sure you want to publish
          {' '}
          <b>{data.code}</b>
          {' '}
          voucher?

        </p>
        )}
    >
      {({ onClick }) => (
        <Button
          disabled={loading}
          title="Publish"
          variant="secondary"
          className="text-white"
          onClick={onClick}
        >
          Publish
        </Button>
      )}
    </ModalConfirm>
  );
}
