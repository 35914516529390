import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import LoginContext from '../login/login.context';
import MyProfile from '../../template/admin/myProfile';
import { DashboardTemplate } from '../../template/components';
import { GET_USER, UPDATE_PROFILE } from './gql';
import client from '../../RegistryClient';
import EditProfileModal from './editProfileModal';
import useCreateAuditTrail from '../auditTrail/useCreateAuditTrail';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();
  const [savingProfile, setSavingProfile] = useState(false)

  const [showProfileForm, setShowProfileForm] = useState(false);
  const [profileError, setProfileError] = useState(null)

  const { data } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    client,
    variables: {
      uid: userUid,
    },
  });

  const result = _.has(data, 'getUser') ? data.getUser : null;

  const [updateProfile] = useMutation(UPDATE_PROFILE, { client, refetchQueries: ['GetUser'] });

  const onUpdateProfile = (profileData, e) => {
    setProfileError(null)
    setSavingProfile(true)
    e.preventDefault();
    updateProfile({
      variables: {
        userUid,
        ...profileData,
        updatedBy: userUid,
      },
    }).then(() => {
      setSavingProfile(false)
      doInsertAuditTrail({
        action: 'UPDATE',
        changes: 'Updated his/her Profile',
        module: 'My Profile',
      });
      // add success message here
      setShowProfileForm(false);
    }).catch((err) => {
      setSavingProfile(false)
      setProfileError(err.toString())
    });
  };

  return (
    <DashboardTemplate>
      <MyProfile
        data={result}
        onClickEdit={() => setShowProfileForm(true)}
      />
      {showProfileForm && (
        <EditProfileModal
          initialValues={result && result.userProfile}
          visible={showProfileForm}
          onCancel={() => setShowProfileForm(false)}
          onSubmit={onUpdateProfile}
          error={profileError}
          onCloseError={() => setProfileError(null)}
          saving={savingProfile}
        />
      )}
    </DashboardTemplate>
  );
}
