import React, {
  useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import RegistryClient from '../../../../RegistryClient';
import { getUserQuery } from './gql';
import { LoginContext } from '../../../login';
import QualificationForm from './qualificationModalForm';
import { Col, Row } from 'react-bootstrap';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [educations, setEducations] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const iUserStatus = _.has(user, 'status') ? user.status : null;
    const iQualifications = _.has(user, 'qualification') ? user.qualification : null;
    const iEducations = _.has(iQualifications, 'educations') ? iQualifications.educations : [];

    setEducations(iEducations || []);

    if (iUserStatus === 'FOR_REVIEW') { setCanEdit(false); } else { setCanEdit(true); }
  }, [userResult]);

  return (
    <div className="card col-10 me-auto ms-auto p-0">
      <div className="card-body custom-border-card pb-0">
        <div className="widget experience-widget mb-0">
          <h4 className="widget-title">
            Educations
            {canEdit && <QualificationForm />}
          </h4>
          <hr />
          <div className="experience-box">
            <Row>
              {educations.map((education) => (
                <Col xs={{ span: 12 }}>
                  <EducationItem {...education} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

function EducationItem(payload) {
  const school = _.has(payload, 'school') ? payload.school : null;
  const degree = _.has(payload, 'degree') ? payload.degree : null;
  const degreeEarned = _.has(payload, 'degreeEarned') ? payload.degreeEarned : null;
  const yearGraduated = _.has(payload, 'yearGraduated') ? payload.yearGraduated : null;
  const schoolAddress = _.has(payload, 'schoolAddress') ? payload.schoolAddress : null;

  return (
    <ul className="experience-list profile-custom-list">
      <li>
        <div className="experience-content">
          <div className="timeline-content">
            <span>School Name</span>
            <div className="row-result">
              {school}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="experience-content">
          <div className="timeline-content">
            <span>Degree</span>
            <div className="row-result">
              {degree}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="experience-content">
          <div className="timeline-content">
            <span>Degree Earned</span>
            <div className="row-result">
              {degreeEarned}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="experience-content">
          <div className="timeline-content">
            <span>Year Graduated</span>
            <div className="row-result">
              {yearGraduated}
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="experience-content">
          <div className="timeline-content">
            <span>School Address</span>
            <div className="row-result">
              {schoolAddress}
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}
