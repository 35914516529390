import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button,
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { LoadingSpinner } from '../../../components';
import { LoginContext } from '../../login';
import CheckoutContext from './checkout.context';
import { getVoucherCodeDetailsQuery, IS_VOUCHER_REDEEMED } from './gql';
import PaymentClient from '../../../PaymentClient';
import Vouchers from './vouchers';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const formPayload = useForm();
  const {
    formState, control, handleSubmit, setError, setValue,
    reset,
  } = formPayload;
  const { errors } = formState;
  const { setVoucher } = useContext(CheckoutContext);

  const handleClose = useCallback(() => {
    setVisible(false);
    reset();
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [getVoucherCode] = useLazyQuery(getVoucherCodeDetailsQuery, {
    fetchPolicy: 'network-only',
    client: PaymentClient,
  });

  const [isVoucherRedeemed] = useLazyQuery(IS_VOUCHER_REDEEMED, {
    client: PaymentClient,
    fetchPolicy: 'network-only'
  })

  const onSubmit = useCallback((data) => {
    async function doSubmit() {
      const variables = { ...data };
      setLoading(true);

      await getVoucherCode({
        variables,
      }).then(async (result) => {
        const iData = _.has(result, 'data') ? result.data : null;
        const iVoucher = _.has(iData, 'getVoucherCodeDetails') ? iData.getVoucherCodeDetails : null;
        const iEndDate = _.has(iVoucher, 'endDate') ? iVoucher.endDate : null;
        const iStatus = _.has(iVoucher, 'status') ? iVoucher.status : null;
        const iRequirement = _.has(iVoucher, 'requirement') ? iVoucher.requirement : null;
        const iTotalUsed = _.has(iVoucher, 'totalUsed') ? iVoucher.totalUsed : null;
        const iType = _.has(iVoucher, 'type') ? iVoucher.type : null;
        const valid = moment().isSameOrBefore(iEndDate);
        const isSystemVoucher = iType === 'REGISTRATION' || iType === 'SESSIONS' ? true : false

        const redeemedVoucher = await isVoucherRedeemed({ variables: { code: data.code, userUid } })
          .then((result) => {
            const dataResult = _.has(result, 'data') ? result.data : null;
            const isRedeemed = _.has(dataResult, 'isVoucherRedeemed') ? dataResult.isVoucherRedeemed : false;
            return isRedeemed;
          })

        if (!iVoucher || iStatus === 'DRAFT' || iStatus === 'DELETED') {
          setError('code', {
            message: 'The voucher does not exist.',
          }, { shouldFocus: true });
        } else if (iTotalUsed >= iRequirement && !isSystemVoucher) {
          setError('code', {
            message: 'Sorry, this voucher has already reached its maximum usage limit. Please try another voucher code.',
          }, { shouldFocus: true });
        } else if (redeemedVoucher) {
          setError('code', {
            message: 'Sorry, the maximum number of redemptions for this code has been reached. Please try again with another voucher code.',
          }, { shouldFocus: true });
        } else if (valid) {
          setVoucher(iVoucher);
          handleClose();
        } else {
          setError('code', {
            message: 'The voucher code you entered is invalid. Please try again with another voucher code.',
          }, { shouldFocus: true });
        }
      })

      setLoading(false);
    }

    doSubmit();
  }, [
    userUid,
    getVoucherCode,
  ]);

  const triggerSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit]);

  const handleSelectVoucher = (value) => {
    setValue('code', value);
  };

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={solid('qrcode')} />
        {' '}
        Insert Voucher Code
      </Button>

      <Modal
        show={visible}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Insert Voucher Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row className="mb-3">
              <Form.Group as={Col} sm={12} controlId="voucher.code">
                <Form.Label>Voucher Code</Form.Label>
                <Controller
                  name="code"
                  control={control}
                  rules={{ required: 'Voucher code is required.' }}
                  render={({ field }) => (
                    <Form.Control
                      isInvalid={!!_.has(errors, 'code')}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'code') ? errors.code.message : 'Invalid code.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form>
          <Vouchers onSelect={handleSelectVoucher} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={triggerSubmit} disabled={loading}>
            {loading ? <LoadingSpinner /> : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
