/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import ls from 'local-storage';
import { useMutation } from '@apollo/client';
import Success from './success';
import Failed from './failed';
import ChargeContext from './charge.context';
import { LoginContext } from '../../../login';
import { PaymentContext } from '../../payment.context';
import PaymentClient from '../../../../PaymentClient';
import { cashInMutation, completePaymentMutation } from '../gql';
import { useAlert } from 'react-alert'
import styledComponents from 'styled-components'
import { Button } from 'react-bootstrap';

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function Index() {
  const location = useLocation();
  const state = _.has(location, 'state') ? location.state : null;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userUid } = useContext(LoginContext);
  const [paymentUid, setPaymentUid] = useState(null);
  const { getPaymentIntent } = useContext(PaymentContext);
  const [actualAmount, setActualAmount] = useState(0);
  const [contentState, setContentState] = useState('loading');
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientKey, setClientKey] = useState(null);
  const [topUpAt, setTopUpAt] = useState(null);
  const alert = useAlert()

  useEffect(() => {
    if (state) {
      const iPaymentIntentId = _.has(state, 'paymentIntentId')
        ? state.paymentIntentId : null;
      const iClientKey = _.has(state, 'clientKey') ? state.clientKey : null;

      setPaymentIntentId(iPaymentIntentId);
      setClientKey(iClientKey);
    } else {
      const iPaymentIntentId = ls.get('paymentIntentId');
      const iClientKey = ls.get('clientKey');

      setPaymentIntentId(iPaymentIntentId);
      setClientKey(iClientKey);
    }
  }, [state, setPaymentIntentId, setClientKey]);

  const [mutateCashIn] = useMutation(cashInMutation, {
    client: PaymentClient,
  });

  const [mutateCompletePayment] = useMutation(completePaymentMutation, {
    client: PaymentClient,
  });

  useEffect(() => {
    if (paymentIntentId && clientKey) {
      // eslint-disable-next-line no-inner-declarations
      async function doCharge() {
        setLoading(true);

        try {
          const paymentIntent = await getPaymentIntent({ paymentIntentId, clientKey });
          const intentData = _.has(paymentIntent, 'data') ? paymentIntent.data : null;
          const attributes = _.has(intentData, 'attributes') ? intentData.attributes : null;
          const payments = _.has(attributes, 'payments') ? attributes.payments : [];
          const payment = payments[0];
          const paymentId = _.has(payment, 'id') ? payment.id : null;

          await mutateCompletePayment({
            variables: {
              sourceId: paymentIntentId,
              paymentId,
              status: 'PAID',
              document: { ...payment },
              updatedBy: userUid,
            },
          }).then(({ data: completeResult }) => {
            const paid = _.has(completeResult, 'completePayment') ? completeResult.completePayment : null;
            const payUid = _.has(paid, 'uid') ? paid.uid : null;
            const iAmount = _.has(paid, 'amount') ? paid.amount : 0;
            const updatedAt = _.has(paid, 'updatedAt') ? paid.updatedAt : 0;

            setPaymentUid(payUid);
            setActualAmount(iAmount);
            setTopUpAt(updatedAt);

            return mutateCashIn({
              variables: {
                userUid,
                amount: iAmount,
                document: {
                  paymentUid: payUid,
                  ...intentData,
                },
              },
            }).catch((err) => {
              const message = `Payment has completed but encountered an error. 
              Please report as soon as possible, alongside with this reference code "paymentUid=${payUid}"`;

              setError(message);
            });
          }).catch(() => {
            const message = `Payment has completed but encountered an error.
            Please report as soon as possible, alongside with this reference code "paymentIntentID=${paymentIntentId}"`;

            setError(message);
          });

          ls.remove('paymentIntentId');
          ls.remove('clientKey');
        } catch (err) {
          const errors = _.has(err, 'errors') ? err.errors : []
          let errorMessage = JSON.stringify(err)

          _.map(errors, row => {
            const code = _.has(row, 'code') ? row.code : null
            const detail = _.has(row, 'detail') ? row.detail : null

            switch (code) {
              case 'parameter_below_minimum':
                errorMessage = `The value for amount cannot be less than 100.`
                break
              default:
                errorMessage = detail || JSON.stringify(row)
            }
          })

          alert.error(errorMessage, { timeout: 5000 })
        }

        setLoading(false);
      }

      doCharge();
    }
  }, [paymentIntentId, clientKey]);

  useEffect(() => {
    if (loading) {
      setContentState('loading');
    }

    if (paymentUid) {
      setContentState('success');
    }

    if (error) {
      setContentState('error');
    }
  }, [loading, paymentUid, error]);

  useEffect(() => {
    if (!paymentIntentId && !clientKey && !paymentUid) {
      setError('Invalid intent ID of null value');
    } else {
      setError(null);
    }
  }, [paymentIntentId, clientKey, paymentUid]);

  const contextPayload = useMemo(() => ({
    error,
    paymentUid,
    amount: actualAmount,
    contentState,
    topUpAt,
  }), [
    error,
    paymentUid,
    actualAmount,
    contentState,
    topUpAt,
  ]);

  return (
    <ChargeContext.Provider value={contextPayload}>
      <RenderContent />
    </ChargeContext.Provider>
  );
}

function RenderContent() {
  const { contentState } = useContext(ChargeContext);

  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderItem() {
    switch (contentState) {
      case 'success': return <Success />;
      case 'error': return <Failed />;
      default: return <ProcessInprogress />;
    }
  }

  return (
    <RenderItem />
  );
}

function ProcessInprogress() {
  const navigate = useNavigate();

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cash In</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Cash In</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fa fa-spin fa-spinner" aria-hidden="true" />

                    <h3>Processing Payment</h3>

                    <p>Payment in progress, please don't refresh or close the page...</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
