/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Button, Image } from 'react-bootstrap';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CheckoutContext from './checkout.context';
import WalletCheckout from './walletCheckout';
import { Gcash, CreditCard } from './paymongo/index';
import { Ratings } from '../../../components';
import { getUserQuery } from './gql';
import RegistryClient from '../../../RegistryClient';
import InsertVoucherCode from './insertVoucherModal';
import { UploaderContext } from '../../../modules/uploader';

const StyledDiv = styledComponents.div`
  &.custom-avatar {
    color: #fe9445;
    background-color: rgb(254 148 69 / 20%);
    border: 3px solid #fe9445;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;;
    overflow: hidden;
    width: 100px;
    margin: 0 16px 0 0;

    .avatar-text {
      color: #fe9445;
    }
`;

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function Index() {
  const navigate = useNavigate();
  const [payMethod, setPayMethod] = useState('WALLET');
  const [tutorName, setTutorName] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [initial, setInitial] = useState(null);
  const [location, setLocation] = useState(null);
  const [path, setPath] = useState(null);
  const { getFileLink } = useContext(UploaderContext);

  const {
    sessionType,
    startDate,
    endDate,
    feesList,
    vatable,
    vat,
    grossSales,
    tutorProfile,
    voucher,
    voucherDiscount,
  } = useContext(CheckoutContext);

  useEffect(() => {
    if (tutorProfile) {
      const { firstName, middleIntial, lastName } = tutorProfile;
      const fullName = `${firstName} ${middleIntial || ''} ${lastName}`;
      const iInitial = firstName && lastName ? `${_.toUpper(firstName.charAt(0))}${_.toUpper(lastName.charAt(0))}` : '';

      setTutorName(fullName);
      setInitial(iInitial);
    }
  }, [tutorProfile]);

  const [fetchUser] = useLazyQuery(getUserQuery, { client: RegistryClient });

  useEffect(() => {
    if (tutorProfile) {
      const userUid = _.has(tutorProfile, 'userUid') ? tutorProfile.userUid : null;

      fetchUser({ variables: { uid: userUid } })
        .then(({ data }) => {
          const user = _.has(data, 'getUser') ? data.getUser : null;
          const iAvatar = _.has(user, 'avatar') ? user.avatar : null;
          const iAvatarStorage = _.has(iAvatar, 'storage') ? iAvatar.storage : null;
          const iPath = _.has(iAvatarStorage, 'path') ? iAvatarStorage.path : null;
          setPath(iPath);

          const profile = _.has(user, 'userProfile') ? user.userProfile : null;
          const iAddress = _.has(profile, 'address') ? profile.address : null;
          const city = _.has(iAddress, 'city') ? iAddress.city : null;
          const countryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
          setLocation(`${city}, ${countryCode}`);
        });
    }
  }, [tutorProfile]);

  useEffect(() => {
    if (path) {
      getFileLink({ filePath: path }).then((uri) => {
        if (uri) {
          setAvatar(uri);
        }
      });
    }
  }, [path]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0);" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Checkout</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div className="card">
                <div className="card-body">

                  <div className="payment-widget">
                    <h4 className="card-title">Payment Method</h4>
                    <div className="payment-list">
                      <label className="payment-radio paypal-option">
                        <input
                          type="radio"
                          name="radio"
                          checked={payMethod === 'WALLET'}
                          onClick={() => setPayMethod('WALLET')}
                        />
                        <span className="checkmark" />
                        Wallet
                      </label>
                      {payMethod === 'WALLET' && <WalletCheckout />}
                    </div>

                    <div className="payment-list">
                      <label className="payment-radio credit-card-option">
                        <input
                          type="radio"
                          name="radio"
                          checked={payMethod === 'GCASH'}
                          onClick={() => setPayMethod('GCASH')}
                        />
                        <span className="checkmark" />
                        GCASH
                      </label>
                      {payMethod === 'GCASH' && <Gcash />}
                    </div>

                    <div className="payment-list">
                      <label className="payment-radio credit-card-option">
                        <input
                          type="radio"
                          name="radio"
                          checked={payMethod === 'CREDIT_CARD'}
                          onClick={() => setPayMethod('CREDIT_CARD')}
                        />
                        <span className="checkmark" />
                        Credit Card
                      </label>
                      {payMethod === 'CREDIT_CARD' && <CreditCard />}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 theiaStickySidebar">
              <div className="card booking-card">
                <div className="card-header">
                  <h4 className="card-title">Booking Summary</h4>
                </div>

                <div className="card-body">
                  <div className="booking-user-info mb-2">
                    {avatar ? (
                      <Image
                        src={avatar}
                        fluid
                        roundedCircle
                        style={{
                          width: '7em',
                          marginRight: '16px',
                        }}
                      />
                    ) : (
                      <StyledDiv className="custom-avatar">
                        <Link>
                          <span className="avatar-text">{initial}</span>
                        </Link>
                      </StyledDiv>
                    )}
                    <div className="booking-info">
                      <h4>{tutorName}</h4>
                      <Ratings readonly />
                      <div className="mentor-details">
                        <p className="user-location">
                          <i className="fas fa-map-marker-alt" />
                          {' '}
                          {location}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="booking-summary">
                    <div className="booking-item-wrap">
                      <ul className="booking-date">
                        <li>
                          Date From
                          {' '}
                          <small className="value text-muted">
                            {startDate && moment(startDate).format('lll')}
                          </small>
                        </li>
                        <li>
                          Date To
                          {' '}
                          <small className="value text-muted">
                            {endDate && moment(endDate).format('lll')}
                          </small>
                        </li>
                        <li>
                          Session
                          {' '}
                          <small className="value text-muted">
                            {sessionType}
                          </small>
                        </li>
                        <li>
                          {voucher ? <VoucherCode /> : <InsertVoucherCode />}
                        </li>
                      </ul>
                      <hr />
                      <ul className="booking-fee">
                        {
                          feesList.map((i) => {
                            const item = _.has(i, 'item') ? i.item : null;
                            const price = _.has(i, 'price') ? i.price : 0.00;

                            return (
                              <li>
                                {item}
                                {' '}
                                <span>
                                  ₱
                                  {' '}
                                  {price}
                                </span>
                              </li>
                            );
                          })
                        }
                      </ul>
                      <div className="booking-total">
                        <ul className="">
                          <VoucherItem voucher={voucherDiscount} />
                          <StyledTotalListItem title="Net" value={vatable} />
                          <StyledTotalListItem title="Vat (Inclusive)" value={vat} />
                          <li>
                            <span>Total</span>
                            <span className="total-cost">
                              ₱
                              {' '}
                              {grossSales}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TotalListItem({ className, title, value }) {
  return (
    <li className={className}>
      <span className="totals__title">{title}</span>
      <span className="totals__value">
        ₱
        {' '}
        {value}
      </span>
    </li>
  );
}

const StyledTotalListItem = styledComponents(TotalListItem)`
      .totals__title {
        font - weight: 500;
      font-size: 15px;
  }

      .totals__value {
        float: right;
      color: #757575;
      font-weight: 400;
      font-size: 15px;
  }
      `;

TotalListItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
};

function VoucherItem(payload) {
  const voucher = _.has(payload, 'voucher') ? payload.voucher : null;
  if (!voucher) { return ''; }

  return <StyledTotalListItem title="Voucher Discount" value={voucher} />;
}

function VoucherCode() {
  const { voucher, setVoucher } = useContext(CheckoutContext);
  const code = _.has(voucher, 'code') ? voucher.code : null;

  const deleteVoucher = useCallback(() => {
    setVoucher(null);
  }, [voucher]);

  return (
    <>
      Voucher
      {' '}
      <Button variant="link" onClick={deleteVoucher}>
        <FontAwesomeIcon icon={solid('trash')} />
      </Button>
      {' '}
      <small className="value text-muted">{code}</small>
    </>
  );
}
