import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import LoginContext from '../../login/login.context';
import { CREATE_TICKET, GET_ALL_TICKET_CATEGORY } from '../../issueAndResolution/gql';
import TicketingClient from '../../../TicketingClient';

import useMarketingNotification from '../../notification/useMarketingNotification';
import useSupportNotification from '../../notification/useSupportNotification';
import { AlertError, LoadingSpinner } from '../../../components';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-files`;
const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

const upload = (files, userUid) => new Promise((resolve, reject) => {
  const formData = new FormData();
  if (files.length > 0) {
    files.map((file) => {
      return formData.append('files', file);
    });
  }
  formData.append('userUid', userUid);

  axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

export default function disputeFormModal(params) {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fileAttachment, setFileAttachment] = useState([]);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(null);
  const payslipUid = _.has(params, 'payslipUid') ? params.payslipUid : null;

  const { sendNotification: sendMarketingNotification } = useMarketingNotification({ userUid });
  const { sendNotification: sendSupportNotification } = useSupportNotification({ userUid });
  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const {
    loading: ticketCategoriesLoading,
    data: ticketCategoriesData,
  } = useQuery(GET_ALL_TICKET_CATEGORY, {
    client: TicketingClient,
    variables: { appCode: APPCODE },
  });

  useEffect(() => {
    const ticketList = _.has(ticketCategoriesData, 'getAllTicketCategory') ? ticketCategoriesData.getAllTicketCategory : [];
    const payslipCategory = ticketList.length > 0 ? ticketList.find((i) => i.name === 'Payslip') : null;
    const payslipTicketCategoryUid = _.has(payslipCategory, 'uid') ? payslipCategory.uid : null;

    if (payslipTicketCategoryUid) {
      setCategory(payslipTicketCategoryUid);
    } else if (!ticketCategoriesLoading) {
      setError({
        title: 'Ticket Category Error',
        body: 'Please contact administrator.',
      });
    }
  }, [ticketCategoriesData]);

  const {
    handleSubmit, formState, control, reset,
  } = useForm({
    defaultValues: {
      payslipControlNumber: payslipUid,
    },
  });

  const { errors } = formState;

  const [saveTicket] = useMutation(
    CREATE_TICKET,
    {
      client: TicketingClient,
      refetchQueries: ['GetPaginatedTickets'],
    },
  );

  const onClose = () => {
    setFileAttachment([]);
    setSaving(false);
    reset();
    setVisible(false);
  };

  const onSubmit = (data) => {
    setSaving(true);
    upload(fileAttachment, userUid).then((result) => {
      const uploadResult = _.has(result, 'data') ? result.data : null;
      const attachments = uploadResult
        ? Object.keys(uploadResult).map((key) => uploadResult[key]) : null;

      saveTicket({
        variables: {
          ...data,
          appCode: APPCODE,
          userUid,
          userFullname: userFullName,
          attachments,
          categoryUid: category,
          otherDetails: { payslipControlNumber: payslipUid },
        },
      }).then((ticketResult) => {
        const ticketData = _.has(ticketResult, 'data') ? ticketResult.data : null;
        const ticket = _.has(ticketData, 'createTicket') ? ticketData.createTicket : null;
        const ticketUid = _.has(ticket, 'uid') ? ticket.uid : null;
        const ticketNumber = _.has(ticket, 'ticketNumber') ? ticket.ticketNumber : null;

        // insert audit trail
        doInsertAuditTrail({
          changes: `Filed an issue with ticket #${ticketNumber}`,
          action: 'CREATE',
          module: 'Issues and Resolution',
        });

        // send marketing a notif
        sendMarketingNotification({
          title: 'New Issues reported',
          message: `${userFullName} reported an issue.`,
          data: {
            urlPath: '/marketing-admin/issue-and-resolution/view',
            urlState: { ticketUid },
          },
        });

        sendSupportNotification({
          title: 'New Issues reported',
          message: `${userFullName} reported an issue.`,
          data: {
            urlPath: '/support/issue-and-resolution/view',
            urlState: { ticketUid },
          },
        });

        onClose();
      }).catch((err) => {
        setError({ title: 'Failed to Create ticket', body: err.toString() });
        setFileAttachment([]);
        setSaving(false);
      });
    }).catch((err) => {
      setError({ title: 'Failed to Create ticket', body: err.toString() });
      setFileAttachment([]);
      setSaving(false);
    });
  };

  const handleUpload = (e) => {
    const fileList = e.target.files;
    setFileAttachment([...fileList, ...fileAttachment]);
  };

  return (
    <>
      <Button variant="link" block onClick={() => setVisible(true)}>
        <FontAwesomeIcon icon={solid('gavel')} />
        {' '}
        Dispute
      </Button>

      {visible && (
        <Modal
          show={visible}
          onHide={() => setVisible(false)}
        >
          <Form onSubmit={handleSubmit(onSubmit)} className="mb-2">
            <Modal.Header closeButton>
              <Modal.Title>Dispute Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {error && (
                <div>
                  <AlertError
                    error={error.body}
                    title={error.title}
                    onClose={() => setError(null)}
                  />
                </div>
              )}

              {ticketCategoriesLoading && <LoadingSpinner />}

              {category && (
                <>
                  <Form.Group className="form-group" controlId="createTicket.controlInput1">
                    <Form.Label>Subject</Form.Label>
                    <Controller
                      name="subject"
                      control={control}
                      rules={{
                        required: 'Subject is required.',
                        validate: {
                          whiteSpace: (value) => !!value.trim() || 'Subject cannot be empty.',
                        },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          autoFocus
                          isInvalid={!!_.has(errors, 'subject')}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {_.has(errors, 'subject') ? errors.subject.message : 'Invalid subject.'}
                    </Form.Control.Feedback>

                  </Form.Group>
                  <Form.Group className="form-group" controlId="createTicket.controlInput2">
                    <Form.Label>Details</Form.Label>
                    <Controller
                      name="details"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          as="textarea"
                          rows={3}
                          {...field}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group className="form-group" controlId="createTicket.payslip">
                    <Form.Label className="form-control-label">Payslip Control Number</Form.Label>
                    <Controller
                      name="payslipControlNumber"
                      control={control}
                      rules={{ required: 'Payslip Control Number is required.' }}
                      autoFocus
                      render={({ field }) => (
                        <Form.Control
                          autoFocus
                          isInvalid={!!_.has(errors, 'payslipControlNumber')}
                          {...field}
                          disabled
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {_.has(errors, 'payslipControlNumber') ? errors.payslipControlNumber.message : 'Invalid Payslip Control Number.'}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group" controlId="createTicket.controlInput4">
                    <Form.Label>Attachments</Form.Label>
                    <Controller
                      name="files"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          autoFocus
                          type="file"
                          onChange={handleUpload}
                          multiple
                          accept="image/*,application/pdf"
                        />
                      )}
                    />
                    <div className="pt-4">
                      {fileAttachment && fileAttachment.map((att, k) => (
                        <p>
                          {att.name}
                        </p>
                      ))}
                    </div>
                  </Form.Group>
                </>
              )}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onClose()} disabled={saving}>Cancel</Button>
              {' '}
              {category && <Button type="submit" variant="primary" disabled={saving}>{!saving ? 'Save' : 'Saving'}</Button>}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
}
