import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { GET_PAGINATED_RECRUITMENTS, GET_RECRUITMENT_SETTINGS } from './gql';
import client from '../../../RegistryClient';
import SettingsModal from './settingsModal';
import RecruitmentLogsModal from './recruitmentLogsModal';
import { CustomTable } from '../../../components';
import UpdateStatusModal from './updateStatusModal';
import { recruitmentStatusLabel } from '../../../constants';
import UserImage from '../../../components/userImage';
import styledComponents from 'styled-components'

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function recruitmentList({ onSelectProfile }) {
  const [showSettings, setShowSettings] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showLogs, setShowLogs] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const { data: settingsData } = useQuery(GET_RECRUITMENT_SETTINGS, { client });

  const { loading, data } = useQuery(GET_PAGINATED_RECRUITMENTS, {
    fetchPolicy: 'network-only',
    client,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      filter: {
        status: selectedStatus,
      },
    },
  });

  const result = _.has(data, 'getPaginatedRecruitments') ? data.getPaginatedRecruitments : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const onViewLogs = (rowValues) => {
    setShowLogs(true);
    setSelectedRow(rowValues);
  };

  const columns = [
    {
      title: 'BASIC INFO',
      dataKey: 'uid',
      render: (text, record) => {
        const user = _.has(record, 'user') ? record.user : null;

        const userProfile = _.has(user, 'userProfile') ? user.userProfile : null;
        const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
        const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;

        const avatar = _.has(record, 'avatar') ? record.avatar : null;
        const avatarStorage = _.has(avatar, 'storage') ? avatar.storage : null;

        const avatarPath = _.has(avatarStorage, 'path') ? avatarStorage.path : null;

        return (
          <h2 className="table-avatar">
            {/* <a
              aria-hidden="true"
              onClick={() => onSelectProfile(record)}
              className="avatar avatar-sm me-2"
            >
              <UserImage filePath={avatarPath} />
            </a>
            <a
              aria-hidden="true"
              onClick={() => onSelectProfile(record)}
            >
              {firstName}
              {' '}
              {lastName}
            </a> */}
            <StyledButtonNav
              variant='link'
              onClick={() => onSelectProfile(record)}
              className="avatar avatar-sm me-2"
            >
              <UserImage filePath={avatarPath} />
            </StyledButtonNav>
            <StyledButtonNav
              variant='link'
              onClick={() => onSelectProfile(record)}
              className="avatar avatar-sm me-2"
            >
              {firstName}
              {' '}
              {lastName}
            </StyledButtonNav>
          </h2>
        );
      },
    },
    {
      title: 'DATE REGISTERED',
      dataKey: 'createdAt',
      render: (text) => <span className="text-center">{moment(text).isValid() && moment(text).format('LL')}</span>,
    },
    {
      title: 'STATUS',
      dataKey: 'status',
      render: (text) => (text ? recruitmentStatusLabel[text] : text),
    },
    {
      title: 'ACTION',
      dataKey: 'uid',
      render: (text, record) => {
        const status = _.has(record, 'status') ? record.status : null;
        return (
          <div style={{ display: 'inline-flex', gap: 8 }}>
            <UpdateStatusModal
              recruitmentData={record}
              currentStatus={status}
            />
            <Button
              variant="secondary"
              onClick={() => onViewLogs(record)}
            >

              View Logs
            </Button>
          </div>
        );
      },
    },
  ];

  const debounceSearch = _.debounce((value) => setSearchText(value), 500)

  const onSearch = (value) => {
    setPage(1);
    debounceSearch(value)
  };

  return (
    <div>
      <h3 className="page-title mb-4">Tutor Recruitment</h3>
      <div>
        <Row className="justify-content-end mb-4 px-2">
          <Col lg={4} style={{ display: 'flex' }}>
            <Button
              size="lg"
              variant="link"
              onClick={() => setShowSettings(true)}
              className="text-lg"
              title="Recruitment Settings"
            >
              <i className="fa fa-cog" />
            </Button>
            <div className="input-group">
              <input
                type="text"
                onChange={(e) => onSearch(e.target.value)}
                placeholder="Search tutor"
                className="form-control"
              />
              <Button
                type="button"
                className="btn btn-primary"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-search" />
              </Button>
            </div>
          </Col>
        </Row>

        <div className="card">
          <div className="card-header">
            <Row>
              <Col lg={3}>
                <select
                  className="form-select"
                  aria-label="select status"
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  placeholder="Select Status"
                >
                  <option value="" selected>All Status</option>
                  <option value="FOR_REVIEW">For Review</option>
                  <option value="FOR_SCREENING">For Screening</option>
                  <option value="FOR_ONBOARDING">For Onboarding</option>
                </select>
              </Col>
            </Row>
          </div>
          <div className="card-body">

            <CustomTable
              loading={loading}
              columns={columns}
              page={page}
              pageSize={pageSize}
              totals={rowCount}
              dataValues={dataResult}
              onPageChange={(nPage) => setPage(nPage)}
            />
          </div>
        </div>
      </div>

      {showSettings && (
        <SettingsModal
          initialValues={_.has(settingsData, 'getRecruitmentSettings') ? settingsData.getRecruitmentSettings : null}
          show={showSettings}
          setShow={setShowSettings}
        />
      )}

      {showLogs
        && (
          <RecruitmentLogsModal
            show={showLogs}
            onHide={() => {
              setShowLogs(false);
              setSelectedRow(null);
            }}
            recruitmentUid={_.has(selectedRow, 'uid') ? selectedRow.uid : null}
          />
        )}
    </div>
  );
}
