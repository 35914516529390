import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
// import moment from 'moment';
// import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPesoSign } from '@fortawesome/free-solid-svg-icons';
import PaymentClient from '../../../PaymentClient';
import { GET_TOTAL_EARNED } from './qql';
import { LoadingSpinner } from '../../../components';

export default function TotalEarned({ tutorUid }) {
  const { data, loading } = useQuery(GET_TOTAL_EARNED, {
    client: PaymentClient,
    variables: {
      tutorUid,
    },
  });

  const result = _.has(data, 'getTotalEarned') ? data.getTotalEarned : null;
  const totalGrossPay = _.has(result, 'totalGrossPay') ? result.totalGrossPay : 0;

  return (
    <div className="dash-widget" style={{ height: '100%' }}>
      <div className="circle-bar">
        <div className="icon-col">
          <i className="fas fa-money-bill" />
        </div>
      </div>
      <div className="dash-widget-info">
        <h3>
          {!loading ? <PesoIcon value={totalGrossPay} /> : <LoadingSpinner text='' />}
        </h3>
        <div>
          <h5>Total Earned</h5>
        </div>
      </div>
    </div>
  );
}

function PesoIcon({ value }) {
  return (
    <>
      <FontAwesomeIcon icon={faPesoSign} />
      {value.toFixed(2)}
    </>
  );
}
