/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */
export const getPaginatedPaymentsQuery = gql`
  query GetPaginatedPayments($offset: Int!, $transactionType: String) {
    getPaginatedPayments(offset: $offset, transactionType: $transactionType) {
      count
      rows {
        id
        uid
        amount
        paymentMethod
        sourceId
        paymentId
        status
        document
        serviceDetails
        voucher
        transactionType
        __typename
      }
    }
  }
`;

export const getPaymentQuery = gql`
  query GetPayment($uid: ID!) {
    getPayment(uid: $uid) {
      id
      uid
      amount
      paymentMethod
      sourceId
      paymentId
      status
      document
      serviceDetails
      voucher
      voucherDiscount

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getUserWalletQuery = gql`
  query GetUserWallet(
    $userUid: ID! 
    $action: String
    $paymentUid: String
  ) {
    getUserWallet(
      userUid: $userUid 
      action: $action
      paymentUid: $paymentUid
    ) {
      id
      uid
      userUid
      balance
      document
      soas {
        id
        uid
        walletUid
        action
        amount
        currentBalance
        previousBalance
        document

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getTuteeQuery = gql`
  query GetTutee($userUid: ID!) {
    getTutee(userUid: $userUid) {
      id
      uid
      userUid
      status
      others
    }
  }
`;

export const getTimeslotSessionQuery = gql`
  query GetTimeslotSession($timeslotUid: String!) {
    getTimeslotSession(timeslotUid: $timeslotUid) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      interest
      __typename
    }
  }
`;
/**
 * End of Query
 */

/**
 * Mutation
 */
export const publishRefundMutation = gql`
  mutation PublishRefund(
    $paymentUid: String!
    $tuteeUid: String!
    $userUid: String!
  ) {
    publishRefund(
      paymentUid: $paymentUid
      tuteeUid: $tuteeUid
      userUid: $userUid
    )
  }
`;
/**
 * End of Mutation
 */
