import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { GET_USER_COMMISSION } from './gql';
import { GET_ALL_USERS } from '../../superadmin/userManagement/gql';
import registryClient from '../../../RegistryClient';
import UserCommissionModal from './userCommissionModal';
import ViewCommissionsModal from './viewCommissionsModal';
import UserImage from '../../../components/userImage';
import { CustomTable } from '../../../components';
import moment from 'moment'
import { Button, Row, Col } from 'react-bootstrap'


export default function userList({ selectedRole }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchText, setSearchText] = useState(null)

  const { loading, data } = useQuery(GET_ALL_USERS, {
    client: registryClient,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      filter: {
        roleCodes: selectedRole,
      },
      exceptRoles: ['SUPERADMIN', 'MARKETING_ADMIN'],
    },
  });

  const result = _.has(data, 'getAllUsers') ? data.getAllUsers : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Name',
      dataKey: 'uid',
      render: (text, record) => {
        const userProfile = _.has(record, 'userProfile') ? record.userProfile : null;

        const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
        const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;
        const avatar = _.has(record, 'avatar') ? record.avatar : null;
        const avatarStorage = _.has(avatar, 'storage') ? avatar.storage : null;

        const avatarPath = _.has(avatarStorage, 'path') ? avatarStorage.path : null;

        return (
          <h2 className="table-avatar">
            <a href="profile.html" className="avatar avatar-sm me-2">
              <UserImage filePath={avatarPath} />

            </a>
            <a href="profile.html">
              {firstName}
              {' '}
              {lastName}
            </a>
          </h2>
        );
      },
    },
    {
      title: 'Commission',
      dataKey: 'commission',
      render: (text, record) => {
        const uid = _.has(record, 'uid') ? record.uid : null;
        const roleCode = _.has(record, 'roleCode') ? record.roleCode : null;

        return <UserCommission userUid={uid} roleCode={roleCode} />;
      },
    },
    {
      title: 'Effectivity Date',
      dataKey: 'effectivityDate',
      render: (text, record) => {
        const uid = _.has(record, 'uid') ? record.uid : null;
        const roleCode = _.has(record, 'roleCode') ? record.roleCode : null;

        return <UserCommissionEffectivityDate userUid={uid} roleCode={roleCode} />;
      },
    },
    {
      title: 'Action',
      dataKey: 'uid',
      render: (text, record) => {
        const id = _.has(record, 'id') ? record.id : null;
        const userUid = _.has(record, 'uid') ? record.uid : null;
        const userProfile = _.has(record, 'userProfile') ? record.userProfile : null;
        const roleCode = _.has(record, 'roleCode') ? record.roleCode : null;

        const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
        const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;
        return (
          <div className="d-flex gap-2" key={`user-${id}`}>
            <UserCommissionModal
              userUid={userUid}
              roleCode={roleCode}
              firstName={firstName}
              lastName={lastName}
            />
            <ViewCommissionsModal
              userUid={userUid}
              firstName={firstName}
              lastName={lastName}
            />
          </div>
        );
      },
    },
  ];

  const debounceSearch = _.debounce((value) => setSearchText(value), 500)

  const onSearch = (value) => {
    setPage(1);
    debounceSearch(value)
  };

  return <Row className='justify-content-end gap-4'>
    <Col lg={4} align='right'>
      <div className="input-group">
        <input
          type="text"
          onChange={(e) => onSearch(e.target.value)}
          placeholder="Search"
          className="form-control"
        />
        <Button
          type="button"
          className="btn btn-primary"
          onClick={(e) => e.preventDefault()}
        >
          <i className="fa fa-search" />

        </Button>
      </div>
    </Col>
    <Col lg={12}>
      <CustomTable
        loading={loading}
        columns={columns}
        page={page}
        pageSize={pageSize}
        totals={rowCount}
        dataValues={dataResult}
        onPageChange={(nPage) => setPage(nPage)}
      />
    </Col>

  </Row>
}

function UserCommission({ userUid, roleCode }) {
  const { data } = useQuery(GET_USER_COMMISSION, {
    fetchPolicy: ' network-only',
    variables: {
      userUid,
      roleCode,
    },
  });

  const result = _.has(data, 'getUserCommission') ? data.getUserCommission : null;
  const percentage = _.has(result, 'percentage') ? result.percentage : '--';

  return (
    <span>
      {percentage}
      %
    </span>
  );
}

function UserCommissionEffectivityDate({ userUid, roleCode }) {
  const { data } = useQuery(GET_USER_COMMISSION, {
    fetchPolicy: ' network-only',
    variables: {
      userUid,
      roleCode,
    },
  });

  const result = _.has(data, 'getUserCommission') ? data.getUserCommission : null;
  const effectivityDate = _.has(result, 'effectivityDate') ? result.effectivityDate : null;

  return (
    <span>
      {moment(effectivityDate).isValid() && moment(effectivityDate).format('LL')}
    </span>
  );
}