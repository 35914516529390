import React from 'react';
import { Badge } from 'react-bootstrap';

export default function voucherStatus({ status }) {
  switch (status) {
    case 'PUBLISHED':
      return <Badge bg="success">Published</Badge>;
    case 'DELETED':
      return <Badge bg="danger">Deleted</Badge>;
    case 'DRAFT':
      return <Badge bg="warning">Draft</Badge>;
    default:
      return '';
  }
}
