/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { AlertError } from '../../components'
import _ from 'lodash';
import { CHANGE_PASSWORD } from './gql'
import { useMutation } from '@apollo/client';
import LoginContext from '../login/login.context';
import client from '../../RegistryClient';
import useCreateAuditTrail from '../auditTrail/useCreateAuditTrail';
import auth from '../../authEmulatorConnect';
import { useNavigate } from 'react-router-dom';
import ls from 'local-storage';
import { signOut } from 'firebase/auth';


export default function changePasswordForm({ onSubmit }) {
  const { userUid, clearLogin } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();
  const navigate = useNavigate();

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)


  const {
    handleSubmit, formState, control, watch,
  } = useForm();
  const { errors } = formState;

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    client,
  });

  const logoutUser = useCallback(() => {
    signOut(auth).then(() => {
      // Sign-out successful.
      ls.clear();
      clearLogin();
      navigate('/');
    });
  });

  const onChangePassword = (passwordData, e) => {
    setSaving(true)
    setError(null)
    e.preventDefault();
    changePassword({
      variables: {
        userUid,
        ...passwordData,
      },
    }).then(() => {
      setSaving(false)
      doInsertAuditTrail({
        action: 'UPDATE',
        changes: 'Change password',
        module: 'My Profile',
      });
      logoutUser();
    }).catch((err) => {
      setSaving(false)
      setError(err.toString())
    });
  };


  return (
    <Form
      noValidate
      onSubmit={handleSubmit(onChangePassword)}
    >
      <Form.Group className="form-group" controlId="changePassword.controlInput1">
      { error && <AlertError
        title='Change password failed'
        error={error}
        onClose={() => {
          setError(null)
        }}
      />}
        <Form.Label className="form-control-label">Current Password</Form.Label>
        <Controller
          name="currentPassword"
          control={control}
          rules={{ required: 'Current Password is required.' }}
          autoFocus
          render={({ field }) => (
            <Form.Control
              autoFocus
              type="password"
              isInvalid={!!_.has(errors, 'currentPassword')}
              {...field}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'currentPassword') ? errors.currentPassword.message : 'Invalid password.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="form-group" controlId="changePassword.controlInput2">
        <Form.Label className="form-control-label">New Password</Form.Label>
        <Controller
          name="newPassword"
          control={control}
          rules={{ required: 'New Password is required.' }}
          autoFocus
          render={({ field }) => (
            <Form.Control
              autoFocus
              type="password"
              isInvalid={!!_.has(errors, 'newPassword')}
              {...field}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'newPassword') ? errors.newPassword.message : 'Invalid password.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="form-group" controlId="changePassword.controlInput3">
        <Form.Label className="form-control-label">Confirm Password</Form.Label>
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: 'Confirm new password is required.',
            validate: (value) => value === watch('newPassword') || 'The passwords do not match',
          }}
          autoFocus
          render={({ field }) => (
            <Form.Control
              autoFocus
              type="password"
              isInvalid={!!_.has(errors, 'confirmPassword')}
              {...field}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'confirmPassword') ? errors.confirmPassword.message : 'Invalid confirm password.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Button 
        className="btn btn-primary" 
        disabled={saving} 
        type="submit">
          {saving ? 'Saving...' : 'Save Changes' }
      </Button>
    </Form>
  );
}
