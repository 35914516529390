import React, {
  useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import {
  Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Ratings } from '../../../components';
import { LoginContext } from '../../login';
import { getStudentFinalRatingQuery } from './gql';

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState(null);
  const [counts, setCounts] = useState(null);
  const { instanceUid } = useContext(LoginContext);

  const { loading: loadingRatings, data: ratingsResult } = useQuery(getStudentFinalRatingQuery, {
    skip: !instanceUid,
    variables: { tuteeUid: instanceUid },
  });

  useEffect(() => {
    setLoading(loadingRatings);
  }, [loadingRatings]);

  useEffect(() => {
    if (ratingsResult) {
      const result = _.has(ratingsResult, 'getStudentFinalRating') ? ratingsResult.getStudentFinalRating : null;
      const finalRating = _.has(result, 'finalRating') ? result.finalRating : null;
      const iCount = _.has(result, 'counts') ? result.counts : null;

      setRatings(finalRating);
      setCounts(iCount);
    }
  }, [ratingsResult]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Overall Ratings
          {' '}
          {
            loading && (
              <Spinner
                animation="border"
                role="status"
                as="div"
                aria-hidden="true"
                size="sm"
                style={{ color: '#FE9445' }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )
          }
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col className="mb-3" lg={{ span: 6 }}>
            <Row>
              <Col lg={{ span: 12 }}>
                <p className="lead">
                  Overall Rating
                </p>
              </Col>

              <Col lg={{ span: 12 }}>
                <span className="text-info" style={{ fontSize: '42px', fontWeight: '400' }}>
                  <FontAwesomeIcon icon={solid('ranking-star')} />
                </span>
                {' '}
                <Ratings readonly size="xl" value={ratings} />
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 6 }}>
            <Row>
              <Col lg={{ span: 12 }}>
                <p className="lead">
                  {' '}
                  Rated Counts
                </p>
              </Col>

              <Col lg={{ span: 12 }}>
                <span className="text-info" style={{ fontSize: '42px', fontWeight: '400' }}>
                  <FontAwesomeIcon icon={solid('comments')} />
                  {' '}
                  {counts}
                </span>
              </Col>
            </Row>

          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
