/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Modal, Card, Form,
} from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import { Ratings } from '../../components';
import { rateStudentMutation, getUserQuery, getStudentSessionRatingQuery } from './gql';
import { LoginContext } from '../login';
import RegistryClient from '../../RegistryClient';
import useCreateAuditTrail from '../auditTrail/useCreateAuditTrail';
import SessionContext from './sessions.context';

const DivWrapper = styledComponents.div`
  .rating {
    display: inline-block;
  }
`;

const ViewContext = createContext();

export default function Index(payload) {
  const [view, setView] = useState(false);
  const { userUid, roleCode } = useContext(LoginContext);
  const {
    handleSubmit, formState, control, reset, watch,
  } = useForm();
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const {
    userUid: tuteeUserUid, sessionUid, tuteeUid, interest
  } = payload;
  const [tuteeName, setTuteeName] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [rating, setRating] = useState(null);
  const [canRate, setCanRate] = useState(false);
  const { sessionStatus } = useContext(SessionContext)

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  useEffect(() => {
    if (roleCode === 'TUTOR' && sessionStatus === 'COMPLETED') {
      setCanRate(true);
    }
  }, [roleCode, sessionStatus]);

  const { data: userResult, loading: loadingUser } = useQuery(getUserQuery, {
    skip: !tuteeUserUid,
    client: RegistryClient,
    variables: { uid: tuteeUserUid },
  });

  const {
    data: ratingResult,
    loading: loadingTuteeRating,
  } = useQuery(getStudentSessionRatingQuery, {
    skip: !tuteeUid || !sessionUid,
    variables: { tuteeUid, sessionUid },
  });

  useEffect(() => {
    if (userResult) {
      const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
      const profile = _.has(user, 'userProfile') ? user.userProfile : null;
      const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
      const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
      const iTuteeName = `${firstName} ${lastName}`;

      setTuteeName(iTuteeName);
    }
  }, [userResult]);

  useEffect(() => {
    if (ratingResult) {
      const result = _.has(ratingResult, 'getTuteeSessionRating') ? ratingResult.getTuteeSessionRating : null;
      const iFeedback = _.has(result, 'feedback') ? result.feedback : null;
      const iRating = _.has(result, 'ratings') ? result.ratings : null;
      console.log({ result })
      setFeedback(iFeedback);
      setRating(iRating);
    }
  }, [ratingResult]);

  const onClose = useCallback(() => {
    setView(false);
    reset();
  });

  const onShow = useCallback(() => {
    setView(true);
  });

  const [mutateReview, { loading: loadingReview }] = useMutation(rateStudentMutation, {
    onCompleted: () => {
      const ratings = watch('ratings');

      doInsertAuditTrail({
        action: 'RATE',
        changes: `${userFullName} Rated ${tuteeName} of ${interest} a ${ratings}-stars rating`,
        module: 'Sessions',
        details: {
          sessionUid,
        },
      });

      setRating(ratings);

      onClose();
    },
    update(cache, { data }) {
      const { rateStudent } = data;
      const studentRating = _.has(ratingResult, 'getTuteeSessionRating') ? ratingResult.getTuteeSessionRating : null;
      let studentRatingId = _.has(studentRating, 'id') ? studentRating.id : null;
      const iFeedback = _.has(rateStudent, 'feedback') ? rateStudent.feedback : null;
      const ratings = _.has(rateStudent, 'ratings') ? rateStudent.ratings : null;

      if (!studentRatingId) {
        studentRatingId = _.has(rateStudent, 'id') ? rateStudent.id : null;
      }

      cache.modify({
        id: `StudentRating:${studentRatingId}`,
        fields: {
          ratings() { return ratings; },
          feedback() { return iFeedback; },
        },
      });
    },
  });

  useEffect(() => {
    setLoading(loadingUser || loadingReview || loadingTuteeRating);
  }, [loadingUser, loadingReview, loadingTuteeRating]);

  const submitForm = useCallback((props) => {
    const variables = {
      ...props, tuteeUserUid, sessionUid, createdBy: userUid,
    };

    mutateReview({ variables });
  });

  const onRate = useCallback(() => {
    handleSubmit(submitForm)();
  });

  const viewPayload = useMemo(
    () => ({ feedback, rating, tuteeName }),
    [feedback, rating, tuteeName],
  );

  if (rating) {
    return (
      <ViewContext.Provider value={viewPayload}>
        <DivWrapper>
          <Ratings className="rating" value={rating} readonly />
          <ViewModal />
        </DivWrapper>
      </ViewContext.Provider>
    );
  }

  const validRating = (value) => {
    if (!value) {
      return 'Rating is required!'
    }

    return true
  }

  return (
    <>
      {canRate ? <Button variant="link" onClick={onShow}>Rate</Button> : `Waiting Tutor's rating`}

      <Modal
        show={view}
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rate Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>
                <p className="lead text-info">
                  <FontAwesomeIcon icon={solid('user-graduate')} />
                  {' '}
                  {tuteeName}
                </p>
              </Card.Title>
              <Card.Subtitle>Please rate your experience with this student.</Card.Subtitle>
              <br />
              <Form noValidate>
                <Form.Group className="form-group" controlId="rate.feedback">
                  <Form.Label>Feedback</Form.Label>
                  <Controller
                    name="feedback"
                    control={control}
                    rules={{ required: 'Feedback is required!' }}
                    render={({ field }) => (
                      <Form.Control
                        autoFocus
                        isInvalid={!!_.has(errors, 'feedback')}
                        as="textarea"
                        rows={4}
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'feedback') ? errors.feedback.message : 'Invalid feedback.'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="form-group" controlId="rate.ratings">
                  <Form.Label className="form-control-label">Ratings</Form.Label>
                  <Controller
                    name="ratings"
                    control={control}
                    rules={{ validate: validRating }}
                    render={({ field }) => (
                      <Form.Control
                        isInvalid={!!_.has(errors, 'ratings')}
                        {...field}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        as={(item) => <Ratings {...item} size="xl" />}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'ratings') ? errors.ratings.message : 'Invalid ratings.'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={onRate}
            disabled={loading}
          >
            <FontAwesomeIcon icon={solid('thumbs-up')} />
            {' '}
            {loading ? 'Loading...' : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ViewModal() {
  const [visible, setVisible] = useState(false);
  const { feedback, rating, tuteeName } = useContext(ViewContext);
  const { control, reset } = useForm({
    defaultValues: { feedback, ratings: rating },
  });

  const onClose = useCallback(() => {
    setVisible(false);
    reset();
  });

  const onShow = useCallback(() => {
    setVisible(true);
  });

  return (
    <>
      <Button
        className="view"
        variant="link"
        size="sm"
        onClick={onShow}
      >
        <FontAwesomeIcon icon={solid('eye')} />
      </Button>

      <Modal
        show={visible}
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Student Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>
                <p className="lead text-info">
                  <FontAwesomeIcon icon={solid('user-graduate')} />
                  {' '}
                  {tuteeName}
                </p>
              </Card.Title>

              <Form noValidate>
                <Form.Group className="form-group" controlId="rate.feedback">
                  <Form.Label>Feedback</Form.Label>
                  <Controller
                    name="feedback"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        as="textarea"
                        rows={4}
                        {...field}
                        disabled
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group className="form-group" controlId="rate.ratings">
                  <Form.Label className="form-control-label">Ratings</Form.Label>
                  <Controller
                    name="ratings"
                    control={control}
                    rules={{ required: 'Ratings is required.' }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        as={(item) => <Ratings {...item} size="xl" readonly />}
                      />
                    )}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
