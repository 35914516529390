import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Error403 from '../../template/components/error403';
import LoginContext from '../login/login.context';

export { default as SupportDashboard } from './dashboard';
export { default as SupportIssueAndResolution } from './issueAndResolution';
export { default as SupportTicketList } from './issueAndResolution/ticketList';

export default function Index() {
  const { roleCode } = useContext(LoginContext);

  if (roleCode !== 'SUPPORT') { return <Error403 />; }

  return (
    <Outlet />
  );
}
