import { useQuery } from '@apollo/client';
import React, {
  useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import RegistryClient from '../../../../RegistryClient';
import { getUserQuery } from './gql';
import { LoginContext } from '../../../login';
import AboutModalForm from '../qualifications/aboutModalForm';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [about, setAbout] = useState(null);
  const [canEdit, setCanEdit] = useState(true);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const iQualification = _.has(user, 'qualification') ? user.qualification : null;
    const iAbout = _.has(iQualification, 'about') ? iQualification.about : null;
    const iUserStatus = _.has(user, 'status') ? user.status : null;

    setAbout(iAbout);

    if (iUserStatus === 'FOR_REVIEW') { setCanEdit(false); } else { setCanEdit(true); }
  }, [userResult]);

  return (
    <div className="card col-10 me-auto ms-auto p-0">
      <div className="card-body custom-border-card pb-0">
        <div className="tab-content pt-0">
          <div role="tabpanel" id="biography" className="tab-pane fade show active">
            <div className="row">
              <div className="col-md-12">
                <div className="widget about-widget custom-about mb-0">
                  <h4 className="widget-title">
                    About Me
                    {' '}
                    {canEdit && <AboutModalForm />}
                  </h4>
                  <hr />
                  <p className="w-100">{about}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
