/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';
import InvoiceContext from './invoice.context';
import { getPaymentQuery, getTimeslotQuery, getUserQuery } from './gql';
import Invoice from './invoice';
import PaymentClient from '../../../PaymentClient';
import RegistryClient from '../../../RegistryClient';

const computeFees = (totalAmount) => {
  const vatableSales = parseFloat(totalAmount) / 1.12;
  const vat = (totalAmount - vatableSales);

  return {
    vatableSales: vatableSales.toFixed(2),
    vat: vat.toFixed(2),
    grossSales: (vatableSales + vat).toFixed(2),
  };
};

export default function Index() {
  const location = useLocation();
  const state = _.has(location, 'state') ? location.state : null;
  const paymentUid = _.has(state, 'paymentUid') ? state.paymentUid : null;
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [paidBy, setPaidBy] = useState(null);
  const [fees, setFees] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [payTypeNumber, setPayTypeNumber] = useState(null);
  const [address, setAddress] = useState(null);
  const [vatAmount, setVatAmount] = useState(null);

  const { data: paymentData } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid },
  });

  const { data: paidByData } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !createdBy,
    variables: { uid: createdBy },
  });

  const [fetchTimeslot] = useLazyQuery(getTimeslotQuery);

  useEffect(() => {
    const payment = _.has(paymentData, 'getPayment') ? paymentData.getPayment : null;
    const method = _.has(payment, 'paymentMethod') ? _.toUpper(payment.paymentMethod) : null;
    const createdAt = _.has(payment, 'paymentMethod') ? moment(payment.createdAt).format('YYYY/MM/DD HH:mm') : null;
    const paymentBy = _.has(payment, 'createdBy') ? payment.createdBy : null;
    const serviceDetails = _.has(payment, 'serviceDetails') ? payment.serviceDetails : null;
    const timeSlotUid = _.has(serviceDetails, 'timeSlotUid') ? serviceDetails.timeSlotUid : null;
    const start = _.has(serviceDetails, 'startDate') ? moment(serviceDetails.startDate).format('lll') : null;
    const amount = _.has(payment, 'amount') ? payment.amount : 0;
    const { vat, grossSales } = computeFees(amount);
    const document = _.has(payment, 'document') ? payment.document : null;
    const attributes = _.has(document, 'attributes') ? document.attributes : null;
    const source = _.has(attributes, 'source') ? attributes.source : null;
    const brand = _.has(source, 'brand') ? source.brand : null;
    const last4 = _.has(source, 'last4') ? source.last4 : null;
    const voucherDiscount = _.has(payment, 'voucherDiscount') ? payment.voucherDiscount : null;
    const computedDiscount = voucherDiscount - (2 * (voucherDiscount));

    if (timeSlotUid) {
      fetchTimeslot({ variables: { uid: timeSlotUid } })
        .then(({ data }) => {
          const getTimeslot = _.has(data, 'getTimeslot') ? data.getTimeslot : null;
          const tutorialProfile = _.has(getTimeslot, 'tutorialProfile') ? getTimeslot.tutorialProfile : null;
          const interest = _.has(tutorialProfile, 'interest') ? tutorialProfile.interest : null;

          const feeItem = {
            service: interest,
            date: start,
            vat,
            grossSales: grossSales - computedDiscount,
          };
          setFees([...fees, feeItem]);
        });
    }

    if (payment) {
      const payMethodMsg = brand ? `${method} ${_.toUpper(brand)}` : method;
      setPaymentMethod(payMethodMsg);
      if (last4) { setPayTypeNumber(`XXXXXXXXXXXX-${last4}`); }
      setCreatedDate(createdAt);
      setCreatedBy(paymentBy);
    }

    if (voucherDiscount) {
      setDiscount(computedDiscount);
    }

    setVatAmount(vat);
  }, [paymentData]);

  useEffect(() => {
    let total = 0;
    _.map(fees, (fee) => {
      const grossSales = _.has(fee, 'grossSales') ? parseFloat(fee.grossSales) : 0;

      total += grossSales;
    });

    setSubTotal((total).toFixed(2));
    setTotalAmount((total + discount).toFixed(2));
  }, [fees, discount]);

  useEffect(() => {
    const user = _.has(paidByData, 'getUser') ? paidByData.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const middleInitial = _.has(profile, 'middleInitial') ? profile.middleInitial : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const fullName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;
    const iAddress = _.has(profile, 'address') ? profile.address : null;
    const add1 = _.has(iAddress, 'address1') ? iAddress.address1 : null;
    const add2 = _.has(iAddress, 'address2') ? iAddress.address2 : null;
    const city = _.has(iAddress, 'city') ? iAddress.city : null;
    const countryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
    const postalCode = _.has(iAddress, 'postalCode') ? iAddress.postalCode : null;

    setPaidBy(fullName);
    setAddress(`${add1} ${add2} ${city}, ${countryCode} ${postalCode}`);
  }, [paidByData]);

  const contextPayload = useMemo(() => ({
    paymentUid,
    paymentMethod,
    createdDate,
    paidBy,
    fees,
    subTotal,
    discount,
    totalAmount,
    payTypeNumber,
    address,
    vatAmount,
  }), [
    paymentUid,
    paymentMethod,
    createdDate,
    paidBy,
    fees,
    subTotal,
    discount,
    totalAmount,
    payTypeNumber,
    address,
    vatAmount,
  ]);

  return (
    <InvoiceContext.Provider value={contextPayload}>
      <Invoice />
    </InvoiceContext.Provider>
  );
}
