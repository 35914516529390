import { gql } from '@apollo/client';

export const GET_USER = gql`
    query GetUser($uid: ID!) {
        getUser(uid: $uid) {
        id
        uid
        username
        email
        roleCode
        agreedPolicyTerm
        status
        userProfile {
          id
          uid
          userUid
          firstName
          middleInitial
          lastName
          age
          mobile
          birthDate
          gender
          address
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        avatar {
          id
          uid
          userUid
          fileCategory
          storage
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        }
    }
`;

export const CHANGE_PASSWORD = gql`
mutation ChangePassword($userUid: String!, $currentPassword: String!, $newPassword: String!) {
    changePassword(userUid: $userUid, currentPassword: $currentPassword, newPassword: $newPassword) {
      id
      uid
      username
      email
      roleCode
      agreedPolicyTerm
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_PROFILE = gql`
mutation UpdateProfile($userUid: String!, $firstName: String!, $lastName: String!, $updatedBy: String!, $middleInitial: String, $mobile: String, $birthDate: String, $gender: String, $address: JSON, $others: JSON) {
  updateProfile(userUid: $userUid, firstName: $firstName, lastName: $lastName, updatedBy: $updatedBy, middleInitial: $middleInitial, mobile: $mobile, birthDate: $birthDate, gender: $gender, address: $address, others: $others) {
    id
    uid
    userUid
    firstName
    middleInitial
    lastName
    age
    mobile
    birthDate
    gender
    address
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const SAVE_FILE = gql`
  mutation SaveFile(
    $userUid: String!
    $fileCategory: String!
    $storage: JSON!
  ) {
    saveFile(
      userUid: $userUid
      fileCategory: $fileCategory
      storage: $storage
    ) {
      id
      uid
      userUid
      fileCategory
      storage
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
