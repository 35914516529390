/* eslint-disable max-len */
import { ApolloClient, InMemoryCache } from '@apollo/client';
// import { toIdValue } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';

const uri = `${process.env.REACT_APP_REGISTRY_API}/graphql`;
export const cache = new InMemoryCache({
  cacheRedirects: {
    // avoid requesting the same data that already in the client that has different key
    Query: {
      // getAvpFile: (_, args) => toIdValue(cache.config.dataIdFromObject({ __typename: 'AVPFile', uid: args.uid })),
      // getTutorialVideo: (_, args) => toIdValue(cache.config.dataIdFromObject({ __typename: 'TutorialVideo', uid: args.uid })),
    },
  },
});

// eslint-disable-next-line new-cap
const link = new createUploadLink({
  uri,
  headers: {
    'Apollo-Require-Preflight': true,
  },
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
