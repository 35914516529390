import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Error403 from '../../template/components/error403';
import LoginContext from '../login/login.context';

export { default as RegisterStudent } from './registration';
export { default as StudentDashboard } from './dashboard';
export { default as StudentSessions } from './mySessions';
export { default as StudentProfile } from './myProfile';
export { default as StudentProfileSettings } from './myProfile/settings';
export { default as UploadStudentId } from './registration/uploadStudentId';
export { default as StudentIssueAndResolution } from './issueAndResolution';
export { default as StudentTicketList } from './issueAndResolution/ticketList';
export { default as StudentAnalytics } from './analytics';

export default function Index() {
  const { roleCode } = useContext(LoginContext);

  if (roleCode !== 'TUTEE') { return <Error403 />; }

  return (
    <Outlet />
  );
}
