import React, { useState } from 'react';
import RecruitmentList from './recruitmentList';
import TutorProfile from './tutorProfile';

export default function Index() {
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const handleSelect = (values) => {
    setSelectedProfile(values);
    setShowProfile(true);
  };

  const handleClose = () => {
    setSelectedProfile(null);
    setShowProfile(false);
  };

  return (
    <div>
      { !showProfile
        ? <RecruitmentList onSelectProfile={handleSelect} />
        : (
          <TutorProfile data={selectedProfile} onClose={handleClose} />
        )}
    </div>
  );
}
