import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import LoginContext from '../login/login.context';
import { DashboardTemplate } from '../../template/components';
import { GET_USERS_NOTIFICATIONS, DELETE_NOTIFICATION, READ_NOTIFICATION } from './gql';
import NotificationClient from '../../NotificationClient';
import DeleteAllModal from './deleteAllModal'
import MarkAllAsReadModal from './markAllAsReadModal';
import { AlertError } from '../../components';

import useMarketingNotification from './useMarketingNotification';
import useTutorNotification from './useTutorNotification';
import { LoadingSpinner } from '../../components';

export default function NotificationList() {
  const navigate = useNavigate();
  const { userUid } = useContext(LoginContext);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [totalRow, setTotalRow] = useState(0);
  const [error, setError] = useState(null)

  // For testing
  const [showTestButton] = useState(false);
  const { sendNotification: sendMarketingNotification } = useMarketingNotification({ userUid });
  const { sendNotification: sendTutorNotification } = useTutorNotification({ userUid });

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    client: NotificationClient,
    refetchQueries: ['getUnreadNotificationCount'],
  });
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    client: NotificationClient,
    refetchQueries: ['getUnreadNotificationCount'],
  });

  const { loading, data } = useQuery(GET_USERS_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    client: NotificationClient,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      userUid,
    },
  });

  useEffect(() => {
    const result = _.has(data, 'getUserNotifications') ? data.getUserNotifications : null;
    const notifications = _.has(result, 'rows') ? result.rows : [];
    const rowCount = _.has(result, 'count') ? result.count : 0;

    setDataList([...dataList, ...notifications]);
    setTotalRow(rowCount);
    return () => {
      setDataList([]);
      setTotalRow(0);
      setPage(1);
    };
  }, [loading, page]);

  const handleRead = ({ notificationUid }) => readNotification({
    variables: {
      userUid,
      notificationUid,
    },
  }).then((result) => {
    const dataResult = _.has(result, 'data') ? result.data : null;
    const notifData = _.has(dataResult, 'readNotification') ? dataResult.readNotification : null;
    const newSetDataList = dataList.map((item) => {
      if (item.uid === notificationUid) {
        return notifData;
      }
      return item;
    });
    setDataList(newSetDataList);
  });

  const handleDelete = ({ notificationUid }) => {
    deleteNotification({
      variables: {
        userUid,
        notificationUids: [notificationUid],
      },
    }).then(() => {
      setDataList(dataList.filter((item) => item.uid !== notificationUid));
    });
  };

  const handleClick = ({ uid, urlPath, urlState }, e) => {
    handleRead({ notificationUid: uid }).then(() => {
      if (urlPath && urlState) {
        navigate(urlPath, { state: urlState });
      }
    });
  };

  return (
    <DashboardTemplate>
      <Row className='gap-2'>
        <h3 className="page-title mb-4">Notifications</h3>

        {showTestButton && (
          <Row>
            <Col lg={6} className="d-flex mb-4 gap-4">
              <Button onClick={() => {
                sendTutorNotification({
                  title: 'Send notif',
                  message: 'Send to all tutors',
                });
              }}
              >
                Send to all Tutors
              </Button>
              <Button onClick={() => {
                sendMarketingNotification({
                  title: 'Send notif',
                  message: 'Send to all marketing',
                });
              }}
              >
                Send to all Marketing
              </Button>
            </Col>
          </Row>
        )}

        <Col lg={12}>
          <ButtonToolbar className="justify-content-end gap-2 mb-4">
            <MarkAllAsReadModal
              onError={({ title, message }) => {
                setError({ title, message })
              }}
              onSuccess={() => setError(null)}
            />
            <DeleteAllModal
              onError={({ title, message }) => {
                setError({ title, message })
              }}
              onSuccess={() => setError(null)}
            />
          </ButtonToolbar>
        </Col>

        {error && <Col lg={12}>
          <AlertError
            error={error.message}
            title={error.title}
            onClose={() => setError(null)}
          />
        </Col>
        }

        <Col lg={12}>
          <div style={{ height: 500, overflow: 'auto' }}>
            <InfiniteScroll
              useWindow={false}
              loadMore={() => {
                setTimeout(() => {
                  setPage(page + 1);
                }, 1500);
              }}
              hasMore={dataList.length < totalRow}
              loader={<div className="text-center"><LoadingSpinner /></div>}
              className="row gap-2"
            >
              {dataList && dataList.map((item, index) => {
                const attr = _.has(item, 'data') ? item.data : null;
                const urlPath = _.has(attr, 'urlPath') ? attr.urlPath : null;
                const urlState = _.has(attr, 'urlState') ? attr.urlState : null;

                return (
                  <Col lg={12}>
                    <Row
                      style={{
                        borderRadius: 10,
                        border: '1px solid rgba(231, 227, 255, 0.3)',
                        boxShadow: '0px 4px 14px rgb(185 185 185 / 12%)',
                        margin: 'auto',
                        padding: 10,
                        background: !item.seenAt ? '#EBECED' : '',
                      }}
                    >
                      <Col
                        lg={8}
                        aria-hidden="true"
                        onClick={(e) => handleClick({ uid: item.uid, urlPath, urlState }, e)}
                      >
                        <h4>{item.title}</h4>
                        <p>{item.message}</p>
                        <p className="text-muted">{moment(item.createdAt).isValid() && moment(item.createdAt).fromNow()}</p>
                      </Col>
                      <Col lg={4} className="m-auto text-end">
                        <div style={{ display: 'inline-flex', gap: 8 }}>
                          <Button
                            variant="primary"
                            disabled={item.seenAt}
                            onClick={() => handleRead({ notificationUid: item.uid })}
                          >
                            {!item.seenAt ? 'Mark as read' : 'Read'}
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => handleDelete({ notificationUid: item.uid })}
                          >
                            Delete
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </InfiniteScroll>
          </div>
        </Col>

      </Row>
    </DashboardTemplate>
  );
}
