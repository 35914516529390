import React, { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import {
  Alert, Button, Col, Row,
} from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import ls from 'local-storage';
import { useAlert } from 'react-alert';
import CashinContext from '../cashin.context';
import GcashIcon from '../../../../components/gcashIcon';
import { PaymentContext } from '../../payment.context';
import { addPaymentMutation } from '../gql';
import PaymentClient from '../../../../PaymentClient';
import { LoginContext } from '../../../login';
import { LoadingBlocker } from '../../../../components';

const PAYMENT_METHOD = 'GCASH';

export default function GcashPayment() {
  const { createGcashSource, getAbsoluteUrl } = useContext(PaymentContext);
  const { userUid } = useContext(LoginContext);
  const {
    canConfirm,
    amount,
  } = useContext(CashinContext);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [mutateAddPayment] = useMutation(addPaymentMutation, {
    client: PaymentClient,
    onCompleted: (result) => {
      const payment = _.has(result, 'addPayment') ? result.addPayment : null;
      const document = _.has(payment, 'document') ? payment.document : null;
      const sourceId = _.has(document, 'id') ? document.id : null;
      const attributes = _.has(document, 'attributes') ? document.attributes : null;
      const redirect = _.has(attributes, 'redirect') ? attributes.redirect : null;
      const checkoutUrl = _.has(redirect, 'checkout_url') ? redirect.checkout_url : null;

      ls.set('sourceId', sourceId);

      window.location.replace(checkoutUrl);
    },
  });

  const confirmPayment = useCallback(async () => {
    setLoading(true);

    const uri = getAbsoluteUrl('/');
    const redirect = {
      success: `${uri}payments/cashin/charge`,
      failed: `${uri}payments/cashin/failed`,
    };

    await createGcashSource({ method: PAYMENT_METHOD, totalAmount: amount, redirect })
      .then((source) => {
        const data = _.has(source, 'data') ? source.data : null;
        const sourceId = _.has(data, 'id') ? data.id : null;
        const attributes = _.has(data, 'attributes') ? data.attributes : null;
        const type = _.has(attributes, 'type') ? attributes.type : null;

        const variables = {
          sourceId,
          amount: parseFloat(amount),
          paymentMethod: type,
          createdBy: userUid,
          document: { ...data },
          serviceDetails: {
            transaction: 'WALLET',
            transactionType: 'CASHIN',
          },
        };

        mutateAddPayment({ variables });
      }).catch((err) => {
        const errors = _.has(err, 'errors') ? err.errors : []
        let errorMessage = JSON.stringify(err)

        _.map(errors, row => {
          const code = _.has(row, 'code') ? row.code : null
          const detail = _.has(row, 'detail') ? row.detail : null

          switch (code) {
            case 'parameter_below_minimum':
              errorMessage = `The value for amount cannot be less than 100.`
              break
            default:
              errorMessage = detail
          }
        })

        alert.error(errorMessage, { timeout: 5000 })

        setLoading(false)
      })
  }, [
    amount,
  ]);

  return (
    <>
      <Row>
        <Col className="mb-3" lg={12}>
          <GcashIcon style={{ width: '280px' }} />
          <Alert>
            You are about to pay using GCASH.
            Please make sure that you have sufficient balance in your wallet.
          </Alert>
        </Col>
        <Col lg={12}>
          <Button variant="primary" size="lg" onClick={confirmPayment} disabled={loading || !canConfirm}>
            {loading ? 'Processing Payment...' : 'Confirm and Pay'}
          </Button>
        </Col>
      </Row>
      <LoadingBlocker loading={loading}>
        <h3 className="text-muted">Payment inprogress, please wait...</h3>
      </LoadingBlocker>
    </>
  );
}
