/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import ls from 'local-storage';
import { useMutation } from '@apollo/client';
import Success from './success';
import Failed from './failed';
import ChargeContext from './charge.context';
import { completePaymentMutation, enrollToSessionMutation } from './gql';
import { LoginContext } from '../../login';
import PaymentClient from '../../../PaymentClient';
import styledComponents from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

const SEC_KEY = process.env.REACT_APP_SEC_KEY || null;
const base64Key = Buffer.from(SEC_KEY).toString('base64');

const retrieveSource = (sourceId) => new Promise((resolve, reject) => {
  try {
    if (!sourceId) { throw new Error('Invalid sourceId, please retry booking again!'); }

    const options = {
      method: 'GET',
      url: `https://api.paymongo.com/v1/sources/${sourceId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${base64Key}`,
      },
    };

    axios
      .request(options)
      .then((response) => { resolve(response.data); })
      .catch(reject);
  } catch (err) {
    reject(err);
  }
});

const chargePayment = (sourceId, amount) => new Promise((resolve, reject) => {
  try {
    if (!sourceId || !amount) { throw new Error('Invalid transaction, please try booking again!'); }

    const options = {
      method: 'POST',
      url: 'https://api.paymongo.com/v1/payments',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${base64Key}`,
      },
      data: {
        data: {
          attributes: {
            currency: 'PHP',
            amount,
            source: { id: sourceId, type: 'source' },
            statement_descriptor: 'LearnLive Tutorial Market',
          },
        },
      },
    };

    axios
      .request(options)
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  } catch (err) {
    reject(err);
  }
});

export default function Index() {
  const sourceId = ls.get('sourceId');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const navigate = useNavigate();
  const { userUid, instanceUid } = useContext(LoginContext);
  const [tutorName, setTutorName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [paymentUid, setPaymentUid] = useState(null);

  const [mutateEnroll] = useMutation(enrollToSessionMutation);

  const [mutateCompletePayment] = useMutation(completePaymentMutation, {
    client: PaymentClient,
    onCompleted: async (data) => {
      const result = _.has(data, 'completePayment') ? data.completePayment : null;
      const payUid = _.has(result, 'uid') ? result.uid : null;
      const serviceDetails = _.has(result, 'serviceDetails') ? result.serviceDetails : null;
      const start = _.has(serviceDetails, 'startDate') ? serviceDetails.startDate : null;
      const timeslotUid = _.has(serviceDetails, 'timeSlotUid') ? serviceDetails.timeSlotUid : null;
      const end = _.has(serviceDetails, 'endDate') ? serviceDetails.endDate : null;
      const tutor = _.has(serviceDetails, 'tutor') ? serviceDetails.tutor : null;
      const firstName = _.has(tutor, 'firstName') ? tutor.firstName : null;
      const middleInitial = _.has(tutor, 'middleInitial') ? tutor.middleInitial : null;
      const lastName = _.has(tutor, 'lastName') ? tutor.lastName : null;
      const fullName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;

      setPaymentUid(payUid);
      setTutorName(fullName);
      setStartDate(start);
      // setEndDate(end);

      await mutateEnroll({
        variables: {
          tuteeUid: instanceUid,
          timeslotUid,
          startDate: start,
          endDate: end,
          paymentUid: payUid,
          createdBy: userUid,
        },
      });
    },
  });

  useEffect(() => {
    if (sourceId) {
      // eslint-disable-next-line no-inner-declarations
      async function doCharge() {
        setLoading(true);
        const source = await retrieveSource(sourceId);
        const data = _.has(source, 'data') ? source.data : null;
        const attributes = _.has(data, 'attributes') ? data.attributes : null;
        const amount = _.has(attributes, 'amount') ? attributes.amount : 0;

        const paidResult = await chargePayment(sourceId, amount)
          .then(({ data: result }) => result)
          .catch((err) => {
            setError(err.toString());
          });
        const paymentId = _.has(paidResult, 'id') ? paidResult.id : null;

        await mutateCompletePayment({
          variables: {
            sourceId,
            paymentId,
            status: 'PAID',
            document: { ...paidResult },
            updatedBy: userUid,
          },
        });

        setPaymentDetails(paidResult);
        ls.remove('sourceId');
        setLoading(false);
      }

      doCharge();
    }
  }, [sourceId]);

  useEffect(() => {
    if (error) { navigate('/'); }
  }, [error]);

  const contextPayload = useMemo(() => ({
    error,
    paymentUid,
    paymentDetails,
    tutorName,
    startDate,
  }), [
    error,
    paymentUid,
    paymentDetails,
    tutorName,
    startDate,
  ]);

  return (
    <ChargeContext.Provider value={contextPayload}>
      {loading && <ProcessInprogress />}
      {paymentUid && <Success />}
      {error && <Failed />}
    </ChargeContext.Provider>
  );
}

function ProcessInprogress() {
  const navigate = useNavigate();

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Booking</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Booking</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fa fa-spin fa-spinner" aria-hidden="true" />

                    <h3>Processing Payment</h3>

                    <p>Payment in progress, please don't refresh or close the page...</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
