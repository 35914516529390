/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getSessionQuery = gql`
  query GetSession($uid: ID!) {
    getSession(uid: $uid) {
      id
      uid
      startDate
      endDate
      status
      link
      canJoin
      timeslot {
        id
        uid
        day
        start
        end
        status
        createdBy
        tutorialProfile {
          id
          uid
          tutorUid
          interest
          sessionType
          price
          status
          createdBy
          tutor {
            id
            uid
            userUid
            status
            others
            priceRange
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getAttendanceQuery = gql`
  query GetAttendance($sessionUid: ID! $instanceUid: String!) {
    getAttendance(sessionUid: $sessionUid instanceUid: $instanceUid) {
      id
      uid
      sessionUid
      instanceUid
      status
      startedAt
      endedAt

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      roleCode
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        __typename
      }
      __typename
    }
  }
`;

export const getSessionEnrolleesQuery = gql`
  query GetSessionEnrollees($sessionUid: ID!) {
    getSessionEnrollees(sessionUid: $sessionUid) {
      id
      uid
      tuteeUid
      sessionUid
      paymentUid
      attendanceStatus
      createdAt
      createdBy
      updatedAt
      updatedBy
      tutee {
        id
        uid
        userUid
        status
        others
      }
    }
  }
`;

export const getMySessionRatinQuery = gql`
  query GetMySessionRating(
    $userUid: String!
    $sessionUid: String!
  ) {
    getMySessionRating(
      userUid: $userUid
      sessionUid: $sessionUid
    ) {
      id
      uid
      sessionUid
      tutorUid
      review
      ratings
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getSessionFilesQuery = gql`
  query GetSessionFiles($sessionUid: String!) {
    getSessionFiles(sessionUid: $sessionUid) {
      id
      uid
      sessionUid
      fileCategory
      storage

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getStudentSessionRatingQuery = gql`
  query GetStudentSessionRating(
    $sessionUid: ID!
    $tuteeUid: String!
  ) {
    getTuteeSessionRating(
      sessionUid: $sessionUid
      tuteeUid: $tuteeUid
    ) {
      id
      uid
      sessionUid
      tuteeUid
      feedback
      ratings
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getPaymentQuery = gql`
  query GetPayment($uid: ID!) {
    getPayment(uid: $uid) {
      id
      uid
      amount
      paymentMethod
      sourceId
      paymentId
      status
      document
      serviceDetails
      voucher
      voucherDiscount
      confirmedDate

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const isFavoriteTutorQuery = gql`
  query IsFavoriteTutor($tutorUid: String!, $tuteeUid: String!) {
    isFavoriteTutor(tutorUid: $tutorUid, tuteeUid: $tuteeUid)
  }
`

/**
 * End of Query
 */

/**
 * Mutation
 */
export const endSessionMutation = gql`
  mutation EndSession(
    $sessionUid: ID! 
    $instanceUid: String!
    $privateNoteText: String
    $sharedNoteText: String
  ) {
    endSession(
      sessionUid: $sessionUid 
      instanceUid: $instanceUid
      privateNoteText: $privateNoteText
      sharedNoteText: $sharedNoteText
    ) {
      id
      uid
      timeslotUid
      startDate
      endDate
      link
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const joinSessionMutation = gql`
  mutation JoinSession($sessionUid: ID! $instanceUid: String!) {
    joinSession(sessionUid: $sessionUid instanceUid: $instanceUid) {
      id
      uid
      sessionUid
      instanceUid
      status
      startedAt
      endedAt

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const leaveSessionMutation = gql`
  mutation LeaveSession($attendanceUid: ID!) {
    leaveSession(attendanceUid: $attendanceUid) {
      id
      uid
      sessionUid
      instanceUid
      status
      startedAt
      endedAt

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const confirmWalletPaymentMutation = gql`
  mutation ConfirmWalletPayment($paymentUid: ID!) {
    confirmWalletPayment(paymentUid: $paymentUid) {
      id
      uid
      userUid
      balance
      chargedUid
      document
      soas {
        id
        uid
        walletUid
        action
        amount
        currentBalance
        previousBalance
        document
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const addReviewMutation = gql`
  mutation AddReview(
    $userUid: String!
    $sessionUid: String!
    $review: String!
    $ratings: Int!
    $favorite: Boolean
    $document: JSON
  ) {
    addReview(
      userUid: $userUid
      sessionUid: $sessionUid
      review: $review
      ratings: $ratings
      favorite: $favorite
      document: $document
    ) {
      id
      uid
      sessionUid
      tutorUid
      review
      ratings
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const addSessionFileMutation = gql`
  mutation AddSessionFile(
    $sessionUid: String!
    $fileCategory: String!
    $storage: JSON!
    $createdBy: String!
  ) {
    addSessionFile(
      sessionUid: $sessionUid
      fileCategory: $fileCategory
      storage: $storage
      createdBy: $createdBy
    ) {
      id
      uid
      sessionUid
      fileCategory
      storage

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const rateStudentMutation = gql`
  mutation RateStudent(
    $tuteeUserUid: String!
    $sessionUid: String!
    $feedback: String
    $ratings: Int!
    $document: JSON
    $createdBy: String!
  ) {
    rateStudent(
      tuteeUserUid: $tuteeUserUid
      sessionUid: $sessionUid
      feedback: $feedback
      ratings: $ratings
      document: $document
      createdBy: $createdBy
    ) {
      id
      uid
      sessionUid
      tuteeUid
      feedback
      ratings
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;
/**
 * End of Mutation
 */
