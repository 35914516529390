/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button, Modal, Alert,
} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from '../../../components';
import loginContext from '../../login/login.context';
import { releasePayslipsMutation } from './gql';
import PaymentClient from '../../../PaymentClient';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail'; 

export default function Index() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userUid } = useContext(loginContext);
  const { state } = useLocation();
  const payrollUid = _.has(state, 'uid') ? state.uid : null;

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const onClose = () => {
    setVisible(false);
  };

  const onShow = () => {
    setVisible(true);
  };

  const [releasePayslips] = useMutation(releasePayslipsMutation, {
    client: PaymentClient,
    onCompleted() {
      onClose();
    },
    update(cache, { data: { releasePayslip: payslip } }) {
      const paylipId = _.has(payslip, 'id') ? payslip.id : null;
      const newStatus = _.has(payslip, 'status') ? payslip.status : null;

      cache.modify({
        id: `Payslip:${paylipId}`,
        fields: {
          status: () => newStatus,
        },
      });
    },
  });

  const onSubmit = useCallback(() => {
    async function doSubmit() {
      setLoading(true);

      const variables = { payrollUid, updatedBy: userUid };

      await releasePayslips({ variables }).then((result => {
        const data = _.has(result, 'data') ? result.data : null
        const dataResult = _.has(data, 'releasePayslips') ? data.releasePayslips : null
        const startDate = _.has(dataResult, 'startDate') ? moment(dataResult.startDate).format('LL') : null
        const endDate = _.has(dataResult, 'endDate') ? moment(dataResult.endDate).format('LL') : null
        
        // audit trail
        doInsertAuditTrail({
          module: 'Payroll',
          changes: `${userFullName} released payslip for payroll generated on ${startDate} - ${endDate}`,
          action: 'CREATE',
        });
      }))


      setLoading(false);
    }

    doSubmit();
  }, [payrollUid, releasePayslips, userUid, userFullName]);

  return (
    <>
      <div className="d-grid gap-2">
        <Button
          variant="secondary"
          block
          onClick={onShow}
        >
          <FontAwesomeIcon icon={solid('money-bill-trend-up')} />
          {' '}
          Release All Payslips
        </Button>
      </div>

      <Modal
        show={visible}
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Release All Tutor's Payslip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            <Alert.Heading>
              <FontAwesomeIcon icon={solid('exclamation-circle')} />
              {' '}
              Reminder
            </Alert.Heading>
            <p className="lead">Releasing payslips will make it visible to all recepient tutors.</p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={loading}>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('circle-check')} />
                  {' '}
                  Confirm
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
