import { gql } from '@apollo/client';

export const GET_TOTAL_CLASS_HOURS = gql`
query getTotalClassHours($tuteeUid: String!, $dateRange: TuteeDateRange) {
    getTotalClassHours(tuteeUid: $tuteeUid, dateRange: $dateRange)
  }
`;

export const GET_TOTAL_REVIEWS = gql`
query Query($userUid: String!) {
    getStudentReviewCount(userUid: $userUid)
  }
`;

export const GET_TOTAL_SPEND = gql`
query getTotalSpend($userUid: String!, $year: String) {
    getTotalSpend(userUid: $userUid, year: $year)
  }
`;

export const GET_STUDENT_SESSION_STATUS_RATE = gql`
query GetStudentSessionStatusRate($tuteeUid: String!, $userUid: String!, $status: SessionStatusRate!) {
    getStudentSessionStatusRate(tuteeUid: $tuteeUid, userUid: $userUid, status: $status) {
      status
      totalSessions
      totalSessionByStatus
      percentage
    }
  }
`;
