import { gql } from '@apollo/client';

export const GET_TOTAL_ENROLLED_STUDENTS = gql`
query getEnrolledTuteesCount($tutorUid: String!) {
    getEnrolledTuteesCount(tutorUid: $tutorUid)
  }
`;

export const GET_TOTAL_TEACHERING_HOURS = gql`
query getTeacheringTotalHours($tutorUid: String!) {
    getTeacheringTotalHours(tutorUid: $tutorUid)
  }
`;

export const GET_TOTAL_EARNED = gql`
query getTotalEarned($tutorUid: String!, $monthYear: String) {
    getTotalEarned(tutorUid: $tutorUid, monthYear: $monthYear)
  }
`;

export const GET_TUTOR_RATING = gql`
query getTutorRating($tutorUid: String!) {
    getTutorRating(tutorUid: $tutorUid)
  }
`;

export const GET_TOTAL_PROFILE_VIEWS = gql`
query getTotalProfileViews($userUid: String!, $appCode: String!, $dateRange: AuditTrailDateRange) {
    getTotalProfileViews(userUid: $userUid, appCode: $appCode, dateRange: $dateRange)
  }
`;
