/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import ls from 'local-storage';
import { useMutation } from '@apollo/client';
import Success from './success';
import Failed from './failed';
import ChargeContext from './charge.context';
import { LoginContext } from '../../../login';
import { PaymentContext } from '../../payment.context';
import PaymentClient from '../../../../PaymentClient';
import { cashInMutation, completePaymentMutation } from '../gql';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';
import styledComponents from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function Index() {
  const sourceId = ls.get('sourceId');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userUid } = useContext(LoginContext);
  const [paymentUid, setPaymentUid] = useState(null);
  const { getSource, chargePayment } = useContext(PaymentContext);
  const [actualAmount, setActualAmount] = useState(0);
  const [contentState, setContentState] = useState('loading');

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const [mutateCashIn] = useMutation(cashInMutation, {
    client: PaymentClient,
  });

  const [mutateCompletePayment] = useMutation(completePaymentMutation, {
    client: PaymentClient,
  });

  useEffect(() => {
    async function doProccess() {
      setLoading(true);

      await getSource(sourceId).then(async (result) => {
        const source = _.has(result, 'data') ? result.data : null;
        const attributes = _.has(source, 'attributes') ? source.attributes : null;
        const amount = _.has(attributes, 'amount') ? attributes.amount : 0;

        const paidResult = await chargePayment(sourceId, amount)
          .then(({ data: paid }) => paid)
          .catch((err) => {
            const errors = _.has(err, 'errors') ? err.errors : [];
            const iError = errors.length && errors[0];
            const code = _.has(iError, 'code') ? iError.code : null;
            const detail = _.has(iError, 'detail') ? iError.detail : null;
            let message = '';

            switch (code) {
              default: message = detail;
            }

            setError(message);
          });

        if (paidResult) {
          const paymentId = _.has(paidResult, 'id') ? paidResult.id : null;

          await mutateCompletePayment({
            variables: {
              sourceId,
              paymentId,
              status: 'PAID',
              document: { ...paidResult },
              updatedBy: userUid,
            },
          }).then(({ data }) => {
            const paid = _.has(data, 'completePayment') ? data.completePayment : null;
            const payUid = _.has(paid, 'uid') ? paid.uid : null;
            const iAmount = _.has(paid, 'amount') ? paid.amount : 0;

            setPaymentUid(payUid);
            setActualAmount(iAmount);

            return mutateCashIn({
              variables: {
                userUid,
                amount: iAmount,
                document: {
                  paymentUid: payUid,
                  ...paidResult,
                },
              },
            }).catch(() => {
              const message = `Payment has completed but encountered an error. 
              Please report as soon as possible, alongside with this reference code "paymentUid=${payUid}"`;

              setError(message);
            });
          }).catch(() => {
            const message = `Payment has completed but encountered an error.
            Please report as soon as possible, alongside with this reference code "srcID=${sourceId}"`;

            setError(message);
          });
        }
      });

      ls.remove('sourceId');
      setLoading(false);
    }

    if (sourceId) {
      doProccess();
    }
  }, [sourceId]);

  useEffect(() => {
    if (loading) {
      setContentState('loading');
    }

    if (paymentUid && actualAmount > 0) {
      // audit trail
      doInsertAuditTrail({
        action: 'CASH IN',
        changes: `${userFullName} Top up an amount of ${actualAmount}`,
        module: 'Wallet',
      });
      setContentState('success');
    }

    if (error) {
      setContentState('error');
    }
  }, [loading, paymentUid, error]);

  useEffect(() => {
    if (!sourceId && !paymentUid) {
      setError('Invalid source ID of null value');
    } else {
      setError(null);
    }
  }, [sourceId, paymentUid]);

  const contextPayload = useMemo(() => ({
    error,
    paymentUid,
    amount: actualAmount,
    contentState,
  }), [
    error,
    paymentUid,
    actualAmount,
    contentState,
  ]);

  return (
    <ChargeContext.Provider value={contextPayload}>
      <RenderContent />
    </ChargeContext.Provider>
  );
}

function RenderContent() {
  const { contentState } = useContext(ChargeContext);

  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderItem() {
    switch (contentState) {
      case 'success': return <Success />;
      case 'error': return <Failed />;
      default: return <ProcessInprogress />;
    }
  }

  return (
    <RenderItem />
  );
}

function ProcessInprogress() {
  const navigate = useNavigate();

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cash In</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Cash In</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fa fa-spin fa-spinner" aria-hidden="true" />

                    <h3>Processing Payment</h3>

                    <p>Payment in progress, please don't refresh or close the page...</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
