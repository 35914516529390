/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getTutorPaginatedPayrollsQuery = gql`
  query GetTutorPaginatedPayrolls($tutorUid: String!, $page: Int!) {
    getTutorPaginatedPayrolls(tutorUid: $tutorUid, page: $page) {
      count
      rows {
        id
        uid
        payrollUid
        tutorUid
        tutor
        learnliveFees
        oneOnOne
        group
        grossPay
        fees
        withholding
        incentives
        deductions
        vat
        netPay
        createdAt
        status
        payroll {
          id
          uid
          type
          startDate
          endDate
          status
          __typename
        }
        payslip {
          id
          uid
          type
          totalGrossPay
          totalIncentives
          totalDeductions
          vat
          totalWithholding
          totalFees
          totalNetPay
          status
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

export const confirmPayrollReleaseMutation = gql`
  mutation ConfirmPayrollRelease($tutorPayrollUid: ID!, $updatedBy: String!) {
    confirmPayrollRelease(tutorPayrollUid: $tutorPayrollUid, updatedBy: $updatedBy) {
      id
      uid
      payrollUid
      tutorUid
      tutor
      createdAt
      status

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

/**
 * End of Mutation
 */
