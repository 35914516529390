/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useQuery } from '@apollo/client';
import { BlobProvider } from '@react-pdf/renderer';
import _ from 'lodash';
import { LoadingSpinner } from '../../../components';
import ViewPayrollContext from './viewPayroll.context';
import RenderPdf from '../../payroll/payslip/renderPdf';
import PaymentClient from '../../../PaymentClient';
import { getTutorPayslipQuery } from './gql';

export default function Index() {
  const { uid: tutorPayrollUid } = useContext(ViewPayrollContext);
  const [tutorPayslip, setTutorPayslip] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [canView, setView] = useState(false);

  const { loading: loadingPayslip, data: tutorPayslipResult } = useQuery(getTutorPayslipQuery, {
    client: PaymentClient,
    variables: { tutorPayrollUid },
  });

  useEffect(() => {
    const iTutorPayslip = _.has(tutorPayslipResult, 'getTutorPayslip') ? tutorPayslipResult.getTutorPayslip : null;

    if (iTutorPayslip) {
      setView(true);
    } else {
      setView(false);
    }

    setTutorPayslip(iTutorPayslip);
  }, [tutorPayslipResult]);

  useEffect(() => {
    setDisabled(loading || !tutorPayslip);
    setLoading(loadingPayslip);
  }, [loadingPayslip, tutorPayslip]);

  return (
    <div>
      {
        canView ? (
          <BlobProvider document={<RenderPdf tutorPayslip={tutorPayslip} />}>
            {({ url, loading: loadingPdf }) => (
              <div className="d-grid gap-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    window.open(url, '_blank');
                  }}
                  disabled={loadingPdf || disabled}
                >
                  {
                    loading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={solid('eye')} />
                        {' '}
                        View Payslip
                      </>
                    )
                  }
                </Button>
              </div>
            )}
          </BlobProvider>
        ) : ''
      }
    </div>
  );
}
