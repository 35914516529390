/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button, Modal, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from './sessions.context';
import { startSessionMutation } from './gql';
import { LoginContext } from '../../login';

function redirectBlank(url) {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  a.click();
}

export default function Index() {
  const [view, setView] = useState(false);
  const navigate = useNavigate();
  const { instanceUid } = useContext(LoginContext);

  const {
    sessionUid,
    interest,
    sessionType,
    canStart,
    sessionLink,
  } = useContext(SessionContext);

  const handleShow = useCallback(() => {
    setView(true);
  }, [sessionUid]);

  const handleClose = useCallback(() => {
    setView(false);
  }, [sessionUid]);

  const [mutateStart, { loading }] = useMutation(startSessionMutation, {
    onCompleted: () => {
      redirectBlank(sessionLink);
      navigate('/session', { state: { sessionUid } });
      handleClose();
    },
  });

  const submitForm = useCallback(() => {
    const variables = {
      sessionUid,
      instanceUid,
    };
    // console.log({ variables });
    mutateStart({ variables });
  }, [sessionUid]);

  return (
    <>
      <Button
        className="text-warning"
        variant="link"
        onClick={handleShow}
        disabled={!canStart}
      >
        <FontAwesomeIcon icon={solid('play')} />
        {' '}
        Start
      </Button>

      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Start Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>{interest}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{sessionType}</Card.Subtitle>

              <Card.Text>
                Are you really sure to start this session?
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Proceed'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
