import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CheckoutContext, { WalletContext } from './checkout.context';
import PaymentClient from '../../../PaymentClient';
import { getUserWalletQuery } from './gql';
import { LoginContext } from '../../login';
import CheckoutModal from './checkoutModal';
import { LoadingSpinner } from '../../../components';

const WalletIcon = styled.i`
  font-size: 6em;
  color: #fe9445;
`;

const WalletLoading = styled.span`
  font-size: 21px;
  vertical-align: middle;
  color: #fd7e14;
  display: inline-block;
`;

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false)
  const [allowPay, setAllowPay] = useState(false);
  const { grossSales } = useContext(CheckoutContext);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [walletUid, setWalletUid] = useState(null);

  const [fetchWallet] = useLazyQuery(getUserWalletQuery, {
    client: PaymentClient,
  });

  useEffect(() => {
    async function doFetchWallet() {
      setLoading(true)

      await fetchWallet({ variables: { userUid } }).then(({ data }) => {
        const wallet = _.has(data, 'getUserWallet') ? data.getUserWallet : null;
        const currentBalance = _.has(wallet, 'balance') ? parseFloat(wallet.balance) : 0;
        const uid = _.has(wallet, 'uid') ? wallet.uid : null;

        setBalance(currentBalance.toFixed(2));
        setWalletUid(uid);
      })

      setLoading(false)
    }

    if (userUid) {
      doFetchWallet()
    }
  }, [userUid]);

  useEffect(() => {
    const computeRemaining = parseFloat(balance) - parseFloat(grossSales);

    if (computeRemaining < 0) { setAllowPay(false); } else { setAllowPay(true); }

    setRemainingBalance(computeRemaining.toFixed(2));
  }, [grossSales, balance]);

  const contextPayload = useMemo(() => ({
    balance,
    remainingBalance,
    allowPay,
    walletUid,
    loading
  }), [
    balance,
    remainingBalance,
    allowPay,
    walletUid,
    loading
  ]);

  return (
    <WalletContext.Provider value={contextPayload}>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12}>
              <StyledBalance />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckoutModal />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </WalletContext.Provider>
  );
}

function Balance({ className }) {
  const { balance, remainingBalance, loading } = useContext(WalletContext);
  const navigate = useNavigate();

  const gotoCashin = useCallback(() => {
    navigate('/payments/cashin');
  });

  return (
    <Card className={`${className} wallet`}>
      <Card.Body>
        <Row>
          <Col lg={2}>
            <WalletIcon
              className="fa fa-wallet"
              aria-hidden="true"
            />
          </Col>
          <Col lg={10}>
            <Card.Title className="wallet__title">
              <p className="lead text-success">
                <span className="wallet__title--balance">
                  {loading ? (
                    <WalletLoading><LoadingSpinner /></WalletLoading>
                  ) : (<>₱ {balance}</>)}
                </span>
                {' '}
                <small className="text-muted">Current Balance</small>
              </p>
            </Card.Title>
            {
              remainingBalance < 0 && !loading && (
                <Card.Subtitle className="mb-2 text-danger">
                  You have insufficient balance, please cash in.
                </Card.Subtitle>
              )
            }
            <Card.Link className="cash-in" onClick={gotoCashin}>
              <FontAwesomeIcon icon={solid('money-bill')} />
              {' '}
              Cash In
            </Card.Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

const StyledBalance = styled(Balance)`
  .cash-in {
    color: #fe9445;
    font-weight: 500;
    font-size: 21px;
  }

  .cash-in:hover {
    cursor: pointer;
  }

  .totals__value {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
  }

  .wallet__title--balance {
    font-size: 2em;
  }
`;

Balance.propTypes = {
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
};
