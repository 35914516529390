import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import Sessions from './sessions';
import { DashboardTemplate } from '../../../template/components';
import { getPaginatedTutorSessionsQuery } from './gql';
import { LoginContext } from '../../login';
import SessionsContext from './sessions.context';

export default function Index() {
  const { instanceUid } = useContext(LoginContext);
  const [sessionList, setSessionList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [filterInterest, setFilterInterest] = useState();
  const [filterStudent, setFilterStudent] = useState();

  const { data: sessionsResult, loading } = useQuery(getPaginatedTutorSessionsQuery, {
    skip: !instanceUid,
    variables: {
      tutorUid: instanceUid,
      filterInterest,
      filterStudent,
    },
    pollInterval: 5000,
  });

  useEffect(() => {
    const sessions = _.has(sessionsResult, 'getPaginatedTutorSessions') ? sessionsResult.getPaginatedTutorSessions : null;
    const rows = _.has(sessions, 'rows') ? sessions.rows : [];

    setSessionList(rows);
  }, [sessionsResult]);

  const contextPayload = useMemo(() => ({
    sessionList,
    tutorList,
    setTutorList,
    setFilterInterest,
    setFilterStudent,
    loading,
  }), [
    sessionList,
    tutorList,
    setTutorList,
    setFilterInterest,
    setFilterStudent,
    loading,
  ]);

  return (
    <DashboardTemplate>
      <SessionsContext.Provider value={contextPayload}>
        <Sessions />
      </SessionsContext.Provider>
    </DashboardTemplate>
  );
}
