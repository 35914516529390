import React, { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import {
  Alert, Button, Card, Col, Row,
} from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import ls from 'local-storage';
import { useAlert } from 'react-alert';
import CheckoutContext from '../checkout.context';
import GcashIcon from '../../../../components/gcashIcon';
import { PaymentContext } from '../../payment.context';
import { addPaymentMutation } from '../gql';
import PaymentClient from '../../../../PaymentClient';
import { LoginContext } from '../../../login';

const PAYMENT_METHOD = 'GCASH';

export default function GcashPayment() {
  const { createGcashSource } = useContext(PaymentContext);
  const { userUid } = useContext(LoginContext);
  const {
    timeSlotUid,
    sessionType,
    startDate,
    endDate,
    grossSales,
    tutorProfile,
  } = useContext(CheckoutContext);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [mutateAddPayment] = useMutation(addPaymentMutation, {
    client: PaymentClient,
    onCompleted: (result) => {
      const payment = _.has(result, 'addPayment') ? result.addPayment : null;
      const document = _.has(payment, 'document') ? payment.document : null;
      const sourceId = _.has(document, 'id') ? document.id : null;
      const attributes = _.has(document, 'attributes') ? document.attributes : null;
      const redirect = _.has(attributes, 'redirect') ? attributes.redirect : null;
      const checkoutUrl = _.has(redirect, 'checkout_url') ? redirect.checkout_url : null;

      ls.set('sourceId', sourceId);

      window.location.href = checkoutUrl;
    },
  });

  const confirmPayment = useCallback(async () => {
    setLoading(true);

    await createGcashSource({ method: PAYMENT_METHOD, totalAmount: grossSales })
      .then((source) => {
        const data = _.has(source, 'data') ? source.data : null;
        const sourceId = _.has(data, 'id') ? data.id : null;
        const attributes = _.has(data, 'attributes') ? data.attributes : null;
        const type = _.has(attributes, 'type') ? attributes.type : null;

        const variables = {
          sourceId,
          amount: parseFloat(grossSales),
          paymentMethod: type,
          createdBy: userUid,
          document: { ...data },
          serviceDetails: {
            timeSlotUid,
            sessionType,
            startDate,
            endDate,
            tutor: { ...tutorProfile },
          },
        };

        mutateAddPayment({ variables });
      })
      .catch((err) => {
        const errors = _.has(err, 'errors') ? err.errors : []
        let errorMessage = JSON.stringify(err)

        _.map(errors, row => {
          const code = _.has(row, 'code') ? row.code : null
          const detail = _.has(row, 'detail') ? row.detail : null

          switch (code) {
            case 'parameter_below_minimum':
              errorMessage = `The value for amount cannot be less than 100.`
              break
            default:
              errorMessage = detail
          }
        })

        alert.error(errorMessage, { timeout: 5000 })
      });

    setLoading(false);
  }, [
    mutateAddPayment,
    grossSales,
    timeSlotUid,
    sessionType,
    startDate,
    endDate,
    tutorProfile,
  ]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col className="mb-3" lg={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <GcashIcon style={{
                      width: '16em',
                      marginTop: '24px',
                    }}
                    />
                  </Col>
                  <Col lg={8}>
                    <Alert>
                      <Alert.Heading>
                        <i className="fa fa-exclamation-circle" aria-hidden="true" />
                        {' '}
                        Reminder
                      </Alert.Heading>
                      <p>
                        You are about to pay using GCASH.
                        Please make sure that you have sufficient balance in your wallet.
                      </p>
                    </Alert>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={12}>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={confirmPayment} disabled={loading}>
                <i className="fa fa-shopping-cart" aria-hidden="true" />
                {' '}
                {loading ? 'Processing Payment...' : 'Confirm and Pay'}
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
