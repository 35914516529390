import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  Modal, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import _ from 'lodash';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import RegistryClient from '../../../../RegistryClient';
import { getUserQuery, saveFileMutation } from './gql';
import { LoginContext } from '../../../login';
import { UploaderContext } from '../../../../modules/uploader';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';

const ButtonIcon = styledComponents(Button)`
  background-color: #fe9445;
  border-radius: 50px;
  padding: 8px 12px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  border: unset;
  margin-right: 8px;

  &:hover, &:active, &:focus {
    background-color: #fe9445;
    border: unset;
    color: #ffffff;
  }
`;

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, handleSubmit, setValue,
  } = formPayload;
  const { errors } = formState;
  const { uploadImg } = useContext(UploaderContext);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const result = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const id = _.has(result, 'id') ? result.id : null;

    setUserId(id);
  }, [userResult]);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateFileSave] = useMutation(saveFileMutation, {
    client: RegistryClient,
    update(cache, { data }) {
      const { saveFile } = data;
      cache.modify({
        id: `User:${userId}`,
        fields: {
          avatar() {
            const newAvatarFrag = cache.writeFragment({
              data: saveFile,
              fragment: gql`
                fragment NewRef on UserFiles {
                  id
                  uid
                  userUid
                  fileCategory
                  storage
                  __typename
                }
              `,
            });

            return newAvatarFrag;
          },
        },
      });
    },
    onCompleted: () => {
      handleClose();
    },
  });

  const submit = useCallback((values) => {
    async function doSubmit() {
      setLoading(true);

      const avatar = _.has(values, 'avatar') ? values.avatar : null;
      const { type } = avatar;
      const splitType = type && type.split('/');
      const ext = splitType.length && _.last(splitType);
      const fileName = `avatar.${ext}`;

      await uploadImg({ file: avatar, fileName })
        .then((result) => {
          const { data: resultData } = result;

          return { ...resultData };
        }).then((uploadedFile) => {
          mutateFileSave({
            variables: { userUid, fileCategory: 'USER_AVATAR', storage: { ...uploadedFile } },
          }).then(() => {
            doInsertAuditTrail({
              action: 'UPDATE',
              module: 'Profile Settings',
              changes: `${userFullName} updated his/her avatar`,
            });
          });
        });

      setLoading(false);
    }

    doSubmit();
  });

  const onUpload = useCallback(() => {
    handleSubmit(submit)();
  });

  return (
    <>
      <OverlayTrigger
        key="edit-avatar"
        placement="top"
        overlay={(
          <Tooltip id="tooltip-edit-avatar">
            Upload avatar
          </Tooltip>
        )}
      >
        <ButtonIcon variant="default" onClick={handleShow}>
          <FontAwesomeIcon icon={solid('image')} />
        </ButtonIcon>
      </OverlayTrigger>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Col} sm={12} controlId="upload.avatar">
            <Form.Label>Avatar</Form.Label>
            <Controller
              name="avatar"
              control={control}
              rules={{ required: 'Avatar is required.' }}
              render={() => (
                <Form.Control
                  isInvalid={!!_.has(errors, 'avatar')}
                  type="file"
                  onChange={(e) => {
                    const fileList = e.target.files;

                    setValue('avatar', fileList[0]);
                  }}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'avatar') ? errors.avatar.message : 'Invalid image.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={onUpload}
            disabled={loading}
          >
            <FontAwesomeIcon icon={solid('file-arrow-up')} />
            {' '}
            {loading ? 'Loading...' : 'Upload'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
