import React, { useContext } from 'react';
import CheckoutForm from './checkoutForm';
import { LoginContext } from '../../login';
import Error403 from '../../../template/components/error403';

export default function Index() {
  const { roleCode } = useContext(LoginContext);

  if (roleCode !== 'TUTEE') {
    return <Error403 />;
  }

  return (
    <CheckoutForm />
  );
}
