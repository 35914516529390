import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import RegistryClient from '../../../../RegistryClient';
import { getUserQuery, sendForReviewMutation } from './gql';
import { LoginContext } from '../../../login';

import useMarketingNotification from '../../../notification/useMarketingNotification';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingSpinner } from '../../../../components';

const STATUS_FOR_APPLICATION = 'FOR_APPLICATION';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [showCanSend, setShowCanSend] = useState(false);

  const { sendNotification } = useMarketingNotification({ userUid });
  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const { data: userResult, refetch } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    let allowSend = true;
    const result = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const iUserStatus = _.has(result, 'status') ? result.status : null;
    const iProfile = _.has(result, 'userProfile') ? result.userProfile : null;
    const iStatus = _.has(iProfile, 'status') ? iProfile.status : null;

    if (iStatus !== 'COMPLETE') {
      allowSend = false;
    }

    setCanSend(allowSend);

    if (iUserStatus === STATUS_FOR_APPLICATION) {
      setShowCanSend(true);
    } else {
      setShowCanSend(false);
    }
  }, [userResult]);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateSave, { loading }] = useMutation(sendForReviewMutation, {
    client: RegistryClient,
    onCompleted: () => {
      setVisible(false);

      // // send notif to all marketing admin
      const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
      const profile = _.has(user, 'userProfile') ? user.userProfile : null;
      const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null;
      const iLastName = _.has(profile, 'lastName') ? profile.lastName : null;
      sendNotification({
        title: 'Application',
        message: `${iFirstName} ${iLastName} submitted an application`,
        data: {
          urlPath: '/marketing-admin/management-settings/tutor-recruitment',
        },
      });

      // insert audit trail
      doInsertAuditTrail({
        action: 'CREATE',
        changes: `${userFullName} Sent Application for Review`,
        module: 'My Profile',
      });
    },
  });

  const sendForReview = useCallback(() => {
    mutateSave({ variables: { userUid } });
  });

  const refresh = useCallback(() => {
    refetch()
  }, [refetch]);

  return (
    <>
      {
        showCanSend && (
          <Button
            className="mb-3 text-warning"
            variant="link"
            size="lg"
            onClick={handleShow}
          >
            <i className="fas fa-paper-plane" />
            {' '}
            Send for Review
          </Button>
        )
      }

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Application for Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            canSend ? (
              <Alert variant="warning">
                You are about to send your profile for review. Are you sure to proceed?
              </Alert>
            ) : (
              <Alert variant="danger">
                Sorry, you need to complete the &quot;Qualifications&quot; details to proceed!
              </Alert>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={refresh} disabled={loading}>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('refresh')} />
                  {' '}
                  Refresh
                </>
              )
            }
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={sendForReview}
            disabled={loading || !canSend}
          >
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('paper-plane')} />
                  {' '}
                  Send
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
