import React, { useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { DashboardTemplate } from '../../../template/components';
import AverageRatingBarChart from './averageRatingBarChart';
import AttendanceOverallRate from './attendanceBarChart';
import TutorsStudentBarChart from './tutorsStudentBarChart';
import TutorsTeacheringHoursBarChart from './tutorsTeacheringHoursBarChart';
import Reviews from './reviews';
import styledComponents from 'styled-components';

const CustomNav = styledComponents(Nav)`
  .nav-link.active {
    color: #FE9445;
  }
`

export default function Index() {
  const [current, setCurrent] = useState('aveTeacherRatings')

  return (
    <DashboardTemplate>
      <Row>
        <Col lg={12} className="mb-3">
          <CustomNav
            activeKey={current}
            onSelect={(selectedKey) => setCurrent(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link eventKey="/aveTeacherRatings">Average Review Ratings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tutorHours">Tutor's Hours</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tutorsStudents">Tutor's Students</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="attendanceRate">Attendance Rates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="studentsReviews">Student's Reviews</Nav.Link>
            </Nav.Item>
          </CustomNav>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <LoadContent content={current} />
        </Col>
      </Row>
    </DashboardTemplate >
  );
}

const LoadContent = (payload) => {
  const { content } = payload

  switch (content) {
    case 'studentsReviews':
      return (
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h5>List Of Reviews Students To All Tutors</h5>
            </div>
          </div>
          <div className="card-body">
            <Reviews />
          </div>
        </div>
      )
    case 'attendanceRate':
      return (
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h5>Overall rate of attendance</h5>
            </div>
          </div>
          <div className="card-body">
            <AttendanceOverallRate />
          </div>
        </div>
      )
    case 'tutorsStudents':
      return (
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h5>Total tutors students</h5>
            </div>
          </div>
          <div className="card-body">
            <TutorsStudentBarChart />
          </div>
        </div>
      )
    case 'tutorHours':
      return (
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h5>Total Tutors tutoring hours </h5>
            </div>
          </div>
          <div className="card-body">
            <TutorsTeacheringHoursBarChart />
          </div>
        </div>
      )
    // case 'tutorsStudents':
    //   return (
    //     <div className="card">
    //       <div className="card-header">
    //         <div className="card-title">
    //           <h5>Total Tutors teachering hours </h5>
    //         </div>
    //       </div>
    //       <div className="card-body">
    //         <TutorsTeacheringHoursBarChart />
    //       </div>
    //     </div>
    //   )
    default:
      return (
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h5>Average Review Rating of all Tutors </h5>
            </div>
          </div>
          <div className="card-body">
            <AverageRatingBarChart />
          </div>
        </div>
      )
  }
}
