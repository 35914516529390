import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTable } from '../../../components';
import { GET_PAGINATED_TICKETS } from '../../issueAndResolution/gql';
import TicketingClient from '../../../TicketingClient';
import SelectTicketStatus from '../../../components/selectTicketStatus';

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const { data, loading } = useQuery(GET_PAGINATED_TICKETS, {
    client: TicketingClient,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      filter: {
        appCode: APPCODE,
        status: selectedStatus,
      },
    },
  });

  const result = _.has(data, 'getPaginatedTickets') ? data.getPaginatedTickets : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Ticket Number',
      dataKey: 'ticketNumber',
    },
    {
      title: 'Category',
      dataKey: 'category',
      render: (record) => {
        const categoryName = _.has(record, 'name') ? record.name : '';
        return categoryName;
      },
    },
    {
      title: 'Details',
      dataKey: 'details',
      render: (text) => (
        <p style={{
          width: 300, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
        }}
        >
          {text}
        </p>
      ),
    },
    {
      title: 'Requestor Name',
      dataKey: 'createdByName',
    },
    {
      title: 'Date Created',
      dataKey: 'createdAt',
      render: (text) => moment(text).isValid() && moment(text).format('LLL'),
    },
    {
      title: 'Status',
      dataKey: 'status',
    },
    {
      title: 'Action',
      dataKey: 'uid',
      render: (text) => (
        <Button
          onClick={() => { navigate(`${location.pathname}/view`, { state: { ticketUid: text } }); }}
        >
          View
        </Button>
      ),
    },
  ];

  const debounceSearch = _.debounce((value) => setSearchText(value), 500)

  const onSearch = (value) => {
    setPage(1);
    debounceSearch(value)
  };

  return (
    <Row className="gap-2">
      <Col lg={12}>
        <Row className="justify-content-end mb-4">
          <Col lg={4} className="d-flex">
            <Button
              size="lg"
              variant="link"
              title="Settings"
              onClick={() => navigate(`${location.pathname}/settings`)}
            >
              <i className="fa fa-cog" />
            </Button>
            <div className="input-group">
              <input
                type="text"
                onChange={(e) => onSearch(e.target.value)}
                placeholder="Search here"
                className="form-control"
              />
              <Button
                type="button"
                className="btn btn-primary"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-search" />

              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={3} className="mb-2">
        <SelectTicketStatus onChange={(value) => setSelectedStatus(value)} />
      </Col>
      <Col lg={12}>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <CustomTable
                loading={loading}
                columns={columns}
                page={page}
                pageSize={pageSize}
                totals={rowCount}
                dataValues={dataResult}
                onPageChange={(nPage) => setPage(nPage)}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
