import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import _ from 'lodash'
import { AddTimeslotModal } from './components';
import { useNavigate } from 'react-router-dom';
import { TimeslotsContext } from '../pages/tutor/timeslots'
import { getTutorialProfile } from '../pages/tutor/timeslots/gql';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment'
import { deleteTimeslotMutation } from '../pages/tutor/timeslots/gql';
import { LoginContext } from '../pages/login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default () => {
  const [activeTab, setActiveTab] = useState('sun')
  const navigate = useNavigate()
  const { state } = useContext(TimeslotsContext)
  const uid = _.has(state, 'uid') ? state.uid : null
  const [interest, setInterest] = useState(null)
  const [price, setPrice] = useState(null)
  const [sessionType, setSessionType] = useState(null)
  const [createdAt, setCreatedAt] = useState(null)
  const [sunSlots, setSunSlots] = useState([])
  const [monSlots, setMonSlots] = useState([])
  const [tueSlots, setTueSlots] = useState([])
  const [wedSlots, setWedSlots] = useState([])
  const [thuSlots, setThuSlots] = useState([])
  const [friSlots, setFriSlots] = useState([])
  const [satSlots, setSatSlots] = useState([])

  const { data: tutorial, refetch } = useQuery(getTutorialProfile, {
    skip: !uid,
    variables: { uid }
  })

  useEffect(() => {
    const result = _.has(tutorial, 'getTutorialProfile') ? tutorial.getTutorialProfile : null
    let interest = _.has(result, 'interest') ? result.interest : null
    let price = _.has(result, 'price') ? parseFloat(result.price).toFixed(2) : null
    let sessionType = _.has(result, 'sessionType') ? result.sessionType : null
    let timeslots = _.has(result, 'timeslots') ? result.timeslots : []
    let createdAt = _.has(result, 'createdAt') ? moment(result.createdAt).format('lll') : null

    setInterest(interest)
    setPrice(price)
    setSessionType(sessionType)
    setCreatedAt(createdAt)

    let sunSlots = _.filter(timeslots, { day: 'SUNDAY' })
    let monSlots = _.filter(timeslots, { day: 'MONDAY' })
    let tueSlots = _.filter(timeslots, { day: 'TUESDAY' })
    let wedSlots = _.filter(timeslots, { day: 'WEDNESDAY' })
    let thuSlots = _.filter(timeslots, { day: 'THURSDAY' })
    let friSlots = _.filter(timeslots, { day: 'FRIDAY' })
    let satSlots = _.filter(timeslots, { day: 'SATURDAY' })

    const ordSunSlots = _.orderBy(sunSlots, ['start'], ['asc'])
    const ordMonSlots = _.orderBy(monSlots, ['start'], ['asc'])
    const ordTueSlots = _.orderBy(tueSlots, ['start'], ['asc'])
    const ordWedSlots = _.orderBy(wedSlots, ['start'], ['asc'])
    const ordThuSlots = _.orderBy(thuSlots, ['start'], ['asc'])
    const ordFriSlots = _.orderBy(friSlots, ['start'], ['asc'])
    const ordSatSlots = _.orderBy(satSlots, ['start'], ['asc'])

    setSunSlots(ordSunSlots)
    setMonSlots(ordMonSlots)
    setTueSlots(ordTueSlots)
    setWedSlots(ordWedSlots)
    setThuSlots(ordThuSlots)
    setFriSlots(ordFriSlots)
    setSatSlots(ordSatSlots)
  }, [tutorial])

  const onSelectTab = useCallback((tab) => {
    setActiveTab(tab)
  }, setActiveTab)

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Schedule Timings</h4>
              <div className="profile-box">
                <p class="h5">Interest <small class="text-muted">{interest}</small></p>
                <p class="h6">Price <small class="text-muted">{price}</small></p>
                <p class="h6">Session <small class="text-muted">{sessionType}</small></p>
                <p class="h6">Date Created <small class="text-muted">{createdAt}</small></p>

                <Row>
                  <Col lg={12}>
                    <div className="card schedule-widget mb-0">

                      <div className="schedule-header">
                        <div className="schedule-nav">
                          <Nav
                            className="nav nav-tabs nav-justified"
                            variant="pills"
                            defaultActiveKey="sun"
                            onSelect={onSelectTab}
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="sun" data-bs-toggle="tab">Sunday</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="mon">Monday</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="tue">Tuesday</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="wed">Wednesday</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="thu">Thursday</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fri">Friday</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="sat">Saturday</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>

                      <div className="tab-content schedule-cont">
                        <div id="slot_sunday" className={`tab-pane ${activeTab !== 'sun' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'SUNDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !sunSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                sunSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>

                        <div id="slot_monday" className={`tab-pane ${activeTab !== 'mon' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'MONDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !monSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                monSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>


                        <div id="slot_tuesday" className={`tab-pane ${activeTab !== 'tue' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'TUESDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !tueSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                tueSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>

                        <div id="slot_wednesday" className={`tab-pane ${activeTab !== 'wed' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'WEDNESDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !wedSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                wedSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>


                        <div id="slot_thursday" className={`tab-pane ${activeTab !== 'thu' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'THURSDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !thuSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                thuSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>


                        <div id="slot_friday" className={`tab-pane ${activeTab !== 'fri' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'FRIDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !friSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                friSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>

                        <div id="slot_saturday" className={`tab-pane ${activeTab !== 'sat' ? 'd-none' : 'active'}`}>
                          <h4 className="card-title d-flex justify-content-between">
                            <span>Time Slots</span>
                            <AddTimeslotModal day={'SATURDAY'} />
                          </h4>
                          <Button className="edit-link" variant="link" onClick={() => refetch()}>
                            <FontAwesomeIcon icon={solid('refresh')} />
                            {' '}
                            Refresh
                          </Button>

                          <div className="user-times">
                            {
                              !satSlots.length ?
                                <p className="text-muted mb-0">Not Available</p> :
                                satSlots.map(slot => <SlotListItem {...slot} />)
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div class="card-footer">
              <Button variant="link" className="text-warning" onClick={() => navigate(-1)}>
                <i class="fa fa-arrow-left" aria-hidden="true"></i>{' '}Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const SlotListItem = (payload) => {
  const { userUid } = useContext(LoginContext);
  const id = _.has(payload, 'id') ? payload.id : null
  const uid = _.has(payload, 'uid') ? payload.uid : null
  const start = _.has(payload, 'start') ? moment(payload.start, 'HH:mm:ss').format('LT') : null
  const end = _.has(payload, 'end') ? moment(payload.end, 'HH:mm:ss').format('LT') : null

  const [mutateDeleteTimeslot] = useMutation(deleteTimeslotMutation, {
    onCompleted: (result) => {
      const { deleteTimeslot: data } = result;

      return data
    },
    update(cache) {
      cache.modify({
        id: `Timeslot:${id}`,
        fields(existing, { readField }) {
          return uid !== readField('uid')
        },
      });
    },
  });

  const deleteTimeslot = useCallback(() => {
    const variables = { uid, deletedBy: userUid };

    mutateDeleteTimeslot({ variables });
  }, [uid]);

  return (
    <div className="user-slot-list">
      {start} - {end}
      {/* <a href="javascript:void(0)" className="delete_schedule"> */}
      <Button variant='link' className='text-danger'>
        <i className="fa fa-times" onClick={deleteTimeslot}></i>
      </Button>
      {/* </a> */}
    </div>
  )
}


