/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */

export const paginatedPayrollsQuery = gql`
  query GetPaginatedPayrolls($page: Int!, $pageSize: Int) {
    getPaginatedPayrolls(page: $page, pageSize: $pageSize) {
      count
      rows {
        id
        uid
        type
        startDate
        endDate
        payDate
        status

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
      }
      __typename
    }
  }
`;

export const getCommissionQuery = gql`
  query GetDefaultCommission($roleCode: String!) {
    getDefaultCommission(roleCode: $roleCode) {
      id
      roleCode
      percentage
      effectivityDate
      __typename
    }
  }
`;

export const getPaginatedTutorsPayrollsQuery = gql`
  query GetPaginatedTutorsPayrolls(
      $payrollUid: ID!
      $page: Int!
      $pageSize: Int
    ) {
      getPaginatedTutorsPayrolls(
        payrollUid: $payrollUid
        page: $page
        pageSize: $pageSize
      ) {
        count
        rows {
          id
          uid
          payrollUid
          tutorUid
          tutor
          learnliveFees
          oneOnOne
          group
          grossPay
          withholding
          incentives
          deductions
          fees
          vat
          netPay
          payrollItems {
            id
            uid
            tutorPayrollUid
            paymentUid
            amount
            document
            __typename
          }
          payrollAdjustments {
            id
            uid
            tutorPayrollUid
            type
            amount
            status
            document
            __typename
          }
          createdAt
          createdBy
          __typename
        }
      }
    }
`;

export const getTutorPayrollQuery = gql`
  query GetTutorPayroll($uid: ID!) {
    getTutorPayroll(uid: $uid) {
      id
      uid
      payrollUid
      tutorUid
      tutor
      learnliveFees
      oneOnOne
      group
      subTotalGrossPay
      grossPay
      fees
      withholding
      incentives
      deductions
      vat
      vatPercent
      netPay
      payrollItems {
        id
        uid
        tutorPayrollUid
        paymentUid
        amount
        document
        paidBy
        grossPay
        fees
        vat
        netPay
        __typename
      }
      payrollAdjustments {
        id
        uid
        tutorPayrollUid
        type
        amount
        status
        document
        __typename
      }
      birForm {
        id
        uid
        tutorPayrollUid
        storage
        __typename
      }
      createdAt
      createdBy
      __typename
    }
  }
`;

export const getPayrollQuery = gql`
  query GetPayroll($uid: ID!) {
    getPayroll(uid: $uid) {
      id
      uid
      type
      startDate
      endDate
      payDate
      commissionRate
      status
      createdAt
      createdBy
      __typename
    }
  }
`;

export const getTutorPayrollItemsQuery = gql`
  query GetTutorPayrollItems($tutorPayrollUid: ID!) {
    getTutorPayrollItems(tutorPayrollUid: $tutorPayrollUid) {
      id
      uid
      type
      tutorPayrollUid
      paymentUid
      amount
      document
      createdAt
      createdBy
      __typename
    }
  }
`;

export const getTutorPayslipQuery = gql`
  query GetTutorPayslip($tutorPayrollUid: String!) {
    getTutorPayslip(tutorPayrollUid: $tutorPayrollUid) {
      id
      uid
      tutorPayrollUid
      type
      subTotalGrossPay
      totalGrossPay
      totalIncentives
      totalDeductions
      vat
      totalWithholding
      totalFees
      totalNetPay
      status
      createdAt
      createdBy
      tutorPayroll {
        id
        uid
        tutor
        vat
        vatPercent
        payroll {
          id
          uid
          startDate
          endDate
          __typename
        }
        birForm {
          id
          uid
          tutorPayrollUid
          storage
          __typename
        }
        __typename
      }
      payslipItems {
        id
        uid
        type
        studentUserUid
        sessionName
        sessionType
        startDate
        endDate
        grossPay
        vat
        fees
        netPay
        document
        __typename
      }
      __typename
    }
  }
`;

export const getPaymentQuery = gql`
  query GetPayment($uid: ID!) {
    getPayment(uid: $uid) {
      id
      uid
      amount
      paymentMethod
      sourceId
      paymentId
      status
      document
      serviceDetails
      voucher
      voucherDiscount
      confirmedDate

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
/**
 * Queries End
 */

/**
 * Mutations
 */

export const createPayrollMutation = gql`
  mutation CreatePayroll(
    $type: String!
    $startDate: String!
    $endDate: String!
    $payDate: String
    $commissionRate: Float!
    $createdBy: String!
  ) {
    createPayroll(
      type: $type
      startDate: $startDate
      endDate: $endDate
      payDate: $payDate
      commissionRate: $commissionRate
      createdBy: $createdBy
    ) {
      id
      uid
      type
      startDate
      endDate
      payDate
      status

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const createAdjustmentMutation = gql`
  mutation CreateAdjustment(
    $type: String!
    $tutorPayrollUid: String!
    $amount: Float!
    $document: JSON
    $createdBy: String!
  ) {
    createAdjustment(
      type: $type
      tutorPayrollUid: $tutorPayrollUid
      amount: $amount
      document: $document
      createdBy: $createdBy
    ) {
      id
      uid
      type
      tutorPayrollUid
      amount
      status
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const generatePayslipMutation = gql`
  mutation GeneratePayslip(
    $payrollUid: ID!
  ) {
    generatePayslips(payrollUid: $payrollUid) {
      id
      uid
      type
      startDate
      endDate
      payDate
      status
      __typename
    }
  }
`;

export const releasePayslipMutation = gql`
  mutation ReleasePayslip($uid: ID! $updatedBy: String!) {
    releasePayslip(uid: $uid updatedBy: $updatedBy) {
      id
      uid
      tutorPayrollUid
      type
      totalGrossPay
      totalIncentives
      totalDeductions
      vat
      totalWithholding
      totalFees
      totalNetPay
      status
      createdAt
      createdBy
      __typename
    }
  }
`;

export const releasePayslipsMutation = gql`
  mutation ReleasePayslips($payrollUid: ID! $updatedBy: String!) {
    releasePayslips(payrollUid: $payrollUid updatedBy: $updatedBy) {
      id
      uid
      type
      startDate
      endDate
      payDate
      status
      __typename
    }
  }
`;

export const publishReleasePayrollsMutation = gql`
  mutation PublishReleasePayrolls($payrollUid: ID! $updatedBy: String!) {
    publishReleasePayrolls(payrollUid: $payrollUid updatedBy: $updatedBy) {
      id
      uid
      type
      startDate
      endDate
      payDate
      status
      __typename
    }
  }
`;

export const saveBirFormMutation = gql`
  mutation SaveBirFile(
    $userUid: String!
    $tutorPayrollUid: String!
    $storage: JSON!
  ) {
    saveBirFile(
      userUid: $userUid
      tutorPayrollUid: $tutorPayrollUid
      storage: $storage
    ) {
      id
      uid
      tutorPayrollUid
      storage

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const deleteAdjustmentMutation = gql`
  mutation DeleteAdjustment(
    $uid: ID!
    $deletedBy: String!
  ) {
    deleteAdjustment(
      uid: $uid
      deletedBy: $deletedBy
    )
  }
`;

/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
