import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import RegVoucherTable, { DownloadExcel as RegDownloadExcel } from './regVoucherTable';
import SessionVoucherTable, { DownloadExcel as SessDownloadExcel } from './sessionVoucherTable';

export default function Index() {
  return (
    <>
      <h3 className="page-title mb-4">System Vouchers</h3>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Card.Title>Student Registration</Card.Title>
              <Card.Subtitle style={{ marginBottom: '1em' }}>
                First 100 student registration
              </Card.Subtitle>
              <RegDownloadExcel />
              <RegVoucherTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Card.Title>Student Booked Sessions</Card.Title>
              <Card.Subtitle style={{ marginBottom: '1em' }}>
                First 100 students who reached 15 booked sessions in a month
                <SessDownloadExcel />
              </Card.Subtitle>
              <SessionVoucherTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
