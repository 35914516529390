/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getTutorialProfile = gql`
  query GetTutorialProfile($uid: ID!) {
    getTutorialProfile(uid: $uid) {
      id
      uid
      tutorUid
      interest
      sessionType
      price
      status
      timeslots {
        id
        uid
        tutorialProfileUid
        day
        start
        end
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const validateTimeslotQuery = gql`
  query ValidateTimeslot(
    $tutorUid: String!
    $day: String!
    $start: String!
    $end: String!
  ) {
    validateTimeslot(
      tutorUid: $tutorUid
      day: $day
      start: $start
      end: $end
    )
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

export const addTimeslotMutation = gql`
  mutation AddTimeslot(
    $tutorialProfileUid: String! 
    $day: String! 
    $start: String!
    $end: String!
    $createdBy: String!
  ) {
    addTimeslot(
      tutorialProfileUid: $tutorialProfileUid
      day: $day
      start: $start
      end: $end
      createdBy: $createdBy
    ) {
      id
      uid
      tutorialProfileUid
      day
      start
      end
      status
      createdBy
      __typename
    }
  }
`;

export const deleteTimeslotMutation = gql`
  mutation DeleteTimeslot(
    $uid: ID!
    $deletedBy: String!
  ) {
    deleteTimeslot(
      uid: $uid
      deletedBy: $deletedBy
    )
  }
`;

/**
 * End of Mutation
 */
