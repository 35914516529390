import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useIdleTimer } from 'react-idle-timer';

import LoginContext from '../login/login.context';
import Header from './header';
import Footer from './footer';
import NAVS from '../../constants/navigation';

export default function Index() {
  const navigate = useNavigate();
  const { userUid, emailVerified, logout } = useContext(LoginContext);

  useEffect(() => {
    if (userUid && !emailVerified) { navigate('/email-verification', { replace: true }); }
  }, [userUid, emailVerified]);

  const onIdle = () => {
    if (userUid) { logout(); }
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 60, // @NOTE autologout if inactive for 1 hour
    // timeout: 1000 * 10, //@NOTE autologout if inactive for 10 seconds for testing only
  });

  return (
    <div className="main-wrapper">
      <HeaderNavigation />
      <Outlet />
      <Footer />
    </div>
  );
}

function HeaderNavigation() {
  const {
    roleCode = 'DEFAULT', userUid, logout,
  } = useContext(LoginContext);
  const { navigation } = NAVS;
  const found = _.find(navigation, { roleCode });
  const links = _.has(found, 'links') ? found.links : [];
  const [authenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    if (userUid) { setAuthenticated(true); } else { setAuthenticated(false); }
  }, [userUid]);

  const onLogout = useCallback(() => {
    logout();
    setAuthenticated(false);
  });

  return (
    <Header navs={links} onLogout={onLogout} authenticated={authenticated} />
  );
}
