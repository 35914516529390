import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Alert,
  Button,
  Modal,
} from 'react-bootstrap';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import RegistryClient from '../../../../RegistryClient';
import { getUserQuery, sendForReviewMutation } from './gql';
import { LoginContext } from '../../../login';
import styledComponents from 'styled-components'

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  vertical-align: unset;
`;

const STATUS_FOR_APPLICATION = 'FOR_APPLICATION';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [, setShowCanSend] = useState(false);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    let allowSend = true;
    const result = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const iUserStatus = _.has(result, 'status') ? result.status : null;
    const iProfile = _.has(result, 'userProfile') ? result.userProfile : null;
    const iStatus = _.has(iProfile, 'status') ? iProfile.status : null;

    if (iStatus !== 'COMPLETE') {
      allowSend = false;
    }

    setCanSend(allowSend);

    if (iUserStatus === STATUS_FOR_APPLICATION) {
      setShowCanSend(true);
    } else {
      setShowCanSend(false);
    }
  }, [userResult]);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateSave, { loading }] = useMutation(sendForReviewMutation, {
    client: RegistryClient,
    onCompleted: () => {
      setVisible(false);
    },
  });

  const sendForReview = useCallback(() => {
    mutateSave({ variables: { userUid } });
  });

  return (
    <>
      {/* <a
        href="javascript:void(0)"
        onClick={handleShow}
        className="btn-blue"
      >
        <i className="fas fa-paper-plane" />
      </a> */}
      <StyledButtonNav
        variant='link'
        onClick={handleShow}
        className="btn-blue"
      >
        <i className="fas fa-paper-plane" />
      </StyledButtonNav>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Application for Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            canSend ? (
              <Alert variant="warning">
                You are about to send your profile for review. Are you sure to proceed?
              </Alert>
            ) : (
              <Alert variant="danger">
                Sorry, you need to complete the &quot;Qualifications&quot; details to proceed!
              </Alert>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={sendForReview}
            disabled={loading || !canSend}
          >
            {loading ? 'Loading...' : 'Send'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
