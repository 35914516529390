/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import ls from 'local-storage';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { LoadingBlocker } from '../../../../components';
import CashinContext from '../cashin.context';
import { PaymentContext } from '../../payment.context';
import { addPaymentMutation } from '../gql';
import PaymentClient from '../../../../PaymentClient';
import { LoginContext } from '../../../login';

export default function CreditCard() {
  const {
    handleSubmit, formState, control,
  } = useForm();
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const {
    createPaymentIntent,
    createPaymentMethod,
    attachPayment,
    getAbsoluteUrl,
  } = useContext(PaymentContext);
  const {
    canConfirm,
    amount,
  } = useContext(CashinContext);
  const { userUid } = useContext(LoginContext);
  const alert = useAlert();
  const navigate = useNavigate();

  const [mutateAddPayment] = useMutation(addPaymentMutation, {
    client: PaymentClient,
  });

  const confirmPayment = useCallback(async (data) => {
    setLoading(true);

    const { cardName, ...etc } = data;
    const convertedAmount = parseFloat(amount) * 100;
    const description = 'Learnlive Wallet Cash In';

    await createPaymentIntent({
      amount: convertedAmount, description,
    })
      .then(async (intent) => {
        try {
          const intentData = _.has(intent, 'data') ? intent.data : null;
          const intentId = _.has(intentData, 'id') ? intentData.id : null;
          const intentAttrib = _.has(intentData, 'attributes') ? intentData.attributes : null;
          const clientKey = _.has(intentAttrib, 'client_key') ? intentAttrib.client_key : null;

          const paymentMethod = await createPaymentMethod({
            details: { ...etc },
            billing: { name: cardName },
          }).then((result) => (_.has(result, 'data') ? result.data : null))

          const paymentMethodId = _.has(paymentMethod, 'id') ? paymentMethod.id : null;
          const attributes = _.has(paymentMethod, 'attributes') ? paymentMethod.attributes : null;
          const type = _.has(attributes, 'type') ? attributes.type : null;

          const variables = {
            sourceId: intentId,
            amount: parseFloat(amount),
            paymentMethod: type,
            createdBy: userUid,
            document: { ...paymentMethod },
            serviceDetails: {
              transaction: 'WALLET',
              transactionType: 'CASHIN',
            },
          };

          mutateAddPayment({ variables });

          const uri = getAbsoluteUrl('/');
          const returnUrl = `${uri}payments/cashin/charge-cc`;

          return attachPayment({
            intentId, paymentMethodId, clientKey, returnUrl,
          })
            .then((result) => {
              if (!result) { throw new Error('Invalid attach payment result!'); }
              const payIntent = _.has(result, 'data') ? result.data : null;
              const payAttrib = _.has(payIntent, 'attributes') ? payIntent.attributes : null;
              const payStatus = _.has(payAttrib, 'status') ? payAttrib.status : null;
              const nextAction = _.has(payAttrib, 'next_action') ? payAttrib.next_action : null;
              const redirects = _.has(nextAction, 'redirect') ? nextAction.redirect : null;
              const url = _.has(redirects, 'url') ? redirects.url : null;
              const lastPaymentError = _.has(payAttrib, 'last_payment_error') ? payAttrib.last_payment_error : null;

              switch (payStatus) {
                case 'awaiting_next_action':
                  ls.set('paymentIntentId', intentId);
                  ls.set('clientKey', clientKey);

                  window.location.href = url;
                  break;
                case 'succeeded':
                  navigate('/payments/cashin/charge-cc', {
                    state: { paymentIntentId: intentId, clientKey }, replace: true,
                  });
                  break;
                case 'processing':
                  return attachPayment({ intentId, paymentMethodId, clientKey });
                default: throw new Error(lastPaymentError);
              }

              return payIntent;
            });
        } catch (err) {
          throw err;
        }
      }).catch((err) => {
        const errors = _.has(err, 'errors') ? err.errors : []
        let errorMessage = JSON.stringify(err)

        _.map(errors, row => {
          const code = _.has(row, 'code') ? row.code : null
          const detail = _.has(row, 'detail') ? row.detail : null

          switch (code) {
            case 'parameter_below_minimum':
              errorMessage = `The value for amount cannot be less than 100.`
              break
            default:
              errorMessage = detail
          }
        })

        alert.error(errorMessage, { timeout: 5000 })

        setLoading(false)
      })
  }, [
    userUid,
    amount,
  ]);

  const validateExpMonth = (value) => {
    var reg = /^\d+$/
    const valid = reg.test(value)
    const length = value.length

    if (length !== 2) {
      return 'Please input 2 digits month format'
    }

    if (!valid) {
      return 'Invalid number format'
    }

    if (value === 0 || value > 12) {
      return 'Invalid month number'
    }

    return true
  }

  const validateCardNumber = (value) => {
    var reg = /^\d+$/
    const valid = reg.test(value)

    if (!valid) {
      return 'Invalid number format'
    }

    return true
  }

  const validateExpYear = (value) => {
    var reg = /^\d+$/
    const valid = reg.test(value)
    const length = value.length

    if (length !== 4) {
      return 'Please input 4 digits year format'
    }

    if (!valid) {
      return 'Invalid number format'
    }

    return true
  }

  const validateCvc = (value) => {
    var reg = /^\d+$/
    const valid = reg.test(value)

    if (!valid) {
      return 'Invalid number format'
    }

    return true
  }

  const triggerPayment = useCallback(() => {
    handleSubmit(confirmPayment)();
  });

  return (
    <>
      <h5>Credit Card</h5>
      <Form noValidate>
        <Row>
          <Form.Group className="form-group card-label" as={Col} controlId="cc.cardName" lg={6}>
            <Form.Label>Name on Card</Form.Label>
            <Controller
              name="cardName"
              control={control}
              rules={{ required: 'Card name is required.' }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'cardName')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'cardName') ? errors.cardName.message : 'Invalid card name.'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group card-label" as={Col} controlId="cc.cardNumber" lg={6}>
            <Form.Label>Card Number</Form.Label>
            <Controller
              name="cardNumber"
              control={control}
              rules={{
                required: 'Card Number is required.',
                validate: validateCardNumber
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'cardNumber')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'cardNumber') ? errors.cardNumber.message : 'Invalid card number.'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group card-label" as={Col} controlId="cc.expMonth" lg={4}>
            <Form.Label>Expiry Month</Form.Label>
            <Controller
              name="expMonth"
              control={control}
              rules={{
                required: 'Expiry month is required.',
                validate: validateExpMonth
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'expMonth')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'expMonth') ? errors.expMonth.message : 'Invalid expiry month.'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group card-label" as={Col} controlId="cc.expYear" lg={4}>
            <Form.Label>Expiry Year</Form.Label>
            <Controller
              name="expYear"
              control={control}
              rules={{
                required: 'Expiry year is required.',
                validate: validateExpYear
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'expYear')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'expYear') ? errors.expYear.message : 'Invalid expiry year.'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group card-label" as={Col} controlId="cc.cvc" lg={4}>
            <Form.Label>CVC</Form.Label>
            <Controller
              name="cvc"
              control={control}
              rules={{
                required: 'CVC is required.',
                validate: validateCvc
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'cvc')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'cvc') ? errors.cvc.message : 'Invalid cvc.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <Button variant="primary" size="lg" onClick={triggerPayment} disabled={loading || !canConfirm}>
              {loading ? 'Processing Payment...' : 'Confirm and Pay'}
            </Button>
          </Col>
        </Row>
      </Form>

      <LoadingBlocker loading={loading}>
        <h3 className="text-muted">Payment inprogress, please wait...</h3>
      </LoadingBlocker>
    </>
  );
}
