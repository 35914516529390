import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import TicketingClient from '../../../TicketingClient';
import { GET_TOTAL_TICKETS } from './gql';
import { LoadingSpinner } from '../../../components'

export default function ticketCountlist() {
  const { data, loading } = useQuery(GET_TOTAL_TICKETS, {
    client: TicketingClient,
  });

  const resultData = _.has(data, 'getTotalTickets') ? data.getTotalTickets : null;
  const openTicket = resultData && resultData.find((res) => res.status === 'OPEN');
  const openTicketCount = _.has(openTicket, 'total') ? parseInt(openTicket.total, 10) : 0;

  const closeTicket = resultData && resultData.find((res) => res.status === 'CLOSED');
  const closeTicketCount = _.has(closeTicket, 'total') ? parseInt(closeTicket.total, 10) : 0;

  const totalReportedTickets = openTicketCount + closeTicketCount;

  return (
    <Row>
      <Col lg={4} className="dash-board-list blue">
        <div className="dash-widget">
          <div className="circle-bar">
            <div className="icon-col">
              <i className="fas fa-users" />
            </div>
          </div>
          <div className="dash-widget-info">
            <h3>
              {!loading ? totalReportedTickets : <LoadingSpinner text=''/>}
            </h3>
            <h6>Reported Tickets</h6>
          </div>
        </div>
      </Col>
      <Col lg={4} className="dash-board-list yellow">
        <div className="dash-widget">
          <div className="circle-bar">
            <div className="icon-col">
              <i className="fas fa-users" />
            </div>
          </div>
          <div className="dash-widget-info">
            <h3>
              {!loading ? openTicketCount : <LoadingSpinner text=''/>}
            </h3>
            <h6>Open Tickets</h6>
          </div>
        </div>
      </Col>
      <Col lg={4} className="dash-board-list pink">
        <div className="dash-widget">
          <div className="circle-bar">
            <div className="icon-col">
              <i className="fas fa-users" />
            </div>
          </div>
          <div className="dash-widget-info">
            <h3>
              {!loading ? closeTicketCount : <LoadingSpinner text=''/>}
            </h3>
            <h6>Closed Tickets</h6>
          </div>
        </div>
      </Col>
    </Row>
  );
}
