/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */
export const getSessionQuery = gql`
 query GetSession($uid: ID!) {
   getSession(uid: $uid) {
     id
     uid
     startDate
     endDate
     status
     link
     canJoin
     timeslot {
       id
       uid
       day
       start
       end
       status
       createdBy
       tutorialProfile {
         id
         uid
         tutorUid
         interest
         sessionType
         price
         status
         createdBy
         tutor {
           id
           uid
           userUid
           status
           others
           priceRange
           __typename
         }
         __typename
       }
       __typename
     }
     __typename
   }
 }
`;

export const getSessionCalendarSlotsQuery = gql`
  query GetSessionCalendarSlots($uid: String!) {
    getSessionCalendarSlots(uid: $uid)
  }
`;

export const getTutorQuery = gql`
  query GetTutor($uid: ID!) {
    getTutor(uid: $uid) {
      id
      uid
      userUid
      status
      createdAt
      tutorials {
        id
        uid
        interest
        sessionType
        price
        status
        timeslots {
          id
          uid
          tutorialProfileUid
          day
          start
          end
          status
          sessions {
            id
            uid
            startDate
            endDate
            enrollees {
              id
              uid
              tuteeUid
              attendanceStatus
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const isEnrolledQuery = gql`
  query IsStudentEnrolled($instanceUid: String! $timeslotUid: String! $startDate: String! $endDate: String!) {
    isStudentEnrolled(instanceUid: $instanceUid timeslotUid: $timeslotUid startDate: $startDate endDate: $endDate)
  }
`;
/**
 * Queries End
 */

/**
 * Mutations
 */

export const rescheduleMutation = gql`
  mutation rescheduleStudentSession(
    $sessionUid: String!
    $tuteeUid: String!
    $timeslotUid: String!
    $startDate: String!
    $endDate: String!
    $remark: String
    $updatedBy: String!
  ) {
    rescheduleStudentSession(
      sessionUid: $sessionUid
      tuteeUid: $tuteeUid
      timeslotUid: $timeslotUid
      startDate: $startDate
      endDate: $endDate
      remark: $remark
      updatedBy: $updatedBy
    ) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      link
      canStart
      canJoin
      interest
      __typename
    }
  }
`;

export const rescheduleTutorMutation = gql`
  mutation rescheduleTutorSession(
    $sessionUid: String!
    $timeslotUid: String!
    $startDate: String!
    $endDate: String!
    $updatedBy: String!
  ) {
    rescheduleTutorSession(
      sessionUid: $sessionUid
      timeslotUid: $timeslotUid
      startDate: $startDate
      endDate: $endDate
      updatedBy: $updatedBy
    ) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      link
      canStart
      canJoin
      interest
      enrollees {
        id
        uid
        status
        createdBy
      }
      __typename
    }
  }
`;

/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
