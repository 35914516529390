/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Modal, Card,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import styledComponents from 'styled-components';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { LoginContext } from '../../login';
import {
  addPaymentMutation, chargeWalletMutation, completePaymentMutation, enrollToSessionMutation,
} from './gql';
import PaymentClient from '../../../PaymentClient';
import CheckoutContext, { WalletContext } from './checkout.context';
import { LoadingSpinner } from '../../../components';

const PAYMENT_METHOD = 'WALLET';
const SuccessContext = createContext();

const StyledCard = styledComponents(Card)`
  .wallet__title--balance {
    font-size: 2em;
  }

  .wallet__title--remaining {
    font-size: 2em;
  }
`;

export default function Index() {
  const { userUid, instanceUid } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();
  const {
    walletUid, balance, remainingBalance, allowPay,
  } = useContext(WalletContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    timeSlotUid,
    sessionType,
    startDate,
    endDate,
    grossSales,
    tutorProfile,
    feesList,
    voucher,
  } = useContext(CheckoutContext);
  const [showSuccess, setShowSuccess] = useState(false);

  const onShow = useCallback(() => {
    setVisible(true);
  });

  const onClose = useCallback(() => {
    setVisible(false);
  });

  const [mutateAddPayment] = useMutation(addPaymentMutation, { client: PaymentClient });
  const [mutateCompletePayment] = useMutation(completePaymentMutation, { client: PaymentClient });
  const [mutateChargeWallet] = useMutation(chargeWalletMutation, { client: PaymentClient });
  const [mutateEnroll] = useMutation(enrollToSessionMutation, {
    onCompleted: () => {
      // audit trail
      if (feesList && feesList.length > 0) {
        // eslint-disable-next-line array-callback-return
        feesList.map((list) => {
          const sessionName = _.has(list, 'item') ? list.item : null;
          doInsertAuditTrail({
            action: 'ENROLL',
            changes: `Enrolled to ${sessionName} on ${startDate && moment(startDate).format('lll')} `,
            module: 'Booking',
            details: { timeSlotUid },
          });
        });
      }

      setVisible(false);
      setShowSuccess(true);
    },
  });

  const confirmPayment = useCallback(() => {
    async function doCharge() {
      setLoading(true);

      const serviceDetails = {
        timeSlotUid,
        sessionType,
        startDate,
        endDate,
        tutor: { ...tutorProfile },
      };

      const amount = parseFloat(grossSales);
      const sourceId = `wal_${moment().valueOf()}`;

      const payDocument = {
        walletUid,
        balance,
        amount,
        remainingBalance,
        timeSlotUid,
      };

      const voucherCode = _.has(voucher, 'code') ? voucher.code : null;

      const variables = {
        sourceId,
        amount,
        paymentMethod: PAYMENT_METHOD,
        createdBy: userUid,
        document: payDocument,
        serviceDetails,
      };

      if (voucher) {
        Object.assign(variables, { voucher: voucherCode });
      }

      await mutateAddPayment({ variables })
        .then(({ data: { addPayment } }) => {
          const paymentUid = _.has(addPayment, 'uid') ? addPayment.uid : null;
          const walletDocument = { sourceId, paymentUid, ...serviceDetails };

          return mutateChargeWallet({
            variables: {
              userUid,
              amount,
              document: walletDocument,
            },
          }).then(({ data: { chargeWallet } }) => {
            const paymentId = _.has(chargeWallet, 'chargedUid') ? chargeWallet.chargedUid : null;

            // eslint-disable-next-line no-param-reassign
            delete chargeWallet.soas;

            const completePayDocument = { ...chargeWallet };

            return mutateCompletePayment({
              variables: {
                sourceId,
                paymentId,
                status: 'PAID',
                document: completePayDocument,
                updatedBy: userUid,
              },
            }).then(() => mutateEnroll({
              variables: {
                tuteeUid: instanceUid,
                timeslotUid: timeSlotUid,
                startDate,
                endDate,
                paymentUid,
                createdBy: userUid,
              },
            }));
          });
        });

      setLoading(false);
    }

    if (allowPay) {
      doCharge();
    }
  }, [allowPay, voucher, grossSales]);

  const successPayload = useMemo(() => ({
    showSuccess,
    setShowSuccess,
  }), [
    showSuccess,
    setShowSuccess,
  ]);

  return (
    <>
      <div className="d-grid gap-2">
        <Button variant="primary" onClick={onShow} size="lg" disabled={loading || !allowPay}>
          <i className="fa fa-shopping-cart" aria-hidden="true" />
          {' '}
          {loading ? 'Processing Payment...' : 'Pay'}
        </Button>
      </div>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Wallet Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledCard>
            <Card.Body>
              <Card.Title className="wallet__title">
                <p className="lead text-success mb-4">
                  <span className="wallet__title--balance">
                    ₱
                    {balance}
                  </span>
                  <br />
                  <small className="text-muted">Current Balance</small>
                </p>
                {
                  remainingBalance > 0 ? (
                    <Card.Subtitle>
                      <p className="lead text-warning">
                        <span className="wallet__title--remaining">
                          ₱
                          {remainingBalance}
                        </span>

                        <br />
                        <small className="text-muted">Remaining balance after this transaction</small>
                      </p>
                    </Card.Subtitle>
                  ) : (
                    <Card.Subtitle className="mb-2 text-danger">
                      You have insufficient balance, please cash in.
                    </Card.Subtitle>
                  )
                }
              </Card.Title>
            </Card.Body>
          </StyledCard>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='link'
            disabled={loading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            disabled={loading || !allowPay}
            onClick={confirmPayment}
            size="lg"
          >
            {loading ? <LoadingSpinner /> : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>

      <SuccessContext.Provider value={successPayload}>
        <SuccessModal />
      </SuccessContext.Provider>
    </>
  );
}

const SuccessDiv = styledComponents.div`
  
`;

function SuccessModal() {
  const navigate = useNavigate();
  const {
    showSuccess,
    setShowSuccess,
  } = useContext(SuccessContext);
  const {
    startDate,
    tutorProfile,
  } = useContext(CheckoutContext);
  const [tutorName, setTutorName] = useState(null);

  useEffect(() => {
    if (tutorProfile) {
      const firstName = _.has(tutorProfile, 'firstName') ? tutorProfile.firstName : null;
      const middleInitial = _.has(tutorProfile, 'middleInitial') ? tutorProfile.middleInitial : null;
      const lastName = _.has(tutorProfile, 'lastName') ? tutorProfile.lastName : null;
      const fullName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;

      setTutorName(fullName);
    } else {
      setTutorName(null);
    }
  }, tutorProfile);

  const onOk = useCallback(() => {
    navigate('/payments', { replace: true });
    setShowSuccess(false);
  });

  return (
    <Modal
      show={showSuccess}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Payment Success</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SuccessDiv className="success-cont">
          <i className="fas fa-check" />
          <h3 className="text-success">Appointment booked Successfully!</h3>

          <p className="text-muted">
            Appointment booked with
            {' '}
            <b>{tutorName}</b>
            {' '}
            on
            {' '}
            <b>{startDate && moment(startDate).format('lll')}</b>
          </p>
        </SuccessDiv>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onOk}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
