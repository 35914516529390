/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { LoadingSpinner } from '../../../components';
import loginContext from '../../login/login.context';
import { deleteAdjustmentMutation, getTutorPayrollQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import ViewPayrollContext from './viewPayroll.context';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail'; 

export default function Index(payload) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userUid } = useContext(loginContext);
  const { uid: tutorPayrollUid, tutorName } = useContext(ViewPayrollContext);
  const { uid, type, amount } = payload;

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const onClose = () => {
    setVisible(false);
  };

  const onShow = () => {
    setVisible(true);
  };

  const insertAuditTrail = () => {
    switch(type) {
      case 'DEDUCTION':
        return doInsertAuditTrail({
          module: 'Payroll',
          changes: `${userFullName} deleted additional deduction of ${amount} for ${tutorName}`,
          action: 'DELETE',
        });
      case 'ADDITIONALS':
        return doInsertAuditTrail({
          module: 'Payroll',
          changes: `${userFullName} deleted additional income of ${amount} for ${tutorName}`,
          action: 'DELETE',
        });
      case 'INCENTIVE':
        return doInsertAuditTrail({
          module: 'Payroll',
          changes: `${userFullName} deleted incentive of ${amount} for ${tutorName}`,
          action: 'DELETE',
        });
      default:
        return
    }
  }

  const [deleteAdjustment] = useMutation(deleteAdjustmentMutation, {
    client: PaymentClient,
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: getTutorPayrollQuery,
      variables: { uid: tutorPayrollUid },
    }],
    onCompleted() {
      insertAuditTrail()
      onClose();
    },
    onError() {
      setLoading(false);
    },
  });

  const onSubmit = useCallback(() => {
    async function doSubmit() {
      setLoading(true);

      const variables = {
        uid,
        deletedBy: userUid,
      };

      await deleteAdjustment({ variables });

      setLoading(false);
    }

    doSubmit();
  }, [uid, userUid, deleteAdjustment]);

  return (
    <>
      <div className="d-grid gap-2">
        <Button variant="link" block onClick={onShow}>
          <FontAwesomeIcon icon={solid('trash')} />
        </Button>
      </div>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Adjustment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            <Alert.Heading>
              <FontAwesomeIcon icon={solid('circle-exclamation')} />
              {' '}
              Warning
            </Alert.Heading>
            <p className="text-warning">You are about to delete this adjustment. Are you sure to proceed?</p>
          </Alert>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={loading}>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('circle-check')} />
                  {' '}
                  Confirm
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
