import { useState, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import LoginContext from '../login/login.context';
import AuditTrailClient from '../../AuditTrailClient';
import RegistryClient from '../../RegistryClient';

import { CREATE_AUDIT_TRAIL } from './gql';
import { getUserQuery } from '../app/gql';

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function useCreateAuditTrail() {
  const { userUid } = useContext(LoginContext);
  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
  const profile = _.has(user, 'userProfile') ? user.userProfile : null;
  const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null;
  const iLastName = _.has(profile, 'lastName') ? profile.lastName : null;
  const userFullName = `${iFirstName} ${iLastName}`;
  const userGender = _.has(profile, 'gender') ? profile.gender : null;

  const [appCode] = useState(APPCODE);
  const [saveAuditTrail] = useMutation(CREATE_AUDIT_TRAIL, {
    client: AuditTrailClient,
  });

  const doInsertAuditTrail = (payload) => {
    const changes = _.has(payload, 'changes') ? payload.changes : null;
    const action = _.has(payload, 'action') ? payload.action : null;
    const details = _.has(payload, 'details') ? payload.details : null;
    const module = _.has(payload, 'module') ? payload.module : null;

    saveAuditTrail({
      variables: {
        appCode,
        userUid,
        userFullName,
        changes,
        action,
        details,
        module,
      },
    });
  };

  return {
    doInsertAuditTrail,
    userFullName,
    userGender,
  };
}
