import React from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import FileViewer from 'react-file-viewer'
import styledComponents from 'styled-components'

import FILE from '../../assets/termsofservice.docx'

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Terms of Service</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Terms of Service</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <Row>
            <Col xs={{ span: 12 }}>
              <FileViewer
                fileType="docx"
                filePath={FILE}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}