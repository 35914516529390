/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  ListGroup,
} from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import { useQuery } from '@apollo/client';
import { SessionContext } from './sessions.context';
import { getSessionFilesQuery } from './gql';
import UploaderContext from '../../../modules/uploader/uploader.context';
import { FileReaderModal } from '../../../components';
import { useAlert } from 'react-alert';

const StyledListGroup = styledComponents(ListGroup)`
  .list__actions {
    ul {
      list-style: none;

      li {
        display: inline;
        margin-left: 1em;
      }
    }
  }
`;

export default function Index() {
  const { sessionUid } = useContext(SessionContext);
  const [sessionFiles, setSessionFiles] = useState([]);

  const { data } = useQuery(getSessionFilesQuery, {
    skip: !sessionUid,
    variables: { sessionUid },
  });

  useEffect(() => {
    const files = _.has(data, 'getSessionFiles') ? data.getSessionFiles : [];

    setSessionFiles(files);
  }, [data, setSessionFiles]);

  return (
    <StyledListGroup>
      {sessionFiles.map((i) => <RenderItem {...i} />)}
    </StyledListGroup>
  );
}

// function redirectBlank(url) {
//   const a = document.createElement('a');
//   a.target = '_blank';
//   a.href = url;
//   a.click();
// }

function RenderItem(payload) {
  const { fileCategory, storage } = payload;
  const { getFileLink } = useContext(UploaderContext)
  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const [filePath, setFilePath] = useState(null)
  const [fileType, setFileType] = useState(null)

  if (fileCategory !== 'SHARED') return null;

  const name = _.has(storage, 'originalname') ? storage.originalname : null;
  const path = _.has(storage, 'path') ? storage.path : null;
  const mimeType = _.has(storage, 'mimetype') ? storage.mimetype : null;

  useEffect(() => {
    async function doFetch() {
      try {
        setLoading(true)

        const uri = await getFileLink({ filePath: path })
          .catch(err => { throw new Error(err) })

        setFilePath(uri)
        setFileType(mimeType)
        setLoading(false)
      } catch (err) {
        const errorMessage = _.has(err, 'message') ? err.message : JSON.stringify(err)
        alert.error(errorMessage, { timeout: 5000 })
        setLoading(false)
      }
    }

    doFetch()
  }, [path, mimeType])

  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-start"
    >
      <div className="list__name text-info">
        <FontAwesomeIcon icon={solid('file-pdf')} />
        {' '}
        {name}
      </div>
      <div className="list__actions">
        <ul>
          <li>
            <FileReaderModal title={name} filePath={filePath} fileType={fileType} loading={loading} />
          </li>
        </ul>
      </div>
    </ListGroup.Item>
  );
}
