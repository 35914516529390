import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPesoSign } from '@fortawesome/free-solid-svg-icons';

export default function voucherCard({
  discount, code, startDate, endDate, remaining, description, onSelect,
}) {
  return (
    <div className="card">
      <div
        className="card-body"
        onClick={() => onSelect(code)}
        aria-hidden="true"
        style={{ cursor: 'pointer' }}
      >
        <Row className="d-flex">
          <Col lg={4} xs={3} className="d-flex m-auto">
            <div className="d-grid text-center">

              <div className="text-xl">
                <i className="fa fa-gift" />
              </div>
              <div>

                <h4>
                  <>
                    <FontAwesomeIcon icon={faPesoSign} />
                    {`${discount} OFF`}
                  </>
                </h4>
              </div>
            </div>
          </Col>
          <Col lg={7} xs={7}>
            <h4>{code}</h4>
            <p className="text-xs">{`${startDate} - ${endDate}`}</p>
            <p className="text-xs">
              Last
              {' '}
              {remaining}
              {' '}
              vouchers available
            </p>
            <p className="text-muted text-xs">{description}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
