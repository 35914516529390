import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Form, Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { addTimeslotMutation, validateTimeslotQuery } from '../../pages/tutor/timeslots/gql';
import { LoginContext } from '../../pages/login';
import { TimeslotsContext } from '../../pages/tutor/timeslots';
import useCreateAuditTrail from '../../pages/auditTrail/useCreateAuditTrail';
import { LoadingSpinner } from '../../components';

export default function ({ day }) {
  const [show, setShow] = useState(false);
  const {
    handleSubmit, formState, control, reset, setValue, getValues
  } = useForm();
  const { errors } = formState;
  const { state } = useContext(TimeslotsContext);
  const id = _.has(state, 'id') ? state.id : null;
  const uid = _.has(state, 'uid') ? state.uid : null;
  const interest = _.has(state, 'interest') ? state.interest : null;

  const { userUid, instanceUid: tutorUid } = useContext(LoginContext);
  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const handleClose = () => {
    setShow(false);
    reset();
  };

  useEffect(() => {
    setValue('day', day);
  }, [day]);

  const handleShow = () => setShow(true);

  const [onSubmit] = useMutation(addTimeslotMutation, {
    onCompleted: () => {
      handleClose();
    },
    update(cache, { data: { addTimeslot } }) {
      cache.modify({
        id: `TutorialProfile:${id}`,
        fields: {
          timeslots(existing) {
            const newTutorialProfileRef = cache.writeFragment({
              data: addTimeslot,
              fragment: gql`
                  fragment NewRef on Timeslot {
                    id
                    uid
                    day
                    start
                    end
                    __typename
                  }
                `,
            });

            const newList = [...existing, newTutorialProfileRef]

            return newList;
          },
        },
      });
    },
  });


  const submitForm = useCallback((data) => {
    const { start, end, ...etc } = data;
    const startTime = start && moment(start).format('HH:mm:ss');
    const endTime = end && moment(end).format('HH:mm:ss');
    const variables = {
      tutorialProfileUid: uid,
      ...etc,
      start: startTime,
      end: endTime,
      createdBy: userUid,
    };

    onSubmit({ variables }).then(() => {
      // insert audit trail
      doInsertAuditTrail({
        action: 'CREATE',
        changes: `${userFullName} added ${startTime} - ${endTime} for ${interest}`,
        module: 'Tutorial Profile',
      });
    });
  });

  const triggerSubmit = () => {
    handleSubmit(submitForm)();
  };

  const [validateTime, { loading: isValidating }] = useLazyQuery(validateTimeslotQuery)

  const validateTimeslot = () => {
    async function validate() {
      const values = getValues()

      // const tutorialProfileUid = uid
      const day = _.has(values, 'day') ? values.day : null
      const start = _.has(values, 'start') ? moment(values.start).format('HH:mm:ss') : null
      const end = _.has(values, 'end') ? moment(values.end).format('HH:mm:ss') : null
      const backwardTime = start && end ? moment(start, 'HH:mm:ss').isSameOrAfter(moment(end, 'HH:mm:ss')) : false

      if (backwardTime) {
        return 'Invalid date range'
      }

      return validateTime({
        variables: { tutorUid, day, start, end }
      }).then(result => {
        const data = _.has(result, 'data') ? result.data : null
        const validateTimeslot = _.has(data, 'validateTimeslot') ? data.validateTimeslot : null
        const valid = _.has(validateTimeslot, 'valid') ? validateTimeslot.valid : null
        const message = _.has(validateTimeslot, 'message') ? validateTimeslot.message : null

        if (valid) {
          return true
        } else {
          return message
        }
      })
    }

    return validate()
  }

  return (
    <>
      <Button className="edit-link" variant="link" onClick={handleShow}>
        <i className="fa fa-plus-circle" />
        {' '}
        Add
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Time Slots</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="timeslot.start">
                  <Form.Label>Day</Form.Label>
                  <Controller
                    name="day"
                    control={control}
                    rules={{
                      required: 'Day is required.'
                    }}
                    render={({ field }) => (
                      <Form.Control
                        isInvalid={!!_.has(errors, 'day')}
                        readOnly
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'day') ? errors.day.message : 'Invalid day.'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="timeslot.start">
                  <Form.Label>Start</Form.Label>
                  <Controller
                    name="start"
                    control={control}
                    rules={{
                      required: 'Start time is requiredss.',
                      validate: { start: async (value) => await validateTimeslot() },
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        isInvalid={!!_.has(errors, 'start')}
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'start') ? errors.start.message : 'Invalid start.'}
                  </Form.Control.Feedback>
                  {isValidating && <LoadingSpinner text="Validating..." />}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="form-group" controlId="timeslot.end">
                  <Form.Label>End</Form.Label>
                  <Controller
                    name="end"
                    control={control}
                    rules={{
                      required: 'End is required.',
                      validate: { end: validateTimeslot },
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        isInvalid={!!_.has(errors, 'end')}
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {_.has(errors, 'end') ? errors.end.message : 'Invalid end.'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={triggerSubmit}>Add Slot</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function CustomDatePicker({ value, isInvalid, name, ...etc }) {
  return (
    <div className={isInvalid ? 'is-invalid' : ''}>
      <ReactDatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="h:mm aa"
        customInput={<Form.Control isInvalid={isInvalid} name={name} />}
        selected={value}
        {...etc}
      />
    </div>
  );
}
