import React from 'react'
import { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ModalConfirm } from '../../components'
import { MARK_ALL_AS_READ } from './gql';
import { useMutation } from '@apollo/client';
import NotificationClient from '../../NotificationClient'
import LoginContext from '../login/login.context';

export default function MarkAllAsReadModal({ onError, onSuccess }) {
    const { userUid } = useContext(LoginContext);
    const [saving, setSaving] = useState(false)
    
    const [markAllAsRead] = useMutation(MARK_ALL_AS_READ, {
        client: NotificationClient
    })

    const onOk = () => {
        setSaving(true)
        markAllAsRead({
            variables: {
                userUid
            }
        })
        .then(() => {
            setSaving(false)
            if(onSuccess) {
                onSuccess()
            }
        })
        .catch(err => {
            setSaving(false)
            if(onError) {
                onError({
                    title: 'Failed to mark all as read',
                    message: err.toString()
                })
            }
        })
    }

    return (
        <ModalConfirm
          onOk={() => onOk()}
          message={(
            <span>
              Are you sure you want to mark all as read?
            </span>
            )}
        >
          {({ onClick }) => (
            <Button
              disabled={saving}
              variant='primary'
              onClick={onClick}
            >
              {!saving ? 'Mark All as Read': 'Loading...'}
    
            </Button>
          )}
        </ModalConfirm>
      );
}
