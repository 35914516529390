/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getPaymentQuery, getTuteeQuery, publishRefundMutation } from './gql';
import PaymentClient from '../../../PaymentClient';
import { DynamicAvatar, LoadingSpinner } from '../../../components';
import { RowContext } from './wallet.context';
import { getTimeslotSessionQuery } from './gql';

export default function Index(payload) {
  const {
    transactionType, paymentUid, userUid, paymentStatus,
  } = payload;
  const [visible, setVisible] = useState(false);
  const [referenceUid, setReferenceUid] = useState(false);
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const [tutor, setTutor] = useState(null);
  const [interest, setInterest] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const { refetchWallet } = useContext(RowContext);
  const [loading, setLoading] = useState(false);
  const [canRefund, setCanRefund] = useState(false);
  const [timeslotUid, setTimeslotUid] = useState(null);

  useEffect(() => {
    if (paymentStatus === 'REFUND') {
      setCanRefund(false);
    } else {
      setCanRefund(true);
    }
  }, [paymentStatus]);

  const { data: paymentResult, loading: paymentLoading } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid },
  });

  useEffect(() => {
    setLoading(setLoading);
  }, [paymentLoading]);

  useEffect(() => {
    if (paymentResult) {
      const iPayment = _.has(paymentResult, 'getPayment') ? paymentResult.getPayment : null;
      const iService = _.has(iPayment, 'serviceDetails') ? iPayment.serviceDetails : null;
      const iAmount = _.has(iPayment, 'amount') ? iPayment.amount : null;
      const iTutor = _.has(iService, 'tutor') ? iService.tutor : null;
      const iUserUid = _.has(iTutor, 'userUid') ? iTutor.userUid : null;
      const iFirstName = _.has(iTutor, 'firstName') ? iTutor.firstName : null;
      const iLastName = _.has(iTutor, 'lastName') ? iTutor.lastName : null;
      const iFullName = `${iFirstName} ${iLastName}`;
      const iStartDate = _.has(iService, 'startDate') ? moment(iService.startDate) : null;
      const iEndDate = _.has(iService, 'endDate') ? moment(iService.endDate) : null;
      const iDateTime = iStartDate && `${iStartDate.format('MMM MM, YYYY')} ${iStartDate.format('hh:mm:ss a')} - ${iEndDate.format('hh:mm:ss a')}`;
      const voucherDiscount = _.has(iPayment, 'voucherDiscount') ? iPayment.voucherDiscount : null
      const iTimeslotUid = _.has(iService, 'timeSlotUid') ? iService.timeSlotUid : null;

      setReferenceUid(paymentUid);
      setTutorUserUid(iUserUid);
      setTutor(iFullName);
      setDateTime(iDateTime);
      setSubTotal(iAmount);
      setTimeslotUid(iTimeslotUid)

      if (voucherDiscount) {
        const iSubtotal = iAmount + voucherDiscount;
        setSubTotal(iSubtotal);
        setDiscount(voucherDiscount - (2 * (voucherDiscount)));
      }
    }
  }, [paymentResult]);

  const [fetchSession] = useLazyQuery(getTimeslotSessionQuery)

  useEffect(() => {
    async function doFetch() {
      const session = await fetchSession({ variables: { timeslotUid } })
        .then((result) => {
          const data = _.has(result, 'data') ? result.data : null;
          const details = _.has(data, 'getTimeslotSession') ? data.getTimeslotSession : null;

          return details;
        });

      const iInterest = _.has(session, 'interest') ? session.interest : null;

      setInterest(iInterest)
    }

    if (timeslotUid) { doFetch(); }
  }, [timeslotUid]);

  useEffect(() => {
    // add discount since it's in negative value
    const totals = subTotal + discount;

    setGrossTotal(totals);
  }, [subTotal]);

  const onShow = useCallback(() => {
    setVisible(true);
  });

  const onClose = useCallback(() => {
    setVisible(false);
  });

  const [doRefund] = useMutation(publishRefundMutation, {
    onCompleted: () => {
      onClose();
    },
  });
  const [doGetTutuee] = useLazyQuery(getTuteeQuery);

  const refund = useCallback(() => {
    async function asyncDoRefund() {
      setLoading(true);

      const tuteeUid = await doGetTutuee({ variables: { userUid } })
        .then((result) => {
          const data = _.has(result, 'data') ? result.data : null;
          const tutee = _.has(data, 'getTutee') ? data.getTutee : null;
          const uid = _.has(tutee, 'uid') ? tutee.uid : null;

          return uid;
        });

      await doRefund({
        variables: {
          paymentUid,
          tuteeUid,
          userUid,
        },
      });

      refetchWallet();

      setLoading(false);
    }

    asyncDoRefund();
  }, [
    doRefund,
    paymentUid,
    userUid,
  ]);

  return (
    <>
      <Button variant="link" onClick={onShow}>{transactionType}</Button>
      <Modal
        show={visible}
        onHide={onClose}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            View Transaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={3}>
              <DynamicAvatar userUid={tutorUserUid} />
            </Col>
            <Col lg={9} style={{ padding: '20px 0' }}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('person-chalkboard')} />
                Tutor
                {' '}
                <small className="text-muted">{tutor}</small>
              </p>
            </Col>
          </Row>

          {!canRefund && (
            <Row>
              <Col lg={1}>
                <p className="lead"><FontAwesomeIcon icon={solid('info-circle')} /></p>
              </Col>
              <Col lg={11}>
                <p className="lead">
                  <small className="text-danger">REFUNDED</small>
                </p>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={1}>
              <p className="lead"><FontAwesomeIcon icon={solid('barcode')} /></p>
            </Col>
            <Col lg={11}>
              <p className="lead">
                <small className="text-muted">{referenceUid}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={1}>
              <p className="lead"><FontAwesomeIcon icon={solid('calendar-day')} /></p>
            </Col>
            <Col lg={11}>
              <p className="lead">
                <small className="text-muted">{dateTime}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={1}>
              <p className="lead"><FontAwesomeIcon icon={solid('chalkboard-user')} /></p>
            </Col>
            <Col lg={11}>
              <p className="lead">
                <small className="text-muted">{interest}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Subtotal
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small className="text-muted">{subTotal && subTotal.toFixed(2)}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Voucher
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small className="text-muted">{discount && discount.toFixed(2)}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Total Amount
              </p>
            </Col>
            <Col lg={8}>
              <h3 className="text-info">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small>{grossTotal && grossTotal.toFixed(2)}</small>
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>

          {
            paymentStatus === 'REFUND' ? '' : (
              <Button
                variant="link"
                onClick={refund}
                disabled={loading}
              >
                <span className="text-danger">
                  <FontAwesomeIcon icon={solid('clock-rotate-left')} />
                  {' '}
                  {loading ? <LoadingSpinner /> : 'Revert Payment'}
                </span>
              </Button>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}
