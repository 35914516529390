import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { DashboardTemplate } from '../../../template/components';
import { SelectSubscriber } from '../../../components';
import TransactionHistory from './transactionHistory';
import WalletContext from './wallet.context';

export default function Index() {
  const formInstance = useForm();
  const [walletUserUid, setWalletUserUid] = useState(null);

  const onSearch = useCallback((uid) => {
    setWalletUserUid(uid);
  }, [setWalletUserUid]);

  const contextPayload = useMemo(() => ({
    walletUserUid,
  }), [walletUserUid]);

  return (
    <WalletContext.Provider value={contextPayload}>
      <DashboardTemplate>
        <h3>Payment History</h3>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <SelectSubscriber
                  onChange={onSearch}
                  placeholder="Please select a subscriber wallet"
                  {...formInstance}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {
          walletUserUid && (
            <Row>
              <Col><TransactionHistory /></Col>
            </Row>
          )
        }
      </DashboardTemplate>
    </WalletContext.Provider>
  );
}
