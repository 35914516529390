import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Col, Form, Modal,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { UploaderContext } from '../../../modules/uploader';
import PaymentClient from '../../../PaymentClient';
import { LoginContext } from '../../login';
import { saveBirFormMutation } from './gql';
import ViewContext from './viewPayroll.context';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const { uid, tutorPayrollId } = useContext(ViewContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, setValue, watch,
  } = formPayload;
  const { errors } = formState;
  const {
    uploadFile,
    getFileExtension,
  } = useContext(UploaderContext);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateFileSave] = useMutation(saveBirFormMutation, {
    client: PaymentClient,
    onCompleted: () => {
      setVisible(false);
    },
    update(cache, { data }) {
      const { saveBirFile } = data;

      cache.modify({
        id: `TutorPayroll:${tutorPayrollId}`,
        fields: {
          birForm() { return saveBirFile; },
        },
      });
    },
  });

  const birForm = watch('birForm');

  useEffect(() => {
    async function doUpload() {
      setLoading(true);

      const ext = await getFileExtension(birForm);
      const fileName = `bir-form_${moment().format('X')}.${ext}`;
      const payload = {
        file: birForm,
        fileName,
        fileLocation: 'birForms',
      };

      await uploadFile(payload).then((result) => {
        const { data } = result;

        mutateFileSave({
          variables: {
            userUid,
            tutorPayrollUid: uid,
            storage: { ...data },
          },
        });
      });

      setLoading(false);
    }
    if (birForm) {
      doUpload();
    }
  }, [birForm]);

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        <FontAwesomeIcon icon={regular('file-pdf')} />
        {' '}
        Upload BIR
      </Button>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group as={Col} sm={12} controlId="acct.birForm">
              <Form.Label>BIF Form</Form.Label>
              <Controller
                name="birForm"
                control={control}
                render={() => (
                  <Form.Control
                    isInvalid={!!_.has(errors, 'birForm')}
                    type="file"
                    onChange={(e) => {
                      const fileList = e.target.files;

                      setValue('birForm', fileList[0]);
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'birForm') ? errors.birForm.message : 'Invalid BIR form.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            {loading ? 'Uploading...' : 'Close'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
