import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

export default function notificationLatest({
  data,
}) {
  const navigate = useNavigate();

  const handleClick = ({ urlPath, urlState }, e) => {
    if (urlPath) {
      navigate(urlPath, { state: urlState });
    }
  };

  return (
    <Dropdown.Menu
      show
      align={{ sm: 'end' }}
      style={{
        maxHeight: 500, overflow: 'auto', width: 300,
      }}
    >
      <Dropdown.Header>Notifications</Dropdown.Header>
      { data && data.length > 0 ? data.map((item, index) => {
        const attr = _.has(item, 'data') ? item.data : null;
        const urlPath = _.has(attr, 'urlPath') ? attr.urlPath : null;
        const urlState = _.has(attr, 'urlState') ? attr.urlState : null;

        return (
          <Dropdown.Item key={`notif-latest-${index}`}>
            <div
              aria-hidden="true"
              className="media d-flex"
              style={{ whiteSpace: 'break-spaces' }}
              onClick={(e) => handleClick({ urlPath, urlState }, e)}
            >
              {/* <div className="pt-2">
                <span className="avatar avatar-sm flex-shrink-0">
                  <img className="avatar-img rounded-circle" alt="User Image" src="/assets/img/user/user.jpg" />
                </span>
              </div> */}
              <div className="media-body m-2">
                <h6>{ item.title}</h6>
                <p className="noti-details">
                  { item.message}
                </p>
                <p className="noti-time">
                  <span className="notification-time text-muted">
                    {moment(item.createdAt).isValid() && moment(item.createdAt).fromNow()}
                  </span>
                </p>
              </div>
            </div>
          </Dropdown.Item>
        );
      }) : <div className="p-2 text-center">Empty</div>}
      <Dropdown.Divider />
      <Dropdown.Item className="text-center" onClick={() => navigate('/notification')}>
        <span>View all Notifications</span>
      </Dropdown.Item>
    </Dropdown.Menu>
  );
}
