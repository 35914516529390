/* eslint-disable no-case-declarations */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import SearchContext from './search.context';
import { getInterestQuery } from './gql';
import { Ratings } from '../../components';

const BadgeButton = styledComponents(Button)`
  color: #fe9445;
`;

export default function Index() {
  const { filters } = useContext(SearchContext);

  return (
    <Row xs="auto">
      {
        Object.keys(filters).map((key) => {
          const value = _.has(filters, key) ? filters[key] : null;

          if (!value) { return null; }

          return (
            <Col>
              <ButtonBadge filter={key} value={value} />
            </Col>
          );
        })
      }
    </Row>
  );
}

function ButtonBadge(payload) {
  const { filters, setFilters } = useContext(SearchContext);
  const { filter, value } = payload;
  const [label, setLabel] = useState(null);

  const [getInterests] = useLazyQuery(getInterestQuery);

  useEffect(() => {
    switch (filter) {
      case 'priceRange':
        const min = value[0];
        const max = value[1];

        setLabel(`${min} - ${max}`);
        break;

      case 'availability':
        setLabel(moment(value).format('YYYY-MM-DD'));
        break;

      case 'interest':
        getInterests({ variables: { uid: value } })
          .then(({ data }) => {
            const interest = _.has(data, 'getInterest') ? data.getInterest : null;
            const title = _.has(interest, 'title') ? interest.title : null;

            setLabel(title);
          });
        break;

      case 'rating':
        setLabel(<Ratings className="rating" value={value} readonly />);
        break;

      default: setLabel(value);
    }
  }, [filter, value]);

  const onClose = useCallback(() => {
    const iFilters = { ...filters };

    _.unset(iFilters, filter);

    setFilters(iFilters);
  }, [filters, filter, value]);

  if (filter === 'priceRange') { return null; }

  return (
    <BadgeButton
      className="mr-1"
      variant="link"
      onClick={onClose}
    >
      {label}
      {' '}
      <FontAwesomeIcon icon={solid('circle-xmark')} />
    </BadgeButton>
  );
}
