import _ from 'lodash';
import React, { useState } from 'react';
import {
  Tab, Row, Col, Nav, Button,
} from 'react-bootstrap';
import TuteesList from './tuteesList';
import TutorsList from './tutorsList';

export default function Index() {
  const [searchText, setSearchText] = useState(null);
  const [selectedRole, setSelectedRole] = useState('TUTEE');

  const debounceSearch = _.debounce((value) => setSearchText(value), 500) 

  const onSearch = (value) => {
    debounceSearch(value)
  };

  const onSelectTab = (selectedKey) => {
    setSearchText(null);
    setSelectedRole(selectedKey);
  };

  return (
    <div>
      <h3 className="page-title mb-4">User Management</h3>
      <Tab.Container 
        id="user-management-tabs" 
        defaultActiveKey={selectedRole} 
        justify
      >
        <Row className="justify-content-end mb-4">
          <Col lg={3}>
            <div className="input-group">
              <input
                type="text"
                onChange={(e) => onSearch(e.target.value)}
                placeholder="Search User"
                className="form-control"
              />
              <Button
                type="button"
                className="btn btn-primary"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-search" />

              </Button>
            </div>
          </Col>
        </Row>
        <Row className="gap-2">
          <Col lg={12}>
            <Nav
              // className="justify-content-end"
              onSelect={(selectedKey) => onSelectTab(selectedKey)}
              variant="pills"
              className="flex-row nav nav-tabs nav-tabs-solid bg-transparent"
            >
              <Nav.Item>
                <Nav.Link eventKey="TUTEE">Students</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="TUTOR">Tutors</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
              <Nav.Link eventKey="PARTNER_MERCHANT">Partner Merchants</Nav.Link>
            </Nav.Item> */}
            </Nav>
          </Col>
          <Col lg={12}>
            <Tab.Content>
              <Tab.Pane eventKey="TUTEE">
                { selectedRole === 'TUTEE' && <TuteesList
                  searchText={searchText}
                  selectedRole={selectedRole}
                />}
              </Tab.Pane>

              <Tab.Pane eventKey="TUTOR">
                { selectedRole === 'TUTOR' && <TutorsList
                  searchText={searchText}
                />}
              </Tab.Pane>

              <Tab.Pane eventKey="PARTNER_MERCHANT">
                <Row>
                  <Col lg={12}>
                    <p className="p-4">Partner Merchants</p>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>

  );
}
