import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import VoucherUsers from './voucherUsers';
import VoucherDetails from './voucherDetails';

export default function view() {
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = _.has(location, 'state') ? location.state : null;
  const voucherUid = _.has(locationState, 'voucherUid') ? locationState.voucherUid : null;
  const voucherCode = _.has(locationState, 'voucherCode') ? locationState.voucherCode : null;

  return (
    <Row>
      <Col lg={12}>
        <Button variant="link" onClick={() => navigate(-1)}>
          <i className="fa fa-arrow-circle-left" />
          {' '}
          Back to Voucher List
        </Button>
      </Col>
      <Col lg={12}>
        <div className="card">
          <div className="card-body">
            <VoucherDetails voucherUid={voucherUid} />
          </div>
        </div>
      </Col>
      <Col lg={12}>
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h5>Students List</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {voucherCode && <VoucherUsers voucherCode={voucherCode} />}
            </div>
          </div>
        </div>
      </Col>
    </Row>

  );
}
