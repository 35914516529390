/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { GET_INTEREST_BY_TITLE, ADD_INTEREST, EDIT_INTEREST } from './gql';
import CoreClient from '../../../../CoreClient';
import LoginContext from '../../../login/login.context';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';
import { AlertError } from '../../../../components';

const getInterestTitle = ({ title, uid }) => CoreClient.query({
  query: GET_INTEREST_BY_TITLE,
  fetchPolicy: 'network-only',
  variables: { title, uid },
}).then((result) => {
  const dataResult = _.has(result, 'data') ? result.data : null;
  const interestData = _.has(dataResult, 'getInterestByTitle') ? dataResult.getInterestByTitle : null;
  return interestData;
}).catch((error) => error);

export default function formModal({
  show, onHide, isEdit, initialValues,
}) {
  const { userUid } = useContext(LoginContext);
  const [saving, setSaving] = useState(false);
  const [apiError, setApiError] = useState(null);

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const [addInterest] = useMutation(ADD_INTEREST, {
    refetchQueries: ['GetPaginatedInterest'],
  });

  const [editInterest] = useMutation(EDIT_INTEREST, {
    refetchQueries: ['GetPaginatedInterest'],
  });

  const {
    handleSubmit, formState, control,
  } = useForm({
    defaultValues: {
      uid: initialValues ? initialValues.uid : null,
      title: initialValues ? initialValues.title : null,
      description: initialValues ? initialValues.description : null,
      participantsLimit: initialValues ? initialValues.participantsLimit : 0,
    },
  });

  const { errors } = formState;

  const onSubmit = (interestData, e) => {
    e.preventDefault();
    setSaving(true);
    if (!isEdit) {
      addInterest({
        variables: {
          ...interestData,
          createdBy: userUid,
          participantsLimit: parseInt(interestData.participantsLimit, 10),
        },
      }).then(() => {
        doInsertAuditTrail({
          action: 'CREATE',
          module: 'Interest',
          changes: `${userFullName} added ${interestData.title}`,
        });
        onHide();
        setSaving(false);
      })
        .catch((err) => {
          setApiError({ title: 'Failed to Create Interest', error: err.toString() });
          setSaving(false);
        });
    } else {
      editInterest({
        variables: {
          ...interestData,
          updatedBy: userUid,
          participantsLimit: parseInt(interestData.participantsLimit, 10),
        },
      })
        .then(() => {
          const title = initialValues ? initialValues.title : null;
          const participantsLimit = initialValues
            ? parseInt(initialValues.participantsLimit, 10) : null;

          if (title !== interestData.title) {
            doInsertAuditTrail({
              action: 'UPDATE',
              module: 'Interest',
              changes: `${userFullName} updated ${title} to ${interestData.title}`,
            });
          }

          if (participantsLimit !== interestData.participantsLimit) {
            doInsertAuditTrail({
              action: 'UPDATE',
              module: 'Interest',
              changes: `${userFullName} set max number limit of Group Session for ${title} from ${participantsLimit} to ${interestData.participantsLimit}`,
            });
          }

          onHide();
          setSaving(false);
        })
        .catch((err) => {
          setApiError({ title: 'Failed to Edit Interest', error: err.toString() });
          setSaving(false);
        });
    }
  };

  const interestNameIsUnique = async (value) => {
    const interestData = await getInterestTitle({
      title: value,
      uid: initialValues ? initialValues.uid : null,
    });
    const interestTitle = _.has(interestData, 'title') ? interestData.title : null;
    return interestTitle !== value || 'Interest name already exists.';
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>

        <Modal.Header>
          <Modal.Title>{!isEdit ? 'Add Interest' : 'Edit Interest' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {apiError && (
          <div>
            <AlertError
              error={apiError.error}
              title={apiError.title}
              onClose={() => setApiError(null)}
            />
          </div>
          )}
          <Form.Group className="form-group" controlId="addInterest.title">
            <Form.Label>Interest Name</Form.Label>
            <Controller
              name="title"
              control={control}
              rules={{
                required: 'Interest name is required.',
                validate: {
                  whiteSpace: (value) => !!value.trim() || 'Interest name cannot be empty.',
                  uniqueName: (value) => interestNameIsUnique(value),
                  maxLength: (value) => value.length <= 255 || 'Interest name character limit 255.',
                },
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'title')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'title') ? errors.title.message : 'Invalid interest name.'}
            </Form.Control.Feedback>

          </Form.Group>

          <Form.Group className="form-group" controlId="addInterest.participantsLimit">
            <Form.Label>
              Participants Limit
              {' '}
              <span className="text-muted">
                (Max limit for group session)
              </span>
            </Form.Label>
            <Controller
              defaultValue={0}
              name="participantsLimit"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  min={0}
                  autoFocus
                  isInvalid={!!_.has(errors, 'participantsLimit')}
                  {...field}
                />
              )}
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="addInterest.description">
            <Form.Label>Description</Form.Label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...field}
                />
              )}
            />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onHide()} disabled={saving}>Cancel</Button>
          {' '}
          <Button type="submit" variant="primary" disabled={saving}>{!saving ? 'Save' : 'Saving'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
