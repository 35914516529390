import React, { useCallback, useEffect, useState } from 'react';
import {
  Card, Button, Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styledComponents from 'styled-components';
import { DashboardTemplate } from '../../../template/components';
import TutorTable from './tutorTable';
import GeneratePayslipModal from './generatePayslipModal';
import { getPayrollQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import ReleasePayslipsModal from './releasePayslips';
import ReleasePayrolls from './releasePayrolls';

const PAYROLL_STATUS_FAILED = 'FAILED';
const PAYROLL_STATUS_COMPLETED = 'COMPLETED';
const PAYROLL_STATUS_PAYSLIP_GENERATED = 'PAYSLIP_GENERATED';
const PAYROLL_STATUS_PAYSLIP_RELEASED = 'PAYSLIP_RELEASED';

const PayrollStatus = styledComponents.div`
  font-size: 18px;
  font-weight: 600;
  color: #FE9445;
  margin: 5px 0 0 16px;
`;

export default function Index() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const payrollUid = _.has(state, 'uid') ? state.uid : null;
  const [canCreatePayslip, setCanCreatePayslip] = useState(false);
  const [canReleasePayslip, setCanReleasePayslip] = useState(false);
  const [canReleasePayroll, setCanReleasePayroll] = useState(false);
  const [payrollStatus, setPayrollStatus] = useState(null);

  const onBack = useCallback(() => {
    navigate('/marketing-admin/payroll');
  });

  const { data: payrollResult } = useQuery(getPayrollQuery, {
    client: PaymentClient,
    variables: { uid: payrollUid }
  });

  useEffect(() => {
    const payroll = _.has(payrollResult, 'getPayroll') ? payrollResult.getPayroll : null;
    const iPayrollStatus = _.has(payroll, 'status') ? payroll.status : null;

    if (iPayrollStatus === PAYROLL_STATUS_COMPLETED || iPayrollStatus === PAYROLL_STATUS_FAILED) {
      setCanCreatePayslip(true);
    } else {
      setCanCreatePayslip(false);
    }

    if (iPayrollStatus === PAYROLL_STATUS_PAYSLIP_GENERATED) {
      setCanReleasePayslip(true);
    } else {
      setCanReleasePayslip(false);
    }

    if (iPayrollStatus === PAYROLL_STATUS_PAYSLIP_RELEASED) {
      setCanReleasePayroll(true);
    } else {
      setCanReleasePayroll(false);
    }

    setPayrollStatus(iPayrollStatus);
  }, [payrollResult]);

  return (
    <DashboardTemplate>
      <>
        <h3 className="pb-3">Manage Payroll</h3>

        <Card>
          <Card.Header>
            <Row>
              <Col lg={{ span: 2 }}>
                <Button variant="link" onClick={onBack}>
                  <FontAwesomeIcon icon={solid('arrow-left')} />
                  {' '}
                  Back
                </Button>
              </Col>
              <Col lg={{ span: 10 }}>
                <div className="d-flex justify-content-end">
                  {canCreatePayslip && (<GeneratePayslipModal />)}
                  {canReleasePayslip && (<ReleasePayslipsModal />)}
                  {canReleasePayroll && <ReleasePayrolls />}
                  {payrollStatus && (
                    <PayrollStatus>
                      <FontAwesomeIcon icon={solid('circle-info')} />
                      {' '}
                      {
                        payrollStatus === 'COMPLETED' ? 'PAYROLL GENERATED' : payrollStatus
                      }
                    </PayrollStatus>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <TutorTable />
          </Card.Body>
        </Card>
      </>
    </DashboardTemplate>
  );
}
