/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { useLazyQuery, useMutation } from '@apollo/client';
import { addDays } from 'date-fns';
import moment from 'moment';
import useNotification from '../../notification/useNotification';
import { SAVE_USER_COMMISSION, GET_USER_COMMISSION } from './gql';
import LoginContext from '../../login/login.context';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { AlertError } from '../../../components'

export default function userCommissionModal({
  userUid, roleCode, firstName, lastName,
}) {
  const [show, setShow] = useState(false);
  const { userUid: currentUserUid } = useContext(LoginContext);
  const { sendNotification } = useNotification({ userUid: currentUserUid });
  const { doInsertAuditTrail } = useCreateAuditTrail();
  const [prevFee, setPrevFee] = useState(null)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const [fetchUserCommission,
    { loading: commissionLoading, data: commissionData },
  ] = useLazyQuery(GET_USER_COMMISSION, {
    fetchPolicy: ' network-only',
    variables: {
      userUid,
      roleCode,
    },
  });

  useEffect(() => {
    if (show) {
      fetchUserCommission();
    }
  }, [show]);

  const {
    handleSubmit, control, formState, setValue,
  } = useForm();
  const { errors } = formState;

  useEffect(() => {
    const commissionResult = _.has(commissionData, 'getUserCommission') ? commissionData.getUserCommission : null;
    const percentage = _.has(commissionResult, 'percentage') ? commissionResult.percentage : 12;
    const effectivityDate = _.has(commissionResult, 'effectivityDate') ? commissionResult.effectivityDate : null;

    setPrevFee(percentage)
    setValue('percentageRate', percentage);
    setValue('effectivityDate', effectivityDate ? new Date(effectivityDate) : null);
  }, [commissionLoading, commissionData]);

  const [saveCommission] = useMutation(SAVE_USER_COMMISSION);

  const onSubmit = (data, e) => {
    setError(null)
    setSaving(true)
    e.preventDefault();
    saveCommission({
      variables: {
        ...data,
        userUid,
        roleCode,
        percentage: parseFloat(data.percentageRate),
        updatedBy: currentUserUid,
      },
    }).then(() => {
      setSaving(false)

      // console.log('insert audit trail');
      doInsertAuditTrail({
        action: 'UPDATE',
        changes: `Updated learnlive fees of ${firstName} ${lastName} from ${prevFee}% to ${data.percentageRate}% effective on ${moment(data.effectivityDate).format('LL')}`,
        module: 'Learnlive Fees',
      });

      sendNotification({
        userUids: [userUid],
        title: 'Learnlive Fees',
        message: `LearnLive Fees has been changed from 
          ${prevFee}% to ${data.percentageRate}% effective on ${moment(data.effectivityDate).format('LL')}`,
      });
      setShow(false);
    })
    .catch(err => {
      setSaving(false)
      setError(err.toString())
    })
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(true)}
      >
        Edit
      </Button>

      { show && (
      <Modal show={show} onHide={() => setShow(false)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>
              Set Commission Rate
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h5 className='mb-4'>{firstName} {lastName}</h5>
          {error && <AlertError 
                title='Update failed'
                error={error}
                onClose={() => setError(null)}
            />}
            <Form.Group>
              <Form.Label>Percentage rate</Form.Label>
              <Controller
                name="percentageRate"
                control={control}
                rules={{
                  required: 'Percentage rate is required.',
                }}
                render={({ field }) => (
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    autoFocus
                    isInvalid={!!_.has(errors, 'percentageRate')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'percentageRate') ? errors.percentageRate.message : 'Invalid Percentage rate.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Effectivity Date</Form.Label>
              <Controller
                name="effectivityDate"
                control={control}
                rules={{ required: 'Effectivity Date is required.' }}
                render={({ field }) => (
                  <DatePicker
                    customInput={(
                      <Form.Control
                        isInvalid={!!_.has(errors, 'effectivityDate')}
                      />
)}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    dateFormat="MMMM d, yyyy"
                    minDate={addDays(new Date(), 14)}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'effectivityDate') ? errors.effectivityDate.message : 'Invalid Effectivity Date.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" disabled={saving} onClick={() => setShow(false)}>Cancel</Button>
            <Button variant="primary" disabled={saving} type="submit">{saving ? 'Saving...': 'Save'}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      )}
    </>
  );
}
