import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import {
  Button,
} from 'react-bootstrap';
import { DELETE_INTEREST } from './gql';
import LoginContext from '../../../login/login.context';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';
import ModalConfirm from '../../../../components/modalConfirm';

export default function deleteModal({ uid, title, onError, onSuccess }) {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();
  const [saving, setSaving] = useState(false)

  const [deleteInterest] = useMutation(DELETE_INTEREST, {
    refetchQueries: ['GetPaginatedInterest'],
  });

  const onOk = () => {
    setSaving(true)
    deleteInterest({
      variables: {
        uid,
        deletedBy: userUid,
      },
    }).then(() => {
      setSaving(false)
      if(onSuccess) {
        onSuccess()
      }
      doInsertAuditTrail({
        action: 'DELETE',
        module: 'Interest',
        changes: `${userFullName} deleted ${title}`,
      });
    })
    .catch(err => {
      setSaving(false)
      if(onError) {
        onError({
          title: 'Failed to delete interest',
          message: err.toString()
        })
      }
    })
  };

  return (
    <ModalConfirm
      onOk={() => onOk()}
      message={(
        <span>
          Are you sure you want to delete
          {' '}
          <b>{ title }</b>
          {' '}
          ?
        </span>
)}
    >
      {({ onClick }) => (
        <Button
          disabled={saving}
          variant="danger"
          onClick={onClick}
          title="Delete"
        >
          {!saving ? 'Delete' : 'Deleting'}
        </Button>
      )}
    </ModalConfirm>
  );
}
