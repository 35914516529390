import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CommissionList from './commissionList';

const PARTNER_MERCHANT = 'PARTNER_MERCHANT';
const INDIVIDUAL_TYPE = 'INDIVIDUAL';

export default function viewCommissionModal({ userUid, firstName, lastName }) {
  const [show, setShow] = useState(false);
  
  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShow(true)}
      >
        View
      </Button>

      { show && (
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>History of Commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-4'>{firstName} {lastName}</h5>
          <CommissionList 
            roleCode={PARTNER_MERCHANT} 
            type={INDIVIDUAL_TYPE} 
            userUid={userUid} 
            firstName={firstName} 
            lastName={lastName}
          />
        </Modal.Body>
      </Modal>
      )}
    </>
  );
}
