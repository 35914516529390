import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import styled from 'styled-components';
import RegistryClient from '../RegistryClient';
import { UploaderContext } from '../modules/uploader';

const StyledDiv = styled.div`
  &.custom-avatar {
    color: #fe9445;
    background-color: rgb(254 148 69 / 20%);
    border: 3px solid #fe9445;
    font-size: 24px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
    overflow: hidden;

    .avatar-text {
      color: #fe9445;
    }
  }

  &.custom-avatar__small {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }

  &.custom-avatar__default {
    width: 75px;
    height: 75px;
  }

  &.custom-avatar__large {
    width: 100px;
    height: 100px;
    font-size: 24px;
  }
`;

const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
      }
      __typename
    }
  }
`;

export default function Index(payload) {
  const {
    userUid, roleCode, noClick = false, size = 'default',
    className,
  } = payload;
  const [avatar, setAvatar] = useState(null);
  const [initial, setInitial] = useState(null);
  const [profileLink, setProfileLink] = useState(null);
  const navigate = useNavigate();
  const [path, setPath] = useState(null);
  const { getFileLink } = useContext(UploaderContext);

  const { data: getUserResult } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !userUid,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(getUserResult, 'getUser') ? getUserResult.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const iInitial = firstName && lastName ? `${_.toUpper(firstName.charAt(0))}${_.toUpper(lastName.charAt(0))}` : '';
    const iAvatar = _.has(user, 'avatar') ? user.avatar : null;
    const iStorage = _.has(iAvatar, 'storage') ? iAvatar.storage : null;
    const iPath = _.has(iStorage, 'path') ? iStorage.path : null;

    setPath(iPath);
    setInitial(iInitial);
  }, [userUid, getUserResult]);

  useEffect(() => {
    switch (roleCode) {
      case 'TUTOR': setProfileLink('/tutor/my-profile');
        break;
      default: setProfileLink('/student/my-profile');
    }
  }, [roleCode]);
  const options = {};

  if (!noClick) { Object.assign(options, { onClick: () => navigate(profileLink) }); }

  useEffect(() => {
    if (path) {
      getFileLink({ filePath: path }).then((uri) => {
        if (uri) {
          setAvatar(uri);
        }
      });
    }
  }, [path]);

  return (
    <StyledDiv className={`custom-avatar custom-avatar__${size} ${className}`}>
      <StyledSmallLink {...options}>
        {avatar ? <Image src={avatar} fluid roundedCircle /> : <span className="avatar-text">{initial}</span>}
      </StyledSmallLink>
    </StyledDiv>
  );
}

const StyledSmallLink = styled.a`
  color: #FFFFFF;
`;
