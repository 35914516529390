import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LiveSessionCard from './liveSessionCard';
import UpcomingSessionCards from './upcomingSessionCard';
import WalletWidget from '../../payments/wallet/walletWidget';
import AttendanceWidget from '../../attendance/attendanceWidget';

export default function Index() {
  return (
    <Row>
      <Col lg={{ span: 12 }}>
        <WalletWidget />
      </Col>
      <Col lg={12}><LiveSessionCard /></Col>
      <Col lg={12}><UpcomingSessionCards /></Col>
      <Col lg={{ span: 6 }}><AttendanceWidget /></Col>
    </Row>
  );
}
