import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../../../../components';
import { GET_AVAILABLE_VOUCHERS } from './gql';
import PaymentClient from '../../../../PaymentClient';
import VoucherCard from './voucherCard';
import LoginContext from '../../../login/login.context';

export default function voucherList({ onSelect }) {
  const { userUid } = useContext(LoginContext);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [dataList, setDataList] = useState([]);
  const [totalRow, setTotalRow] = useState(0);

  const {
    loading, error, data,
  } = useQuery(GET_AVAILABLE_VOUCHERS, {
    client: PaymentClient,
    fetchPolicy: 'network-only',
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      userUid,
    },
  });

  useEffect(() => {
    const result = _.has(data, 'getAvailableVouchers') ? data.getAvailableVouchers : null;
    const dataResult = _.has(result, 'rows') ? result.rows : [];
    const rowCount = _.has(result, 'count') ? result.count : 0;

    setDataList([...dataList, ...dataResult]);
    setTotalRow(rowCount);
  }, [loading, page]);

  if (loading) {
    return <div className="text-center"><LoadingSpinner /></div>;
  }

  if (error) {
    return <div><p className="text-center text-danger">Something went wrong!</p></div>;
  }

  return (
    <div style={{ height: 700, overflow: 'auto' }} id="scrollableDiv">
      <InfiniteScroll
        dataLength={dataList.length}
        next={() => {
          setTimeout(() => {
            setPage(page + 1);
          }, 1500);
        }}
        loader={<div className="text-center"><LoadingSpinner /></div>}
        hasMore={dataList.length < totalRow}
        endMessage={dataList.length === totalRow
          && (
          <p className="text-center text-muted">
            <b>--</b>
          </p>
          )}
        className="row gap-2"
        scrollableTarget="scrollableDiv"
      >
        { dataList.length > 0
          && dataList.map((item) => {
            const code = _.has(item, 'code') ? item.code : null;
            const description = _.has(item, 'description') ? item.description : null;
            const amount = _.has(item, 'amount') ? item.amount : null;

            const startDate = _.has(item, 'startDate') ? item.startDate : null;
            const start = moment(startDate).isValid() && moment(startDate).format('ll');
            const endDate = _.has(item, 'endDate') ? item.endDate : null;
            const end = moment(endDate).isValid() && moment(endDate).format('ll');

            const requirement = _.has(item, 'requirement') ? item.requirement : 0;
            const totalUsed = _.has(item, 'totalUsed') ? item.totalUsed : 0;
            const totalRemaining = requirement - totalUsed;

            if(totalRemaining < 1) {
              return null
            }

            return (
              <VoucherCard
                code={code}
                description={description}
                remaining={totalRemaining}
                startDate={start}
                endDate={end}
                discount={amount}
                onSelect={onSelect}
              />
            );
          })}

      </InfiniteScroll>
    </div>

  );
}
