import React from 'react';
import { Badge } from 'react-bootstrap';

export default function voucherStatus({ status }) {
  switch (status) {
    case 'PRESENT':
      return <Badge bg="success">Present</Badge>;
    case 'LATE':
      return <Badge bg="warning">Late</Badge>;
    case 'NO_SHOW':
      return <Badge bg="secondary">No Show</Badge>;
    default:
      return '';
  }
}
