/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      email
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      avatar {
        id
        uid
        fileCategory
        storage
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
/**
 * Queries End
 */

/**
 * Mutations
 */
export const updateProfileMutation = gql`
  mutation UpdateProfile(
    $userUid: String!
    $firstName: String!
    $middleInitial: String
    $lastName: String!
    $mobile: String
    $birthDate: String
    $gender: String
    $address: JSON
    $updatedBy: String!
    ) {
      updateProfile(
      userUid: $userUid
      firstName: $firstName
      middleInitial: $middleInitial
      lastName: $lastName
      mobile: $mobile
      birthDate: $birthDate
      gender: $gender
      address: $address
      updatedBy: $updatedBy
    ) {
      id
      uid
      userUid
      firstName
      middleInitial
      lastName
      age
      mobile
      birthDate
      gender
      address
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const saveFileMutation = gql`
  mutation SaveFile(
    $userUid: String!
    $fileCategory: String!
    $storage: JSON!
  ) {
    saveFile(
      userUid: $userUid
      fileCategory: $fileCategory
      storage: $storage
    ) {
      id
      uid
      userUid
      fileCategory
      storage
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
