/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation, useQuery } from '@apollo/client';
import { BlobProvider } from '@react-pdf/renderer';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { LoadingSpinner } from '../../../components';
import RenderPdf from './renderPdf';
import PaymentClient from '../../../PaymentClient';
import RegistryClient from '../../../RegistryClient';
import { getTutorPayslipQuery, getUserQuery, validateUser } from './gql';
import { LoginContext } from '../../login'
import styledComponents from 'styled-components'

const ViewToggle = styledComponents.div`
  color: #757575;
  cursor: pointer;
  display: flex;
  position: relative;

  i {
    position: absolute;
    top: -2.1em;
    right: 1em;
  }

  .active {
    color: #FE9445;
  }
`

const ModalContext = createContext();

export default function Index(payload) {
  const { uid: tutorPayrollUid } = payload;
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState(null);

  const onClose = () => {
    setVisible(false);
  };

  const onShow = () => {
    setVisible(true);
  };

  const { data: getUserResult } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !userUid,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(getUserResult, 'getUser') ? getUserResult.getUser : null;
    const iUsername = _.has(user, 'username') ? user.username : null;

    setUsername(iUsername);
  }, [getUserResult]);

  const contextPayload = useMemo(() => ({
    authenticated,
    setAuthenticated,
    username,
    tutorPayrollUid,
  }), [
    authenticated,
    setAuthenticated,
    username,
    tutorPayrollUid,
  ]);

  return (
    <ModalContext.Provider value={contextPayload}>
      <Button variant="link" block onClick={onShow}>
        <FontAwesomeIcon icon={solid('money-bill-trend-up')} />
        {' '}
        Payslip
      </Button>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Password Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {authenticated ? <LinkButton /> : <PasswordModal />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </ModalContext.Provider>
  );
}

function LinkButton() {
  const [tutorPayslip, setTutorPayslip] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { tutorPayrollUid } = useContext(ModalContext);
  const [canView, setCanView] = useState(false)

  const { loading: loadingPayslip, data: tutorPayslipResult } = useQuery(getTutorPayslipQuery, {
    client: PaymentClient,
    variables: { tutorPayrollUid },
  });

  useEffect(() => {
    const iTutorPayslip = _.has(tutorPayslipResult, 'getTutorPayslip') ? tutorPayslipResult.getTutorPayslip : null;

    setTutorPayslip(iTutorPayslip);
  }, [tutorPayslipResult]);

  useEffect(() => {
    const status = _.has(tutorPayslip, 'status') ? tutorPayslip.status : null

    setCanView(status !== 'PENDING');
    setDisabled(loading || !tutorPayslip);
    setLoading(loadingPayslip);
  }, [loadingPayslip, tutorPayslip]);

  return (
    <div>
      <BlobProvider document={<RenderPdf tutorPayslip={tutorPayslip} />}>
        {({ url, loading: loadingPdf }) => (
          <div className="d-grid gap-2">
            {
              !canView && (
                <Alert variant='danger'>
                  <Alert.Heading><FontAwesomeIcon icon={solid('circle-info')} /> Status Pending</Alert.Heading>
                  <p className='mb-0'>Payslip is not yet available for viewing.</p>
                </Alert>
              )
            }

            <Button
              variant="secondary"
              onClick={() => { window.open(url, '_blank'); }}
              disabled={loadingPdf || disabled || !canView}
            >
              {
                loading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <FontAwesomeIcon icon={solid('eye')} />
                    {' '}
                    View Payslip
                  </>
                )
              }
            </Button>
          </div>
        )}
      </BlobProvider>
    </div>
  );
}

function PasswordModal() {
  const [loading, setLoading] = useState(false);
  const { username, setAuthenticated } = useContext(ModalContext);
  const {
    handleSubmit, formState, control, setError,
  } = useForm();
  const { errors } = formState
  const [passwordType, setPasswordType] = useState('password')

  const togglePasswordType = useCallback(() => {
    const type = passwordType === 'password' ? 'text' : 'password'

    setPasswordType(type)
  }, [passwordType])

  const [validate] = useMutation(validateUser, {
    client: RegistryClient,
    onCompleted: () => {
      setAuthenticated(true);
      setLoading(false);
    },
    onError: () => {
      setAuthenticated(false);
      setError('password', { message: 'Failed to authenticate!' }, { shouldFocus: true });
      setLoading(false);
    },
  });

  const submitForm = useCallback((payload) => {
    const password = _.has(payload, 'password') ? payload.password : null;
    const variables = { username, password };

    setLoading(true);
    validate({ variables });
  });

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Form.Group className="form-group" controlId="auth.password">
        <Form.Label className="form-control-label">Password</Form.Label>
        <Controller
          name="password"
          control={control}
          rules={{ required: 'Password is required.' }}
          autoFocus
          render={({ field }) => (
            <Form.Control
              autoFocus
              type={passwordType}
              isInvalid={!!_.has(errors, 'password')}
              {...field}
            />
          )}
        />
        <ViewToggle>
          <i>
            <FontAwesomeIcon
              className={passwordType === 'text' ? 'active' : ''}
              icon={regular('eye')}
              onClick={togglePasswordType}
            />
          </i>
        </ViewToggle>
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'password') ? errors.password.message : 'Invalid password.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Button className="btn btn-primary login-btn" type="submit" disabled={loading}>
        {loading ? 'Loading…' : 'Authenticate'}
      </Button>
    </Form>
  );
}
