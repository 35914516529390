import React, {
  useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import RegistryClient from '../../../../RegistryClient';
import { getUserQuery } from './gql';
import { LoginContext } from '../../../login';
import OthersModalForm from './othersModalForm';

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [tin, setTin] = useState(null);
  const [wantAttendVibalCert, setWantAttendVibalCert] = useState(null);
  const [canEdit, setCanEdit] = useState(true);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const iUserStatus = _.has(user, 'status') ? user.status : null;
    const qualification = _.has(user, 'qualification') ? user.qualification : null;
    const iTin = _.has(qualification, 'tin') ? qualification.tin : null;
    const iWantAttendVibalCert = _.has(qualification, 'wantAttendVibalCert') ? qualification.wantAttendVibalCert : null;

    setTin(iTin);
    setWantAttendVibalCert(iWantAttendVibalCert ? 'YES' : 'NO');

    if (iUserStatus === 'FOR_REVIEW') { setCanEdit(false); } else { setCanEdit(true); }
  }, [userResult]);

  return (
    <div className="card col-10 me-auto ms-auto p-0">
      <div className="card-body custom-border-card pb-0">
        <div className="widget experience-widget mb-0">
          <h4 className="widget-title">
            Others
            {canEdit && <OthersModalForm />}
          </h4>
          <hr />
          <div className="experience-box">
            <ul className="experience-list profile-custom-list">
              <li>
                <div className="experience-content">
                  <div className="timeline-content">
                    <span>TIN #</span>
                    <div className="row-result">
                      {tin}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="experience-content">
                  <div className="timeline-content">
                    <span>Willing to attend Vibal certification</span>
                    <div className="row-result">
                      {wantAttendVibalCert}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
