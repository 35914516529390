import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import RegistryClient from '../../../../RegistryClient';
import { getUserFilesQuery, getUserQuery } from './gql';
import { LoginContext } from '../../../login';
import VideoModalForm from './vidModalForm';
import GovModalForm from './govModalForm';
import NbiModalForm from './nbiModalForm';
import VaccinationModalForm from './vaccinationModalForm';
import DemoTeachingModalForm from './demoTeachingModalForm';
import { ImageViewer, VideoPlayer } from '../../../../components';

const FILE_CATEGORY_GOV = 'GOV_ID'
const FILE_CATEGORY_NBI = 'NBI'
const FILE_CATEGORY_VACCINATION = 'VACCINATION'
const FILE_CATEGORY_INTRO = 'INTRO_VIDEO';
const FILE_CATEGORY_DEMO = 'DEMO_TEACHING'

const getFileUrl = (file) => {
  const govStorage = _.has(file, 'storage') ? file.storage : null;
  const govPublicUrl = _.has(govStorage, 'path') ? govStorage.path : null;

  return govPublicUrl
}

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [vidUrl, setVidUrl] = useState(null);
  const [govUrl, setGovUrl] = useState(null);
  const [nbiUrl, setNbiUrl] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [vaccinationUrl, setVaccineUrl] = useState(null);
  const [demoUrl, setDemoUrl] = useState(null);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null
    const iUserStatus = _.has(user, 'status') ? user.status : null;

    if (iUserStatus === 'FOR_REVIEW') { setCanEdit(false); } else { setCanEdit(true); }
  }, [userResult]);

  const { data: filesResults, refetch } = useQuery(getUserFilesQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { userUid },
  });

  useEffect(() => {
    console.log({ filesResults })
    const userFiles = _.has(filesResults, 'getUserFiles') ? filesResults.getUserFiles : []

    const govId = _.find(userFiles, { fileCategory: FILE_CATEGORY_GOV })
    const govPublicUrl = getFileUrl(govId)
    setGovUrl(govPublicUrl);

    const nbi = _.find(userFiles, { fileCategory: FILE_CATEGORY_NBI })
    const nbiPublicUrl = getFileUrl(nbi)
    setNbiUrl(nbiPublicUrl);

    const vax = _.find(userFiles, { fileCategory: FILE_CATEGORY_VACCINATION })
    const vaxPublicUrl = getFileUrl(vax)
    setVaccineUrl(vaxPublicUrl);

    const intro = _.find(userFiles, { fileCategory: FILE_CATEGORY_INTRO })
    const introPublicUrl = getFileUrl(intro)
    setVidUrl(introPublicUrl);

    const demo = _.find(userFiles, { fileCategory: FILE_CATEGORY_DEMO })
    const demoPublicUrl = getFileUrl(demo)
    setDemoUrl(demoPublicUrl);
  }, [filesResults])

  const vidThumb = useMemo(() => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        vidUrl ? (
          <VideoPlayer filePath={vidUrl} controls />
        ) : <EmptyPlaceHolder />
      }
    </>
  ), [vidUrl]);

  const demoThumb = useMemo(() => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        demoUrl ? (
          <VideoPlayer filePath={demoUrl} controls />
        ) : <EmptyPlaceHolder />
      }
    </>
  ), [demoUrl]);

  return (
    <div className="card col-10 me-auto ms-auto p-0">
      <div className="card-body custom-border-card pb-0">
        <div className="widget experience-widget mb-0">
          <h4 className="widget-title">User Files</h4>
          <hr />
          <Row>
            <Col lg={4}>
              <div className="timeline-content">
                <span>
                  Government ID
                  {' '}
                  {canEdit && <GovModalForm onCompleted={refetch} />}
                </span>
                <div className="row-result">
                  {
                    govUrl ? (
                      <ImageViewer
                        filePath={govUrl}
                        thumbnail
                        fluid
                      />
                    ) : <EmptyPlaceHolder />
                  }
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="timeline-content">
                <span>
                  NBI
                  {' '}
                  {canEdit && <NbiModalForm onCompleted={refetch} />}
                </span>
                <div className="row-result">
                  {
                    nbiUrl ? (
                      <ImageViewer
                        filePath={nbiUrl}
                        thumbnail
                        fluid
                      />
                    ) : <EmptyPlaceHolder />
                  }
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="timeline-content">
                <span>
                  Vaccination Card
                  {' '}
                  {canEdit && <VaccinationModalForm onCompleted={refetch} />}
                </span>
                <div className="row-result">
                  {
                    vaccinationUrl ? (
                      <ImageViewer
                        filePath={vaccinationUrl}
                        thumbnail
                        fluid
                      />
                    ) : <EmptyPlaceHolder />
                  }
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div
                className="mb-3"
                style={{ width: 'auto', height: 'auto', overflow: 'hidden' }}
              >
                <span>
                  Video Intro
                  {' '}
                  {canEdit && <VideoModalForm onCompleted={refetch} />}
                </span>
                <div className="row-result">
                  {vidThumb || <EmptyPlaceHolder />}
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div
                className="mb-3"
                style={{ width: 'auto', height: 'auto', overflow: 'hidden' }}
              >
                <span>
                  Demo Teaching
                  {' '}
                  {canEdit && <DemoTeachingModalForm onCompleted={refetch} />}
                </span>
                <div className="row-result">
                  {demoThumb || <EmptyPlaceHolder />}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

function EmptyPlaceHolder() {
  return (
    <div>
      <p>
        Empty Result
        {' '}
        <br />
        <span className="text-muted">Please upload a file!</span>
      </p>
    </div>
  );
}
