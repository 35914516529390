/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getTeacherLiveSessionQuery = gql`
  query GetTeacherLiveSession($instanceUid: ID!) {
    getTeacherLiveSession(instanceUid: $instanceUid) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      link
      canStart
      canJoin
      interest
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getTeacherUpcomingQuery = gql`
  query GetTeacherUpcomingSession($instanceUid: ID!) {
    getTeacherUpcomingSession(instanceUid: $instanceUid) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      link
      canStart
      canJoin
      interest
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

/**
 * End of Mutation
 */
