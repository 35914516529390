import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styledComponents from 'styled-components';
import LoginContext from '../login/login.context';
// import { Button } from 'react-bootstrap';

const CUSTOMERCARE = process.env.REACT_APP_CUSTOMERCARE;
const VIBAL_CONTACT = process.env.REACT_APP_CONTACT;

const EmailLink = styledComponents.a`
  color: #FFFFFF;
  &:hover {
    color: #fe9445;
  }
`;

// const StyledButtonNav = styledComponents(Button)`
//   padding: unset;
//   font-size: inherit;
//   margin: unset;
//   line-height: unset;
//   text-align: unset;
//   vertical-align: unset;
//   border: unset;
//   border-radius: unset;
// `;

export default function Index() {
  const { userUid, roleCode } = useContext(LoginContext);

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row">

            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <img src="/assets/img/logo.png" alt="logo" />
                </div>
                <div className="footer-about-content">
                  <p>
                    LearnLive! Tutorial Marketplace is a tutor booking platform.
                    Tutors can offer their expertise whether academic, hobbies,
                    or skills to interested individuals.
                    Students can choose a wide selection of tutors based on their tutorial needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">Terms & Policy</h2>
                <ul>
                  <li><Link to="/terms">Terms of Service</Link></li>
                  <li><Link to="/privacy">Privacy Policy</Link></li>
                </ul>
              </div>

            </div>

            <div className="col-lg-2 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">For Students</h2>
                <ul>
                  <li><Link to="/search">Search Tutors</Link></li>
                  {
                    !userUid && (
                      <>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/register">Register</Link></li>
                      </>
                    )
                  }
                  <li><Link to="/search">Booking</Link></li>
                  <li>
                    <Link
                      to="/faqs"
                      state={{ roleCode: 'STUDENT' }}
                    >
                      FAQs
                    </Link>

                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">For Tutors</h2>
                <ul>
                  {
                    !userUid && (
                      <>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/register-tutor">Register</Link></li>
                      </>
                    )
                  }

                  {
                    userUid && roleCode === 'TUTOR' && (
                      <>
                        <li><Link to="/tutor/sessions">Sessions</Link></li>
                        <li><Link to="/tutor">Dashboard</Link></li>
                      </>
                    )
                  }
                  <li><Link to="/faqs" state={{ roleCode: 'TUTOR' }}>FAQs</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-contact">
                <h2 className="footer-title">Contact Us</h2>
                <div className="footer-contact-info">
                  <div className="footer-address">
                    <p>Vibe Technologies</p>
                    <p>
                      1253 Gregorio Araneta Ave, cor. Ma. Clara St.,
                      Brgy Sto. Domingo, Quezon City,
                      Metro Manila
                    </p>
                    <p>Philippines</p>
                  </div>
                  <p>{VIBAL_CONTACT}</p>

                  <div className="footer-widget footer-menu">
                    <ul>
                      <li>
                        <EmailLink href={`mailto:${CUSTOMERCARE}`} target="__blank">{CUSTOMERCARE}</EmailLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container-fluid">

          <div className="copyright">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p className="mb-0">&copy; 2023 Learnlive Tutorial Market. All rights reserved.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="social-icon text-md-end">
                  <ul>
                    <li>
                      {/* <a href="#" target="_blank"> */}
                      <i className="fab fa-instagram" />
                      {/* </a> */}
                    </li>
                    <li>
                      {/* <a href="#" target="_blank"> */}
                      <i className="fab fa-linkedin-in" />
                      {/* </a> */}
                    </li>
                    <li>
                      {/* <a href="#" target="_blank"> */}
                      <i className="fab fa-twitter" />
                      {' '}
                      {/* </a> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
}
