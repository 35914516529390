/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getTutorPayslipQuery = gql`
  query GetTutorPayslip($tutorPayrollUid: String!) {
    getTutorPayslip(tutorPayrollUid: $tutorPayrollUid) {
      id
      uid
      tutorPayrollUid
      type
      subTotalGrossPay
      totalGrossPay
      totalIncentives
      totalDeductions
      totalWithholding
      totalFees
      totalNetPay
      status
      createdAt
      createdBy
      tutorPayroll {
        id
        uid
        tutor
        vat
        vatPercent
        payroll {
          id
          uid
          startDate
          endDate
          __typename
        }
        __typename
      }
      payslipItems {
        id
        uid
        type
        studentUserUid
        sessionName
        sessionType
        startDate
        endDate
        grossPay
        vat
        fees
        netPay
        document
        __typename
      }
      __typename
    }
  }
`;

export const getTutorPaginatedPayrollsQuery = gql`
  query GetTutorPaginatedPayrolls($tutorUid: String!, $page: Int!) {
    getTutorPaginatedPayrolls(tutorUid: $tutorUid, page: $page) {
      count
      rows {
        id
        uid
        payrollUid
        tutorUid
        tutor
        learnliveFees
        oneOnOne
        group
        grossPay
        fees
        withholding
        incentives
        deductions
        vat
        netPay
        status
        createdAt
        payroll {
          id
          uid
          type
          startDate
          endDate
          status
          __typename
        }
        payslip {
          id
          uid
          type
          totalGrossPay
          totalIncentives
          totalDeductions
          vat
          totalWithholding
          totalFees
          totalNetPay
          status
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      username
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
      }
      __typename
    }
  }
`;

export const getTutorPayrollQuery = gql`
  query GetTutorPayroll($uid: ID!) {
    getTutorPayroll(uid: $uid) {
      id
      uid
      payrollUid
      tutorUid
      tutor
      learnliveFees
      oneOnOne
      group
      subTotalGrossPay
      grossPay
      vat
      vatPercent
      fees
      withholding
      incentives
      deductions
      vat
      netPay
      payroll {
        id
        uid
        type
        startDate
        endDate
        payDate
        commissionRate
        status
        __typename
      }
      payrollItems {
        id
        uid
        tutorPayrollUid
        paymentUid
        amount
        document
        paidBy
        grossPay
        fees
        vat
        netPay
        __typename
      }
      payrollAdjustments {
        id
        uid
        tutorPayrollUid
        type
        amount
        status
        document
        __typename
      }
      birForm {
        id
        uid
        tutorPayrollUid
        storage
        __typename
      }
      createdAt
      createdBy
      __typename
    }
  }
`;

export const getPayrollQuery = gql`
  query GetPayroll($uid: ID!) {
    getPayroll(uid: $uid) {
      id
      uid
      type
      startDate
      endDate
      payDate
      commissionRate
      status
      birForm {
        id
        uid
        tutorPayrollUid
        storage
        __typename
      }
      
      createdAt
      createdBy
      __typename
    }
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

export const validateUser = gql`
 mutation ValidateUser($username: String!, $password: String!) {
   validateUser(username: $username, password: $password) {
     token
     user {
       id
       uid
       username
       email
       roleCode
       agreedPolicyTerm
       status
       userProfile {
         id
         uid
         userUid
         firstName
         middleInitial
         lastName
         age
         mobile
         birthDate
         gender
         address
         status
         createdAt
         createdBy
         updatedAt
         updatedBy
       }
       createdAt
       createdBy
       updatedAt
       updatedBy
     }
   }
 }
`;

/**
 * End of Mutation
 */
