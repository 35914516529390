import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Button, Row, Col
} from 'react-bootstrap';
import UpdateStatusModal from './updateStatusModal';
import UserImage from '../../../components/userImage';
import { ImageViewer, VideoPlayer } from '../../../components';

export default function tutorProfile({ data, onClose }) {
  const user = _.has(data, 'user') ? data.user : null;
  const status = _.has(data, 'status') ? data.status : null;
  const userProfile = _.has(user, 'userProfile') ? user.userProfile : null;
  const username = _.has(user, 'username') ? user.username : null;
  const email = _.has(user, 'email') ? user.email : null;
  const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
  const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;
  const birthDate = _.has(userProfile, 'birthDate') ? userProfile.birthDate : null;
  const mobile = _.has(userProfile, 'mobile') ? userProfile.mobile : null;
  const address = _.has(userProfile, 'address') ? userProfile.address : null;
  const address1 = _.has(address, 'address1') ? address.address1 : null;
  const address2 = _.has(address, 'address2') ? address.address2 : null;
  const city = _.has(address, 'city') ? address.city : null;
  const postalCode = _.has(address, 'postalCode') ? address.postalCode : null;
  const countryCode = _.has(address, 'countryCode') ? address.countryCode : null;

  const avatar = _.has(user, 'avatar') ? user.avatar : null;
  const avatarStorage = _.has(avatar, 'storage') ? avatar.storage : null;
  const avatarFilePath = _.has(avatarStorage, 'path') ? avatarStorage.path : null;

  const qualification = _.has(user, 'qualification') ? user.qualification : null;
  const about = _.has(qualification, 'about') ? qualification.about : null;
  const educations = _.has(qualification, 'educations') ? qualification.educations : null;
  const certifications = _.has(qualification, 'certifications') ? qualification.certifications : [];

  const govId = _.has(qualification, 'govId') ? qualification.govId : null;
  const govIdStorage = _.has(govId, 'storage') ? govId.storage : null;
  const govIdFilePath = _.has(govIdStorage, 'path') ? govIdStorage.path : null;

  const nbi = _.has(qualification, 'nbi') ? qualification.nbi : null;
  const nbiStorage = _.has(nbi, 'storage') ? nbi.storage : null;
  const nbiFilePath = _.has(nbiStorage, 'path') ? nbiStorage.path : null;

  const introVideo = _.has(qualification, 'introVideo') ? qualification.introVideo : null;
  const introVideoStorage = _.has(introVideo, 'storage') ? introVideo.storage : null;
  const introVideoFilePath = _.has(introVideoStorage, 'path') ? introVideoStorage.path : null;

  const demoTeaching = _.has(qualification, 'demoTeaching') ? qualification.demoTeaching : null;
  const demoTeachingStorage = _.has(demoTeaching, 'storage') ? demoTeaching.storage : null;
  const demotTeachingPath = _.has(demoTeachingStorage, 'path') ? demoTeachingStorage.path : null;

  return (
    <div className="row">
      <Col lg={6} xs={12} className="mb-4">
        <Button variant="link" onClick={() => onClose()}>
          <i className="fa fa-arrow-circle-left" />
          {' '}
          Back to Tutor Recruitment List
        </Button>
      </Col>
      <Col lg={{ span: 6 }} xs={{ span: 12 }} align="right" className="mb-4">
        <UpdateStatusModal recruitmentData={data}
          currentStatus={status}
        />
      </Col>

      <div className="card">

        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-auto profile-image">
              {avatarFilePath
                ? (
                  <UserImage
                    className="pro-avatar"
                    filePath={avatarFilePath}
                    width={100}
                    height={100}
                  />
                )
                : (
                  <div className="pro-avatar">
                    {firstName && firstName.charAt(0).toUpperCase()}
                    {lastName && lastName.charAt(0).toUpperCase()}
                  </div>
                )}
            </div>
            <div className="col ml-md-n2 profile-user-info">
              <h4 className="mb-0">
                {firstName}
                {' '}
                {lastName}
              </h4>
              <h6 className="text-muted">
                <a
                  href="/cdn-cgi/l/email-protection"
                  className="__cf_email__ text-muted"
                  data-cfemail="60010c0c050e04011609132001040d090e4e030f0d"
                >
                  {email}
                </a>

              </h6>
              {/* <div className="pb-3">
                <i className="fa fa-map-marker" />
                {' '}
                Florida, United States
              </div> */}
              <div className="about-text">
                {about}

              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Personal Details</h4>

          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Name</p>
            <p className="col-sm-10">
              {firstName}
              {' '}
              {lastName}
            </p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Username</p>
            <p className="col-sm-10">{username}</p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Email ID</p>
            <p className="col-sm-10">
              <a
                href="/cdn-cgi/l/email-protection"
                className="__cf_email__"
                data-cfemail="7c1d10101912181d0a150f3c19041d110c1019521f1311"
              >
                {email}
              </a>
            </p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Date of Birth</p>
            <p className="col-sm-10">{moment(birthDate).isValid() && moment(birthDate).format('LL')}</p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Mobile</p>
            <p className="col-sm-10">{mobile}</p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Address 1</p>
            <p className="col-sm-10">
              {address1}
            </p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Address 2</p>
            <p className="col-sm-10">
              {address2}
            </p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">City</p>
            <p className="col-sm-10">
              {city}
            </p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Postal Code</p>
            <p className="col-sm-10">
              {postalCode}
            </p>
          </div>
          <div className="row">
            <p className="col-sm-2 text-muted mb-0 mb-sm-3">Country</p>
            <p className="col-sm-10">
              {countryCode}
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Educational Attainment</h4>
          <Row>
            {educations && educations.length > 0 && educations.map((education) => (
              <Col xl={4}>
                <h6>{education.degree}</h6>
                <div>{education.degreeEarned}</div>
                <div>{education.school}</div>
                <div>{education.schoolAddress}</div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Eligibility/Certification</h4>
          <Row style={{ display: 'inline-flex', gap: 8 }}>
            {certifications.length > 0 && certifications.map((certification) => {
              const certificationStorage = _.has(certification, 'storage') ? certification.storage : null;
              const certificationPublicurl = _.has(certificationStorage, 'path') ? certificationStorage.path : null;
              return (
                <Col>
                  <ImageViewer key={`certificate-${certification.id}`} filePath={certificationPublicurl} fluid />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Government ID</h4>
          <Row style={{ display: 'inline-flex', gap: 8 }}>
            <Col>
              <ImageViewer key="govId" filePath={govIdFilePath} fluid />
            </Col>
          </Row>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">NBI Clearance</h4>
          <Row style={{ gap: 8 }}>
            <Col>
              <ImageViewer key="nbi" filePath={nbiFilePath} fluid />
            </Col>
          </Row>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Video Introduction</h4>
          <Row>
            <Col xl={12}>

              <VideoPlayer
                filePath={introVideoFilePath}
              />
            </Col>
          </Row>

        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Demo Teaching</h4>
          <Row>
            <Col xl={12}>

              <VideoPlayer
                filePath={demotTeachingPath}
              />
            </Col>
          </Row>

        </div>
      </div>

    </div>
  );
}
