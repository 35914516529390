/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */
export const getUserQualificationQuery = gql`
  query getUserQualification($userUid: String!) {
    getUserQualification(userUid: $userUid) {
      id
      uid
      userUid
      about
      educations
      certifications
      introVideo
      govId
      nbi
      tin
      wantAttendVibalCert
      demoTeaching

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      progressPercentage
      qualification {
        id
        uid
        about
        educations
        certifications
        introVideo
        govId
        nbi
        tin
        wantAttendVibalCert
        demoTeaching
        
        __typename
      }
      userProfile {
        id
        uid
        status
        firstName
        middleInitial
        lastName
        __typename
      }
      __typename
    }
  }
`;

export const getUserFilesQuery = gql`
  query GetUserFiles($userUid: ID!, $fileCategory: String) {
    getUserFiles(userUid: $userUid, fileCategory: $fileCategory) {
      id
      uid
      userUid
      fileCategory
      storage
      createdBy
      __typename
    }
  }
`
/**
 * Queries End
 */

/**
 * Mutations
 */
export const saveQualificationsMutation = gql`
  mutation saveQualifications(
      $userUid: ID!
      $about: String
      $educations: JSON
      $tin: String
      $wantAttendVibalCert: Boolean
    ) {
      saveQualifications(
        userUid: $userUid
        about: $about
        educations: $educations
        tin: $tin
        wantAttendVibalCert: $wantAttendVibalCert
    ) {
      id
      uid
      userUid
      about
      educations
      certifications
      introVideo
      govId
      nbi
      tin
      wantAttendVibalCert
      demoTeaching

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const saveFileMutation = gql`
  mutation SaveFile(
    $userUid: String!
    $fileCategory: String!
    $storage: JSON!
  ) {
    saveFile(
      userUid: $userUid
      fileCategory: $fileCategory
      storage: $storage
    ) {
      id
      uid
      userUid
      fileCategory
      storage
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const sendForReviewMutation = gql`
  mutation SendForReview(
      $userUid: String!
    ) {
      sendForReview(
        userUid: $userUid
    ) {
      id
      uid
      status
      __typename
    }
  }
`;

export const insertCertificateMutation = gql`
  mutation InsertCertificate(
    $userUid: String!
    $description: String!
    $storage: JSON!
  ) {
    insertCertificate(
      userUid: $userUid
      description: $description
      storage: $storage
    ) {
      id
      uid
      userUid
      fileCategory
      storage
      __typename
    }
  }
`;

export const deleteFileMutation = gql`
  mutation deleteFile(
    $uid: ID!
    $deletedBy: String!
  ) {
    deleteFile(
      uid: $uid
      deletedBy: $deletedBy
    )
  }
`;
/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
