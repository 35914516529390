import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Error403 from '../../template/components/error403';
import LoginContext from '../login/login.context';

export { default as MarketingAdminDashboard } from './dashboard';
export { default as ManagementSettings } from './managementSettings';
export { default as MarketingUserManagement } from './userManagement';
export { default as TutorRecruitment } from './tutorRecruitment';
export { default as ScheduleAndClassManagement } from './scheduleAndClassManagement';
export { default as CommissionManagement } from './comissionManagemet';
export { default as Vouchers } from './vouchers';
export { default as MarketingIssueAndResolution } from './issueAndResolution';
export { default as MarketingTicketList } from './issueAndResolution/ticketList';
export { default as MarketingTicketCategory } from './issueAndResolution/ticketCategory';
export { default as MarketingAttendance } from './attendance';
export { default as SystemVouchers } from './systemVouchers';
export { default as MarketingAnalytics } from './analytics';
export { default as VoucherView } from './vouchers/voucherView';

export default function Index() {
  const { roleCode } = useContext(LoginContext);

  if (roleCode !== 'MARKETING_ADMIN') { return <Error403 />; }

  return (
    <Outlet />
  );
}
