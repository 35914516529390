/* eslint-disable react/prop-types */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Card, Col, Row, Button,
} from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import styledComponents from 'styled-components';
import { CustomPlaceHolder, CustomTable, LoadingSpinner } from '../../../components';
import { DashboardTemplate } from '../../../template/components';
import {
  getPayrollQuery,
  getTutorPayrollQuery,
  getTutorPayslipQuery,
  getUserQuery,
} from './gql';
import PaymentClient from '../../../PaymentClient';
import RegistryClient from '../../../RegistryClient';
import ViewContext from './viewPayroll.context';
import AddIncentiveModal from './addIncentiveModal';
import AddDeductionModal from './addDeductionModal';
import ViewPayslip from './viewPayslip';
import UploadBirModal from './uploadBirModal';
import DeleteAdjustmentModal from './deleteAdjustmentModal';
import AddAdditionals from './addAdditionals';
import { getPaymentQuery } from './gql';

const PayrollContext = createContext()

const ButtonWrapper = styledComponents.div`
  &.mr {
    margin-right: 1em;
  }
`;

export default function Index() {
  const { state } = useLocation();
  const uid = _.has(state, 'uid') ? state.uid : null;
  const navigate = useNavigate();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [canViewPayslip, setCanViewPayslip] = useState(false);
  const [hasPayslip, setHasPayslip] = useState(false);
  const [payslipStatus, setPayslipStatus] = useState(null);
  const [canUpload, setCanUpload] = useState(false);
  const [tutorPayrollId, setTutorPayrollId] = useState(null);
  const [tutorName, setTutorName] = useState(null);
  const [vatPercent, setVatPercent] = useState(null);

  const { data: tutorPayslipResult, loading } = useQuery(getTutorPayslipQuery, {
    client: PaymentClient,
    variables: { tutorPayrollUid: uid },
  });

  useEffect(() => {
    const iTutorPayslip = _.has(tutorPayslipResult, 'getTutorPayslip') ? tutorPayslipResult.getTutorPayslip : null;
    const status = _.has(iTutorPayslip, 'status') ? iTutorPayslip.status : 'NOT GENERATED';
    const iTutorPayroll = _.has(iTutorPayslip, 'tutorPayroll') ? iTutorPayslip.tutorPayroll : null;
    const iTutorPayrollId = _.has(iTutorPayroll, 'id') ? iTutorPayroll.id : null;
    const birForm = _.has(iTutorPayroll, 'birForm') ? iTutorPayroll.birForm : null;
    const iVatPercent = _.has(iTutorPayroll, 'vatPercent') ? iTutorPayroll.vatPercent : null;

    setVatPercent(iVatPercent)

    if (iTutorPayslip) {
      setCanViewPayslip(true);
      setHasPayslip(true);
    } else {
      setCanViewPayslip(false);
      setHasPayslip(false);
    }

    if (birForm) { setCanUpload(false); } else { setCanUpload(true); }

    setTutorPayrollId(iTutorPayrollId);
    setPayslipStatus(status);
  }, [tutorPayslipResult]);

  const onBack = useCallback(() => {
    navigate(-1);
  });

  const contextPayload = useMemo(() => ({
    uid,
    loadingPdf,
    setLoadingPdf,
    tutorPayrollId,
    tutorName,
    setTutorName,
    vatPercent
  }), [
    uid,
    loadingPdf,
    setLoadingPdf,
    tutorPayrollId,
    tutorName,
    setTutorName,
    vatPercent
  ]);

  return (
    <DashboardTemplate>
      <ViewContext.Provider value={contextPayload}>
        <h3 className="pb-3">View Payroll</h3>

        <Card>
          <Card.Header>
            <Row>
              <Col lg={{ span: 2 }}>
                <Button variant="link" onClick={onBack}>
                  <FontAwesomeIcon icon={solid('arrow-left')} />
                  {' '}
                  Back
                </Button>
              </Col>
              <Col lg={{ span: 10 }}>
                <div className="d-flex justify-content-end">
                  {!hasPayslip && (
                    <>
                      <ButtonWrapper className="mr">
                        <AddAdditionals />
                      </ButtonWrapper>
                      <ButtonWrapper className="mr">
                        <AddIncentiveModal />
                      </ButtonWrapper>
                      <ButtonWrapper className="mr">
                        <AddDeductionModal />
                      </ButtonWrapper>
                    </>
                  )}

                  {canViewPayslip && (
                    <ButtonWrapper className="mr">
                      <ViewPayslip />
                    </ButtonWrapper>
                  )}

                  {canUpload && (
                    <ButtonWrapper className="mr">
                      <UploadBirModal />
                    </ButtonWrapper>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={{ span: 12 }}>
                <p className="lead">
                  Payslip Status
                  {' '}
                  <span className="text-info">
                    {
                      loading ? (
                        <LoadingSpinner />
                      ) : payslipStatus
                    }
                  </span>
                </p>
              </Col>
            </Row>
            <PayrollDetails />
          </Card.Body>
        </Card>
      </ViewContext.Provider>
    </DashboardTemplate>
  );
}

function PayrollDetails() {
  const { uid, tutorName, setTutorName } = useContext(ViewContext);
  const [loading, setLoading] = useState(false);
  const [payrollUid, setPayrollUid] = useState(null);
  const [payPeriod, setPayPeriod] = useState(null);
  const [payrollItems, setPayrollItems] = useState([]);
  const [subTotalGross, setSubTotalGross] = useState(null);
  const [totalGross, setTotalGross] = useState(null);
  const [vat, setVat] = useState(null);
  const [withholding, setWithholding] = useState(null);
  const [totalFees, setTotalFees] = useState(null);
  const [totalNet, setTotalNet] = useState(null);
  const [totalIncentives, setTotalIncentives] = useState(null);
  const [totalDeductions, setTotalDeductions] = useState(null);
  const [payrollAdjustments, setPayrollAdjustments] = useState([]);
  const [vatPercent, setVatPercent] = useState(null);

  const {
    loading: loadingTutorPayroll,
    data: payrollTutorResult,
  } = useQuery(getTutorPayrollQuery, {
    client: PaymentClient,
    variables: { uid },
  });

  const {
    loading: loadingPayroll,
    data: payrollResult,
  } = useQuery(getPayrollQuery, {
    client: PaymentClient,
    skip: !payrollUid,
    variables: { uid: payrollUid },
  });

  useEffect(() => {
    setLoading(
      loadingTutorPayroll
      || loadingPayroll,
    );
  }, [
    setLoading,
    loadingTutorPayroll,
    loadingPayroll,
  ]);

  useEffect(() => {
    if (payrollTutorResult) {
      const tutorPayroll = _.has(payrollTutorResult, 'getTutorPayroll')
        ? payrollTutorResult.getTutorPayroll : null;
      const iTutor = _.has(tutorPayroll, 'tutor') ? tutorPayroll.tutor : null;
      const iPayrollUid = _.has(tutorPayroll, 'payrollUid') ? tutorPayroll.payrollUid : null;
      const iPayrollItems = _.has(tutorPayroll, 'payrollItems') ? tutorPayroll.payrollItems : [];
      const subTotalGrossPay = _.has(tutorPayroll, 'subTotalGrossPay') ? parseFloat(tutorPayroll.subTotalGrossPay).toFixed(2) : null;
      const iVat = _.has(tutorPayroll, 'vat') ? parseFloat(tutorPayroll.vat).toFixed(2) : null;
      const iVatPercent = _.has(tutorPayroll, 'vatPercent') ? parseFloat(tutorPayroll.vatPercent) : null;
      const grossPay = _.has(tutorPayroll, 'grossPay') ? parseFloat(tutorPayroll.grossPay).toFixed(2) : null;
      const iWithholding = _.has(tutorPayroll, 'withholding') ? parseFloat(tutorPayroll.withholding).toFixed(2) : null;
      const fees = _.has(tutorPayroll, 'fees') ? parseFloat(tutorPayroll.fees).toFixed(2) : null;
      const net = _.has(tutorPayroll, 'netPay') ? parseFloat(tutorPayroll.netPay).toFixed(2) : null;
      const adjustments = _.has(tutorPayroll, 'payrollAdjustments') ? tutorPayroll.payrollAdjustments : null;
      const iIncentives = _.has(tutorPayroll, 'incentives') ? parseFloat(tutorPayroll.incentives).toFixed(2) : null;
      const iDeductions = _.has(tutorPayroll, 'deductions') ? parseFloat(tutorPayroll.deductions).toFixed(2) : null;

      setTutorName(iTutor);
      setPayrollUid(iPayrollUid);
      setPayrollItems(iPayrollItems);
      setSubTotalGross(subTotalGrossPay)
      setVat(iVat)
      setVatPercent(iVatPercent)
      setTotalGross(grossPay);
      setWithholding(iWithholding);
      setTotalFees(fees);
      setTotalNet(net);
      setTotalIncentives(iIncentives);
      setTotalDeductions(iDeductions);
      setPayrollAdjustments(adjustments);
    }
  }, [
    payrollTutorResult,
    setTutorName,
    setPayrollUid,
    setPayrollItems,
  ]);

  useEffect(() => {
    if (payrollResult) {
      const payroll = _.has(payrollResult, 'getPayroll') ? payrollResult.getPayroll : null;
      const iStartDate = _.has(payroll, 'startDate') ? payroll.startDate : null;
      const iEndDate = _.has(payroll, 'endDate') ? payroll.endDate : null;
      const iPayPeriod = `${moment(iStartDate).format('ll')} to ${moment(iEndDate).format('ll')}`;

      setPayPeriod(iPayPeriod);
    }
  }, [setPayPeriod, payrollResult]);

  const contextPayload = useMemo(() => ({
    vatPercent
  }), [
    vatPercent
  ]);

  if (loading) {
    return <CustomPlaceHolder loading={loading} rowSet={6} />
  }

  return (
    <PayrollContext.Provider value={contextPayload}>
      <dl className="dl-horizontal">
        <dt>Tutor</dt>
        <dd><p className="lead">{tutorName}</p></dd>

        <dt>Pay Period</dt>
        <dd><p className="lead">{payPeriod}</p></dd>

        <dt>One-On-One Sessions</dt>
        <dd><SingleSessionTable rows={payrollItems} /></dd>

        <dt>Group Sessions</dt>
        <dd><GroupSessionTable rows={payrollItems} /></dd>

        <dt>Adjustments</dt>
        <dd><AdjustmentsTable rows={payrollAdjustments} /></dd>

        <dt>Subtotal Gross Pay</dt>
        <dd><PesoValue amount={subTotalGross} /></dd>

        <dt>VAT ({vatPercent}%)</dt>
        <dd><PesoValue amount={`${vat && '-' + vat}`} /></dd>

        <dt>Total Incentives</dt>
        <dd><PesoValue amount={`${totalIncentives && '+' + totalIncentives}`} /></dd>

        <dt>Total Deductions</dt>
        <dd><PesoValue amount={`${totalDeductions && '-' + totalDeductions}`} /></dd>

        <dt>Total Learnlive Fees</dt>
        <dd>
          <dd><PesoValue amount={`${totalFees && '-' + totalFees}`} /></dd>
        </dd>
        <hr />

        <dt>Total Gross Pay</dt>
        <dd><PesoValue amount={totalGross} /></dd>

        <dt>Total Withholding Tax</dt>
        <dd>
          <PesoValue amount={`${withholding && '-' + withholding}`} />
        </dd>
        <hr />

        <dt>Total Net Pay</dt>
        <dd>
          <PesoValue amount={totalNet} />
        </dd>
      </dl>
    </PayrollContext.Provider>
  );
}

function SingleSessionTable(payload) {
  const rows = _.has(payload, 'rows') ? payload.rows : [];
  const [payments, setPayments] = useState([]);
  const { vatPercent } = useContext(PayrollContext)

  useEffect(() => {
    const filtered = _.filter(rows, (row) => {
      const doc = _.has(row, 'document') ? row.document : null;
      const type = _.has(doc, 'sessionType') ? doc.sessionType : null;

      if (type === 'SINGLE') { return true; } return false;
    });

    const data = _.map(filtered, (row) => {
      const document = _.has(row, 'document') ? row.document : null;
      const iStart = _.has(document, 'startDate') ? moment(document.startDate).format('YYYY/MM/DD HH:mm') : null;
      const iEnd = _.has(document, 'endDate') ? moment(document.endDate).format('YYYY/MM/DD HH:mm') : null;
      const schedule = `${iStart} to ${iEnd}`;
      const baseRate = _.has(row, 'amount') ? row.amount : null;
      const grossPay = _.has(row, 'grossPay') ? row.grossPay : null;
      const fees = _.has(row, 'fees') ? row.fees : null;
      const vat = _.has(row, 'vat') ? row.vat : null;
      const netPay = _.has(row, 'netPay') ? row.netPay : null;
      const paidBy = _.has(row, 'paidBy') ? row.paidBy : null;
      const paymentUid = _.has(row, 'paymentUid') ? row.paymentUid : null;

      return {
        schedule,
        baseRate,
        grossPay,
        fees,
        vat,
        netPay,
        paidBy,
        paymentUid
      };
    });

    setPayments(data);
  }, [rows]);

  const columns = useMemo(() => [
    {
      title: 'Student',
      dataKey: 'paidBy',
      width: '20%',
      render: (value) => <PaidBy userUid={value} />,
    },
    {
      title: 'Schedule',
      dataKey: 'schedule',
      width: '5%',
    },
    {
      title: 'Confirmed Date',
      dataKey: 'paymentUid',
      width: '10%',
      render: (value) => <ConfirmedDate paymentUid={value} />,
    },
    {
      title: 'Base Rate',
      dataKey: 'baseRate',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Gross Pay',
      dataKey: 'grossPay',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Learnlive Fees',
      dataKey: 'fees',
      width: '15%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: `VAT(${vatPercent}%)`,
      dataKey: 'vat',
      width: '5%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'Net Pay',
      dataKey: 'netPay',
      width: '10%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={payments}
    />
  );
}

function GroupSessionTable(payload) {
  const rows = _.has(payload, 'rows') ? payload.rows : [];
  const [payments, setPayments] = useState([]);
  const { vatPercent } = useContext(PayrollContext)

  useEffect(() => {
    const filtered = _.filter(rows, (row) => {
      const doc = _.has(row, 'document') ? row.document : null;
      const type = _.has(doc, 'sessionType') ? doc.sessionType : null;

      if (type === 'GROUP') { return true; } return false;
    });

    const data = _.map(filtered, (row) => {
      const document = _.has(row, 'document') ? row.document : null;
      const iStart = _.has(document, 'startDate') ? moment(document.startDate).format('YYYY/MM/DD HH:mm') : null;
      const iEnd = _.has(document, 'endDate') ? moment(document.endDate).format('YYYY/MM/DD HH:mm') : null;
      const schedule = `${iStart} to ${iEnd}`;
      const baseRate = _.has(row, 'amount') ? row.amount : null;
      const grossPay = _.has(row, 'grossPay') ? row.grossPay : null;
      const fees = _.has(row, 'fees') ? row.fees : null;
      const vat = _.has(row, 'vat') ? row.vat : null;
      const netPay = _.has(row, 'netPay') ? row.netPay : null;
      const paidBy = _.has(row, 'paidBy') ? row.paidBy : null;
      const paymentUid = _.has(row, 'paymentUid') ? row.paymentUid : null;

      return {
        schedule,
        baseRate,
        grossPay,
        fees,
        vat,
        netPay,
        paidBy,
        paymentUid
      };
    });

    setPayments(data);
  }, [rows]);

  const columns = useMemo(() => [
    {
      title: 'Student',
      dataKey: 'paidBy',
      width: '20%',
      render: (value) => <PaidBy userUid={value} />,
    },
    {
      title: 'Schedule',
      dataKey: 'schedule',
      width: '5%',
    },
    {
      title: 'Confirmed Date',
      dataKey: 'paymentUid',
      width: '10%',
      render: (value) => <ConfirmedDate paymentUid={value} />,
    },
    {
      title: 'Base Rate',
      dataKey: 'baseRate',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Gross Pay',
      dataKey: 'grossPay',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Learnlive Fees',
      dataKey: 'fees',
      width: '15%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: `VAT(${vatPercent}%)`,
      dataKey: 'vat',
      width: '5%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'Net Pay',
      dataKey: 'netPay',
      width: '10%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={payments}
    />
  );
}

function AdjustmentsTable(payload) {
  const rows = _.has(payload, 'rows') ? payload.rows : [];

  const columns = useMemo(() => [
    {
      title: 'Amount',
      dataKey: 'amount',
      render: (value, row) => {
        const type = _.has(row, 'type') ? row.type : null;
        let negative = false;

        if (type === 'DEDUCTION') {
          negative = true;
        }
        return <PesoValue amount={value} negative={negative} />;
      },
    },
    {
      title: 'Type',
      dataKey: 'type',
    },
    {
      title: 'Additional Name',
      dataKey: 'document',
      render: (document) => {
        const name = _.has(document, 'name') ? document.name : null;

        return name;
      },
    },
    {
      title: '',
      dataKey: 'uid',
      render: (uid, row) => {
        const type = _.has(row, 'type') ? row.type : null;
        const amount = _.has(row, 'amount') ? row.amount : null;

        return <DeleteAdjustmentModal uid={uid} type={type} amount={amount} />
      },
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={rows}
    />
  );
}

function PesoValue(props) {
  const { amount, negative } = props;

  return (
    <>
      <FontAwesomeIcon icon={solid('peso-sign')} />
      {' '}
      {negative && '-'}
      {amount}
    </>
  );
}

function PaidBy(props) {
  const { userUid } = props;
  const [fullName, setFullName] = useState(null);

  const { loading, data } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !userUid,
    variables: { uid: userUid },
  });

  useEffect(() => {
    if (data) {
      const user = _.has(data, 'getUser') ? data.getUser : null;
      const profile = _.has(user, 'userProfile') ? user.userProfile : null;
      const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
      const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
      const iFullName = `${firstName} ${lastName}`;

      setFullName(iFullName);
    }
  }, [data]);

  return (
    <>
      <FontAwesomeIcon icon={solid('user')} />
      {' '}
      {loading ? <LoadingSpinner /> : fullName}
    </>
  );
}

function ConfirmedDate({ paymentUid }) {
  const [date, setDate] = useState(null)

  const { data } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid }
  })

  useEffect(() => {
    const payment = _.has(data, 'getPayment') ? data.getPayment : null
    const confirmedDate = _.has(payment, 'confirmedDate') ? moment(payment.confirmedDate).format('YYYY/MM/DD HH:mm') : null

    setDate(confirmedDate)
  }, [data])

  return (
    <>{date}</>
  )
}
