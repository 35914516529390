import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { GET_LAST_ACTIVITY } from './gql';
import { getSessionQuery } from '../../session/gql';
import AuditrTrailClient from '../../../AuditTrailClient';
import UserImage from '../../../components/userImage';
import { recruitmentStatusLabel } from '../../../constants'
import UpdateStatusModal from './updateStatusModal'

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function tuteesTableItem({ userData }) {
  const uid = _.has(userData, 'uid') ? userData.uid : null;
  const status = _.has(userData, 'status') ? userData.status : null;
  const email = _.has(userData, 'email') ? userData.email : null;
  const userProfile = _.has(userData, 'userProfile') ? userData.userProfile : null;

  const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
  const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;
  // const createdAt = _.has(userData, 'createdAt') ? userData.createdAt : null;

  const avatar = _.has(userData, 'avatar') ? userData.avatar : null;
  const avatarStorage = _.has(avatar, 'storage') ? avatar.storage : null;

  const avatarPath = _.has(avatarStorage, 'path') ? avatarStorage.path : null;

  // activity
  const { data } = useQuery(GET_LAST_ACTIVITY, {
    client: AuditrTrailClient,
    variables: {
      userUid: uid,
      appCode: APPCODE,
      module: 'Sessions',
    },
  });

  const lastActivityResult = _.has(data, 'getLastActivity') ? data.getLastActivity : null;
  const lastActivityChanges = _.has(lastActivityResult, 'changes') ? lastActivityResult.changes : null;
  const lastActivityDetails = _.has(lastActivityResult, 'details') ? lastActivityResult.details : null;
  const sessionUid = _.has(lastActivityDetails, 'sessionUid') ? lastActivityDetails.sessionUid : null;
  const lastActivityCreatedAt = _.has(lastActivityResult, 'createdAt') ? lastActivityResult.createdAt : null

  return (
    <tr>
      <td>
        <h2 className="table-avatar">
          <Link to={`/profile/${uid}`} className="avatar avatar-sm me-2">
            <UserImage filePath={avatarPath} />

          </Link>
          <Link to={`/profile/${uid}`}>
            {firstName}
            {' '}
            {lastName}
          </Link>
        </h2>
      </td>
      <td width="15%">
        {lastActivityChanges}
      </td>
      <td>
        {moment(lastActivityCreatedAt).isValid() && moment(lastActivityCreatedAt).format('LLL')}
      </td>
      <td>
        {sessionUid ? <LastTutor sessionUid={sessionUid} /> : ''}
      </td>
      <td>
        <span className={status === 'ACTIVE' ? 'text-success' : 'text-secondary'}>
          {recruitmentStatusLabel[status]}
        </span>
      </td>
      <td>
        <UpdateStatusModal
          userUid={uid}
          status={status}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      </td>
    </tr>
  );
}

function LastTutor({ sessionUid }) {
  const { data } = useQuery(getSessionQuery, {
    variables: { uid: sessionUid },
  });
  const sessionResult = _.has(data, 'getSession') ? data.getSession : null;
  const timeslot = _.has(sessionResult, 'timeslot') ? sessionResult.timeslot : null;
  const tutorialProfile = _.has(timeslot, 'tutorialProfile') ? timeslot.tutorialProfile : null;
  const tutor = _.has(tutorialProfile, 'tutor') ? tutorialProfile.tutor : null;
  const tutorOthers = _.has(tutor, 'others') ? tutor.others : null;
  const firstName = _.has(tutorOthers, 'firstName') ? tutorOthers.firstName : null;
  const lastName = _.has(tutorOthers, 'lastName') ? tutorOthers.lastName : null;

  return (
    <span>
      {firstName}
      {' '}
      {lastName}
    </span>
  );
}
