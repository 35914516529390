import { useQuery } from '@apollo/client';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Button, Table } from 'react-bootstrap';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import SessionContext from './sessions.context';
import { getAttendanceQuery, getPaymentQuery, getSessionEnrolleesQuery } from './gql';
import { DynamicAvatar, LoadingSpinner } from '../../components';
import RatingModal from './ratingModal';
import PaymentClient from '../../PaymentClient';
import moment from 'moment'

const time = new Date();
time.setSeconds(time.getSeconds() + 600);

export default function Index() {
  const {
    sessionUid,
  } = useContext(SessionContext);
  const [enrollees, setEnrollees] = useState([]);

  const { data: enrolleesResult } = useQuery(getSessionEnrolleesQuery, {
    skip: !sessionUid,
    variables: { sessionUid },
  });

  useEffect(() => {
    const result = _.has(enrolleesResult, 'getSessionEnrollees') ? enrolleesResult.getSessionEnrollees : [];

    setEnrollees(result);
  }, [enrolleesResult]);

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>STUDENT</th>
          <th>Attendance</th>
          <th>Payment Confirmed Date</th>
          <th style={{ textAlign: 'center' }}>Student Rating</th>
        </tr>
      </thead>

      <tbody>
        {enrollees.map((list) => <TableRow item={list} />)}
      </tbody>
    </Table>
  );
}

function TableRow(payload) {
  const { item } = payload;
  const { sessionUid, interest } = useContext(SessionContext);
  const [fullName, setFullName] = useState(null);
  const [instanceUid, setInstanceUid] = useState(null);
  const [attendanceStatus, setAttendanceStatus] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const navigate = useNavigate();
  const [paymentUid, setPaymentUid] = useState(null);

  useEffect(() => {
    const tutee = _.has(item, 'tutee') ? item.tutee : null;
    const iPaymentUid = _.has(item, 'paymentUid') ? item.paymentUid : null;
    const rTuteeUid = _.has(tutee, 'uid') ? tutee.uid : null;
    const tuteeUserUid = _.has(tutee, 'userUid') ? tutee.userUid : null;
    const others = _.has(tutee, 'others') ? tutee.others : null;
    const firstName = _.has(others, 'firstName') ? _.startCase(_.toLower(others.firstName)) : null;
    const middleInitial = _.has(others, 'middleInitial') ? _.startCase(_.toLower(others.middleInitial)) : null;
    const lastName = _.has(others, 'lastName') ? _.startCase(_.toLower(others.lastName)) : null;
    const tuteeName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;

    setUserUid(tuteeUserUid);
    setFullName(tuteeName);
    setInstanceUid(rTuteeUid);
    setPaymentUid(iPaymentUid)
  }, [item]);

  const { data: attendanceResult } = useQuery(getAttendanceQuery, {
    skip: !instanceUid || !sessionUid,
    variables: { sessionUid, instanceUid },
  });

  useEffect(() => {
    const attendance = _.has(attendanceResult, 'getAttendance') ? attendanceResult.getAttendance : null;
    const attStatus = _.has(attendance, 'status') ? attendance.status : null;

    setAttendanceStatus(attStatus);
  }, [attendanceResult]);

  const redirectProfile = useCallback(() => {
    navigate(`/profile/${userUid}`);
  }, [userUid]);

  return (
    <tr>
      <td>
        <h2 className="table-avatar">
          <DynamicAvatar
            userUid={userUid}
            roleCode="TUTEE"
            size="small"
            noClick
          />
          <Button variant="link" onClick={redirectProfile}>{fullName}</Button>
        </h2>
      </td>
      <td>{attendanceStatus}</td>
      <td><ConfirmedDate paymentUid={paymentUid} /></td>
      <td align="center">
        <RatingModal
          userUid={userUid}
          sessionUid={sessionUid}
          tuteeUid={instanceUid}
          interest={interest}
        />
      </td>
    </tr>
  );
}

function ConfirmedDate({ paymentUid }) {
  const [date, setDate] = useState(null)

  const { loading, data } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid }
  })

  useEffect(() => {
    const payment = _.has(data, 'getPayment') ? data.getPayment : null
    const confirmedDate = _.has(payment, 'confirmedDate') ? payment.confirmedDate : null
    const parsedDate = confirmedDate ? moment(confirmedDate).format('lll') : null

    setDate(parsedDate)
  }, [data])

  return (
    <>
      {loading ? <LoadingSpinner /> : date}
    </>
  )
}
