import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DashboardTemplate } from '../../../template/components';
import RatingTable from './ratingTable';
import OverallRating from './overallRating';

export default function Index() {
  return (
    <DashboardTemplate>
      <Row>
        <Col lg={12}>
          <OverallRating />
        </Col>
        <Col lg={12}>
          <RatingTable />
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
