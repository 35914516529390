import React, {
  createContext,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserQuery, saveFileMutation, saveProfileMutation } from './gql';
import RegistryClient from '../../../RegistryClient';

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-image`;
const UploadContext = createContext();

export default function Index() {
  const location = useLocation();
  const state = _.has(location, 'state') ? location.state : null;
  const userUid = _.has(state, 'userUid') ? state.userUid : null;
  const [isUnder18, setIsUnder18] = useState(false);
  const [others, setOthers] = useState(null);

  const { data } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(data, 'getUser') ? data.getUser : null;
    const userProfile = _.has(user, 'userProfile') ? user.userProfile : null;
    const age = _.has(userProfile, 'age') ? userProfile.age : null;
    const iBirthDate = _.has(userProfile, 'birthDate') ? userProfile.birthDate : null;
    const iOthers = _.has(userProfile, 'others') ? userProfile.others : null;

    if (age && age < 18) {
      setIsUnder18(true);
    } else if (age && age >= 18) {
      setIsUnder18(false);
    } else {
      const iAge = moment().diff(moment(iBirthDate, 'DD MMM YYYY'), 'years');
      if (iAge < 18) { setIsUnder18(true); } else { setIsUnder18(false); }
    }

    setOthers(iOthers);
  }, [data]);

  const contextPayload = useMemo(() => ({ userUid, others }), [userUid, others]);

  return (
    <UploadContext.Provider value={contextPayload}>
      <div className="bg-pattern-style bg-pattern-style-register">
        <div className="content">

          <div className="account-content">
            <div className="account-box">
              <div className="login-right">
                <div className="login-header">
                  <h3>
                    <span>STUDENT</span>
                    {' '}
                    {
                      isUnder18 ? 'Upload School ID' : 'Upload Government ID'
                    }
                  </h3>
                </div>
                {
                  isUnder18 ? <UploadStudentId /> : <UploadStudentGovId />
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </UploadContext.Provider>
  );
}

const upload = (file, fileName, userUid) => new Promise((resolve, reject) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('userUid', userUid);
  formData.append('fileName', fileName);

  axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

function UploadStudentId() {
  const navigate = useNavigate();
  const { userUid, others } = useContext(UploadContext);
  const formPayload = useForm();
  const {
    formState, control, setValue, handleSubmit, setError
  } = formPayload;
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  const [mutateFileSave] = useMutation(saveFileMutation, {
    client: RegistryClient,
  });

  const [mutateProfileSave] = useMutation(saveProfileMutation, {
    client: RegistryClient,
  });

  const submitForm = useCallback(async (data) => {
    async function doSubmit() {
      try {
        setLoading(true);
        const { schoolId, guardianId, ...etc } = data;

        if (schoolId) {
          let { type } = schoolId;
          let splitType = type && type.split('/');
          let ext = splitType.length && _.last(splitType);
          let fileName = `school-id.${ext}`;

          await upload(schoolId, fileName, userUid).then((result) => {
            const { data: uploaded } = result;

            return mutateFileSave({
              variables: {
                userUid,
                fileCategory: 'STUDENT_ID',
                storage: { ...uploaded },
              },
            }).then(async () => {
              if (guardianId) {
                let { type } = guardianId;
                let splitType = type && type.split('/');
                let ext = splitType.length && _.last(splitType);
                let fileName = `guardian-id.${ext}`;

                return upload(guardianId, fileName, userUid).then((result) => {
                  const { data: uploaded } = result;

                  return mutateFileSave({
                    variables: {
                      userUid,
                      fileCategory: 'GUARDIAN_ID',
                      storage: { ...uploaded },
                    },
                  })
                }).catch(err => {
                  const res = _.has(err, 'response') ? err.response : null
                  const message = _.has(res, 'data') ? res.data : err.toString()

                  setError('guardianId', { message })

                  throw new Error(err)
                })
              }
            });
          }).catch(err => {
            const res = _.has(err, 'response') ? err.response : null
            const message = _.has(res, 'data') ? res.data : err.toString()

            setError('schoolId', { message })

            throw new Error(err)
          })
        }

        await mutateProfileSave({
          variables: {
            userUid,
            others: { ...others, guardian: { ...etc } },
            updatedBy: userUid,
          },
        });

        setLoading(false);
        navigate('/login', { replace: true });
      } catch (err) {
        setLoading(false);
      }
    }

    doSubmit()
  }, [userUid]);

  const validateImage = (value) => {
    const { type } = value
    const split = type.split('/')
    const imageType = split[0]

    if (imageType !== 'image') {
      return 'Please upload an image file!'
    }

    return true
  }

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Row>
        <Form.Group as={Col} sm={12} controlId="upload.id" className="mb-3">
          <Form.Label>Student School ID</Form.Label>
          <Controller
            name="schoolId"
            control={control}
            rules={{
              required: 'Field is required.',
              validate: validateImage
            }}
            render={({ field }) => (
              <Form.Control
                isInvalid={!!_.has(errors, 'schoolId')}
                type="file"
                onChange={(e) => {
                  const fileList = e.target.files;

                  setValue('schoolId', fileList[0]);
                }}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errors, 'schoolId') ? errors.schoolId.message : 'Invalid File.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <hr />
      <h4>Guardian Details</h4>

      <Row>
        <Col lg={5}>
          <Form.Group className="form-group" controlId="guardian.firstName">
            <Form.Label>First Name</Form.Label>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: 'First name is required.' }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'firstName')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'firstName') ? errors.firstName.message : 'Invalid first name.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="form-group" controlId="guardian.middleInitial">
            <Form.Label>MI</Form.Label>
            <Controller
              name="middleInitial"
              control={control}
              rules={{ maxLength: { value: 3, message: 'Max length is 3.' } }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'middleInitial')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'middleInitial') ? errors.middleInitial.message : 'Invalid middle initial.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={5}>
          <Form.Group className="form-group" controlId="guardian.lastName">
            <Form.Label className="form-control-label">Last Name</Form.Label>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: 'Last name is required.' }}
              autoFocus
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'lastName')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'lastName') ? errors.lastName.message : 'Invalid last name.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Form.Group as={Col} sm={12} controlId="guardianId.id" className="mb-3">
          <Form.Label>Guardian Government ID</Form.Label>
          <Controller
            name="guardianId"
            control={control}
            rules={{
              required: 'Field is required.',
              validate: validateImage
            }}
            render={({ field }) => (
              <Form.Control
                isInvalid={!!_.has(errors, 'guardianId')}
                type="file"
                // {...field}
                onChange={(e) => {
                  const fileList = e.target.files;

                  setValue('guardianId', fileList[0]);
                }}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errors, 'guardianId') ? errors.guardianId.message : 'Invalid File.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </Button>
    </Form>
  );
}

function UploadStudentGovId() {
  const navigate = useNavigate();
  const { userUid } = useContext(UploadContext);
  const formPayload = useForm();
  const {
    formState, control, setValue, handleSubmit, setError
  } = formPayload;
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  const [mutateFileSave] = useMutation(saveFileMutation, {
    client: RegistryClient,
  });

  const submitForm = useCallback(async (data) => {
    async function doSubmit() {
      try {
        setLoading(true);
        const { studentGovId } = data;
        const { type } = studentGovId;
        const splitType = type && type.split('/');
        const ext = splitType.length && _.last(splitType);
        const fileName = `student-gov-id.${ext}`;

        await upload(studentGovId, fileName, userUid).then((result) => {
          const { data: uploaded } = result;

          mutateFileSave({
            variables: {
              userUid,
              fileCategory: 'STUDENT_GOV_ID',
              storage: { ...uploaded },
            },
          })
        }).catch(err => {
          const res = _.has(err, 'response') ? err.response : null
          const message = _.has(res, 'data') ? res.data : err.toString()

          setError('studentGovId', { message })

          throw new Error(err)
        })

        setLoading(false);
        navigate('/login');
      } catch (err) {
        setLoading(false);
      }
    }

    doSubmit()
  }, [userUid]);

  const validateImage = (value) => {
    const { type } = value
    const split = type.split('/')
    const imageType = split[0]

    if (imageType !== 'image') {
      return 'Please upload an image file!'
    }

    return true
  }

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Row>
        <Form.Group as={Col} sm={12} controlId="student.id" className="mb-3">
          <Form.Label>Student Government ID</Form.Label>
          <Controller
            name="studentGovId"
            control={control}
            rules={{
              required: 'Field is required.',
              validate: validateImage
            }}
            render={({ field }) => (
              <Form.Control
                isInvalid={!!_.has(errors, 'studentGovId')}
                type="file"
                onChange={(e) => {
                  const fileList = e.target.files;

                  setValue('studentGovId', fileList[0]);
                }}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errors, 'studentGovId') ? errors.studentGovId.message : 'Invalid File.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </Button>
    </Form>
  );
}
