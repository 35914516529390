/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */
export const getTutorQuery = gql`
  query GetTutor($uid: ID!) {
    getTutor(uid: $uid) {
      id
      uid
      userUid
      status
      createdAt
      priceRange
      tutorials {
        id
        uid
        interest
        sessionType
        price
        status
        timeslots {
          id
          uid
          tutorialProfileUid
          day
          start
          end
          status
          sessions {
            id
            uid
            startDate
            endDate
            enrollees {
              id
              uid
              tuteeUid
              attendanceStatus
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const isEnrolledQuery = gql`
  query IsStudentEnrolled($instanceUid: String! $timeslotUid: String! $startDate: String! $endDate: String!) {
    isStudentEnrolled(instanceUid: $instanceUid timeslotUid: $timeslotUid startDate: $startDate endDate: $endDate)
  }
`;

export const hasSessionSlotQuery = gql`
  query HasSessionSlot($startDate: String!, $endDate: String!, $timeslotUid: String!) {
    hasSessionSlot(startDate: $startDate, endDate: $endDate, timeslotUid: $timeslotUid)
  }
`;

export const getElasticSearchQuery = gql`
  query GetElasticSearch($userUid: String!) {
    getElasticSearch(userUid: $userUid) {
      id
      uid
      referenceUid
      type
      document
      __typename
    }
  }
`
/**
 * Queries End
 */

/**
 * Mutations
 */
export const saveElasticSearchMutation = gql`
  mutation SaveElasticSearch(
    $referenceUid: ID
    $type: String!
    $document: JSON
  ) {
    saveElasticSearch(
      referenceUid: $referenceUid
      type: $type
      document: $document
    ) {
      id
      uid
      referenceUid
      type
      document
      __typename
    }
  }
`;
/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
