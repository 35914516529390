import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { GET_PAGINATED_RECRUITMENT_LOGS } from './gql';
import { CustomTable } from '../../../components';
import RegistryClient from '../../../RegistryClient';
import { recruitmentStatusLabel } from '../../../constants';

export default function recruitmentLogsModal({ show, onHide, recruitmentUid }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const { loading, data } = useQuery(GET_PAGINATED_RECRUITMENT_LOGS, {
    client: RegistryClient,
    variables: {
      recruitmentUid,
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
    },
  });

  const result = _.has(data, 'getPaginatedRecruitmentLogs') ? data.getPaginatedRecruitmentLogs : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Status',
      dataKey: 'status',
      render: (text) => (text ? recruitmentStatusLabel[text] : text),
    },
    {
      title: 'Remarks',
      dataKey: 'remarks',
    },
    {
      title: 'Date',
      dataKey: 'createdAt',
    },
  ];

  return (
    <Modal show={show} onHide={() => onHide()}>
      <Modal.Header>
        <Modal.Title>
          Recruitment Logs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <RecruitmentLogsTable columns={columns} dataSource={rows} /> */}
        <CustomTable
          loading={loading}
          columns={columns}
          page={page}
          pageSize={pageSize}
          totals={rowCount}
          dataValues={dataResult}
          onPageChange={(nPage) => setPage(nPage)}
        />
      </Modal.Body>
    </Modal>
  );
}
