import { gql } from '@apollo/client';

export const GET_USERS_NOTIFICATIONS = gql`
    query GetUserNotifications($userUid: String!, $offset: Int, $limit: Int) {
        getUserNotifications(userUid: $userUid, offset: $offset, limit: $limit) {
            count
            rows {
                id
                uid
                title
                message
                userUid
                seenAt
                createdAt
                createdBy
                updatedAt
                updatedBy
                data
            }
        }
    }
`;

export const GET_UNREAD_NOTIFICATION_COUNT = gql`
    query getUnreadNotificationCount($userUid: String!) {
        getUnreadNotificationCount(userUid: $userUid)
    }
`;

export const SAVE_REGISTRATION_TOKEN = gql`
    mutation SaveRegistrationToken($registrationToken: String!, $userUid: String!) {
        saveRegistrationToken(registrationToken: $registrationToken, userUid: $userUid) {
            id
            uid
            userUid
            registrationToken
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
`;

export const SEND_NOTIFICATION = gql`
    mutation SendNotification($users: [String]! $data: JSON $title: String! $message: String! $createdBy: String!) {
        sendNotification(users: $users data: $data title: $title message: $message createdBy: $createdBy) {
            id
            uid
            title
            message
            userUid
            seenAt
            createdAt
            createdBy
            updatedAt
            updatedBy
            data
        }
    }
`;

export const READ_NOTIFICATION = gql`
    mutation ReadNotification($userUid: String!, $notificationUid: String!) {
        readNotification(userUid: $userUid, notificationUid: $notificationUid) {
        id
        uid
        title
        message
        data
        userUid
        seenAt
        createdAt
        createdBy
        updatedAt
        updatedBy
        }
    }
`;

export const DELETE_NOTIFICATION = gql`
mutation DeleteNotifications($userUid: String!, $notificationUids: [String]!) {
    deleteNotifications(userUid: $userUid, notificationUids: $notificationUids)
  }
`;

export const MARK_ALL_AS_READ = gql`
mutation MarkAllAsReadNotification($userUid: String!) {
    markAllAsReadNotification(userUid: $userUid) {
        id
        uid
        title
        message
        data
        userUid
        seenAt
        createdAt
        createdBy
        updatedAt
        updatedBy
    }
}
`

export const DELETE_ALL_NOTIFICATION = gql`
mutation DeleteAllNotifications($userUid: String!) {
    deleteAllNotifications(userUid: $userUid)
}
`