/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button, Modal, Card, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import {
  Controller, FormProvider, useForm, useFormContext,
} from 'react-hook-form';
import _ from 'lodash';
import { useAlert } from 'react-alert';
import { SessionContext } from './sessions.context';
import { addSessionFileMutation, endSessionMutation } from './gql';
import { LoginContext } from '../../login';
import { UploaderContext } from '../../../modules/uploader';

// function redirectBlank(url) {
//   const a = document.createElement('a');
//   a.target = '_blank';
//   a.href = url;
//   a.click();
// }

function isImage(props) {
  if (!props) { return true; }

  const { type } = props;
  const split = type ? type.split('/') : [];
  const imageFile = split[0];

  if (imageFile && imageFile !== 'image') { return 'Please upload an image file!'; }

  return null;
}

function isPdf(props) {
  if (!props) { return true; }

  const { type } = props;

  if (type && type !== 'application/pdf') { return 'Invalid pdf file!'; }

  return true;
}

export default function Index() {
  const [view, setView] = useState(false);
  const { instanceUid } = useContext(LoginContext);
  const { uploadFile } = useContext(UploaderContext);
  const { userUid } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const {
    sessionUid,
    interest,
    sessionType,
  } = useContext(SessionContext);

  const formMethods = useForm();
  const {
    handleSubmit,
  } = formMethods;

  const handleShow = useCallback(() => {
    setView(true);
  }, [sessionUid]);

  const handleClose = useCallback(() => {
    setView(false);
  }, [sessionUid]);

  const [mutateEnd] = useMutation(endSessionMutation, {
    onCompleted: () => {
      handleClose();
    },
  });

  const [mutateAddFile] = useMutation(addSessionFileMutation);

  const finalSubmit = useCallback((formValues) => {
    async function doSubmit() {
      try {
        setLoading(true);
        const {
          sessionProof, privateNote, privateNoteText, sharedNote, sharedNoteText,
        } = formValues;

        await uploadFile({ file: sessionProof })
          .then(({ data: storage }) => {
            const fileCategory = 'SESSION_PROOF';

            const variables = {
              sessionUid,
              fileCategory,
              storage,
              createdBy: userUid,
            };

            return mutateAddFile({ variables });
          });

        if (privateNote) {
          await uploadFile({ file: privateNote })
            .then(({ data: storage }) => {
              const fileCategory = 'PRIVATE';

              const variables = {
                sessionUid,
                fileCategory,
                storage,
                createdBy: userUid,
              };

              return mutateAddFile({ variables });
            });
        }

        if (sharedNote) {
          await uploadFile({ file: sharedNote })
            .then(({ data: storage }) => {
              const fileCategory = 'SHARED';

              const variables = {
                sessionUid,
                fileCategory,
                storage,
                createdBy: userUid,
              };

              return mutateAddFile({ variables });
            });
        }

        const variables = {
          sessionUid,
          instanceUid,
          privateNoteText,
          sharedNoteText,
        };

        mutateEnd({ variables });
        setLoading(false);
      } catch (err) {
        alert.error(err.toString(), { timeout: 3000 });
        setLoading(false);
      }
    }

    doSubmit();
  }, [sessionUid, instanceUid, userUid, mutateAddFile, mutateEnd]);

  const submitForm = useCallback(() => {
    handleSubmit(finalSubmit)();
  }, [handleSubmit, finalSubmit]);

  return (
    <FormProvider {...formMethods}>
      <Button
        className="text-danger"
        variant="link"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={solid('play')} />
        {' '}
        End
      </Button>

      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Complete Current Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>{interest}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{sessionType}</Card.Subtitle>

              <Card.Text>
                Are you really sure to end this session?
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Upload Files</Card.Title>
              <UploadFiles />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitForm}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'End Session'}
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}

function UploadFiles() {
  const {
    formState,
    control,
    setValue,
  } = useFormContext();

  const { errors } = formState;

  return (
    <Form className="mb-3" noValidate>
      <Form.Group className="mb-3" sm={12} controlId="session.sessionProof">
        <Form.Label>
          Class Attachment
          {' '}
          <small className="text-muted">proof of class(image e.g. jpg/jpeg/png)</small>
        </Form.Label>
        <Controller
          name="sessionProof"
          control={control}
          rules={{
            required: 'Proof of session is required!',
            validate: { isImage },
          }}
          render={() => (
            <Form.Control
              isInvalid={!!_.has(errors, 'sessionProof')}
              type="file"
              onChange={(e) => {
                const fileList = e.target.files;

                setValue('sessionProof', fileList[0]);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'sessionProof') ? errors.sessionProof.message : 'Invalid file.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-1" sm={12} controlId="session.privateNoteText">
        <Form.Label>
          Private Notes Text
        </Form.Label>
        <Controller
          name="privateNoteText"
          control={control}
          render={({ field }) => (
            <Form.Control
              as="textarea"
              isInvalid={!!_.has(errors, 'privateNoteText')}
              {...field}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'privateNoteText') ? errors.privateNoteText.message : 'Invalid input.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-1" sm={12} controlId="session.privateNote">
        <Form.Label>
          Private Notes
          {' '}
          <small className="text-muted">(PDF file)</small>
        </Form.Label>
        <Controller
          name="privateNote"
          control={control}
          rules={{
            validate: {
              isPdf,
            },
          }}
          render={() => (
            <Form.Control
              isInvalid={!!_.has(errors, 'privateNote')}
              type="file"
              onChange={(e) => {
                const fileList = e.target.files;

                setValue('privateNote', fileList[0]);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'privateNote') ? errors.privateNote.message : 'Invalid file.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" sm={12} controlId="session.sharedNoteText">
        <Form.Label>Shared Notes Text</Form.Label>
        <Controller
          name="sharedNoteText"
          control={control}
          render={({ field }) => (
            <Form.Control
              as="textarea"
              isInvalid={!!_.has(errors, 'sharedNoteText')}
              {...field}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'sharedNoteText') ? errors.sharedNoteText.message : 'Invalid input.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" sm={12} controlId="session.sharedNote">
        <Form.Label>
          Shared Notes
          {' '}
          <small className="text-muted">(PDF file)</small>
        </Form.Label>
        <Controller
          name="sharedNote"
          control={control}
          rules={{
            validate: {
              isPdf,
            },
          }}
          render={() => (
            <Form.Control
              isInvalid={!!_.has(errors, 'sharedNote')}
              type="file"
              onChange={(e) => {
                const fileList = e.target.files;

                setValue('sharedNote', fileList[0]);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'sharedNote') ? errors.sharedNote.message : 'Invalid file.'}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
}