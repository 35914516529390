import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
// import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { DatePicker } from '../../../components';
import { GET_TOTAL_CLASS_HOURS } from './gql';
import { LoadingSpinner } from '../../../components'


export default function TotalClassHours({ instanceUid }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const { loading, data } = useQuery(GET_TOTAL_CLASS_HOURS, {
    variables: {
      tuteeUid: instanceUid,
      dateRange: dateRange ? {
        startDate,
        endDate,
      } : null,
    },
  });

  const result = _.has(data, 'getTotalClassHours') ? data.getTotalClassHours : 0;

  const yellow = 'rgba(249, 206, 35, 0.1)';
  return (
    <div className="card" style={{ backgroundColor: yellow, height: '100%' }}>
      <div className="card-header" style={{ backgroundColor: yellow }}>
        <div className="d-flex justify-content-between align-items-center gap-2">
          <h5 className="card-title">Total Class Hours</h5>
          <div>
            <DatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable
            />

          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="dash-board-list yellow ">
          <div
            className="dash-widget"
            style={{
              height: '100%',
              background: 'none',
              boxShadow: 'none',
              marginBottom: 0,
            }}
          >
            <div className="circle-bar">
              <div className="icon-col">
                <i className="fas fa-clock" />
              </div>
            </div>
            <div className="dash-widget-info">
              <h3>
                {!loading ? result : <LoadingSpinner text='' />}
              </h3>
              <h6>Total</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
