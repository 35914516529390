/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Queries
 */

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      email
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      qualification {
        id
        uid
        userUid
        about
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getUserQualificationQuery = gql`
  query getUserQualification($userUid: String!) {
    getUserQualification(userUid: $userUid) {
      id
      uid
      userUid
      about
      education
      certification
      introVideo
      govId
      nbi

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getUserWalletQuery = gql`
  query GetUserWallet($userUid: ID!) {
    getUserWallet(userUid: $userUid) {
      id
      uid
      userUid
      balance
      __typename
    }
  }
`;
/**
 * Queries End
 */

/**
 * Mutations
 */
export const sendForReviewMutation = gql`
  mutation SendForReview(
      $userUid: String!
    ) {
      sendForReview(
        userUid: $userUid
    ) {
      id
      uid
      status
      __typename
    }
  }
`;

export const saveFileMutation = gql`
  mutation SaveFile(
    $userUid: String!
    $fileCategory: String!
    $storage: JSON!
  ) {
    saveFile(
      userUid: $userUid
      fileCategory: $fileCategory
      storage: $storage
    ) {
      id
      uid
      userUid
      fileCategory
      storage
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const changePasswordMutation = gql`
  mutation Mutation(
    $userUid: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      userUid: $userUid
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
      uid
      email
      status
    }
  }
`;

export const deleteRegisteredUserMutation = gql`
  mutation Mutation(
    $userUid: String! 
  ) {
    deleteRegisteredUser(
      userUid: $userUid
    )
  }
`;

export const deleteTutorMutation = gql`
  mutation Mutation(
    $uid: ID!
    $deletedBy: String! 
  ) {
    deleteTutor(
      uid: $uid
      deletedBy: $deletedBy
    )
  }
`;
/**
 * Mutations End
 */

/**
 * Fragments
 * */

/**
 * Fragments End
 * */
