import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import _ from 'lodash';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, Cell,
} from 'recharts';
import moment from 'moment';
import { GET_TOTAL_SPEND } from './gql';
import PaymentClient from '../../../PaymentClient';
import { DatePicker, LoadingSpinner } from '../../../components';

export default function TotalSpendBarChart({ userUid }) {
  const [startDate, setStartDate] = useState(null);
  const { data, loading } = useQuery(GET_TOTAL_SPEND, {
    client: PaymentClient,
    variables: {
      userUid,
      year: startDate ? moment(startDate).format('YYYY') : moment().format('YYYY'),
    },
  });

  const result = _.has(data, 'getTotalSpend') ? data.getTotalSpend : [];

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center gap-2">

          <div className="card-title">
            <h5>Yearly Spendings</h5>
          </div>
          <div>
            <DatePicker
              placeholderText="Year"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showYearPicker
              dateFormat="yyyy"
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div style={{ width: '100%', height: 400 }} className="d-flex m-auto">
          {!loading ? (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart width={150} height={40} data={result}>
                <Tooltip />
                <XAxis dataKey="month" />
                <Bar barSize={20} dataKey="totalSpend" name="Total Spend" fill="#82ca9d">

                  {result.map((entry, index) => {
                    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                    // eslint-disable-next-line react/no-array-index-key
                    return <Cell key={`cell-${index}`} fill={`#${randomColor}`} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )
            : <div className="m-auto"><LoadingSpinner /></div>}
        </div>
      </div>
    </div>

  );
}
