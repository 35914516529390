/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import LoginContext from '../../login/login.context';
import { SAVE_RECRUITMENT_SETTINGS } from './gql';
import client from '../../../RegistryClient';

export default function SettingsModal({ show, setShow, initialValues }) {
  const { userUid } = useContext(LoginContext);
  const coolOffPeriod = initialValues ? initialValues.find((item) => item.name === 'coolOffPeriod') : null;
  const coolOffPeriodType = initialValues ? initialValues.find((item) => item.name === 'coolOffPeriodType') : null;

  const [saveSettings, { loading }] = useMutation(SAVE_RECRUITMENT_SETTINGS, {
    client,
  });

  const {
    handleSubmit, formState, control,
  } = useForm({
    defaultValues: {
      coolOffPeriod: _.has(coolOffPeriod, 'value') ? coolOffPeriod.value : '',
      coolOffPeriodType: _.has(coolOffPeriodType, 'value') ? coolOffPeriodType.value : 'DAYS',
    },
  });

  const { errors } = formState;

  const onSubmit = (data, e) => {
    e.preventDefault();
    saveSettings({
      variables: {
        ...data,
        userUid,
      },
    }).then(() => {
      setShow(false);
    });
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>Recruitment Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
            <Form.Label>Cool off Period Type</Form.Label>
            <Controller
              name="coolOffPeriodType"
              control={control}
              render={({ field }) => (
                <Form.Select
                  rows={3}
                  {...field}
                >
                  <option value="DAYS">DAYS</option>
                  <option value="MONTHS">MONTHS</option>

                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'coolOffPeriodType') ? errors.coolOffPeriodType.message : 'Invalid Cool Off Period Type.'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
            <Form.Label>Cool off Period</Form.Label>
            <Controller
              name="coolOffPeriod"
              control={control}
              render={({ field }) => (
                <Form.Control
                  rows={3}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'coolOffPeriod') ? errors.coolOffPeriod.message : 'Invalid Cool Off Period.'}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)} disabled={loading}>Cancel</Button>
          {' '}
          <Button type="submit" variant="primary" disabled={loading}>{!loading ? 'Save' : 'Saving'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
