import React, { useState, useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import CategoryModalForm from './categoryModalForm';
import { CustomTable, ModalConfirm } from '../../../../components';
import TicketingClient from '../../../../TicketingClient';
import LoginContext from '../../../login/login.context';
import {
  GET_PAGINATED_TICKET_CATEGORY,
  UPDATE_TICKET_CATEGORY_STATUS,
  DELETE_TICKET_CATEGORY,
} from '../../../issueAndResolution/gql';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function ticketCategory() {
  const navigate = useNavigate();
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { doInsertAuditTrail } = useCreateAuditTrail();

  const onSearch = (value) => {
    setPage(1);
    setSearchText(value);
  };

  const [updateCategoryStatus] = useMutation(UPDATE_TICKET_CATEGORY_STATUS, {
    client: TicketingClient,
    refetchQueries: ['GetPaginatedTicketCategory'],
  });

  const [deleteCategory] = useMutation(DELETE_TICKET_CATEGORY, {
    client: TicketingClient,
    refetchQueries: ['GetPaginatedTicketCategory'],
  });

  const {
    loading, data, refetch,
  } = useQuery(GET_PAGINATED_TICKET_CATEGORY, {
    client: TicketingClient,
    fetchPolicy: 'network-only',
    variables: {
      appCode: APPCODE,
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
    },
  });

  const result = _.has(data, 'getPaginatedTicketCategory') ? data.getPaginatedTicketCategory : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const handleUpdateStatus = ({ uid, name, status }) => {
    updateCategoryStatus({
      variables: {
        categoryUid: uid,
        userUid,
        status,
      },
    }).then(() => {
      if (status === 'published') {
        doInsertAuditTrail({
          action: 'PUBLISH',
          module: 'Issues and Resolution',
          changes: `Published Issues category  ${name}`,
        });
      } else {
        doInsertAuditTrail({
          action: 'UNPUBLISH',
          module: 'Issues and Resolution',
          changes: `Unpublished Issues category  ${name}`,
        });
      }

      refetch();
    });
  };

  const handleDelete = ({ uid, name }) => {
    deleteCategory({
      variables: {
        categoryUid: uid,
        userUid,
      },
    }).then(() => {
      doInsertAuditTrail({
        action: 'DELETE',
        module: 'Issues and Resolution',
        changes: `Deleted Issues category  ${name}`,
      });
      refetch();
    });
  };

  const handleEdit = (rowData) => {
    setVisible(true);
    setSelectedRow(rowData);
    setIsEdit(true);
  };

  const closeForm = () => {
    setVisible(false);
    setIsEdit(false);
    setSelectedRow(null);
  };

  const columns = [
    { title: 'Name', dataKey: 'name' },
    {
      title: 'Description',
      dataKey: 'description',
      // render: (text) => (
      //   <div style={{ width: '30%' }}>
      //     <p>{text}</p>
      //   </div>
      // ),
    },
    { title: 'Status', dataKey: 'status' },
    {
      title: 'Action',
      dataKey: 'uid',
      render: (text) => {
        const rowData = _.find(dataResult, (item) => item.uid === text);
        const status = _.has(rowData, 'status') ? rowData.status : null;
        const name = _.has(rowData, 'name') ? rowData.name : null;

        const isDefault = _.has(rowData, 'isDefault') ? rowData.isDefault : null;
        return (
          <div width="20%" className="d-flex gap-2">
            <ModalConfirm
              onOk={() => handleUpdateStatus({ uid: text, name, status: status === 'draft' ? 'published' : 'draft' })}
              message={`Are you sure you want to ${status === 'draft' ? 'publish' : 'unpublish'} ${name}?`}
            >
              {({ onClick }) => {
                if (status === 'draft') {
                  return <Button onClick={() => onClick()}>Publish</Button>;
                }
                return (
                  <Button
                    variant="secondary"
                    onClick={() => onClick()}
                  >
                    Unpublish
                  </Button>
                );
              }}

            </ModalConfirm>
            {!isDefault && status !== 'published' && <Button variant="success" onClick={() => handleEdit(rowData)}>Edit</Button>}
            {!isDefault && (
              <ModalConfirm
                onOk={() => handleDelete({ uid: text, name })}
                message={`Are you sure you want to delete ${name}?`}
              >
                {({ onClick }) => <Button variant="danger" onClick={() => onClick()}>Delete</Button>}
              </ModalConfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Row className="gap-4">
      <Col lg={12}>
        <Button variant="link" onClick={() => navigate(-1)}>
          <i className="fa fa-arrow-circle-left" />
          {' '}
          Back to Ticket List
        </Button>
      </Col>

      <Col lg={12}>
        <Row className="justify-content-end mb-4 gap-2">
          <Col lg={3} className="d-flex">
            <div className="input-group">
              <input
                type="text"
                onChange={(e) => onSearch(e.target.value)}
                placeholder="Search here"
                className="form-control"
              />
              <Button
                type="button"
                className="btn btn-primary"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-search" />

              </Button>
            </div>
          </Col>
          <Col lg={3} className="d-grid">
            <Button
              type="button"
              className="btn btn-primary"
              onClick={() => setVisible(true)}
            >
              Create Category
            </Button>

          </Col>
        </Row>
      </Col>
      <Col lg={12}>
        <h4 className="page-title mb-4">Ticket Categories</h4>
      </Col>
      <Col lg={12}>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">

              <CustomTable
                loading={loading}
                columns={columns}
                page={page}
                pageSize={pageSize}
                totals={rowCount}
                dataValues={dataResult}
                onPageChange={(nPage) => setPage(nPage)}
              />
            </div>
          </div>
        </div>
      </Col>
      {visible && (
        <CategoryModalForm
          isEdit={isEdit}
          defaultValues={selectedRow}
          show={visible}
          onHide={() => closeForm()}
        />
      )}
    </Row>
  );
}
