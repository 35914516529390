import React, { useState, useContext } from 'react';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import SelectTicketCategory from '../../../components/selectTicketCategory';
import { CREATE_TICKET } from '../gql';
import TicketingClient from '../../../TicketingClient';
import RegistryClient from '../../../RegistryClient';
import LoginContext from '../../login/login.context';
import { getUserQuery } from '../../app/gql';
import useMarketingNotification from '../../notification/useMarketingNotification';
import useSupportNotification from '../../notification/useSupportNotification';
import { AlertError } from '../../../components';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-files`;
const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

const upload = (files, userUid) => new Promise((resolve, reject) => {
  const formData = new FormData();
  if (files.length > 0) {
    files.map((file) => {
      return formData.append('files', file);
    });
  }
  formData.append('userUid', userUid)

  return axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

export default function ticketForm() {
  const { userUid, roleCode } = useContext(LoginContext);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [fileAttachment, setFileAttachment] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { sendNotification: sendMarketingNotification } = useMarketingNotification({ userUid });
  const { sendNotification: sendSupportNotification } = useSupportNotification({ userUid });
  const { doInsertAuditTrail } = useCreateAuditTrail();

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
  const profile = _.has(user, 'userProfile') ? user.userProfile : null;
  const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null;
  const iLastName = _.has(profile, 'lastName') ? profile.lastName : null;
  const userFullname = `${iFirstName} ${iLastName}`;

  const {
    handleSubmit, formState, control, reset,
  } = useForm();

  const { errors } = formState;

  const [saveTicket] = useMutation(
    CREATE_TICKET,
    {
      client: TicketingClient,
      refetchQueries: ['GetPaginatedTickets'],
    },
  );

  const onSubmit = (data) => {
    setSaving(true);
    upload(fileAttachment, userUid).then((result) => {
      const uploadResult = _.has(result, 'data') ? result.data : null;
      const attachments = uploadResult
        ? Object.keys(uploadResult).map((key) => uploadResult[key]) : null;

      saveTicket({
        variables: {
          ...data,
          appCode: APPCODE,
          userUid,
          userFullname,
          attachments,
          otherDetails: { payslipControlNumber: data.payslipControlNumber, userRole: roleCode },
        },
      }).then((ticketResult) => {
        const ticketData = _.has(ticketResult, 'data') ? ticketResult.data : null;
        const ticket = _.has(ticketData, 'createTicket') ? ticketData.createTicket : null;
        const ticketUid = _.has(ticket, 'uid') ? ticket.uid : null;
        const ticketNumber = _.has(ticket, 'ticketNumber') ? ticket.ticketNumber : null;

        // insert audit trail
        doInsertAuditTrail({
          changes: `Filed an issue with ticket #${ticketNumber}`,
          action: 'CREATE',
          module: 'Issues and Resolution',
        });

        // send marketing a notif
        sendMarketingNotification({
          title: 'New Issues reported',
          message: `${userFullname} reported an issue.`,
          data: {
            urlPath: '/marketing-admin/issue-and-resolution/view',
            urlState: { ticketUid },
          },
        });

        sendSupportNotification({
          title: 'New Issues reported',
          message: `${userFullname} reported an issue.`,
          data: {
            urlPath: '/support/issue-and-resolution/view',
            urlState: { ticketUid },
          },
        });

        setFileAttachment([]);
        setSaving(false);
        reset();
        navigate(-1);
      }).catch((err) => {
        setError(err.toString());
        setFileAttachment([]);
        setSaving(false);
      });
    })
      .catch((err) => {
        setError(err.toString());
        setFileAttachment([]);
        setSaving(false);
      });
  };

  const handleUpload = (e) => {
    const fileList = e.target.files;
    setFileAttachment([...fileList, ...fileAttachment]);
  };

  return (
    <Row>
      <Col lg={12} className="mb-4">
        <h3>Create Ticket</h3>
      </Col>
      {error && (
        <Col lg={12}>
          <AlertError
            error={error}
            title="Failed to create ticket"
            onClose={() => setError(null)}
          />
        </Col>
      )}
      <Col lg={6}>
        <Form onSubmit={handleSubmit(onSubmit)} className="mb-2">
          <Form.Group className="form-group" controlId="createTicket.controlInput1">
            <Form.Label>Subject</Form.Label>
            <Controller
              name="subject"
              control={control}
              rules={{
                required: 'Subject is required.',
                validate: {
                  whiteSpace: (value) => !!value.trim() || 'Subject cannot be empty.',
                },
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'subject')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'subject') ? errors.subject.message : 'Invalid subject.'}
            </Form.Control.Feedback>

          </Form.Group>
          <Form.Group className="form-group" controlId="createTicket.controlInput2">
            <Form.Label>Details</Form.Label>
            <Controller
              name="details"
              control={control}
              render={({ field }) => (
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...field}
                />
              )}
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="createTicket.controlInput3">
            <Form.Label className="form-control-label">Category</Form.Label>
            <Controller
              name="categoryUid"
              control={control}
              rules={{ required: 'category is required.' }}
              autoFocus
              render={({ field }) => (
                <SelectTicketCategory
                  autoFocus
                  isInvalid={!!_.has(errors, 'categoryUid')}
                  {...field}
                  control={control}
                  formState={formState}
                  onSelect={(category) => {
                    const categoryName = _.has(category, 'name') ? category.name : null;
                    if (categoryName) {
                      const sCategoryName = _.lowerCase(categoryName);
                      setSelectedCategory(sCategoryName);
                    }
                  }}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'categoryUid') ? errors.categoryUid.message : 'Invalid category.'}
            </Form.Control.Feedback>
          </Form.Group>

          {selectedCategory === 'payslip' && (
            <Form.Group className="form-group" controlId="createTicket.payslip">
              <Form.Label className="form-control-label">Payslip Control Number</Form.Label>
              <Controller
                name="payslipControlNumber"
                control={control}
                rules={{ required: 'Payslip Control Number is required.' }}
                autoFocus
                render={({ field }) => (
                  <Form.Control
                    autoFocus
                    isInvalid={!!_.has(errors, 'payslipControlNumber')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'payslipControlNumber') ? errors.payslipControlNumber.message : 'Invalid Payslip Control Number.'}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group className="form-group" controlId="createTicket.controlInput4">
            <Form.Label>Attachments</Form.Label>
            <Controller
              name="files"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  autoFocus
                  type="file"
                  onChange={handleUpload}
                  multiple
                  accept="image/*,application/pdf"
                />
              )}
            />
            <div className="pt-4">
              {fileAttachment && fileAttachment.map((att, k) => (
                <p>
                  {att.name}
                </p>
              ))}
            </div>
          </Form.Group>

          <Button variant="secondary" onClick={() => navigate(-1)} disabled={saving}>Cancel</Button>
          {' '}
          <Button type="submit" variant="primary" disabled={saving}>{!saving ? 'Save' : 'Saving'}</Button>
        </Form>
      </Col>

    </Row>
  );
}
