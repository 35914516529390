import React, { useCallback, useContext, useEffect } from 'react';
import {
  Button,
  Col, Form, Row,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SearchContext from './search.context';
import { SelectInterest, DatePicker, Ratings } from '../../components';
import PriceRange from './priceRange';

export default function Index(payload) {
  const { onSearch } = payload;
  const {
    filters,
  } = useContext(SearchContext);
  const formInstance = useForm({
    defaultValues: {
      priceRange: [100, 1000],
    },
  });
  const {
    register, handleSubmit, setValue, control, reset, watch, getValues,
  } = formInstance;

  useEffect(() => {
    const search = _.has(filters, 'search') ? filters.search : null;
    const location = _.has(filters, 'location') ? filters.location : null;

    setValue('location', location);
    setValue('search', search);
  }, [filters]);

  const watchType = watch('sessionType');

  useEffect(() => {
    if (watchType === 'Select session') {
      setValue('sessionType', undefined);
    }
  }, [watchType]);

  const submitForm = useCallback((data) => {
    const iFilters = {};
    Object.keys(data).map((key) => {
      const value = _.has(data, key) ? data[key] : null;

      if (value) {
        Object.assign(iFilters, { [key]: value });
      }

      return value
    });

    if (onSearch) { onSearch({ filters: iFilters }); }
  });

  const onClickSearch = () => {
    handleSubmit(submitForm)();
  };

  const onClear = useCallback(() => {
    reset();

    setValue('location', undefined);
    setValue('search', undefined);

    const values = getValues();
    onSearch({ filters: { ...values } });
  }, [getValues]);

  return (
    <div className="card search-filter">
      <div className="card-header">
        <h4 className="card-title mb-0">Search Filter</h4>
      </div>
      <div className="card-body">
        <Form noValidate>
          <Row>
            <Col className="mb-3" xs={{ span: 12 }}>
              <Button
                className="btn btn-block"
                variant="link"
                onClick={onClear}
              >
                <FontAwesomeIcon icon={solid('arrows-spin')} />
                {' '}
                Clear
              </Button>
            </Col>
            <Col xs={{ span: 12 }}>
              <div className="btn-search mb-3">
                <Button
                  className="btn btn-block"
                  onClick={onClickSearch}
                >
                  <FontAwesomeIcon icon={solid('search')} />
                  {' '}
                  Search
                </Button>
              </div>
            </Col>
          </Row>

          <hr />

          <div className="filter-widget">
            <Form.Group as={Col} sm={12} controlId="search.search">
              <Form.Label>Search</Form.Label>
              <Form.Control {...register('search')} />
            </Form.Group>
          </div>

          <div className="filter-widget">
            <SelectInterest {...formInstance} label="Interest" rules={false} />
          </div>

          <div className="filter-widget">
            <Form.Group as={Col} sm={12} controlId="search.location">
              <Form.Label>Location</Form.Label>
              <Form.Control {...register('location')} />
            </Form.Group>
          </div>

          <div className="filter-widget">
            <Form.Group as={Col} sm={12} controlId="search.availability">
              <Form.Label>Availability</Form.Label>
              <Controller
                name="availability"
                control={control}
                autoFocus
                render={({ field }) => (
                  <DatePicker {...field} minDate={new Date()} />
                )}
              />
            </Form.Group>
          </div>

          <div className="filter-widget">
            <Form.Group as={Col} sm={12} controlId="search.session">
              <Form.Label>Session Type</Form.Label>
              <Form.Select {...register('sessionType')} placeholder="Select Session">
                <option value={undefined}>Select session</option>
                <option value="SINGLE">One on One</option>
                <option value="GROUP">Group</option>
              </Form.Select>
            </Form.Group>
          </div>

          <div className="filter-widget">
            <Form.Group as={Col} sm={12} controlId="search.prices">
              <Form.Label className="mb-5">Price</Form.Label>
              <br />
              <PriceRange
                {...register('priceRange')}
                defaultValues={getValues('priceRange')}
                setValue={setValue}
                step={10}
                max={2500}
              />
            </Form.Group>
          </div>
          <br />
          <div className="filter-widget">
            <Form.Group as={Col} sm={12} controlId="search.rating">
              <Form.Label>Rating</Form.Label>
              <Ratings
                value={_.has(filters, 'rating') ? filters.rating : 0}
                onChange={(value) => setValue('rating', value)}
              />
            </Form.Group>
          </div>

          <hr />

          <Row>
            <Col xs={{ span: 12 }}>
              <div className="btn-search mb-3">
                <Button
                  className="btn btn-block"
                  onClick={onClickSearch}
                >
                  <FontAwesomeIcon icon={solid('search')} />
                  {' '}
                  Search
                </Button>
              </div>
            </Col>
            <Col className="mb-3" xs={{ span: 12 }}>
              <Button
                className="btn btn-block"
                variant="link"
                onClick={onClear}
              >
                <FontAwesomeIcon icon={solid('arrows-spin')} />
                {' '}
                Clear
              </Button>
            </Col>
          </Row>

        </Form>
      </div>
    </div>
  );
}
