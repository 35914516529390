import { useMutation, useQuery } from '@apollo/client';
import React, {
  useCallback, useContext, useEffect
} from 'react';
import {
  Form, Card,
} from 'react-bootstrap';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { QualificationForm } from '../../../../components';
import { LoginContext } from '../../../login';
import { getUserQualificationQuery, saveQualificationsMutation } from './gql';
import RegistryClient from '../../../../RegistryClient';
import { DashboardTemplate } from '../../../../template/components';

export default function Index() {
  const formPayload = useForm();
  const {
    handleSubmit, setValue,
  } = formPayload;

  const navigate = useNavigate();
  const { userUid } = useContext(LoginContext);

  const { data: qualificationResult } = useQuery(getUserQualificationQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { userUid },
  });

  useEffect(() => {
    const result = _.has(qualificationResult, 'getUserQualification') ? qualificationResult.getUserQualification : null;
    const iAbout = _.has(result, 'about') ? result.about : null;
    const iEducation = _.has(result, 'education') ? result.education : null;

    setValue('about', iAbout);
    setValue('education', iEducation);
  }, [qualificationResult]);

  const [mutateSave] = useMutation(saveQualificationsMutation, {
    client: RegistryClient,
    onCompleted: () => {
      navigate(-1);
    },
  });

  const submitForm = useCallback((data) => {
    mutateSave({
      variables: {
        userUid,
        ...data,
      },
    });
  }, [userUid, mutateSave]);

  return (
    <DashboardTemplate>
      <Card>
        <Card.Body>
          <Form noValidate onSubmit={handleSubmit(submitForm)}>
            <QualificationForm {...formPayload} />
            <div className="submit-section">
              <button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
              <button type="button" className="btn btn-link" onClick={() => navigate(-1)}>Cancel</button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </DashboardTemplate>
  );
}
