import { useQuery } from '@apollo/client';
import React, {
  useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import ProfileContext from './profile.context';
import { getUserInstanceQuery, getUserQuery } from './gql';
import RegistryClient from '../../RegistryClient';
import { DynamicAvatar, LoadingSpinner, TutorRating } from '../../components';

export default function Index() {
  const { userUid } = useContext(ProfileContext);
  const [firstName, setFirstName] = useState(null);
  const [middleInitial, setMiddleInitial] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [age, setAge] = useState(null);
  const [city, setCity] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [instanceUid, setInstanceUid] = useState(null);
  const [about, setAbout] = useState(null);

  const { loading: loadingUser, data: userResult } = useQuery(getUserQuery, {
    client: RegistryClient,
    variables: { uid: userUid },
    skip: !userUid,
  });

  const {
    loading: loadingUserInstance,
    data: userInstanceResult,
  } = useQuery(getUserInstanceQuery, {
    variables: { userUid },
    skip: !userUid,
  });

  useEffect(() => {
    if (loadingUser || loadingUserInstance) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingUser, loadingUserInstance]);

  useEffect(() => {
    const userInstance = _.has(userInstanceResult, 'getUserInstance') ? userInstanceResult.getUserInstance : null;
    const iUserInstanceUid = _.has(userInstance, 'uid') ? userInstance.uid : null;

    setInstanceUid(iUserInstanceUid);
  }, [userInstanceResult]);

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const iProfile = _.has(user, 'userProfile') ? user.userProfile : null;
    const iFirstName = _.has(iProfile, 'firstName') ? iProfile.firstName : null;
    const iMiddleInitial = _.has(iProfile, 'middleInitial') ? iProfile.middleInitial : null;
    const iLastName = _.has(iProfile, 'lastName') ? iProfile.lastName : null;
    const iFullName = `${iFirstName} ${iLastName}`;
    const iGender = _.has(iProfile, 'gender') ? iProfile.gender : null;
    const iBirthDate = _.has(iProfile, 'birthDate') ? iProfile.birthDate : null;
    const iAge = _.has(iProfile, 'age') ? iProfile.age : null;
    const iAddress = _.has(iProfile, 'address') ? iProfile.address : null;
    const iCity = _.has(iAddress, 'city') ? iAddress.city : null;
    const iCountryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
    const iQualification = _.has(user, 'qualification') ? user.qualification : null;
    const iAbout = _.has(iQualification, 'about') ? iQualification.about : null;

    setFirstName(iFirstName);
    setMiddleInitial(iMiddleInitial);
    setLastName(iLastName);
    setFullName(iFullName);
    setGender(iGender);
    setBirthDate(iBirthDate);
    setCity(iCity);
    setCountryCode(iCountryCode);
    setAge(iAge);
    setAbout(iAbout);
  }, [userResult]);

  if (loading) { return <LoadingSpinner />; }

  return (
    <>
      <div className="card col-10 me-auto ms-auto p-0">
        <div className="card-body">
          <div className="mentor-widget">
            <div className="user-info-left align-items-center">
              <div className="mentor-img d-flex flex-wrap justify-content-center">
                <DynamicAvatar userUid={userUid} />
                <TutorRating tutorUid={instanceUid} />
                <div className="mentor-details m-0">
                  <p className="user-location m-0">
                    <i className="fas fa-map-marker-alt" />
                    {' '}
                    {city}
                    ,
                    {' '}
                    {countryCode}
                  </p>
                </div>
              </div>
              <div className="user-info-cont">
                <h4 className="usr-name">
                  {fullName}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {about && (
        <div className="card col-10 me-auto ms-auto p-0">
          <div className="card-body custom-border-card pb-0">
            <div className="tab-content pt-0">
              <div role="tabpanel" id="biography" className="tab-pane fade show active">
                <div className="row">
                  <div className="col-md-12">
                    <div className="widget about-widget custom-about mb-0">
                      <h4 className="widget-title">
                        About Me
                      </h4>
                      <hr />
                      <p className="w-100">{about}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card col-10 me-auto ms-auto p-0">
        <div className="card-body custom-border-card pb-0">
          <div className="widget education-widget mb-0">
            <h4 className="widget-title">Personal Details</h4>
            <hr />
            <div className="experience-box">
              <ul className="experience-list profile-custom-list">
                <li>
                  <div className="experience-content">
                    <div className="timeline-content">
                      <span>First Name</span>
                      <div className="row-result">{firstName}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="experience-content">
                    <div className="timeline-content">
                      <span>Middle Initial</span>
                      <div className="row-result">{middleInitial}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="experience-content">
                    <div className="timeline-content">
                      <span>Last Name</span>
                      <div className="row-result">{lastName}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="experience-content">
                    <div className="timeline-content">
                      <span>Gender</span>
                      <div className="row-result">{gender}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="experience-content">
                    <div className="timeline-content">
                      <span>Date of Birth</span>
                      <div className="row-result">{birthDate && moment(birthDate).format('ll')}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="experience-content">
                    <div className="timeline-content">
                      <span>Age</span>
                      <div className="row-result">{age}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
