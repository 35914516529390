import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Button,
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import axios from 'axios';
import Promise from 'bluebird';
import moment from 'moment';
import RegistryClient from '../../../../RegistryClient';
import { insertCertificateMutation } from './gql';
import { LoginContext } from '../../../login';
import { getUserQuery } from '../gql';

const FormContext = createContext();

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-image`;

const upload = (file, fileName, userUid) => new Promise((resolve, reject) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('userUid', userUid);
  formData.append('fileName', fileName);

  axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

export default function Index(payload) {
  const { userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, handleSubmit, setValue, reset
  } = formPayload;
  const { errors } = formState;
  const { onCreate } = payload

  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setVisible(false);
    reset()
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateFileSave] = useMutation(insertCertificateMutation, {
    client: RegistryClient,
    awaitRefetchQueries: true,
    // update percentage progress accross the system
    refetchQueries: [
      {
        query: getUserQuery,
        client: RegistryClient,
        variables: { uid: userUid },
      }
    ],
    onCompleted: () => {
      onCreate()
      handleClose();
    },
  });

  const onSubmit = useCallback(async (data) => {
    setLoading(true);

    const description = _.has(data, 'description') ? data.description : null;
    const certificate = _.has(data, 'certificate') ? data.certificate : null;
    const { type } = certificate;
    const splitType = type && type.split('/');
    const ext = splitType.length && _.last(splitType);
    const fileName = `certifications/${moment().format('x')}-certificate.${ext}`;

    await upload(certificate, fileName, userUid)
      .then((result) => {
        const { data: resultData } = result;

        return { ...resultData };
      }).then(async (results) => {
        await mutateFileSave({
          variables: {
            userUid,
            description,
            storage: { ...results },
          },
        });
      });

    setLoading(false);
  }, [userUid, mutateFileSave]);

  const triggerSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit]);

  const contextPayload = useMemo(() => ({
    control,
    errors,
    setValue,
  }), [
    control,
    errors,
    setValue,
  ]);

  return (
    <FormContext.Provider value={contextPayload}>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={solid('folder-plus')} />
      </Button>

      <Modal
        show={visible}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Insert New Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row className="mb-3">
              <Form.Group as={Col} sm={12} controlId="cert.description">
                <Form.Label>Description</Form.Label>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: 'Field is required.' }}
                  render={({ field }) => (
                    <Form.Control
                      isInvalid={!!_.has(errors, 'description')}
                      as="textarea"
                      rows={4}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'description') ? errors.description.message : 'Invalid description.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} sm={12} controlId="cert.certificate">
                <Form.Label>Eligibility/Certification</Form.Label>
                <Controller
                  name="certificate"
                  control={control}
                  rules={{ required: 'Field is required.' }}
                  render={() => (
                    <Form.Control
                      isInvalid={!!_.has(errors, 'certificate')}
                      type="file"
                      onChange={(e) => {
                        const fileList = e.target.files;

                        setValue('certificate', fileList[0]);
                      }}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'certificate') ? errors.certificate.message : 'Invalid certificate.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={triggerSubmit} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </FormContext.Provider>
  );
}
