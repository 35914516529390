/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */
export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      email
      roleCode
      status
      userProfile {
        id
        uid
        birthDate
        age
        others
      }
    }
  }
`;

export const countRemainingSystemVoucherQuery = gql`
  query CountRemainingSystemVoucher($type: String!) {
    countRemainingSystemVoucher(type: $type)
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

export const saveFileMutation = gql`
  mutation SaveFile(
    $userUid: String!
    $fileCategory: String!
    $storage: JSON!
  ) {
    saveFile(
      userUid: $userUid
      fileCategory: $fileCategory
      storage: $storage
    ) {
      id
      uid
      userUid
      fileCategory
      storage
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const saveProfileMutation = gql`
  mutation UpdateProfile(
    $userUid: String!
    $others: JSON
    $updatedBy: String!
    ) {
      saveProfile(
      userUid: $userUid
      others: $others
      updatedBy: $updatedBy
    ) {
      id
      uid
      others
      __typename
    }
  }
`;

export const createRegistrationVoucherMutation = gql`
  mutation CreateRegistrationVoucher(
    $createdBy: String!
    $recepientEmail: String!
    $recepientUserUid: String!
    $recepientName: String
    $description: String
  ) {
    createRegistrationVoucher(
      createdBy: $createdBy
      recepientEmail: $recepientEmail
      recepientUserUid: $recepientUserUid
      recepientName: $recepientName
      description: $description
    ) {
      id
      uid
      code
      amount
      recepientUserUid
      status
      type
      startDate
      endDate
      description
      emailSent
      document
      createdAt
      createdBy
    }
  }
`;

/**
 * End of Mutation
 */
