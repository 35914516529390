/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import PopularMentors from './popularMentors';
import Courses from './courses';
// import FeaturedTutors from './featuredTutors';

export default function Index() {
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();

  const submitForm = useCallback((data) => {
    navigate('/search', { state: { ...data } });
  });

  return (
    <>
      <section className="section section-search">
        <div className="container">
          <div className="banner-wrapper m-auto text-center">
            <div className="banner-header">
              <h1>Search Tutor</h1>
              <p>Discover the best tutors nearest you.</p>
            </div>

            <div className="search-box">
              <Form noValidate onSubmit={handleSubmit(submitForm)}>
                <div className="form-search">
                  <div className="form-inner">

                    <div className="form-group search-location">
                      <i className="material-icons">my_location</i>
                      <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                          <Form.Control
                            autoFocus
                            placeholder="Location"
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <div className="form-group search-info">
                      <i className="material-icons">location_city</i>
                      <Controller
                        name="search"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Interest or Tutor"
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <Button type="submit" variant="primary" className="btn btn-secondary search-btn mt-0">
                      Search
                      {' '}
                      <i className="fas fa-long-arrow-alt-right" />
                    </Button>
                  </div>
                </div>
              </Form>
            </div>

          </div>
        </div>
      </section>

      <section className="section how-it-works">
        <div className="container">
          <div className="section-header text-center">
            <span>GET STARTED</span>
            <div className="sec-dots">
              <span />
              <span />
              <span />
            </div>
          </div>
          <div className="row justify-content-center feature-list">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="feature-box text-center top-box">
                <div className="feature-header">
                  <div className="feature-icon">
                    <span className="circle bg-green"><i className="fas fa-sign-in-alt" /></span>
                  </div>
                  <div className="feature-cont">
                    <div className="feature-text">Sign up</div>
                  </div>
                </div>
                <p>
                  Register to explore the possibilities of advanced learning.
                </p>
                <span className="text-green">01</span>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3 offset-lg-1">
              <div className="feature-box text-center">
                <div className="feature-header">
                  <div className="feature-icon">
                    <span className="circle bg-blue"><i className="material-icons">accessibility</i></span>
                  </div>
                  <div className="feature-cont">
                    <div className="feature-text">Sign in</div>
                  </div>
                </div>
                <p>Use the username and password you registered to search for your tutor.</p>
                <span className="text-blue">02</span>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3 offset-lg-1">
              <div className="feature-box text-center top-box">
                <div className="feature-header">
                  <div className="feature-icon">
                    <span className="circle bg-orange"><i className="material-icons">event_seat</i></span>
                  </div>
                  <div className="feature-cont">
                    <div className="feature-text">Start now</div>
                  </div>
                </div>
                <p>Enjoy learning with our experts.</p>
                <span className="text-orange">03</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section popular-mendors">
        <div className="mendor-title">
          <div className="section-header text-center">
            <div className="container">
              <span>Tutoring Goals</span>
              <h2 className="text-white">Most In Demand Tutors</h2>
              <p className="sub-title text-white">
                Choose the most popular learning tutors to help you achieve your learning goals.
              </p>
              <div className="sec-dots">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>

        <PopularMentors />
      </section>

      <section className="section path-section">
        <div className="section-header text-center">
          <div className="container">
            <span>LEARNING PATH</span>
            <h2>Subject area or interest</h2>
            <p className="sub-title">
              Choose the subject or interest you want to focus on.
            </p>
            <div className="sec-dots">
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>

        <Courses />
      </section>

      {/* <section className="section profile-section">
        <div className="section-header text-center">
          <div className="container">
            <span>MOST VIEWED</span>
            <h2>Featured Profile of this week</h2>
            <p className="sub-title">
              Are you looking to join online institutions?
              {' '}
              Now it's very simple, Sign up with mentoring
            </p>
            <div className="sec-dots">
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
        <div className="container">
          <FeaturedTutors />
        </div>
      </section> */}

      <section className="section statistics-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="statistics-list">
                <div className="statistics-icon">
                  <i className="fas fa-street-view" />
                </div>
                <div className="statistics-content">
                  <span>500+</span>
                  <h3>Happy Clients</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="statistics-list">
                <div className="statistics-icon">
                  <i className="fas fa-history" />
                </div>
                <div className="statistics-content">
                  <span>120+</span>
                  <h3>Online Appointments</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="statistics-list">
                <div className="statistics-icon">
                  <i className="fas fa-user-check" />
                </div>
                <div className="statistics-content">
                  <span>100%</span>
                  <h3>Job Satisfaction</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
