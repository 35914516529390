import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import InvoiceContext from './invoice.context';
import styledComponents from 'styled-components'
import { Button } from 'react-bootstrap';

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function Index() {
  const navigate = useNavigate();
  const {
    paymentUid,
    paymentMethod,
    createdDate,
    paidBy,
    fees,
    subTotal,
    discount,
    totalAmount,
    payTypeNumber,
    address,
    vatAmount,
  } = useContext(InvoiceContext);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Invoice View</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Invoice View</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="invoice-content">
                <div className="invoice-item">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="invoice-logo">
                        <img src="/assets/img/logo.png" alt="logo" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p className="invoice-details">
                        <strong>Order UID:</strong>
                        {' '}
                        {paymentUid}
                        {' '}
                        <br />
                        <strong>Issued:</strong>
                        {' '}
                        {createdDate}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="invoice-item">
                  <div className="row">
                    <div className="col-md-6 offset-md-6">
                      <div className="invoice-info invoice-info2">
                        <strong className="customer-text">Invoice To</strong>
                        <p className="invoice-details">
                          {paidBy}
                          {' '}
                          <br />
                          {address}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="invoice-item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="invoice-info">
                        <strong className="customer-text">Payment Method</strong>
                        <p className="invoice-details invoice-details-two">
                          {paymentMethod}
                          {' '}
                          <br />
                          {payTypeNumber}
                          {/* {' '}
                          <br />
                          HDFC Bank
                          <br /> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="invoice-item invoice-table-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="invoice-table table table-bordered">
                          <thead>
                            <tr>
                              <th>Service</th>
                              <th className="text-center">Date</th>
                              {/* <th className="text-center">VAT</th> */}
                              <th className="text-end">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              fees.map((fee) => {
                                const service = _.has(fee, 'service') ? fee.service : null;
                                const date = _.has(fee, 'date') ? fee.date : null;
                                // const vat = _.has(fee, 'vat') ? fee.vat : 0;
                                const grossSales = _.has(fee, 'grossSales') ? fee.grossSales : 0;

                                return (
                                  <tr>
                                    <td>{service}</td>
                                    <td className="text-center">{date}</td>
                                    {/* <td className="text-center">{vat}</td> */}
                                    <td className="text-end">{grossSales}</td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4 ml-auto">
                      <div className="table-responsive">
                        <table className="invoice-table-two table">
                          <tbody>
                            <tr>
                              <th>Subtotal:</th>
                              <td><span>{subTotal}</span></td>
                            </tr>
                            <tr>
                              <th>Discount:</th>
                              <td><span>{discount}</span></td>
                            </tr>
                            <tr>
                              <th>VAT:</th>
                              <td><span>{vatAmount}</span></td>
                            </tr>
                            <tr>
                              <th>Total Amount:</th>
                              <td><span>{totalAmount}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="other-info">
                  <h4>Other information</h4>
                  <p className="text-muted mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed
                    dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex.
                    Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque
                    tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero.
                  </p>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
