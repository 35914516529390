import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DashboardTemplate } from '../../../template/components';
import TotalProfileView from './TotalProfileView';
import TotalEnrolledStudent from './TotalEnrolledStudent';
import TotalTeacheringHours from './TotalTeacheringHours';
import LoginContext from '../../login/login.context';
import TotalEarned from './TotalEarned';
import AvarageRating from './AvarageRating';

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function Index() {
  const { instanceUid, userUid } = useContext(LoginContext);

  return (
    <DashboardTemplate>
      <Row>
        <Col lg={4} className="dash-board-list pink">
          <AvarageRating tutorUid={instanceUid} />

        </Col>
        <Col lg={4} className="dash-board-list yellow">
          <TotalTeacheringHours tutorUid={instanceUid} />
        </Col>
        <Col lg={4} className="dash-board-list blue">
          <TotalEnrolledStudent tutorUid={instanceUid} />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col lg={6} className="dash-board-list blue">
          <TotalEarned tutorUid={instanceUid} />
        </Col>
        <Col lg={6}>
          <TotalProfileView userUid={userUid} appCode={APPCODE} />
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
