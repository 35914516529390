/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import SessionContext, { SessionRowContext } from './sessions.context';
import { leaveSessionMutation } from './gql';

export default function Index() {
  const [view, setView] = useState(false);
  const [canLeave, setCanLeave] = useState(false);

  const {
    attendanceUid,
    sessionUid,
    interest,
    sessionType,
    link,
    status: sessionStatus,
  } = useContext(SessionRowContext);

  const { setRateSessionUid, setRateInterest } = useContext(SessionContext);

  const handleShow = useCallback(() => {
    setView(true);
  }, [sessionUid]);

  const handleClose = useCallback(() => {
    setView(false);
  }, [sessionUid]);

  const [mutateLeave, { loading }] = useMutation(leaveSessionMutation, {
    onCompleted: () => {
      handleClose();
      setRateSessionUid(sessionUid);
      setRateInterest(interest);
    },
  });

  const onJoin = useCallback(() => {
    mutateLeave({
      variables: { attendanceUid },
    });
  }, [link]);

  useEffect(() => {
    if (sessionStatus === 'COMPLETED') {
      setCanLeave(true);
    } else {
      setCanLeave(false);
    }
  }, [sessionStatus]);

  return (
    <>
      <Button
        className="text-danger"
        variant="link"
        onClick={handleShow}
        disabled={!canLeave}
      >
        <FontAwesomeIcon icon={solid('stop')} />
        {' '}
        Confirm End
      </Button>

      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            End Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>{interest}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{sessionType}</Card.Subtitle>

              <Card.Text>
                You are about to end this session, are you really sure to proceed?
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={onJoin}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'End'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
