/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      roleCode
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        __typename
      }
      qualification {
        id
        uid
        userUid
        about
        __typename
      }
      __typename
    }
  }
`;

export const getUserInstanceQuery = gql`
  query GetUserInstance($userUid: ID!) {
    getUserInstance(userUid: $userUid) {
      ... on Tutor {
        id
        uid
        userUid
        status
        others
        priceRange
        rating
        __typename
      }
      ... on Tutee {
        id
        uid
        userUid
        status
        others
        __typename
      }
    }
  }
`;

export const getElasticSearchQuery = gql`
  query GetElasticSearch($userUid: String!) {
    getElasticSearch(userUid: $userUid) {
      id
      uid
      referenceUid
      type
      document
      __typename
    }
  }
`

/**
 * End of Query
 */

/**
 * Mutation
 */
export const saveElasticSearchMutation = gql`
  mutation SaveElasticSearch(
    $referenceUid: ID
    $type: String!
    $document: JSON
  ) {
    saveElasticSearch(
      referenceUid: $referenceUid
      type: $type
      document: $document
    ) {
      id
      uid
      referenceUid
      type
      document
      __typename
    }
  }
`;
/**
 * End of Mutation
 */
