import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Row, Col, Button, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { INSERT_TICKET_THREAD_MESSAGE } from '../gql';
import RegistryClient from '../../../RegistryClient';
import TicketingClient from '../../../TicketingClient';
import { getUserQuery } from '../../app/gql';
import LoginContext from '../../login/login.context';

export default function Index({
  ticketUid, onSubmit, onError, ticketThreadUid,
}) {
  const { userUid } = useContext(LoginContext);
  const [message, setMessage] = useState(null);
  const [saving, setSaving] = useState(false);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
  const profile = _.has(user, 'userProfile') ? user.userProfile : null;
  const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null;
  const iLastName = _.has(profile, 'lastName') ? profile.lastName : null;
  const userFullname = `${iFirstName} ${iLastName}`;

  const {
    handleSubmit, formState, control, reset,
  } = useForm({
    defaultValues: {
      message,
    },
  });

  const [sendMessage] = useMutation(
    INSERT_TICKET_THREAD_MESSAGE,
    {
      client: TicketingClient,
      refetchQueries: ['GetTicket'],
      onCompleted: () => {
        reset();
      },
    },
  );

  const { errors } = formState;

  const onSave = (data, e) => {
    e.preventDefault();
    setSaving(true);
    sendMessage({
      variables: {
        ticketUid,
        message: data.message,
        userUid,
        userFullname,

      },
    }).then((result) => {
      const dataResult = _.has(result, 'data') ? result.data : null;
      const messageResult = _.has(dataResult, 'insertTicketThreadMessage') ? dataResult.insertTicketThreadMessage : null;
      setMessage(null);
      e.target.reset();
      onSubmit(messageResult);
      setSaving(false);
    }).catch((error) => {
      if (onError) {
        onError(error.toString());
      }
      setSaving(false);
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(onSave)}
      id="reply12"
    >
      <Row className="justify-content-between">
        <Col lg={10}>
          <Form.Group className="form-group" controlId="replyTicket.controlInput1">
            <Controller
              name="message"
              control={control}
              rules={{
                required: 'Message is required.',
                validate: {
                  whiteSpace: (value) => !!value.trim() || 'Message cannot be empty.',
                },
              }}
              render={({ field }) => (
                <Form.Control
                  size="lg"
                  // as="textarea"
                  rows={2}
                  autoFocus
                  isInvalid={!!_.has(errors, 'message')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'message') ? errors.message.message : 'Invalid Message.'}
            </Form.Control.Feedback>
          </Form.Group>

        </Col>
        <Col lg={2}>
          <div className="d-grid">
            <Button
              type="submit"
              variant="primary"
              disabled={saving}
              className="form-group"
            >
              Send
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
