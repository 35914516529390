import moment from 'moment';
import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { CustomTable } from '../../../components';
import { LoginContext } from '../../login';
import { getTutorPaginatedPayrollsQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import ViewPayslip from './viewPayslip';
import ConfirmPayrollModal from '../confirmPayrollModal';
import ViewPayroll from './viewPayroll';
import DisputeFormModal from './disputeFormModal';

const PAYROLL_STATUS_RELEASED = 'RELEASED';

export default function Index() {
  const { instanceUid: tutorUid } = useContext(LoginContext);
  const [page, setPage] = useState(1);
  const [totals, setTotals] = useState(0);
  const [payrolls, setPayrolls] = useState([]);

  const { loading, data: tutorPayrollResult } = useQuery(getTutorPaginatedPayrollsQuery, {
    client: PaymentClient,
    skip: !tutorUid,
    variables: { tutorUid, page },
  });

  useEffect(() => {
    const paginatedPayrolls = _.has(tutorPayrollResult, 'getTutorPaginatedPayrolls')
      ? tutorPayrollResult.getTutorPaginatedPayrolls : [];
    const count = _.has(paginatedPayrolls, 'count') ? paginatedPayrolls.count : 0;
    const rows = _.has(paginatedPayrolls, 'rows') ? paginatedPayrolls.rows : [];

    setTotals(count);
    setPayrolls(rows);
  }, [tutorPayrollResult]);

  const columns = useMemo(() => [
    {
      title: 'Payroll Date',
      dataKey: 'createdAt',
      render: (createdAt) => createdAt && moment(createdAt).format('ll'),
    },
    {
      title: 'Pay Period',
      dataKey: 'payroll',
      render: (payroll) => {
        const iStartDate = _.has(payroll, 'startDate') ? payroll.startDate : null;
        const iEndDate = _.has(payroll, 'endDate') ? payroll.endDate : null;
        const iPayPeriod = `${moment(iStartDate).format('ll')} to ${moment(iEndDate).format('ll')}`;

        return iPayPeriod;
      },
    },
    {
      title: 'Status',
      dataKey: 'status',
    },
    {
      title: '',
      dataKey: 'uid',
      render: (uid, row) => <RenderActions row={row} />,
    },
  ]);

  const onPageChange = useCallback((current) => {
    setPage(current);
  }, [setPage]);

  return (
    <CustomTable
      columns={columns}
      loading={loading}
      dataValues={payrolls}
      page={page}
      totals={totals}
      onPageChange={onPageChange}
    />
  );
}

function RenderActions(payload) {
  const row = _.has(payload, 'row') ? payload.row : null;
  const uid = _.has(row, 'uid') ? row.uid : null;
  const status = _.has(row, 'status') ? row.status : null;
  const payslip = _.has(row, 'payslip') ? row.payslip : null;
  const payslipUid = _.has(payslip, 'uid') ? payslip.uid : null;

  const [canConfirm, setCanConfirm] = useState(false);

  useEffect(() => {
    if (status === PAYROLL_STATUS_RELEASED) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  }, [setCanConfirm, status]);

  return (
    <div className="d-flex justify-content-end">
      <ViewPayroll uid={uid} />
      <ViewPayslip uid={uid} />
      {canConfirm && <ConfirmPayrollModal uid={uid} />}
      <DisputeFormModal payslipUid={payslipUid} />
    </div>
  );
}
