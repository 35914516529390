import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import Sessions from './sessions';
import { DashboardTemplate } from '../../../template/components';
import { getPaginatedTuteeSessionsQuery } from './gql';
import { LoginContext } from '../../login';
import SessionContext from './sessions.context';

export default function Index() {
  const { instanceUid } = useContext(LoginContext);
  const [sessionList, setSessionList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [filterInterest, setFilterInterest] = useState();
  const [filterTutor, setFilterTutor] = useState();
  const [filterFavorite, setFilterFavorite] = useState();
  const [loadingTable, setLoadingTable] = useState(false);
  const [rateSessionUid, setRateSessionUid] = useState(null);
  const [rateInterest, setRateInterest] = useState(null);
  const [filterStatus, setFilterStatus] = useState()

  const { data: sessionsResult, loading } = useQuery(getPaginatedTuteeSessionsQuery, {
    skip: !instanceUid,
    variables: {
      tuteeUid: instanceUid,
      filterInterest,
      filterTutor,
      filterFavorite,
      filterStatus
    },
    pollInterval: 3000,
  });

  useEffect(() => {
    setLoadingTable(loading)
  }, [loading, setLoadingTable])

  useEffect(() => {
    const sessions = _.has(sessionsResult, 'getPaginatedTuteeSessions') ? sessionsResult.getPaginatedTuteeSessions : null;
    const rows = _.has(sessions, 'rows') ? sessions.rows : [];

    setSessionList(rows);
  }, [sessionsResult]);

  const contextPayload = useMemo(() => ({
    sessionList,
    tutorList,
    setTutorList,
    setFilterInterest,
    setFilterTutor,
    setFilterFavorite,
    loadingTable,
    setLoadingTable,
    rateSessionUid,
    setRateSessionUid,
    rateInterest,
    setRateInterest,
    filterStatus,
    setFilterStatus
  }), [
    sessionList,
    tutorList,
    setTutorList,
    setFilterInterest,
    setFilterTutor,
    setFilterFavorite,
    loadingTable,
    setLoadingTable,
    rateSessionUid,
    setRateSessionUid,
    rateInterest,
    setRateInterest,
    filterStatus,
    setFilterStatus
  ]);

  return (
    <DashboardTemplate>
      <SessionContext.Provider value={contextPayload}>
        <Sessions />
      </SessionContext.Provider>
    </DashboardTemplate>
  );
}
