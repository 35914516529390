import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import Booking from './calendar';
import { getTutorQuery, getUserQuery } from './gql';
import BookingContext from './booking.context';
import RegistryClient from '../../RegistryClient';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const state = _.has(loc, 'state') ? loc.state : null;
  const [tutor, setTutor] = useState();
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const tutorUid = _.has(state, 'tutorUid') ? state.tutorUid : null;
  const [tutorProfile, setTutorProfile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [location, setLocation] = useState(null);

  const { data: getTutorResult, loading: tutorLoading } = useQuery(getTutorQuery, {
    skip: !tutorUid,
    variables: { uid: tutorUid },
  });

  useEffect(() => {
    const getTutorData = _.has(getTutorResult, 'getTutor') ? getTutorResult.getTutor : null;

    setTutorUserUid(_.has(getTutorData, 'userUid') && getTutorData.userUid);
    setTutor(getTutorData);
    setLoading(tutorLoading);
  }, [getTutorResult, tutorLoading]);

  const { data: userTutorResult } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !tutorUserUid,
    variables: { uid: tutorUserUid },
  });

  useEffect(() => {
    const result = _.has(userTutorResult, 'getUser') ? userTutorResult.getUser : null;
    const profile = _.has(result, 'userProfile') ? result.userProfile : null;
    setTutorProfile(profile);

    const iAvatar = _.has(result, 'avatar') ? result.avatar : null;
    const iAvatarStorage = _.has(iAvatar, 'storage') ? iAvatar.storage : null;
    const iPath = _.has(iAvatarStorage, 'path') ? iAvatarStorage.path : null;
    setAvatar(iPath)

    const iAddress = _.has(profile, 'address') ? profile.address : null;
    const city = _.has(iAddress, 'city') ? iAddress.city : null;
    const countryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
    setLocation(`${city}, ${countryCode}`);
  }, [userTutorResult]);

  const contextPayload = useMemo(() => ({
    tutor,
    tutorProfile,
    loading,
    avatar,
    location,
    tutorUserUid,
  }), [loading, tutor, tutorProfile, avatar, location, tutorUserUid]);

  return (
    <BookingContext.Provider value={contextPayload}>
      <Booking loading={loading} />
    </BookingContext.Provider>
  );
}
