import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell, Brush,
} from 'recharts';
import { GET_TUTORS_TEACHERING_HOURS } from './gql';
import { LoadingSpinner } from '../../../components';

export default function tutorsTeacheringHoursBarChart() {
  const { data, loading } = useQuery(GET_TUTORS_TEACHERING_HOURS);

  const result = _.has(data, 'getTutorsTeacheringHours') ? data.getTutorsTeacheringHours : null;
  let maxNumber = 0
  const parsedData = result && result.length > 0 ? result.map((item) => {
    const tutor = _.has(item, 'tutor') ? item.tutor : null;
    const others = _.has(tutor, 'others') ? tutor.others : null;
    const firstName = _.has(others, 'firstName') ? others.firstName : null;
    const lastName = _.has(others, 'lastName') ? others.lastName : null;
    const totalHours = _.has(item, 'totalHours') ? item.totalHours : 0;

    if(totalHours > maxNumber) {
      maxNumber = totalHours
    }

    return {
      name: `${firstName} ${lastName}`,
      totalHours,
    };
  }) : [];

  return (
    <div style={{ width: '100%', height: 400 }} className="d-flex m-auto">
      { !loading
        ? (
          <ResponsiveContainer width="100%"  height={400}>
            <BarChart width={150} height={40} data={parsedData}>
              <Tooltip />
              <XAxis dataKey="name" />
              <YAxis dataKey="totalHours" domain={[0, maxNumber]} hide />
              <Bar barSize={40} dataKey="totalHours" name="Total hours" fill="#ffc658">
                {parsedData.map((entry, index) => {
                  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                  // eslint-disable-next-line react/no-array-index-key
                  return <Cell key={`cell-${index}`} fill={`#${randomColor}`} />;
                })}
              </Bar>
              <Brush dataKey="name" height={30} stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        )
        : <div className="m-auto"><LoadingSpinner /></div>}
    </div>
  );
}
