/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getAttendanceQuery = gql`
  query GetAttendance($sessionUid: ID! $instanceUid: String!) {
    getAttendance(sessionUid: $sessionUid instanceUid: $instanceUid) {
      id
      uid
      sessionUid
      instanceUid
      status
      startedAt
      endedAt

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getPaginatedTuteeSessionsQuery = gql`
  query GetPaginatedTuteeSessions(
    $tuteeUid: ID!
    $filterInterest: String
    $filterTutor: String
    $filterFavorite: Boolean
    $filterStatus: String
  ) {
    getPaginatedTuteeSessions(
      tuteeUid: $tuteeUid
      filterInterest: $filterInterest
      filterTutor: $filterTutor
      filterFavorite: $filterFavorite
      filterStatus: $filterStatus
    ) {
      count
      rows {
        id
        uid
        startDate
        endDate
        status
        link
        canJoin
        canCancel
        canRate
        canResched
        timeslot {
          id
          uid
          day
          start
          end
          status
          createdBy
          tutorialProfile {
            id
            uid
            tutorUid
            interest
            sessionType
            price
            status
            createdBy
            __typename
          }
          __typename
        }
        enrollees {
          status
          __typename
        }
        __typename
      }
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        __typename
      }
      __typename
    }
  }
`;

export const getPaymentQuery = gql`
  query GetPayment($uid: ID!) {
    getPayment(uid: $uid) {
      id
      uid
      amount
      paymentMethod
      sourceId
      paymentId
      status
      document
      serviceDetails
      voucher
      voucherDiscount

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getStudentEnrolledSessionQuery = gql`
  query GetStudentEnrolledSession(
    $sessionUid: String!
    $tuteeUid: String!
  ) {
    getStudentEnrolledSession(
      sessionUid: $sessionUid
      tuteeUid: $tuteeUid
    ) {
      id
      uid
      tuteeUid
      sessionUid
      paymentUid
      status
      attendanceStatus

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getStudentSessionRestrictionsQuery = gql`
  query GetStudentSessionRestrictions($sessionUid: String!, $tuteeUid: String!) {
    getStudentSessionRestrictions(sessionUid: $sessionUid, tuteeUid: $tuteeUid){
      enrolledUid
      enrolledStatus
      sessionStatus
      canJoin
      canCancel
      canReschedule
      canRate
    }
  }
`

export const isFavoriteTutorQuery = gql`
  query IsFavoriteTutor($tutorUid: String!, $tuteeUid: String!) {
    isFavoriteTutor(tutorUid: $tutorUid, tuteeUid: $tuteeUid)
  }
`

export const getTimeslotSessionQuery = gql`
  query GetTimeslotSession($timeslotUid: String!) {
    getTimeslotSession(timeslotUid: $timeslotUid) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      interest
      __typename
    }
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */
export const joinSessionMutation = gql`
 mutation JoinSession($sessionUid: ID! $instanceUid: String!) {
   joinSession(sessionUid: $sessionUid instanceUid: $instanceUid) {
    id
    uid
    sessionUid
    instanceUid
    status
    startedAt
    endedAt

    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
   }
 }
`;

export const leaveSessionMutation = gql`
 mutation LeaveSession($attendanceUid: ID!) {
  leaveSession(attendanceUid: $attendanceUid) {
    id
    uid
    sessionUid
    instanceUid
    status
    startedAt
    endedAt

    createdAt
    createdBy
    updatedAt
    updatedBy
    __typename
   }
 }
`;

export const cancelBookingMutation = gql`
  mutation CancelBooking(
    $sessionUid: String!
    $tuteeUid: String!
    $updatedBy: String!
  ) {
    cancelBooking(
      sessionUid: $sessionUid
      tuteeUid: $tuteeUid
      updatedBy: $updatedBy
    ) {
      id
      uid
      status
      __typename
    }
  }
`;

export const cancelTutorSessionMutation = gql`
  mutation CancelTutorSession(
    $sessionUid: String!
    $tutorUid: String!
    $updatedBy: String!
  ) {
    cancelTutorSession(
      sessionUid: $sessionUid
      tutorUid: $tutorUid
      updatedBy: $updatedBy
    ) 
  }
`;

export const addReviewMutation = gql`
  mutation AddReview(
    $userUid: String!
    $sessionUid: String!
    $review: String!
    $ratings: Int!
    $favorite: Boolean
    $document: JSON
  ) {
    addReview(
      userUid: $userUid
      sessionUid: $sessionUid
      review: $review
      ratings: $ratings
      favorite: $favorite
      document: $document
    ) {
      id
      uid
      sessionUid
      tutorUid
      review
      ratings
      document

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;
/**
 * End of Mutation
 */
