import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Range } from 'react-range';

export default function Index(payload) {
  const {
    ref,
    name,
    isInvalid,
    defaultValues,
    min = 0,
    max = 1000,
    onChange,
    setValue,
    ...etc
  } = payload;

  const [prices, setPrices] = useState([0, 10]);

  // Expand default values to avoid refreshing of value to default after submit
  const startValue = defaultValues && defaultValues[0];
  const endValue = defaultValues && defaultValues[1];

  const handleChange = useCallback((values) => {
    setPrices(values);
    if (setValue) { setValue(name, values); }
  });

  useEffect(() => {
    if (startValue && endValue) {
      setPrices([startValue, endValue]);
      if (setValue) { setValue(name, [startValue, endValue]); }
    }
  }, [startValue, endValue]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Range
        min={min}
        max={max}
        values={prices}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#ccc',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({
          props, value, isDragged,
        }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              borderRadius: '4px',
              // backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
              backgroundColor: isDragged ? '#548BF4' : '#FE9445',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '12px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4',
              }}
            >
              {value.toFixed(1)}
            </div>
            <div
              style={{
                height: '16px',
                width: '16px',
                color: '#FFF',
                fontSize: '18px',
              }}
            >
              <FontAwesomeIcon icon={solid('tag')} />
            </div>
          </div>
        )}
        {...etc}
      />
    </div>
  );
}
