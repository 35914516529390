import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button,
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import RegistryClient from '../../../../RegistryClient';
import { getUserQualificationQuery, saveQualificationsMutation } from './gql';
import { LoginContext } from '../../../login';
import { getUserQuery } from '../gql';

export default function Index() {
  const { userId, userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, handleSubmit, setValue,
  } = formPayload;
  const { errors } = formState;

  const { data: qualificationResult } = useQuery(getUserQualificationQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { userUid },
  });

  useEffect(() => {
    const result = _.has(qualificationResult, 'getUserQualification') ? qualificationResult.getUserQualification : null;
    const about = _.has(result, 'about') ? result.about : null;

    setValue('about', about);
  }, [qualificationResult]);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const [mutateSave, { loading }] = useMutation(saveQualificationsMutation, {
    client: RegistryClient,
    awaitRefetchQueries: true,
    // update percentage progress accross the system
    refetchQueries: [
      {
        query: getUserQuery,
        client: RegistryClient,
        variables: { uid: userUid },
      }
    ],
    onCompleted: () => {
      handleClose();
    },
    update(cache, { data: { saveQualifications: qualification } }) {
      cache.modify({
        id: `User:${userId}`,
        fields: {
          qualification: () => qualification,
        },
      });
    },
  });

  const onSubmit = useCallback((data) => {
    mutateSave({
      variables: {
        userUid,
        ...data,
      },
    });
  }, [userUid, mutateSave]);

  const triggerSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit]);

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={regular('edit')} />
      </Button>

      <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Qualification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Row className="mb-3">
              <Form.Group as={Col} sm={12} controlId="qual.about">
                <Form.Label>About Me</Form.Label>
                <Controller
                  name="about"
                  control={control}
                  rules={{ required: 'About me is required.' }}
                  render={({ field }) => (
                    <Form.Control as="textarea" rows={10} {...field} />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'about') ? errors.about.message : 'Invalid about me.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={triggerSubmit} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
