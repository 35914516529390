/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

/**
 * End of Query
 */

/**
 * Mutation
 */

export const addPaymentMutation = gql`
  mutation AddPayment(
    $sourceId: String!
    $amount: Float!
    $paymentMethod: String!
    $document: JSON!
    $serviceDetails: JSON!
    $createdBy: String!
  ) {
    addPayment(
      sourceId: $sourceId
      amount: $amount
      paymentMethod: $paymentMethod
      document: $document
      serviceDetails: $serviceDetails
      createdBy: $createdBy
    ) {
      id
      uid
      sourceId
      amount
      paymentMethod
      paymentId
      status
      document
      serviceDetails

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const completePaymentMutation = gql`
  mutation CompletePayment(
      $sourceId: String!
      $paymentId: String!
      $status: String!
      $document: JSON!
      $updatedBy: String!
      
  ) {
    completePayment(
      sourceId: $sourceId
      paymentId: $paymentId
      status: $status
      document: $document
      updatedBy: $updatedBy
    ) {
      id
      uid
      sourceId
      amount
      paymentMethod
      paymentId
      status
      document
      serviceDetails

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const cashInMutation = gql`
  mutation CashIn(
    $userUid: ID!
    $amount: Float!
    $document: JSON!
  ) {
    cashIn(
      userUid: $userUid
      amount: $amount
      document: $document
    ) {
      id
      uid
      userUid
      balance
      document
      soas {
        id
        uid
        walletUid
        action
        document

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getUserWalletQuery = gql`
  query GetUserWallet($userUid: ID!) {
    getUserWallet(userUid: $userUid) {
      id
      uid
      userUid
      balance
      document
      soas {
        id
        uid
        walletUid
        action
        amount
        currentBalance
        previousBalance
        document

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

/**
 * End of Mutation
 */
