import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import ReactExport from 'react-export-excel';
import { Button } from 'react-bootstrap';
import Promise from 'bluebird';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import RegistryClient from '../../../RegistryClient';
import PaymentClient from '../../../PaymentClient';
import { getPaginatedSystemVouchersQuery, getUserQuery, getAllSystemVouchersQuery } from './gql';
import { CustomTable, LoadingSpinner } from '../../../components';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const SYSTEM_VOUCHER_TYPE = 'SESSIONS';

export default function Index() {
  const [page, setPage] = useState(1);
  const [totals, setTotals] = useState(0);
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    loading: loadingVouchers,
    data: sysVoucherResult,
  } = useQuery(getPaginatedSystemVouchersQuery, {
    client: PaymentClient,
    variables: { page, type: SYSTEM_VOUCHER_TYPE },
  });

  useEffect(() => {
    setLoading(loadingVouchers);
  }, [loadingVouchers]);

  useEffect(() => {
    const paginatedSystemVouchers = _.has(sysVoucherResult, 'getPaginatedSystemVouchers')
      ? sysVoucherResult.getPaginatedSystemVouchers : null;
    const count = _.has(paginatedSystemVouchers, 'count') ? paginatedSystemVouchers.count : 0;
    const rows = _.has(paginatedSystemVouchers, 'rows') ? paginatedSystemVouchers.rows : [];

    setTotals(count);
    setVouchers(rows);
  }, [sysVoucherResult, setVouchers, setTotals]);

  const columns = useMemo(() => [
    {
      title: 'Learner\'s Name',
      dataKey: 'createdBy',
      render: (createdBy) => <RenderStudent createdBy={createdBy} />,
    },
    {
      title: 'Email',
      dataKey: 'createdBy',
      render: (createdBy) => <RenderEmail createdBy={createdBy} />,
    },
    {
      title: 'Date Awarded',
      dataKey: 'createdAt',
      render: (createdAt) => moment(createdAt).format('lll'),
    },
    {
      title: 'Status',
      dataKey: 'status',
    },
  ]);

  const onPageChange = useCallback((current) => {
    setPage(current);
  }, [setPage]);

  return (
    <CustomTable
      columns={columns}
      loading={loading}
      dataValues={vouchers}
      page={page}
      totals={totals}
      onPageChange={onPageChange}
    />
  );
}

function RenderStudent(payload) {
  const { createdBy } = payload;
  const [student, setStudent] = useState(null);

  const { loading, data } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !createdBy,
    variables: { uid: createdBy },
  });

  useEffect(() => {
    const user = _.has(data, 'getUser') ? data.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const studentName = `${firstName} ${lastName}`;

    setStudent(studentName);
  }, [data]);

  return (
    <div>
      {loading ? <LoadingSpinner /> : student}
    </div>
  );
}

function RenderEmail(payload) {
  const { createdBy } = payload;
  const [email, setEmail] = useState(null);

  const { loading, data } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !createdBy,
    variables: { uid: createdBy },
  });

  useEffect(() => {
    const user = _.has(data, 'getUser') ? data.getUser : null;
    const iEmail = _.has(user, 'email') ? user.email : null;

    setEmail(iEmail);
  }, [data]);

  return (
    <div>
      {loading ? <LoadingSpinner /> : email}
    </div>
  );
}

export function DownloadExcel() {
  const [vouchers, setVouchers] = useState([]);

  const { loading, data } = useQuery(getAllSystemVouchersQuery, {
    client: PaymentClient,
    variables: { type: SYSTEM_VOUCHER_TYPE },
  });

  useEffect(() => {
    const getUser = (createdBy) => RegistryClient.query({
      query: getUserQuery,
      variables: { uid: createdBy },
    });

    async function doFetch() {
      const rows = _.has(data, 'getAllSystemVouchers') ? data.getAllSystemVouchers : [];

      const parsed = await Promise.map(rows, async (row) => {
        const createdBy = _.has(row, 'createdBy') ? row.createdBy : null;
        const user = await getUser(createdBy)
          .then((result) => {
            const iData = _.has(result, 'data') ? result.data : null;
            const iUser = _.has(iData, 'getUser') ? iData.getUser : null;

            return iUser;
          });
        const profile = _.has(user, 'userProfile') ? user.userProfile : null;
        const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
        const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
        const studentName = `${firstName} ${lastName}`;
        const studentEmail = _.has(user, 'email') ? user.email : null;

        return { ...row, studentName, studentEmail };
      });

      setVouchers(parsed);
    }

    doFetch();
  }, [data]);

  return (
    <div>
      {
        loading ? <LoadingSpinner /> : (
          <ExcelFile
            element={(
              <Button variant="link">
                <FontAwesomeIcon icon={solid('file-export')} />
                {' '}
                Export
              </Button>
            )}
            filename="SessVouchers100"
          >
            <ExcelSheet data={vouchers} name="First 100 Registration Vouchers">
              <ExcelColumn label="Learner's Name" value="studentName" />
              <ExcelColumn label="Email" value="studentEmail" />
              <ExcelColumn
                label="Date Awarded"
                value={(col) => {
                  const regDate = _.has(col, 'createdAt') ? moment(col.createdAt).format('lll') : null;

                  return regDate;
                }}
              />
              <ExcelColumn label="Status" value="status" />
            </ExcelSheet>
          </ExcelFile>
        )
      }
    </div>
  );
}
