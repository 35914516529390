import { gql } from '@apollo/client';

export const GET_ALL_TUTOR_ATTENDANCE = gql`
query GetAllTutorsAttendance($offset: Int!, $searchText: String, $limit: Int, $dateRange: AttendanceDateRange $status: String) {
    getAllTutorsAttendance(offset: $offset, searchText: $searchText, limit: $limit, dateRange: $dateRange status: $status) {
      count
      rows {
        id
        uid
        sessionUid
        instanceUid
        status
        startedAt
        endedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
        tutorDetails {
          id
          uid
          userUid
          status
          others
          priceRange
          rating
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        session {
            id
            uid
            timeslotUid
            startDate
            endDate
            status
            link
            canStart
            canJoin
            interest
            canResched
            canCancel
            createdAt
            createdBy
            updatedAt
            updatedBy
            timeslot {
              id
              uid
              day
              start
              end
              status
              createdBy
              tutorialProfile {
                id
                uid
                tutorUid
                interest
                sessionType
                price
                status
                createdBy
              }
            }
          }
      }
    }
  }
`;
