import React from 'react';
import { Card } from 'react-bootstrap';
import { DashboardTemplate } from '../../../template/components';
import CreatePayrollModal from './createPayrollModal';
import PayrollTable from './payrollTable';

export default function Index() {
  return (
    <DashboardTemplate>
      <>
        <h3 className="pb-3">Payroll</h3>

        <Card>
          <Card.Header>
            <Card.Title>
              <CreatePayrollModal />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <PayrollTable />
          </Card.Body>
        </Card>
      </>
    </DashboardTemplate>
  );
}
