/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLazyQuery } from '@apollo/client';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import WalletContext from './wallet.context';
import { LoginContext } from '../../login';
import { getUserWalletQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import { LoadingSpinner } from '../../../components';

const HeaderCard = styledComponents(Card)`
  .wallet-header__balance {
    font-size: 36px;
    margin: 0 16px 16px 0;
    display: inline-flex;
    vertical-align: middle;
    color: #00cc52;
    text-shadow: 0px 0px aliceblue;

    i.fa {
      margin-right: 8px;
      margin-top: 3px;
    }
  }

  .wallet-header__balance:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .wallet-header__balance--low {
    color: #ffbc34;
  }

  .wallet-header__balance--zero {
    color: red;
  }

  .wallet-header__label {
    vertical-align: middle;
    margin-bottom: 16px;
  }

  .card-title {
    margin-bottom: 0px;
  }

  .card-text {
    text-shadow: 0px 0px aliceblue;
  }

  .wallet-loading {
    font-size: 21px;
    vertical-align: middle;
    color: #fd7e14;
  }
`;

export default function Index() {
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const { userUid, roleCode } = useContext(LoginContext);
  const [transactions, setTransactions] = useState([]);
  const [mode, setMode] = useState();
  const [walletDescription, setWalletDescription] = useState(null)
  const [loading, setLoading] = useState(false)

  const [fetchWallet] = useLazyQuery(getUserWalletQuery, {
    client: PaymentClient,
    pollInterval: 10000,
  });

  useEffect(() => {
    if (roleCode) {
      switch (roleCode) {
        case 'TUTOR':
          setMode('TEACHER');
          setWalletDescription('Total Earnings');
          break;
        default:
          setMode('STUDENT');
          setWalletDescription('Remaining Balance');
      }
    }
  }, [roleCode]);

  useEffect(() => {
    async function doFetchWallet() {
      setLoading(true)

      await fetchWallet({ variables: { userUid } }).then(({ data }) => {
        const wallet = _.has(data, 'getUserWallet') ? data.getUserWallet : null;
        const remaining = _.has(wallet, 'balance') ? parseFloat(wallet.balance) : 0;
        const soas = _.has(wallet, 'soas') ? wallet.soas : [];

        setBalance(remaining.toFixed(2));
        setTransactions(soas);
      })

      setLoading(false)
    }

    if (userUid) {
      doFetchWallet()
    }
  }, [userUid]);

  const contextPayload = useMemo(() => ({
    balance,
    transactions,
    mode,
    loading
  }), [
    balance,
    transactions,
    mode,
    loading
  ]);

  const cashIn = useCallback(() => {
    navigate('/payments/cashin');
  });

  return (
    <WalletContext.Provider value={contextPayload}>
      <Row>
        <Col lg={12}>
          <HeaderCard className="wallet-header">
            <Card.Body>
              <Card.Title>
                <p className="wallet-header__label text-secondary">{walletDescription}</p>
                {' '}
                <RenderBalance />
              </Card.Title>

              {mode === 'STUDENT' && (
                <>
                  <Card.Text><RenderBalanceText /></Card.Text>
                  <Button variant="primary" onClick={cashIn}>
                    <i className="fa fa-credit-card" aria-hidden="true" />
                    {' '}
                    Cash In
                  </Button>
                </>
              )}
            </Card.Body>
          </HeaderCard>
        </Col>
      </Row>
    </WalletContext.Provider>
  );
}

function RenderBalance() {
  const { loading, balance } = useContext(WalletContext);
  const navigate = useNavigate();

  const goToWallet = useCallback(() => {
    navigate('/payments');
  })

  if (loading) return <span className='wallet-loading'><LoadingSpinner /></span>

  if (balance < 1) {
    return (
      <span
        className="wallet-header__balance wallet-header__balance--zero"
        onClick={goToWallet}
      >
        <i className="fa fa-wallet" aria-hidden="true" />
        {' '}
        ₱ {balance}
      </span>
    );
  } if (balance < 200) {
    return (
      <span
        className="wallet-header__balance wallet-header__balance--low"
        onClick={goToWallet}
      >
        <i className="fa fa-wallet" aria-hidden="true" />
        {' '}
        ₱
        {balance}
      </span>
    );
  }

  return (
    <span className="wallet-header__balance" onClick={goToWallet}>
      <i className="fa fa-wallet" aria-hidden="true" />
      {' '}
      ₱
      {balance}
    </span>
  );
}

function RenderBalanceText() {
  const { loading, balance } = useContext(WalletContext);

  if (loading) return ''

  if (balance < 1) {
    return (
      <p className="text-danger">
        <FontAwesomeIcon icon={solid('exclamation-circle')} />
        {' '}
        You have zero balance remaining, please cash in.
      </p>
    );
  } if (balance < 200) {
    return (
      <p className="text-warning">
        <FontAwesomeIcon icon={solid('exclamation-circle')} />
        {' '}
        You have low balance remaining, please cash in.
      </p>
    );
  }

  return '';
}
