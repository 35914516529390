import React from 'react';
import {
  Tab, Row, Col, Nav,
} from 'react-bootstrap';
import Interest from './interests';

export default function Index() {
  return (
    <div>
      <h3 className="page-title mb-4">Schedule and Class Management</h3>
      <Tab.Container defaultActiveKey="interest" fill>
        <Row>
          <Col lg={12} className="mb-4">
            <Nav
              variant="pills"
              className="flex-row nav nav-tabs nav-tabs-solid bg-transparent"
            >
              {/* <Nav.Item>
                <Nav.Link eventKey="main">Schedule and Class </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="interest">Interests</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Col lg={12}>
            <Tab.Content>
              {/* <Tab.Pane eventKey="main">
                <Row>
                  <Col lg={12}>
                    <div className="card ">
                      <div className="card-body">
                        <h4 className="card-title">Tutor Availability</h4>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="card ">
                      <div className="card-body">
                        <h4 className="card-title">Expert Class</h4>
                      </div>
                    </div>
                  </Col>

                </Row>
              </Tab.Pane> */}
              <Tab.Pane eventKey="interest">
                <Interest />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>

  );
}
