import { gql } from '@apollo/client';

export const GET_VOUCHER = gql`
query GetVoucher($uid: String!) {
    getVoucher(uid: $uid) {
      id
      uid
      code
      status
      startDate
      endDate
      requirement
      amount
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const GET_PAGINATED_VOUCHERS = gql`
query GetPaginatedVouchers($offset: Int, $limit: Int, $searchText: String, $filter: VoucherFilter) {
    getPaginatedVouchers(offset: $offset, limit: $limit, searchText: $searchText, filter: $filter) {
      count
      rows {
        id
        uid
        code
        status
        startDate
        endDate
        requirement
        amount
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }  
`;

export const GET_VOUCHER_BY_CODE = gql`
query GetVoucherByCode($code: String! $uid: String) {
  getVoucherByCode(code: $code uid: $uid) {
    id
    uid
    code
    status
    startDate
    endDate
    requirement
    amount
    description
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const CREATE_VOUCHER = gql`
mutation CreateVoucher($code: String!, $createdBy: String!, $startDate: String, $endDate: String, $amount: Float, $requirement: Int, $description: String) {
    createVoucher(code: $code, createdBy: $createdBy, startDate: $startDate, endDate: $endDate, amount: $amount, requirement: $requirement, description: $description) {
      id
      uid
      code
      status
      startDate
      endDate
      requirement
      amount
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_VOUCHER = gql`
mutation UpdateVoucher($uid: String!, $code: String!, $updatedBy: String!, $status: String, $startDate: String, $endDate: String, $requirement: Int, $amount: Float, $description: String) {
    updateVoucher(uid: $uid, code: $code, updatedBy: $updatedBy, status: $status, startDate: $startDate, endDate: $endDate, requirement: $requirement, amount: $amount, description: $description) {
      id
      uid
      code
      status
      startDate
      endDate
      requirement
      amount
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_VOUCHER_STATUS = gql`
mutation UpdateVoucherStatus($uid: String!, $status: String!, $updatedBy: String!) {
    updateVoucherStatus(uid: $uid, status: $status, updatedBy: $updatedBy) {
      id
      uid
      code
      status
      startDate
      endDate
      requirement
      amount
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_VOUCHER = gql`
mutation DeleteVoucher($uid: String!, $deletedBy: String!) {
  deleteVoucher(uid: $uid, deletedBy: $deletedBy)
}
`;

export const getPaginatedSystemVouchersQuery = gql`
  query GetPaginatedSystemVouchers($page: Int!, $type: String!) {
    getPaginatedSystemVouchers(page: $page, type: $type) {
      count
      rows {
        id
        uid
        code
        amount
        recepientUserUid
        status
        type
        startDate
        endDate
        description
        emailSent
        document
        createdAt
        createdBy
      }
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      email
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      userProfile {
        firstName
        lastName
      }
      __typename
    }
  }
`;

export const getAllSystemVouchersQuery = gql`
  query GetAllSystemVouchers($type: String!) {
    getAllSystemVouchers(type: $type) {
      id
      uid
      code
      amount
      recepientUserUid
      status
      type
      startDate
      endDate
      description
      emailSent
      document
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
