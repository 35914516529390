import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { DashboardTemplate } from '../../template/components';
import PayslipTable from './payslip/payslipTable';

export default function Index() {
  return (
    <DashboardTemplate>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Card.Title>Payslips</Card.Title>
              <PayslipTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
