// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import firebaseConfig from './firebaseConfig.json';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const GENERATED_MESSAGING_KEY = process.env.REACT_APP_FCM_API_KEY;

export const requestNotificationPermission = (setToken) => new Promise(async (resolve, reject) => {
  const status = await Notification.requestPermission();

  if (status === 'granted') {
    await getToken(messaging, { vapidKey: GENERATED_MESSAGING_KEY })
      .then((currentToken) => {
        if (currentToken) {
          // console.log('current token for client: ', currentToken);
          setToken(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
          resolve(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
          setToken(null);
          // shows on the UI that permission is required
          resolve(null)
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
        reject(err);
      });
  }
});

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});

export default app;
