import React from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';
import RenderPdf from './renderPdf';
import styledComponents from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

export default function Index() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const tutorPayrollUid = _.has(state, 'tutorPayrollUid') ? state.tutorPayrollUid : null;

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Paylip</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active">Payslip</li>
                  <li className="breadcrumb-item active">View</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <BlobProvider document={<RenderPdf tutorPayrollUid={tutorPayrollUid} />}>
                {({ url }) => (
                  <div>
                    There's something going on on the fly
                    {' '}
                    {url}
                  </div>
                )}
              </BlobProvider>
              <RenderPdf tutorPayrollUid={tutorPayrollUid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
