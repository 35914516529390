/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

/**
 * End of Query
 */

/**
 * Mutation
 */

export const completePaymentMutation = gql`
 mutation CompletePayment(
    $sourceId: String!
    $paymentId: String!
    $status: String!
    $document: JSON!
    $updatedBy: String!
    
 ) {
  completePayment(
     sourceId: $sourceId
     paymentId: $paymentId
     status: $status
     document: $document
     updatedBy: $updatedBy
   ) {
     id
     uid
     sourceId
     amount
     paymentMethod
     paymentId
     status
     document
     serviceDetails

     createdAt
     createdBy
     updatedAt
     updatedBy
   }
 }
`;

export const enrollToSessionMutation = gql`
  mutation EnrollToSession(
    $tuteeUid: String!
    $timeslotUid: String!
    $startDate: String!
    $endDate: String!
    $paymentUid: String!
    $createdBy: String!
  ) {
    enrollToSession(
      tuteeUid: $tuteeUid
      timeslotUid: $timeslotUid
      startDate: $startDate
      paymentUid: $paymentUid
      endDate: $endDate
      createdBy: $createdBy
    ) {
      id
      uid
      tuteeUid
      sessionUid
      paymentUid
      attendanceStatus

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

/**
 * End of Mutation
 */
