import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import _ from 'lodash';
import { SEND_NOTIFICATION } from './gql';
import NotificationClient from '../../NotificationClient';
import RegistryClient from '../../RegistryClient';
import { GET_ALL_USERS } from '../superadmin/userManagement/gql';

// Send notification to all tutors
const useTutorNotification = (payload) => {
  const userUid = _.has(payload, 'userUid') ? payload.userUid : null;

  const [userUids, setUserUids] = useState([]);
  const [sending, setSending] = useState(false);

  //   get all tutors uids
  useQuery(GET_ALL_USERS, {
    client: RegistryClient,
    variables: {
      offset: 0,
      limit: 10000,
      filter: {
        roleCodes: ['TUTOR'],
      },
    },
    onCompleted: (result) => {
      const dataResult = _.has(result, 'getAllUsers') ? result.getAllUsers : null;
      const users = _.has(dataResult, 'rows') ? dataResult.rows : [];
      const uids = (users.length > 0 && users.map((user) => user.uid)) || [];
      setUserUids(uids);
    },
  });

  const [saveNotification, { error }] = useMutation(SEND_NOTIFICATION, {
    client: NotificationClient,
  });

  const sendNotification = (params) => {
    const title = _.has(params, 'title') ? params.title : null;
    const message = _.has(params, 'message') ? params.message : null;

    setSending(true);
    if (userUids.length > 0) {
      saveNotification({
        variables: {
          users: userUids,
          title,
          message,
          createdBy: userUid,
        },
      }).then(() => {
        setSending(false);
      }).catch((error) => {
        console.log('sendNotification.error', error);
        setSending(false);
      });
    }
  };

  return {
    sendNotification,
    sending,
    error,
  };
};

export default useTutorNotification;
