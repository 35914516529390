import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
import { GET_TOTAL_ENROLLED_STUDENTS } from './qql';
import { LoadingSpinner } from '../../../components';

export default function TotalEnrolledStudent({ tutorUid }) {
  const { data, loading } = useQuery(GET_TOTAL_ENROLLED_STUDENTS, {
    variables: {
      tutorUid,
    },
  });
  const result = _.has(data, 'getEnrolledTuteesCount') ? data.getEnrolledTuteesCount : 0;

  return (
    <div className="dash-widget" style={{ height: '100%' }}>
      <div className="circle-bar">
        <div className="icon-col">
          <i className="fas fa-users" />
        </div>
      </div>
      <div className="dash-widget-info">
        <h3>
          { !loading ? result : <LoadingSpinner text='' />}
        </h3>
        <h6>Total Enrolled Student</h6>
      </div>
    </div>
  );
}
