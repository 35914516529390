import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import Booking from './calendar';
import { getSessionQuery, getTutorQuery, getUserQuery } from './gql';
import RescheduleContext from './reschedule.context';
import RegistryClient from '../../RegistryClient';
import { LoadingBlocker } from '../../components';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const loc = useLocation();
  const state = _.has(loc, 'state') ? loc.state : null;
  const [tutor, setTutor] = useState();
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const sessionUid = _.has(state, 'sessionUid') ? state.sessionUid : null;
  const [tutorUid, setTutorUid] = useState(null);
  const [tutorProfile, setTutorProfile] = useState(null);
  const [location, setLocation] = useState(null);
  const [currentStartDate, setCurrentStartDate] = useState(null);

  const { data: getSessionResult, loading: sessionLoading } = useQuery(getSessionQuery, {
    skip: !sessionUid,
    variables: { uid: sessionUid },
  });

  useEffect(() => {
    if (!sessionLoading) {
      const iSession = _.has(getSessionResult, 'getSession') ? getSessionResult.getSession : null;
      const iTimeslot = _.has(iSession, 'timeslot') ? iSession.timeslot : null;
      const iProfile = _.has(iTimeslot, 'tutorialProfile') ? iTimeslot.tutorialProfile : null;
      const iTutorUid = _.has(iProfile, 'tutorUid') ? iProfile.tutorUid : null;
      const iStartDate = _.has(iSession, 'startDate') ? iSession.startDate : null;

      setTutorUid(iTutorUid);
      setCurrentStartDate(iStartDate);
    }
  }, [getSessionResult, sessionLoading]);

  const { data: getTutorResult, loading: tutorLoading } = useQuery(getTutorQuery, {
    skip: !tutorUid,
    variables: { uid: tutorUid },
  });

  useEffect(() => {
    const getTutorData = _.has(getTutorResult, 'getTutor') ? getTutorResult.getTutor : null;

    setTutorUserUid(_.has(getTutorData, 'userUid') && getTutorData.userUid);
    setTutor(getTutorData);
  }, [getTutorResult, tutorLoading]);

  const { data: userTutorResult, loading: userLoading } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !tutorUserUid,
    variables: { uid: tutorUserUid },
  });

  useEffect(() => {
    const result = _.has(userTutorResult, 'getUser') ? userTutorResult.getUser : null;
    const profile = _.has(result, 'userProfile') ? result.userProfile : null;
    setTutorProfile(profile);

    const iAddress = _.has(profile, 'address') ? profile.address : null;
    const city = _.has(iAddress, 'city') ? iAddress.city : null;
    const countryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
    setLocation(`${city}, ${countryCode}`);
  }, [userTutorResult, userLoading]);

  useEffect(() => {
    if (userLoading || tutorLoading || sessionLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    userLoading,
    tutorLoading,
    sessionLoading,
  ]);

  const contextPayload = useMemo(() => ({
    tutor,
    tutorProfile,
    loading,
    location,
    sessionUid,
    setLoading,
    currentStartDate,
  }), [
    loading,
    tutor,
    tutorProfile,
    location,
    sessionUid,
    setLoading,
    currentStartDate,
  ]);

  return (
    <RescheduleContext.Provider value={contextPayload}>
      <Booking />
      <LoadingBlocker loading={loading}>
        <p className="text-info">Please wait while fetching the details...</p>
      </LoadingBlocker>
    </RescheduleContext.Provider>
  );
}
