import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Card, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ProfileForm } from '../../../../components';
import { DashboardTemplate } from '../../../../template/components';
import { getUserQuery, saveFileMutation, updateProfileMutation } from './gql';
import { LoginContext } from '../../../login';
import RegistryClient from '../../../../RegistryClient';

const getAge = (birthDate) => moment().diff(moment(birthDate, 'DD MMM YYYY'), 'years');
const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/upload-image`;

const upload = (file, userUid) => new Promise((resolve, reject) => {
  const { type } = file;
  const splitType = type && type.split('/');
  const ext = splitType.length && _.last(splitType);
  const fileName = `user-avatar.${ext}`;
  const formData = new FormData();

  formData.append('file', file);
  formData.append('userUid', userUid);
  formData.append('fileName', fileName);

  axios.post(UPLOAD_URL, formData)
    .then(resolve).catch(reject);
});

export default function Index() {
  const navigate = useNavigate();
  const { userUid } = useContext(LoginContext);
  const [avatar, setAvatar] = useState(null);
  const [userId, setUserId] = useState(null);
  const formPayload = useForm();
  const {
    handleSubmit, setValue, watch,
  } = formPayload;

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
    const id = _.has(user, 'id') ? user.id : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const iEmail = _.has(user, 'email') ? user.email : null;
    const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const iMI = _.has(profile, 'middleInitial') ? profile.middleInitial : null;
    const iLastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const iBirthDate = _.has(profile, 'birthDate') && !!profile.birthDate ? new Date(profile.birthDate) : null;
    const iGender = _.has(profile, 'gender') ? profile.gender : null;
    const iMobile = _.has(profile, 'mobile') ? profile.mobile : null;
    const iAddress = _.has(profile, 'address') ? profile.address : null;
    const iAddress1 = _.has(iAddress, 'address1') ? iAddress.address1 : null;
    const iAddress2 = _.has(iAddress, 'address2') ? iAddress.address2 : null;
    const iCity = _.has(iAddress, 'city') ? iAddress.city : null;
    const iPostalCode = _.has(iAddress, 'postalCode') ? iAddress.postalCode : null;
    const iCountryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
    const iAvatar = _.has(user, 'avatar') ? user.avatar : null;
    const iStorage = _.has(iAvatar, 'storage') ? iAvatar.storage : null;
    const publicUrl = _.has(iStorage, 'publicUrl') ? iStorage.publicUrl : null;

    setUserId(id);
    setAvatar(publicUrl);
    setValue('email', iEmail);
    setValue('firstName', iFirstName);
    setValue('middleInitial', iMI);
    setValue('lastName', iLastName);

    setValue('birthDate', iBirthDate);
    setValue('gender', iGender);
    setValue('mobile', iMobile);
    setValue('address1', iAddress1);
    setValue('address2', iAddress2);
    setValue('city', iCity);
    setValue('postalCode', iPostalCode);
    setValue('countryCode', iCountryCode);
  }, [userResult]);

  const watchBirthDate = watch('birthDate');
  const watchFile = watch('file');

  const [mutateFileSave] = useMutation(saveFileMutation, {
    client: RegistryClient,
    update(cache, { data }) {
      const { saveFile } = data;
      cache.modify({
        id: `User:${userId}`,
        fields: {
          avatar() {
            const newAvatarFrag = cache.writeFragment({
              data: saveFile,
              fragment: gql`
                fragment NewRef on UserFiles {
                  id
                  uid
                  userUid
                  fileCategory
                  storage
                  __typename
                }
              `,
            });

            return newAvatarFrag;
          },
        },
      });
    },
  });

  useEffect(() => {
    if (watchBirthDate) {
      const computeAge = getAge(watchBirthDate);

      setValue('age', computeAge);
    }

    if (watchFile) {
      upload(watchFile, userUid).then((result) => {
        const { data } = result;

        mutateFileSave({
          variables: { userUid, fileCategory: 'USER_AVATAR', storage: { ...data } },
        });
      });
    }
  }, [watchBirthDate, watchFile]);

  const [mutateUpdate] = useMutation(updateProfileMutation, {
    client: RegistryClient,
    onCompleted: () => {
      navigate('/tutor/my-profile');
    },
  });

  const submitForm = useCallback((data) => {
    const firstName = _.has(data, 'firstName') ? data.firstName : null;
    const middleInitial = _.has(data, 'middleInitial') ? data.middleInitial : null;
    const lastName = _.has(data, 'lastName') ? data.lastName : null;
    const birthDate = _.has(data, 'birthDate') ? data.birthDate : null;
    const mobile = _.has(data, 'mobile') ? data.mobile : null;
    const gender = _.has(data, 'gender') ? data.gender : null;
    const address1 = _.has(data, 'address1') ? data.address1 : null;
    const address2 = _.has(data, 'address2') ? data.address2 : null;
    const city = _.has(data, 'city') ? data.city : null;
    const postalCode = _.has(data, 'postalCode') ? data.postalCode : null;
    const countryCode = _.has(data, 'countryCode') ? data.countryCode : null;

    mutateUpdate({
      variables: {
        userUid,
        firstName,
        middleInitial,
        lastName,
        birthDate,
        gender,
        mobile,
        address: {
          address1, address2, city, postalCode, countryCode,
        },
        updatedBy: userUid,
      },
    });
  }, []);

  return (
    <DashboardTemplate>
      <Card>
        <Card.Body>
          <Form noValidate onSubmit={handleSubmit(submitForm)}>
            <ProfileForm {...formPayload} avatar={avatar} />
            <div className="submit-section">
              <button type="submit" className="btn btn-primary submit-btn">Save Changes</button>
              <button type="button" className="btn btn-link" onClick={() => navigate(-1)}>Cancel</button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </DashboardTemplate>
  );
}
