import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPesoSign } from '@fortawesome/free-solid-svg-icons';
import { GET_VOUCHER } from './gql';
import PaymentClient from '../../../PaymentClient';
import { LoadingSpinner } from '../../../components';
import VoucherStatus from './voucherStatus';

export default function view({ voucherUid }) {
  const { loading, data, error } = useQuery(GET_VOUCHER, {
    client: PaymentClient,
    variables: { uid: voucherUid },
  });

  const dataResult = _.has(data, 'getVoucher') ? data.getVoucher : null;
  const code = _.has(dataResult, 'code') ? dataResult.code : null;
  const description = _.has(dataResult, 'description') ? dataResult.description : null;
  const amount = _.has(dataResult, 'amount') ? dataResult.amount : 0;
  const discountAmount = amount.toFixed(2);
  const startDate = _.has(dataResult, 'startDate') ? dataResult.startDate : null;
  const endDate = _.has(dataResult, 'endDate') ? dataResult.endDate : null;
  const requirement = _.has(dataResult, 'requirement') ? dataResult.requirement : 0;
  const totalUsed = _.has(dataResult, 'totalUsed') ? dataResult.totalUsed : 0;
  const totalRemaining = requirement - totalUsed;
  const start = moment(startDate).isValid() && moment(startDate).format('L');
  const end = moment(endDate).isValid() && moment(endDate).format('L');
  const status = _.has(dataResult, 'status') ? dataResult.status : null;

  if (loading) {
    return <div className="text-center"><LoadingSpinner /></div>;
  }

  if (error) {
    return <div><p className="text-center text-danger">Something went wrong!</p></div>;
  }

  return (
    <Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Code</p>
        </Col>
        <Col>
          <p>{code}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Discount</p>
        </Col>
        <Col>
          <p>
            {' '}
            <FontAwesomeIcon icon={faPesoSign} />
            {discountAmount}
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Duration</p>
        </Col>
        <Col>
          <p>{`${start} - ${end}`}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Requirement</p>
        </Col>
        <Col>
          <p>{requirement}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Remaining</p>
        </Col>
        <Col>
          <p>{totalRemaining}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Status</p>
        </Col>
        <Col>
          <VoucherStatus status={status} />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <p className="text-muted">Description</p>
        </Col>
        <Col>
          <p>{description}</p>
        </Col>
      </Row>
    </Row>
  );
}
