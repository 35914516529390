import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
import { string } from 'prop-types';
import { GET_TOTAL_TEACHERING_HOURS } from './qql';
import { LoadingSpinner } from '../../../components';

export default function TotalTeacheringHours({ tutorUid }) {
  const { data, loading } = useQuery(GET_TOTAL_TEACHERING_HOURS, {
    variables: {
      tutorUid,
    },
  });
  const result = _.has(data, 'getTeacheringTotalHours') ? data.getTeacheringTotalHours : 0;

  return (
    <div className="dash-widget" style={{ height: '100%' }}>
      <div className="circle-bar">
        <div className="icon-col">
          <i className="fas fa-clock" />
        </div>
      </div>
      <div className="dash-widget-info">
        <h3>
          {!loading ? result : <LoadingSpinner text=''/>}
        </h3>
        <h6>Total Tutoring Hours </h6>
      </div>
    </div>
  );
}

TotalTeacheringHours.propTypes = {
  tutorUid: string.isRequired,
};
