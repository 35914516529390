import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CustomTable } from '../../../components';
import { getPaginatedTutorsPayrollsQuery } from './gql';
import PaymentClient from '../../../PaymentClient';

export default function Index() {
  const [payrolls, setPayrolls] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const payrollUid = _.has(state, 'uid') ? state.uid : null;

  const {
    loading: loadingTutors,
    data: payrollResult,
  } = useQuery(getPaginatedTutorsPayrollsQuery, {
    client: PaymentClient,
    variables: { payrollUid, page, pageSize },
    pollInterval: 5000
  });

  useEffect(() => {
    setLoading(loadingTutors);
  }, [loadingTutors]);

  useEffect(() => {
    async function doParse() {
      setLoading(true);
      const result = _.has(payrollResult, 'getPaginatedTutorsPayrolls') ? payrollResult.getPaginatedTutorsPayrolls : null;
      const iCount = _.has(result, 'count') ? result.count : 0;
      const rows = _.has(result, 'rows') ? result.rows : [];

      setCount(iCount);
      setPayrolls(rows);
      setLoading(false);
    }

    if (payrollResult) {
      doParse();
    }
  }, [payrollResult, setCount, setPayrolls]);

  const columns = useMemo(() => [
    {
      title: 'Tutor',
      dataKey: 'tutor',
      width: '20%',
    },
    {
      title: 'Exclusive Class #',
      dataKey: 'oneOnOne',
      width: '5%',
    },
    {
      title: 'Group Class #',
      dataKey: 'group',
      width: '10%',
    },
    {
      title: 'Gross Pay',
      dataKey: 'grossPay',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Adjustments',
      dataKey: 'incentives',
      width: '10%',
      render: (incentives, row) => {
        const deductions = _.has(row, 'deductions') ? parseFloat(row.deductions) : 0;
        const additionals = incentives ? parseFloat(incentives) : 0;
        const total = additionals - deductions;

        return <PesoValue amount={total.toFixed(2)} />;
      },
    },
    {
      title: 'Withholding',
      dataKey: 'withholding',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Learnlive Fees',
      dataKey: 'fees',
      width: '15%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'VAT(12%)',
      dataKey: 'vat',
      width: '5%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'Net Pay',
      dataKey: 'netPay',
      width: '10%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: '',
      dataKey: 'uid',
      width: '5%',
      render: (uid) => <ViewDetails uid={uid} />,
    },
  ]);

  const onPageChange = useCallback((current) => {
    setPage(current);
  }, [setPage]);

  return (
    <CustomTable
      columns={columns}
      dataValues={payrolls}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      totals={count}
      loading={loading}
    />
  );
}

function ViewDetails(payload) {
  const navigate = useNavigate();
  const { uid } = payload;

  const onView = useCallback(() => {
    navigate('view', { state: { uid } });
  });

  return (
    <Button variant="link" onClick={onView}>
      <FontAwesomeIcon icon={solid('eye')} />
      {' '}
      View
    </Button>
  );
}

function PesoValue(props) {
  const { amount } = props;

  return (
    <>
      <FontAwesomeIcon icon={solid('peso-sign')} />
      {' '}
      {amount}
    </>
  );
}
