import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import _ from 'lodash';
import { CustomTable, Ratings } from '../../../components';
import { GET_PAGINATED_TUTOR_RATINGS } from './gql';

export default function reviews() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const { data, loading } = useQuery(GET_PAGINATED_TUTOR_RATINGS, {
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
    },
  });

  const result = _.has(data, 'getPaginatedTutorRatings') ? data.getPaginatedTutorRatings : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'Tutor',
      dataKey: 'tutorUid',
      render: (text, row) => {
        const tutorDetails = _.has(row, 'tutor') ? row.tutor : null;
        const others = _.has(tutorDetails, 'others') ? tutorDetails.others : null;
        const firstName = _.has(others, 'firstName') ? others.firstName : null;
        const lastName = _.has(others, 'lastName') ? others.lastName : null;

        return (
          <div style={{ width: 200 }}>

            <span>
              {firstName}
              {' '}
              {lastName}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Review',
      dataKey: 'review',
      render: (text) => <div style={{ width: 350 }}>{text}</div>,
    },
    {
      title: 'Rating',
      dataKey: 'ratings',
      render: (text) => <div style={{ width: 180 }}><Ratings readonly defaultValues={text} /></div>,

    },
  ];

  return (
    <CustomTable
      loading={loading}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totals={rowCount}
      dataValues={dataResult}
      onPageChange={(nPage) => setPage(nPage)}
    />
  );
}
