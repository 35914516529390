/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner, CustomTable } from '../../../components';
import PaymentClient from '../../../PaymentClient';
import RegistryClient from '../../../RegistryClient';
import { getTutorPayrollQuery, getUserQuery } from './gql';

const ModalContext = createContext();

export default function Index(payload) {
  const { uid } = payload;
  const { state } = useLocation();
  const tutorPayrollUid = _.has(state, 'tutorPayrollUid') ? state.tutorPayrollUid : null;
  const [visible, setVisible] = useState(false);
  const [tutorName, setTutorName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payPeriod, setPayPeriod] = useState(null);
  const [payrollItems, setPayrollItems] = useState([]);
  const [totalGross, setTotalGross] = useState(null);
  const [withholding, setWithholding] = useState(null);
  const [totalFees, setTotalFees] = useState(null);
  const [totalNet, setTotalNet] = useState(null);
  const [totalIncentives, setTotalIncentives] = useState(null);
  const [totalDeductions, setTotalDeductions] = useState(null);
  const [birForm, setBirForm] = useState(null);
  const [payrollAdjustments, setPayrollAdjustments] = useState([]);
  const [subTotalGross, setSubTotalGross] = useState(null);
  const [vat, setVat] = useState(null);
  const [vatPercent, setVatPercent] = useState(null);

  const clearLocationState = () => {
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    if (tutorPayrollUid === uid) { onShow(); }
  }, [tutorPayrollUid]);

  const onClose = () => {
    setVisible(false);
    clearLocationState();
  };

  const onShow = () => {
    setVisible(true);
  };

  const {
    loading: loadingTutorPayroll,
    data: payrollTutorResult,
  } = useQuery(getTutorPayrollQuery, {
    client: PaymentClient,
    variables: { uid },
  });

  useEffect(() => {
    setLoading(loadingTutorPayroll);
  }, [loadingTutorPayroll]);

  useEffect(() => {
    if (payrollTutorResult) {
      const tutorPayroll = _.has(payrollTutorResult, 'getTutorPayroll')
        ? payrollTutorResult.getTutorPayroll : null;
      const iTutor = _.has(tutorPayroll, 'tutor') ? tutorPayroll.tutor : null;
      const iPayrollItems = _.has(tutorPayroll, 'payrollItems') ? tutorPayroll.payrollItems : [];
      const subTotalGrossPay = _.has(tutorPayroll, 'subTotalGrossPay') ? parseFloat(tutorPayroll.subTotalGrossPay).toFixed(2) : null;
      const grossPay = _.has(tutorPayroll, 'grossPay') ? parseFloat(tutorPayroll.grossPay).toFixed(2) : null;
      const iVat = _.has(tutorPayroll, 'vat') ? parseFloat(tutorPayroll.vat).toFixed(2) : null;
      const iVatPercent = _.has(tutorPayroll, 'vatPercent') ? parseFloat(tutorPayroll.vatPercent) : null;
      const iWithholding = _.has(tutorPayroll, 'withholding') ? parseFloat(tutorPayroll.withholding).toFixed(2) : null;
      const fees = _.has(tutorPayroll, 'fees') ? parseFloat(tutorPayroll.fees).toFixed(2) : null;
      const net = _.has(tutorPayroll, 'netPay') ? parseFloat(tutorPayroll.netPay).toFixed(2) : null;
      const iIncentives = _.has(tutorPayroll, 'incentives') ? parseFloat(tutorPayroll.incentives).toFixed(2) : null;
      const iDeductions = _.has(tutorPayroll, 'deductions') ? parseFloat(tutorPayroll.deductions).toFixed(2) : null;
      const iBirForm = _.has(tutorPayroll, 'birForm') ? tutorPayroll.birForm : null;
      const storage = _.has(iBirForm, 'storage') ? iBirForm.storage : null;
      const publicUrl = _.has(storage, 'publicUrl') ? storage.publicUrl : null;
      const iPayroll = _.has(tutorPayroll, 'payroll') ? tutorPayroll.payroll : null;
      const iStartDate = _.has(iPayroll, 'startDate') ? iPayroll.startDate : null;
      const iEndDate = _.has(iPayroll, 'endDate') ? iPayroll.endDate : null;
      const iPayPeriod = `${moment(iStartDate).format('ll')} to ${moment(iEndDate).format('ll')}`;
      const adjustments = _.has(tutorPayroll, 'payrollAdjustments') ? tutorPayroll.payrollAdjustments : null;

      setTutorName(iTutor);
      setPayrollItems(iPayrollItems);
      setSubTotalGross(subTotalGrossPay)
      setTotalGross(grossPay);
      setVat(iVat)
      setVatPercent(iVatPercent)
      setWithholding(iWithholding);
      setTotalFees(fees);
      setTotalNet(net);
      setTotalIncentives(iIncentives);
      setTotalDeductions(iDeductions);
      setBirForm(publicUrl);
      setPayPeriod(iPayPeriod);
      setPayrollAdjustments(adjustments);
    }
  }, [
    payrollTutorResult,
    setTutorName,
    setPayrollItems,
  ]);

  const viewBir = useCallback(() => {
    if (birForm) { window.open(birForm, '_blank'); }
  }, [birForm]);

  const contextPayload = useMemo(() => ({
    loading,
    tutorName,
    payPeriod,
    payrollItems,
    totalGross,
    withholding,
    totalFees,
    totalNet,
    totalIncentives,
    totalDeductions,
    payrollAdjustments,
    subTotalGross,
    vat,
    vatPercent
  }), [
    loading,
    tutorName,
    payPeriod,
    payrollItems,
    totalGross,
    withholding,
    totalFees,
    totalNet,
    totalIncentives,
    totalDeductions,
    payrollAdjustments,
    subTotalGross,
    vat,
    vatPercent
  ]);

  return (
    <ModalContext.Provider value={contextPayload}>
      <Button variant="link" block onClick={onShow}>
        <FontAwesomeIcon icon={solid('eye')} />
        {' '}
        View
      </Button>

      <Modal
        show={visible}
        onHide={onClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Payroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayrollDetails />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>

          {birForm && (
            <Button variant="primary" onClick={viewBir}>
              <FontAwesomeIcon icon={solid('file-pdf')} />
              {' '}
              View BIR Form
            </Button>
          )}

        </Modal.Footer>
      </Modal>
    </ModalContext.Provider>
  );
}

function PayrollDetails() {
  const {
    loading,
    tutorName,
    payPeriod,
    payrollItems,
    subTotalGross,
    totalGross,
    withholding,
    vat,
    vatPercent,
    payrollAdjustments,
    totalFees,
    totalNet,
    totalIncentives,
    totalDeductions,
  } = useContext(ModalContext);

  return (
    <div>
      {
        loading ? <LoadingSpinner /> : (
          <dl className="dl-horizontal">
            <dt>Tutor</dt>
            <dd><p className="lead">{tutorName}</p></dd>

            <dt>Pay Period</dt>
            <dd><p className="lead">{payPeriod}</p></dd>

            <dt>One-On-One Sessions</dt>
            <dd><SingleSessionTable rows={payrollItems} /></dd>

            <dt>Group Sessions</dt>
            <dd><GroupSessionTable rows={payrollItems} /></dd>

            <dt>Adjustments</dt>
            <dd><AdjustmentsTable rows={payrollAdjustments} /></dd>

            <dt>Subtotal Gross Pay</dt>
            <dd><PesoValue amount={subTotalGross} /></dd>

            <dt>VAT ({vatPercent}%)</dt>
            <dd><PesoValue amount={`${vat && '-' + vat}`} /></dd>

            <dt>Total Incentives</dt>
            <dd><PesoValue amount={`${totalIncentives && '+' + totalIncentives}`} /></dd>

            <dt>Total Deductions</dt>
            <dd><PesoValue amount={`${totalDeductions && '-' + totalDeductions}`} /></dd>

            <dt>Total Learnlive Fees</dt>
            <dd>
              <dd><PesoValue amount={`${totalFees && '-' + totalFees}`} /></dd>
            </dd>
            <hr />

            <dt>Total Gross Pay</dt>
            <dd><PesoValue amount={totalGross} /></dd>

            <dt>Total Withholding Tax</dt>
            <dd>
              <PesoValue amount={`${withholding && '-' + withholding}`} />
            </dd>
            <hr />

            <dt>Total Net Pay</dt>
            <dd>
              <PesoValue amount={totalNet} />
            </dd>
          </dl>
        )
      }
    </div>
  );
}

function SingleSessionTable(payload) {
  const rows = _.has(payload, 'rows') ? payload.rows : [];
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const filtered = _.filter(rows, (row) => {
      const doc = _.has(row, 'document') ? row.document : null;
      const type = _.has(doc, 'sessionType') ? doc.sessionType : null;

      if (type === 'SINGLE') { return true; } return false;
    });

    const data = _.map(filtered, (row) => {
      const document = _.has(row, 'document') ? row.document : null;
      const iStart = _.has(document, 'startDate') ? moment(document.startDate).format('YYYY/MM/DD HH:mm') : null;
      const iEnd = _.has(document, 'endDate') ? moment(document.endDate).format('YYYY/MM/DD HH:mm') : null;
      const schedule = `${iStart} to ${iEnd}`;
      const baseRate = _.has(row, 'amount') ? row.amount : null;
      const grossPay = _.has(row, 'grossPay') ? row.grossPay : null;
      const fees = _.has(row, 'fees') ? row.fees : null;
      const vat = _.has(row, 'vat') ? row.vat : null;
      const netPay = _.has(row, 'netPay') ? row.netPay : null;
      const paidBy = _.has(row, 'paidBy') ? row.paidBy : null;

      return {
        schedule,
        baseRate,
        grossPay,
        fees,
        vat,
        netPay,
        paidBy,
      };
    });

    setPayments(data);
  }, [rows]);

  const columns = useMemo(() => [
    {
      title: 'Student',
      dataKey: 'paidBy',
      width: '20%',
      render: (value) => <PaidBy userUid={value} />,
    },
    {
      title: 'Schedule',
      dataKey: 'schedule',
      width: '5%',
    },
    {
      title: 'Base Rate',
      dataKey: 'baseRate',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Gross Pay',
      dataKey: 'grossPay',
      width: '15%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Learnlive Fees',
      dataKey: 'fees',
      width: '20%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'VAT(12%)',
      dataKey: 'vat',
      width: '5%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'Net Pay',
      dataKey: 'netPay',
      width: '10%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={payments}
    />
  );
}

function GroupSessionTable(payload) {
  const rows = _.has(payload, 'rows') ? payload.rows : [];
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const filtered = _.filter(rows, (row) => {
      const doc = _.has(row, 'document') ? row.document : null;
      const type = _.has(doc, 'sessionType') ? doc.sessionType : null;

      if (type === 'GROUP') { return true; } return false;
    });

    const data = _.map(filtered, (row) => {
      const document = _.has(row, 'document') ? row.document : null;
      const iStart = _.has(document, 'startDate') ? moment(document.startDate).format('YYYY/MM/DD HH:mm') : null;
      const iEnd = _.has(document, 'endDate') ? moment(document.endDate).format('YYYY/MM/DD HH:mm') : null;
      const schedule = `${iStart} to ${iEnd}`;
      const baseRate = _.has(row, 'amount') ? row.amount : null;
      const grossPay = _.has(row, 'grossPay') ? row.grossPay : null;
      const fees = _.has(row, 'fees') ? row.fees : null;
      const vat = _.has(row, 'vat') ? row.vat : null;
      const netPay = _.has(row, 'netPay') ? row.netPay : null;
      const paidBy = _.has(row, 'paidBy') ? row.paidBy : null;

      return {
        schedule,
        baseRate,
        grossPay,
        fees,
        vat,
        netPay,
        paidBy,
      };
    });

    setPayments(data);
  }, [rows]);

  const columns = useMemo(() => [
    {
      title: 'Student',
      dataKey: 'paidBy',
      width: '20%',
      render: (value) => <PaidBy userUid={value} />,
    },
    {
      title: 'Schedule',
      dataKey: 'schedule',
      width: '5%',
    },
    {
      title: 'Base Rate',
      dataKey: 'baseRate',
      width: '10%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Gross Pay',
      dataKey: 'grossPay',
      width: '15%',
      render: (value) => <PesoValue amount={value.toFixed(2)} />,
    },
    {
      title: 'Learnlive Fees',
      dataKey: 'fees',
      width: '20%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'VAT(12%)',
      dataKey: 'vat',
      width: '5%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
    {
      title: 'Net Pay',
      dataKey: 'netPay',
      width: '10%',
      render: (value) => {
        const amount = parseFloat(value).toFixed(2);

        return <PesoValue amount={amount} />;
      },
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={payments}
    />
  );
}

function AdjustmentsTable(payload) {
  const rows = _.has(payload, 'rows') ? payload.rows : [];

  const columns = useMemo(() => [
    {
      title: 'Amount',
      dataKey: 'amount',
      render: (value, row) => {
        const type = _.has(row, 'type') ? row.type : null;
        let negative = false;

        if (type === 'DEDUCTION') {
          negative = true;
        }
        return <PesoValue amount={value} negative={negative} />;
      },
    },
    {
      title: 'Type',
      dataKey: 'type',
    },
    {
      title: 'Additional Name',
      dataKey: 'document',
      render: (document) => {
        const name = _.has(document, 'name') ? document.name : null;

        return name;
      },
    },
  ]);

  return (
    <CustomTable
      columns={columns}
      dataValues={rows}
    />
  );
}

function PesoValue(props) {
  const { amount } = props;

  return (
    <>
      <FontAwesomeIcon icon={solid('peso-sign')} />
      {' '}
      {amount}
    </>
  );
}

function PaidBy(props) {
  const { userUid } = props;
  const [fullName, setFullName] = useState(null);

  const { loading, data } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !userUid,
    variables: { uid: userUid },
  });

  useEffect(() => {
    if (data) {
      const user = _.has(data, 'getUser') ? data.getUser : null;
      const profile = _.has(user, 'userProfile') ? user.userProfile : null;
      const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
      const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
      const iFullName = `${firstName} ${lastName}`;

      setFullName(iFullName);
    }
  }, [data]);

  return (
    <>
      <FontAwesomeIcon icon={solid('user')} />
      {' '}
      {loading ? <LoadingSpinner /> : fullName}
    </>
  );
}
