import React, { useState } from 'react';
import {
  Offcanvas, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import VoucherList from './voucherList';

export default function Index({ onSelect }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSelect = (value) => {
    if (onSelect) {
      onSelect(value);
    }
    setShow(false);
  };

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        Browse available vouchers
      </Button>

      <Offcanvas show={show} onHide={handleClose} style={{ zIndex: 10000 }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Available Vouchers</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <VoucherList onSelect={handleSelect} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

Index.defaultProps = {
  onSelect: null,
};

Index.propTypes = {
  onSelect: PropTypes.instanceOf(Function),
};
