import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { GET_PAGINATED_COMMISSIONS } from './gql';
import { CustomTable } from '../../../components';
import { Button } from 'react-bootstrap'
import CommissionModal from './commissionModal'

export default function commissionList({ roleCode, type, userUid, firstName, lastName }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const { loading, data } = useQuery(GET_PAGINATED_COMMISSIONS, {
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      filter: {
        roleCode,
        type,
        userUid,
      },
    },
  });

  const columns = [
    {
      title: 'Commission Rate',
      dataKey: 'percentage',
      render: (text) => {
        return <span>
          {text}
          %
        </span>
      }
    },
    {
      title: 'Effectivity Date',
      dataKey: 'effectivityDate',
      render: (text, record) => {
        const effectivityDate = _.has(record, 'effectivityDate') ? record.effectivityDate : null;

        return moment(effectivityDate).isValid() && moment(effectivityDate).format('LL');
      },
    },
    {
      title: 'Action',
      dataKey: 'uid',
      render: (text, record) => {
        const uid = _.has(record, 'uid') ? record.uid : null
        const effectivityDate = _.has(record, 'effectivityDate') ? record.effectivityDate : null;
        const percentage = _.has(record, 'percentage') ? record.percentage : null;

        const today = new Date()
        if (new Date(effectivityDate) <= today) {
          return <Button disabled>Edit</Button>
        }

        return <CommissionModal
          roleCode={roleCode}
          userUid={userUid}
          uid={uid}
          firstName={firstName}
          lastName={lastName}
          initialValues={{
            percentage,
            effectivityDate
          }}
        />
      }
    }
  ];

  const result = _.has(data, 'getPaginatedCommissions') ? data.getPaginatedCommissions : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  return (
    <CustomTable
      loading={loading}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totals={rowCount}
      dataValues={dataResult}
      onPageChange={(nPage) => setPage(nPage)}
    />
  );
}
