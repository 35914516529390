import { getAuth, connectAuthEmulator } from 'firebase/auth';
import app from './firebaseInit';

const ENABLED = process.env.REACT_APP_AUTH_EMULATOR || false;
const HOST = process.env.REACT_APP_AUTH_EMULATOR_HOST || 'http://localhost:9099';

const auth = getAuth(app);

if (ENABLED === 'true') {
  connectAuthEmulator(auth, HOST);
}

export default auth;
