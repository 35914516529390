import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Error403 from '../../template/components/error403';
import LoginContext from '../login/login.context';
import PaymentProvider from './payment.context';

export { default as CashIn } from './cashin';
export { default as CashInCharge } from './cashin/paymongo/charge';
export { default as CashInChargeCC } from './cashin/paymongo/charge-cc';
export { default as Checkout } from './checkout';
export { default as Invoice } from './invoice';
export { default as Wallet } from './wallet';
export { default as ChargeCC } from './charge/cc';
export { default as ChargeCC3D } from './charge/cc3d';

export default function Index() {
  const { roleCode } = useContext(LoginContext);

  if (roleCode !== 'TUTEE' && roleCode !== 'TUTOR') {
    return <Error403 />;
  }

  return (
    <PaymentProvider>
      <Outlet />
    </PaymentProvider>
  );
}
