/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button, Modal, Card, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { SessionRowContext } from './sessions.context';
import { getAttendanceQuery, joinSessionMutation } from './gql';
import { LoginContext } from '../../login';
import useNotification from '../../notification/useNotification';

function redirectBlank(url) {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  a.click();
}

export default function Index() {
  const [view, setView] = useState(false);
  const { instanceUid, userUid } = useContext(LoginContext);
  const navigate = useNavigate();
  const { sendNotification } = useNotification({ userUid });

  const {
    sessionUid,
    interest,
    sessionType,
    canJoin,
    link,
    status,
    tutorUserUid,
    studentFullname,
  } = useContext(SessionRowContext);

  const handleShow = useCallback(() => {
    setView(true);
  }, [sessionUid]);

  const handleClose = useCallback(() => {
    setView(false);
  }, [sessionUid]);

  const [mutateJoin, { loading }] = useMutation(joinSessionMutation, {
    onCompleted: () => {
      // sendNotification to teacher
      sendNotification({
        title: 'Session',
        message: `${studentFullname} joined session.`,
        userUids: [tutorUserUid],
        data: {
          urlPath: '/session',
          urlState: { sessionUid },
        },
      });

      redirectBlank(link);
      handleClose();
      navigate('/session', { state: { sessionUid } });
    },
    refetchQueries: [
      {
        query: getAttendanceQuery,
        variables: { instanceUid, sessionUid },
      },
    ],
  });

  const onJoin = useCallback(() => {
    mutateJoin({
      variables: {
        sessionUid,
        instanceUid,
      },
    });
  }, [link]);

  const AlertMessage = status === 'COMPLETED' ? 'Session already completed!' : 'Session has not yet started by the tutor.';
  return (
    <>
      <Button
        className="text-warning"
        variant="link"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={solid('play')} />
        {' '}
        Join
      </Button>

      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Join Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              {
                !canJoin && (
                  <Alert variant="danger">
                    <Alert.Heading>Join Disabled</Alert.Heading>
                    {AlertMessage}
                  </Alert>
                )
              }
              <Card.Title>{interest}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{sessionType}</Card.Subtitle>

              <Card.Text>
                Are you really sure to start this session?
              </Card.Text>

              <Card.Text>
                Hitting join button will confirm your attendance.
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={onJoin}
            disabled={loading || !canJoin}
          >
            {loading ? 'Loading...' : 'Join'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
