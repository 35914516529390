import React, {
  createContext,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import RegistryClient from '../../../../RegistryClient';
import { getUserQualificationQuery, saveQualificationsMutation } from './gql';
import { LoginContext } from '../../../login';
import { getUserQuery } from '../gql';

const FormContext = createContext();

export default function Index() {
  const { userId, userUid } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const formPayload = useForm();
  const {
    formState, control, handleSubmit, watch, setValue,
  } = formPayload;
  const { errors } = formState;
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'educations',
    rules: { minLength: 1, required: 'Atleast one entry is required!' },
  });
  const [canSave, setCanSave] = useState(false);

  const { data: qualificationResult } = useQuery(getUserQualificationQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { userUid },
  });

  useEffect(() => {
    const result = _.has(qualificationResult, 'getUserQualification') ? qualificationResult.getUserQualification : null;
    const iEducations = _.has(result, 'educations') ? result.educations : [];

    if (iEducations && iEducations.length) {
      setValue('educations', iEducations);
    }
  }, [qualificationResult]);

  const handleClose = useCallback(() => {
    setVisible(false);
  });

  const handleShow = useCallback(() => {
    setVisible(true);
  });

  const handleAppend = useCallback(() => {
    append({
      school: null,
      degree: null,
      degreeEarned: null,
      yearGraduated: null,
      schoolAddress: null,
    });
  });

  const [mutateSave, { loading }] = useMutation(saveQualificationsMutation, {
    client: RegistryClient,
    awaitRefetchQueries: true,
    // update percentage progress accross the system
    refetchQueries: [
      {
        query: getUserQuery,
        client: RegistryClient,
        variables: { uid: userUid },
      }
    ],
    onCompleted: () => {
      handleClose();
    },
    update(cache, { data: { saveQualifications: qualification } }) {
      cache.modify({
        id: `User:${userId}`,
        fields: {
          qualification: () => qualification,
        },
      });
    },
  });

  const onSubmit = useCallback((data) => {
    mutateSave({
      variables: {
        userUid,
        ...data,
      },
    });
  }, [userUid, mutateSave]);

  const triggerSubmit = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit]);

  const contextPayload = useMemo(() => ({
    control,
    errors,
    remove,
  }), [
    control,
    errors,
    remove,
  ]);

  const watchEducations = watch('educations');

  useEffect(() => {
    if (watchEducations && !watchEducations.length) {
      setCanSave(false);
    } else { setCanSave(true); }
  }, [watchEducations]);

  return (
    <FormContext.Provider value={contextPayload}>
      <Button variant="link" onClick={handleShow}>
        <FontAwesomeIcon icon={regular('edit')} />
      </Button>

      <Modal show={visible} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Qualification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group as={Col} sm={12} controlId="reg.education" hasValidation>
              <Form.Label>Schools</Form.Label>
              {
                fields.map((field, index) => (
                  <FormItem index={index} field={field} />
                ))
              }
              {/* <Form.Control.Feedback type="invalid">
                {educError || 'Invalid school.'}
              </Form.Control.Feedback> */}
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleAppend} disabled={loading}>
            Add Education
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={triggerSubmit} disabled={loading || !canSave}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </FormContext.Provider>
  );
}

function FormItem(payload) {
  const { control, errors, remove } = useContext(FormContext);
  const { index, field: item } = payload;
  const { educations } = errors;
  const errorRow = educations && educations[index];

  const onRemove = useCallback(() => {
    remove(index);
  });

  return (
    <StyledDiv>
      <Row className="mb-3">
        <Form.Group as={Col} sm={6} controlId="reg.education">
          <Form.Label>School Name</Form.Label>
          <Controller
            key={item.id}
            name={`educations.${index}.school`}
            control={control}
            rules={{ required: 'Field is required.' }}
            render={({ field }) => (
              <Form.Control
                isInvalid={!!_.has(errorRow, 'school')}
                {...field}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errorRow, 'school') ? errorRow.school.message : 'Invalid school.'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm={6} controlId="reg.education">
          <Form.Label>Degree</Form.Label>
          <Controller
            name={`educations.${index}.degree`}
            control={control}
            rules={{ required: 'Field is required.' }}
            render={({ field }) => (
              <Form.Select
                isInvalid={!!_.has(errorRow, 'degree')}
                {...field}
              >
                <option value={undefined}>Select Degree</option>
                <option value="COLLEGE">College / Bachelor's Degree</option>
                <option value="MASTERS">Master's Degree</option>
                <option value="DOCTORATE">Doctorate Degree</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errorRow, 'school') ? errorRow.school.message : 'Invalid school.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} sm={6} controlId="reg.degreeEarned">
          <Form.Label>Degree Earned</Form.Label>
          <Controller
            name={`educations.${index}.degreeEarned`}
            control={control}
            rules={{ required: 'Field is required.' }}
            render={({ field }) => (
              <Form.Control
                autoFocus
                isInvalid={!!_.has(errorRow, 'degreeEarned')}
                {...field}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errorRow, 'degreeEarned') ? errorRow.degreeEarned.message : 'Invalid degree earned.'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm={6} controlId="reg.yearGraduated">
          <Form.Label>Year Graduated</Form.Label>
          <Controller
            name={`educations.${index}.yearGraduated`}
            control={control}
            rules={{ required: 'Field is required.' }}
            render={({ field }) => (
              <Form.Control
                autoFocus
                isInvalid={!!_.has(errorRow, 'yearGraduated')}
                {...field}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errorRow, 'yearGraduated') ? errorRow.yearGraduated.message : 'Invalid year graduated.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} sm={12} controlId="reg.schoolAddress">
          <Form.Label>Address</Form.Label>
          <Controller
            name={`educations.${index}.schoolAddress`}
            control={control}
            rules={{ required: 'Field is required.' }}
            render={({ field }) => (
              <Form.Control
                autoFocus
                isInvalid={!!_.has(errorRow, 'schoolAddress')}
                {...field}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {_.has(errorRow, 'schoolAddress') ? errorRow.schoolAddress.message : 'Invalid school address.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Button variant="link" onClick={onRemove}>
        <FontAwesomeIcon icon={solid('trash')} />
        {' '}
        Remove
      </Button>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  border-bottom: 1px solid;
  margin-bottom: 16px;
`;
