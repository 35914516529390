export default [
  { code: 'KINDER', label: 'Kinder' },
  { code: 'GRADE_1', label: 'Grade 1' },
  { code: 'GRADE_2', label: 'Grade 2' },
  { code: 'GRADE_3', label: 'Grade 3' },
  { code: 'GRADE_4', label: 'Grade 4' },
  { code: 'GRADE_5', label: 'Grade 5' },
  { code: 'GRADE_6', label: 'Grade 6' },
  { code: 'GRADE_7', label: 'Grade 7' },
  { code: 'GRADE_8', label: 'Grade 8' },
  { code: 'GRADE_9', label: 'Grade 9' },
  { code: 'GRADE_10', label: 'Grade 10' },
  { code: 'GRADE_11', label: 'Grade 11' },
  { code: 'GRADE_12', label: 'Grade 12' },
];
