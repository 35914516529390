/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Modal, Card, Table, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { SessionContext, ViewSessionContext } from './sessions.context';
import { getSessionEnrolleesQuery, addSessionLinkMutation, addSessionFileMutation } from './gql';
import { UploaderContext } from '../../../modules/uploader';
import { LoginContext } from '../../login';
import SharedNotes from './sharedNotes';
import { DynamicAvatar } from '../../../components';

const VALID_URL = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

const PROFILE_LINK = '/profile';

export default function Index() {
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const [enrollees, setEnrollees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const {
    sessionUid,
    interest,
    sessionType,
    status,
  } = useContext(SessionContext);

  const { data: enrolleesResult } = useQuery(getSessionEnrolleesQuery, {
    skip: !sessionUid,
    variables: { sessionUid },
  });

  useEffect(() => {
    const result = _.has(enrolleesResult, 'getSessionEnrollees') ? enrolleesResult.getSessionEnrollees : [];

    setEnrollees(result);
  }, [enrolleesResult]);

  useEffect(() => {
    if (loading || status === 'COMPLETED') {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [loading, setCanSave, status]);

  const handleShow = useCallback(() => {
    setView(true);
  });

  const handleClose = useCallback(() => {
    setView(false);
    setSubmit(false);
  });

  const contextPayload = useMemo(() => ({
    sessionUid,
    setLoading,
    submit,
    setSubmit,
    setView,
    canSave,
  }), [
    sessionUid,
    setLoading,
    submit,
    setSubmit,
    setView,
    canSave,
  ]);

  return (
    <ViewSessionContext.Provider value={contextPayload}>
      <Button
        className="text-info"
        variant="link"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={solid('eye')} />
        {' '}
        View
      </Button>

      <Modal
        show={view}
        fullscreen
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            View Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>{interest}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{sessionType}</Card.Subtitle>

              <Table responsive>
                <thead>
                  <tr>
                    <th>STUDENTS</th>
                    <th>STATUS</th>
                  </tr>
                </thead>

                <tbody>
                  {enrollees.map((list) => <TableRow item={list} />)}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Add Link to Session</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Url link where you will conduct the live session.
                E.g.(Viber, Google Meet, Zoom, etc.)
              </Card.Subtitle>
              <AddLinkForm />
              <SharedNotes />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => navigate('/session', { state: { sessionUid } })}
          >
            View Session
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {
            canSave && (
              <Button
                variant="primary"
                onClick={() => setSubmit(true)}
              >
                {loading ? 'Loading...' : 'Save Changes'}
              </Button>
            )
          }
        </Modal.Footer>
      </Modal>
    </ViewSessionContext.Provider>
  );
}

function TableRow(payload) {
  const { item } = payload;
  const [fullName, setFullName] = useState(null);
  const [status] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [profileRoute, setProfileRoute] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tutee = _.has(item, 'tutee') ? item.tutee : null;
    const others = _.has(tutee, 'others') ? tutee.others : null;
    const firstName = _.has(others, 'firstName') ? others.firstName : null;
    const middleInitial = _.has(others, 'middleInitial') ? others.middleInitial : null;
    const lastName = _.has(others, 'lastName') ? others.lastName : null;
    const tuteeName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;
    const iUserUid = _.has(tutee, 'userUid') ? tutee.userUid : null;

    setFullName(tuteeName);
    setUserUid(iUserUid);
  }, [item]);

  useEffect(() => {
    setProfileRoute(`${PROFILE_LINK}/${userUid}`);
  }, [userUid]);

  const redirectToProfile = useCallback(() => {
    navigate(profileRoute);
  }, [profileRoute]);

  return (
    <tr>
      <td>
        <h2 className="table-avatar">
          <Link to={profileRoute}>
            <DynamicAvatar userUid={userUid} size="small" />
          </Link>

          <Button
            variant="link"
            onClick={redirectToProfile}
          >
            {fullName}
          </Button>
        </h2>
      </td>
      <td>{status}</td>
    </tr>
  );
}

function AddLinkForm() {
  const {
    sessionUid, setLoading, submit, setSubmit, setView, canSave,
  } = useContext(ViewSessionContext);
  const { sessionLink } = useContext(SessionContext);
  const { uploadFile } = useContext(UploaderContext);
  const { userUid } = useContext(LoginContext);
  const alert = useAlert();

  const {
    handleSubmit, formState, control, reset, setValue,
  } = useForm({
    defaultValues: {
      link: sessionLink,
    },
  });

  const { errors } = formState;

  const [mutateSessionFile, { loading: uploadLoading }] = useMutation(addSessionFileMutation, {
    onError: () => {
      setSubmit(false);
    },
  });

  const [mutateLink, { loading }] = useMutation(addSessionLinkMutation, {
    onCompleted: () => {
      reset();
      setView(false);
      setSubmit(false);
    },
    onError: () => {
      setSubmit(false);
    },
  });

  useEffect(() => {
    setLoading(loading || uploadLoading);
  }, [loading, uploadLoading, setLoading]);

  const submitForm = useCallback((data) => {
    const { sharedNote, ...etc } = data;
    const variables = {
      sessionUid,
      ...etc,
    };

    async function doSubmit() {
      if (sharedNote) {
        const { name: sharedNoteName } = sharedNote;

        await uploadFile({
          file: sharedNote,
          fileName: sharedNoteName,
        })
          .then(({ data: storage }) => {
            const fileCategory = 'SHARED';

            return mutateSessionFile({
              variables: {
                sessionUid,
                fileCategory,
                storage,
                createdBy: userUid,
              },
            });
          }).catch((err) => { alert.error(err.toString(), { timeout: 3000 }); });
      }

      await mutateLink({ variables })
        .catch((err) => { alert.error(err.toString(), { timeout: 3000 }); });
    }

    doSubmit();
  }, [sessionUid]);

  useEffect(() => {
    if (submit) {
      handleSubmit(submitForm)();
    }
  }, [submit]);

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      setSubmit(false);
    }
  }, [errors]);

  return (
    <Form noValidate onSubmit={() => handleSubmit(submitForm)} className="mb-3">
      <Form.Group className="form-group" controlId="session.link">
        <Form.Label>Link</Form.Label>
        <Controller
          name="link"
          control={control}
          rules={{
            required: { value: true, message: 'Link is required.' },
            pattern: {
              value: VALID_URL,
              message: 'Invalid link format.',
            },
          }}
          render={({ field }) => (
            <Form.Control
              autoFocus
              disabled={!canSave}
              isInvalid={!!_.has(errors, 'link')}
              {...field}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'link') ? errors.link.message : 'Invalid link.'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group sm={12} controlId="session.sharedNote">
        <Form.Label>Shared Notes</Form.Label>
        <Controller
          name="sharedNote"
          control={control}
          rules={{
            validate: {
              isPdf: (props) => {
                // eslint-disable-next-line react/prop-types
                const type = _.has(props, 'type') ? props.type : null;
                console.log({ type });
                if (type && type !== 'application/pdf') { return 'Invalid PDF file!'; }

                return true;
              },
            },
          }}
          render={() => (
            <Form.Control
              isInvalid={!!_.has(errors, 'sharedNote')}
              type="file"
              onChange={(e) => {
                const fileList = e.target.files;

                setValue('sharedNote', fileList[0]);
              }}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {_.has(errors, 'sharedNote') ? errors.sharedNote.message : 'Invalid file.'}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
}
