import { useQuery } from '@apollo/client';
import React from 'react';
import _ from 'lodash';
import { GET_STUDENT_SESSION_STATUS_RATE } from './gql';
import { LoadingSpinner } from '../../../components'

export default function CancellationRate({ instanceUid, userUid }) {
  const { loading, data } = useQuery(GET_STUDENT_SESSION_STATUS_RATE, {
    variables: {
      tuteeUid: instanceUid,
      userUid,
      status: 'CANCELLED',
    },
  });

  const result = _.has(data, 'getStudentSessionStatusRate') ? data.getStudentSessionStatusRate : 0;
  const percentage = _.has(result, 'percentage') ? result.percentage : 0;

  return (
    <div className="dash-widget" style={{ height: '100%' }}>
      <div className="circle-bar">
        <div className="icon-col">
          <i className="fas fa-clock" />
        </div>
      </div>
      <div className="dash-widget-info">
        <h3>
          {!loading ? `${percentage}%` : <LoadingSpinner text='' />}
        </h3>
        <div>
          <h5>Cancellation Rate</h5>
        </div>
      </div>
    </div>
  );
}
