import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Error403 from '../../template/components/error403';
import LoginContext from '../login/login.context';

export { default as RegisterTutor } from './registration';
export { default as TutorDashboard } from './dashboard';
export { default as MyProfile } from './myProfile';
export { default as Timeslots } from './timeslots';
export { default as TutorSessions } from './mySessions';
export { default as CompleteProfile } from './completeProfile';
export { default as ProfileSettings } from './myProfile/settings';
export { default as TutorIssueAndResolution } from './issueAndResolution';
export { default as TutorTicketList } from './issueAndResolution/ticketList';
export { default as TutorAnalytics } from './analytics';

export { Qualifications } from './myProfile';
export { QualificationSettings } from './myProfile';

export default function Index() {
  const { roleCode } = useContext(LoginContext);

  if (roleCode !== 'TUTOR') { return <Error403 />; }

  return (
    <Outlet />
  );
}
