import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
// import Search from './search';
import SearchContext from './search.context';
import ElasticSearch from './elasticSearch';

export default function Login() {
  const loc = useLocation();
  const state = _.has(loc, 'state') ? loc.state : null;
  const location = _.has(state, 'location') ? state.location : null;
  const searchState = _.has(state, 'search') ? state.search : null;
  const [filters, setFilters] = useState([]);
  // const [search, setSearch] = useState(searchState);

  useEffect(() => {
    const iFilters = {
      priceRange: [100, 1000],
    };

    if (location) {
      Object.assign(iFilters, { location });
    }

    if (searchState) {
      Object.assign(iFilters, { search: searchState });
    }

    if (iFilters) {
      setFilters(iFilters);
    }

    window.history.replaceState({}, document.title);
  }, [location, searchState]);

  const contextPayload = useMemo(() => ({
    filters,
    setFilters,
  }), [
    filters,
    setFilters,
  ]);

  return (
    <SearchContext.Provider value={contextPayload}>
      {/* <Search /> */}
      <ElasticSearch />
    </SearchContext.Provider>
  );
}
