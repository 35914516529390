import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPesoSign } from '@fortawesome/free-solid-svg-icons';
import VoucherModalForm from './voucherModalForm';
import PaymentClient from '../../../PaymentClient';
import { GET_PAGINATED_VOUCHERS } from './gql';
import { CustomTable, AlertError } from '../../../components';
import PublishModal from './publishModal';
import DeleteModal from './deleteModal';
import VoucherStatus from './voucherStatus';

const DRAFT = 'DRAFT';

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState(null)

  const { loading, data } = useQuery(GET_PAGINATED_VOUCHERS, {
    fetchPolicy: 'network-only',
    client: PaymentClient,
    variables: {
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
      filter: {
        status: selectedStatus,
      },
    },
  });

  const result = _.has(data, 'getPaginatedVouchers') ? data.getPaginatedVouchers : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const onEdit = (item) => {
    setShowModal(true);
    setIsEdit(true);
    setSelectedItem(item);
  };

  const debounceSearch = _.debounce((value) => setSearchText(value), 500)

  const onSearch = (value) => {
    setPage(1);
    debounceSearch(value)
  };

  const columns = [
    {
      title: 'Voucher Code',
      dataKey: 'code',
    },
    {
      title: 'Discount',
      dataKey: 'amount',
      render: (text, record) => {
        const amount = _.has(record, 'amount') ? record.amount : null;
        const discountAmount = amount.toFixed(2);

        return (
          <p>
            <FontAwesomeIcon icon={faPesoSign} />
            {discountAmount}
          </p>
        );
      },
    },
    {
      title: 'Duration',
      dataKey: 'duration',
      render: (text, record) => {
        const startDate = _.has(record, 'startDate') ? record.startDate : null;
        const start = moment(startDate).isValid() && moment(startDate).format('L');
        const endDate = _.has(record, 'endDate') ? record.endDate : null;
        const end = moment(endDate).isValid() && moment(endDate).format('L');
        return <span>{`${start} - ${end}`}</span>;
      },
    },
    {
      title: 'Requirement',
      dataKey: 'requirement',
    },
    {
      title: 'Total Used',
      dataKey: 'totalUsed',
    },
    {
      title: 'Remaining',
      dataKey: 'remaining',
      render: (text, record) => {
        const requirement = _.has(record, 'requirement') ? record.requirement : 0;
        const totalUsed = _.has(record, 'totalUsed') ? record.totalUsed : 0;
        const totalRemaining = requirement - totalUsed;
        return <span>{totalRemaining}</span>;
      },
    },
    {
      title: 'Status',
      dataKey: 'status',
      render: (text, record) => {
        const status = _.has(record, 'status') ? record.status : null;
        return <VoucherStatus status={status} />;
      },
    },
    {
      title: 'Action',
      dataKey: 'uid',
      render: (text, record) => {
        const status = _.has(record, 'status') ? record.status : 0;
        const code = _.has(record, 'code') ? record.code : 0;
        const uid = _.has(record, 'uid') ? record.uid : 0;

        return (
          <div className="d-flex gap-2">
            {status === DRAFT && (
              <>
                <Button
                  title="Edit"
                  onClick={() => onEdit(record)}
                  variant="success"
                >
                  Edit
                </Button>
                <PublishModal data={record}
                  onError={({ title, message }) => setError({ title, message })}
                  onSuccess={() => setError(null)}
                />

              </>

            )}
            <Button
              title="View"
              variant="primary"
              onClick={() => { navigate(`${location.pathname}/view`, { state: { voucherUid: uid, voucherCode: code } }); }}
            >
              View
            </Button>
            {status !== 'DELETED' && <DeleteModal
              data={record}
              onError={({ title, message }) => setError({ title, message })}
              onSuccess={() => setError(null)}
            />}
          </div>
        );
      },
    },
  ];

  const onClickAdd = () => {
    setShowModal(true);
  };

  return (
    <div className="row">
      <h3 className="page-title mb-4">Vouchers</h3>

      <Col lg={12}>
        <div className="row mb-4 justify-content-end gap-2">
          <div className="col-lg-3">
            <div className="input-group">
              <input
                type="text"
                onChange={(e) => onSearch(e.target.value)}
                placeholder="Search Voucher"
                className="form-control"
              />
              <Button
                type="button"
                className="btn btn-primary"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-search" />

              </Button>
            </div>
          </div>
          <div className="col-lg-3 d-grid">
            <Button onClick={() => onClickAdd()} type="button">
              <i className="fa fa-plus" />
              {' '}
              Add Voucher
            </Button>
          </div>
        </div>
      </Col>

      {error && (
        <AlertError
          error={error.title}
          title={error.message}
          onClose={() => setError(null)}
        />
      )}

      <div className="card card-table">
        <div className="card-body">
          <CustomTable
            loading={loading}
            columns={columns}
            page={page}
            pageSize={pageSize}
            totals={rowCount}
            dataValues={dataResult}
            onPageChange={(nPage) => setPage(nPage)}
          />
        </div>
      </div>

      {showModal && (
        <VoucherModalForm
          isEdit={isEdit}
          initialValues={selectedItem}
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setIsEdit(false);
            setSelectedItem(null);
          }}
        />
      )}
    </div>
  );
}
