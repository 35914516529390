import React, { useState, useContext } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { AlertError } from '../../../../components';
import TicketingClient from '../../../../TicketingClient';
import { CREATE_TICKET_CATEGORY, UPDATE_TICKET_CATEGORY } from '../../../issueAndResolution/gql';
import LoginContext from '../../../login/login.context';
import useCreateAuditTrail from '../../../auditTrail/useCreateAuditTrail';

const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';

export default function categoryForm({
  show, onHide, isEdit, defaultValues,
}) {
  const { userUid } = useContext(LoginContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const { doInsertAuditTrail } = useCreateAuditTrail();

  const categoryUid = _.has(defaultValues, 'uid') ? defaultValues.uid : null;
  const name = _.has(defaultValues, 'name') ? defaultValues.name : null;
  const description = _.has(defaultValues, 'description') ? defaultValues.description : null;

  const {
    handleSubmit, control, formState, reset,
  } = useForm({
    defaultValues: {
      name,
      description,
    },
  });
  const { errors } = formState;

  const [createTicketCategory] = useMutation(
    CREATE_TICKET_CATEGORY,
    {
      client: TicketingClient,
      refetchQueries: ['GetPaginatedTicketCategory'],
    },
  );

  const [updateTicketCategory] = useMutation(
    UPDATE_TICKET_CATEGORY,
    {
      client: TicketingClient,
      refetchQueries: ['GetPaginatedTicketCategory'],
    },
  );

  const onClose = () => {
    reset();
    setSaving(false);
    if (onHide) {
      onHide();
    }
  };

  const onSubmit = (data) => {
    setSaving(true);

    if (!isEdit) {
      createTicketCategory({
        variables: {
          ...data,
          userUid,
          appCode: APPCODE,
        },
      }).then(() => {
        doInsertAuditTrail({
          action: 'CREATE',
          module: 'Issues and Resolution',
          changes: `Created Issues category from ${data.name}`,
        });

        onClose();
      }).catch((err) => {
        setSaving(false);
        setError(err.toString());
      });
    } else {
      updateTicketCategory({
        variables: {
          ...data,
          userUid,
          categoryUid,
        },
      }).then(() => {
        doInsertAuditTrail({
          action: 'UPDATE',
          module: 'Issues and Resolution',
          changes: `Updated Issues category ${name} to ${data.name}`,
        });
        onClose();
      }).catch((err) => {
        setSaving(false);
        setError(err.toString());
      });
    }
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>
            Create Ticket Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
          <AlertError
            error={error}
            title="Failed to Save Ticket Category"
            onClose={() => setError(null)}
          />
          ) }
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Controller
              name="name"
              control={control}
              rules={{
                required: 'Category name is required.',
                validate: {
                  whiteSpace: (value) => !!value.trim() || 'Category name cannot be empty.',
                  maxLength: (value) => value.length <= 255 || 'Category name character limit 255.',
                },
              }}
              render={({ field }) => (
                <Form.Control
                  autoFocus
                  isInvalid={!!_.has(errors, 'name')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'name') ? errors.name.message : 'Invalid name.'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Form.Control
                  as="textarea"
                  autoFocus
                  isInvalid={!!_.has(errors, 'description')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'description') ? errors.description.message : 'Invalid description.'}
            </Form.Control.Feedback>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={saving} onClick={() => onClose()}>Cancel</Button>
          <Button variant="primary" disabled={saving} type="submit">{!saving ? 'Save' : 'Saving'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
