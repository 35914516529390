/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Button, Modal, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { LoadingSpinner } from '../../../components';
import loginContext from '../../login/login.context';
import { createAdjustmentMutation, getTutorPayrollQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import ViewPayrollContext from './viewPayroll.context';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail'; 

const TYPE_DEDUCTION = 'DEDUCTION';

export default function Index() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userUid } = useContext(loginContext);
  const { uid, tutorName } = useContext(ViewPayrollContext);

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const {
    handleSubmit, formState, control, reset,
  } = useForm();
  const { errors } = formState;

  const onClose = () => {
    setVisible(false);
    reset();
  };

  const onShow = () => {
    setVisible(true);
  };

  const [createDeduction] = useMutation(createAdjustmentMutation, {
    client: PaymentClient,
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: getTutorPayrollQuery,
      variables: { uid },
    }],
    onCompleted() {
      onClose();
    },
    onError() {
      setLoading(false);
    },
  });

  const onSubmit = useCallback((values) => {
    async function doSubmit() {
      setLoading(true);

      const amount = _.has(values, 'amount') ? parseFloat(values.amount) : null;
      const variables = {
        type: TYPE_DEDUCTION,
        tutorPayrollUid: uid,
        amount,
        createdBy: userUid,
      };

      await createDeduction({ variables }).then(() => {
        // insert audit trail
        doInsertAuditTrail({
          module: 'Payroll',
          changes: `${userFullName} added deduction of ${amount} for ${tutorName}`,
          action: 'CREATE',
        });
      })


      setLoading(false);
    }

    doSubmit();
  }, [userUid, createDeduction, userFullName, tutorName]);

  const triggerSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <div className="d-grid gap-2">
        <Button variant="secondary" block onClick={onShow}>
          <FontAwesomeIcon icon={solid('file-circle-minus')} />
          {' '}
          Add Deduction
        </Button>
      </div>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Deduction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p className="lead">
            Current Commission Rate is
            {' '}
            <span className="text-info">
              {commissionRate}
              %
            </span>
          </p> */}
          <Form noValidate>
            <Form.Group className="form-group" controlId="adjustment.amount">
              <Form.Label>Add deduction</Form.Label>
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: 'Amount is required.',
                  pattern: {
                    value: /^\d*\.?\d*$/,
                    message: 'Invalid amount format!',
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    autoFocus
                    isInvalid={!!_.has(errors, 'amount')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'amount') ? errors.amount.message : 'Invalid amount.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
            {' '}
            Close
          </Button>
          <Button variant="primary" onClick={triggerSubmit} disabled={loading}>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={solid('circle-check')} />
                  {' '}
                  Create
                </>
              )
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
