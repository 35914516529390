/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */

export const getPaginatedTutorSessionsQuery = gql`
  query GetPaginatedTutorSessions(
    $tutorUid: ID!
    $filterInterest: String
    $filterStudent: String
  ) {
    getPaginatedTutorSessions(
      tutorUid: $tutorUid
      filterInterest: $filterInterest
      filterStudent: $filterStudent
    ) {
      count
      rows {
        id
        uid
        startDate
        endDate
        link
        status
        canStart
        canCancel
        canResched
        timeslot {
          id
          uid
          day
          start
          end
          status
          createdBy
          tutorialProfile {
            id
            uid
            tutorUid
            interest
            sessionType
            price
            status
            createdBy
            __typename
          }
          __typename
        }
        files {
          id
          uid
          sessionUid
          fileCategory
          storage
        }
        __typename
      }
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        __typename
      }
      __typename
    }
  }
`;

export const getSessionEnrolleesQuery = gql`
  query GetSessionEnrollees($sessionUid: ID!) {
    getSessionEnrollees(sessionUid: $sessionUid) {
      id
      uid
      tuteeUid
      sessionUid
      paymentUid
      attendanceStatus
      createdAt
      createdBy
      updatedAt
      updatedBy
      tutee {
        id
        uid
        userUid
        status
        others
      }
    }
  }
`;

export const getSessionFilesQuery = gql`
  query GetSessionFiles($sessionUid: String!) {
    getSessionFiles(sessionUid: $sessionUid) {
      id
      uid
      sessionUid
      fileCategory
      storage

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getSessionsStudentsQuery = gql`
  query GetAllSessionsStudents($tutorUid: String!) {
    getAllSessionsStudents(tutorUid: $tutorUid) {
      id
      uid
      tuteeUid
      sessionUid
      paymentUid
      attendanceStatus
      tutee {
        id
        uid
        userUid
        others
        __typename
      }
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */
export const addSessionLinkMutation = gql`
  mutation AddSessionLink($sessionUid: ID! $link: String!) {
    addSessionLink(sessionUid: $sessionUid link: $link) {
        id
        uid
        timeslotUid
        startDate
        endDate
        link
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
    }
  }
`;

export const endSessionMutation = gql`
  mutation EndSession($sessionUid: ID! $instanceUid: String!) {
    endSession(sessionUid: $sessionUid instanceUid: $instanceUid) {
      id
      uid
      timeslotUid
      startDate
      endDate
      link
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const startSessionMutation = gql`
  mutation StartSession($sessionUid: ID! $instanceUid: String!) {
    startSession(sessionUid: $sessionUid instanceUid: $instanceUid ) {
        id
        uid
        timeslotUid
        startDate
        endDate
        link
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
    }
  }
`;

export const addSessionFileMutation = gql`
  mutation AddSessionFile(
    $sessionUid: String! 
    $fileCategory: String!
    $storage: JSON!
    $createdBy: String!
  ) {
    addSessionFile(
      sessionUid: $sessionUid 
      fileCategory: $fileCategory
      storage: $storage
      createdBy: $createdBy
    ) {
        id
        uid
        sessionUid
        fileCategory
        storage

        createdAt
        createdBy
        updatedAt
        updatedBy
    }
  }
`;

export const cancelBookingMutation = gql`
  mutation CancelBooking(
    $sessionUid: String!
    $tuteeUid: String!
    $updatedBy: String!
  ) {
    cancelBooking(
      sessionUid: $sessionUid
      tuteeUid: $tuteeUid
      updatedBy: $updatedBy
    ) {
      id
      uid
      status
      __typename
    }
  }
`;

export const cancelTutorSessionMutation = gql`
  mutation CancelTutorSession(
    $sessionUid: String!
    $tutorUid: String!
    $updatedBy: String!
  ) {
    cancelTutorSession(
      sessionUid: $sessionUid
      tutorUid: $tutorUid
      updatedBy: $updatedBy
    ) 
  }
`;
/**
 * End of Mutation
 */
