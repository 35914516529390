import { useMutation } from '@apollo/client';
import { useState } from 'react';
import _ from 'lodash';
import { SEND_NOTIFICATION } from './gql';
import NotificationClient from '../../NotificationClient';

// Send notification to all marketing admins
const useNotification = (payload) => {
  const userUid = _.has(payload, 'userUid') ? payload.userUid : null;
  const [sending, setSending] = useState(false);

  const [saveNotification, { error }] = useMutation(SEND_NOTIFICATION, {
    client: NotificationClient,
  });

  const sendNotification = (params) => {
    const title = _.has(params, 'title') ? params.title : null;
    const message = _.has(params, 'message') ? params.message : null;
    const userUids = _.has(params, 'userUids') ? params.userUids : []; // sendTo
    const data = _.has(params, 'data') ? params.data : null;

    setSending(true);
    if (userUids.length > 0) {
      saveNotification({
        variables: {
          users: userUids,
          title,
          message,
          createdBy: userUid,
          data,
        },
      }).then(() => {
        setSending(false);
      }).catch((error) => {
        setSending(false);
      });
    }
  };

  return {
    sendNotification,
    sending,
    error,
  };
};

export default useNotification;
