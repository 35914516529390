import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import CheckoutContext from './checkout.context';
import CheckoutForm from './checkoutForm';

const computeFees = (totalAmount, voucherAmount) => {
  let vatableSales = parseFloat(totalAmount) / 1.12;
  let vat = (totalAmount - vatableSales);
  let discountedAmount = null;

  if (voucherAmount) {
    discountedAmount = totalAmount - voucherAmount;
    vatableSales = parseFloat(discountedAmount) / 1.12;
    vat = (discountedAmount - vatableSales);
  }

  return {
    vatableSales: vatableSales.toFixed(2),
    vat: vat.toFixed(2),
    grossSales: (vatableSales + vat).toFixed(2),
  };
};

export default function Index() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = _.has(location, 'state') ? location.state : null;
  const [timeSlotUid, setTimeSlotUid] = useState(null);
  const [sessionType, setSessionType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [feesList, setFeesList] = useState([]);
  const [vatable, setVatable] = useState(0);
  const [vat, setVat] = useState(0);
  const [grossSales, setGrossSales] = useState(0);
  const [tutorProfile, setTutorProfile] = useState(null);
  const [voucher, setVoucher] = useState(null);
  const [voucherDiscount, setVoucherDiscount] = useState(0);

  useEffect(() => {
    if (!state) { navigate('/search', { state: { replace: true } }); }

    const totalAmount = _.has(state, 'price') ? state.price : 0;
    const itemTitle = _.has(state, 'title') ? state.title : null;

    setTutorProfile(_.has(state, 'tutor') && state.tutor);
    setTimeSlotUid(_.has(state, 'timeSlotUid') && state.timeSlotUid);
    setSessionType(_.has(state, 'sessionType') && state.sessionType);
    setStartDate(_.has(state, 'startDate') && state.startDate);
    setEndDate(_.has(state, 'endDate') && state.endDate);

    setFeesList([{ item: itemTitle, price: parseFloat(totalAmount).toFixed(2) }]);

    const computedFees = computeFees(totalAmount);

    setVatable(_.has(computedFees, 'vatableSales') && computedFees.vatableSales);
    setVat(_.has(computedFees, 'vat') && computedFees.vat);
    setGrossSales(_.has(computedFees, 'grossSales') && computedFees.grossSales);
    setVoucherDiscount(null);

    if (voucher) {
      const voucherAmount = _.has(voucher, 'amount') ? voucher.amount : null;
      const discountedFees = computeFees(totalAmount, voucherAmount);
      const iVatableSales = _.has(discountedFees, 'vatableSales') ? discountedFees.vatableSales : null;
      const iVat = _.has(discountedFees, 'vat') ? discountedFees.vat : null;
      const iGrossSales = _.has(discountedFees, 'grossSales') ? discountedFees.grossSales : null;
      const discountedAmount = voucherAmount - (2 * voucherAmount);

      setVoucherDiscount(discountedAmount);
      setVatable(iVatableSales);
      setVat(iVat);
      setGrossSales(iGrossSales);
    }
  }, [state, voucher]);

  const contextPayload = useMemo(() => ({
    timeSlotUid,
    sessionType,
    startDate,
    endDate,
    feesList,
    vatable,
    vat,
    grossSales,
    tutorProfile,
    voucher,
    setVoucher,
    voucherDiscount,
  }), [
    timeSlotUid,
    sessionType,
    startDate,
    endDate,
    feesList,
    vatable,
    vat,
    voucher,
    setVoucher,
    voucherDiscount,
  ]);

  return (
    <CheckoutContext.Provider value={contextPayload}>
      <CheckoutForm />
    </CheckoutContext.Provider>
  );
}
