/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Query
 */
export const getUserWalletQuery = gql`
  query GetUserWallet($userUid: ID!) {
    getUserWallet(userUid: $userUid) {
      id
      uid
      userUid
      balance
      document
      soas {
        id
        uid
        walletUid
        action
        amount
        currentBalance
        previousBalance
        document

        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
  }
`;

export const getPaymentQuery = gql`
  query GetPayment($uid: ID!) {
    getPayment(uid: $uid) {
      id
      uid
      amount
      paymentMethod
      sourceId
      paymentId
      status
      document
      serviceDetails
      voucher
      voucherDiscount
      confirmedDate

      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const getPayrollQuery = gql`
  query GetPayroll($uid: ID!) {
    getPayroll(uid: $uid) {
      id
      uid
      type
      startDate
      endDate
      payDate
      status
      __typename
    }
  }
`;

export const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      email
      status
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        interest
        others
        __typename
      }
      __typename
    }
  }
`;

export const getTimeslotSessionQuery = gql`
  query GetTimeslotSession($timeslotUid: String!) {
    getTimeslotSession(timeslotUid: $timeslotUid) {
      id
      uid
      timeslotUid
      startDate
      endDate
      status
      interest
      __typename
    }
  }
`;

/**
 * End of Query
 */

/**
 * Mutation
 */

/**
 * End of Mutation
 */
