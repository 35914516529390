import React, {
  useMemo, useState,
} from 'react';
import {
  Card, Col, Form, InputGroup, Row,
} from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DashboardTemplate } from '../../../template/components';
import Calendar from '../../../modules/calendar';
import CalendarFilterContext from '../../../modules/calendar/calendarFilter.context';

export default function Index() {
  const [searchUid, setSearchUid] = useState(null);
  const [selectList, setSelectList] = useState([]);

  const contextPayload = useMemo(() => ({
    searchUid,
    setSearchUid,
    selectList,
    setSelectList,
  }), [
    searchUid,
    setSearchUid,
    selectList,
    setSelectList,
  ]);

  return (
    <DashboardTemplate>
      <CalendarFilterContext.Provider value={contextPayload}>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={{ span: 4 }}>
                <InputGroup>
                  <InputGroup.Text id="search-icon">
                    <FontAwesomeIcon icon={solid('search')} />
                  </InputGroup.Text>
                  <Form.Select
                    onChange={({ target }) => {
                      const { value } = target;

                      if (value !== 'false') {
                        setSearchUid(value);
                      } else {
                        setSearchUid(null);
                      }
                    }}
                  >
                    <option value={undefined}>Select Tutor</option>
                    {
                      selectList.map((i) => {
                        const uid = _.has(i, 'uid') ? i.uid : null;
                        const value = _.has(i, 'value') ? i.value : null;

                        return <option value={uid}>{value}</option>;
                      })
                    }
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Calendar />
          </Card.Body>
        </Card>
      </CalendarFilterContext.Provider>
    </DashboardTemplate>
  );
}
