/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Button, Modal, Row, Col,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getPaymentQuery, getTimeslotSessionQuery, getUserQuery } from './gql';
import PaymentClient from '../../../PaymentClient';
import { DynamicAvatar } from '../../../components';
import RegistryClient from '../../../RegistryClient';

export default function Index(payload) {
  const { paymentUid } = payload;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [student, setStudent] = useState(null);
  const [interest, setInterest] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [studentUserUid, setStudentUserUid] = useState(null);
  const [timeslotUid, setTimeslotUid] = useState(null);
  const [dateDuration, setDateDuration] = useState(null);
  const [confirmed, setConfirmed] = useState(null)

  const { data: paymentResult } = useQuery(getPaymentQuery, {
    client: PaymentClient,
    skip: !paymentUid,
    variables: { uid: paymentUid },
  });

  const [fetchUser] = useLazyQuery(getUserQuery, { client: RegistryClient });

  useEffect(() => {
    if (paymentResult) {
      const iPayment = _.has(paymentResult, 'getPayment') ? paymentResult.getPayment : null;
      const iService = _.has(iPayment, 'serviceDetails') ? iPayment.serviceDetails : null;
      const iAmount = _.has(iPayment, 'amount') ? iPayment.amount : null;
      const iCreatedBy = _.has(iPayment, 'createdBy') ? iPayment.createdBy : null;
      const iTimeslotUid = _.has(iService, 'timeSlotUid') ? iService.timeSlotUid : null;
      const voucherDiscount = _.has(iPayment, 'voucherDiscount') ? iPayment.voucherDiscount : null;
      const iConfirmedDate = _.has(iPayment, 'confirmedDate') ? moment(iPayment.confirmedDate).format('lll') : null;

      // setInterest('Swimming Lesson');
      setStudentUserUid(iCreatedBy);
      setTimeslotUid(iTimeslotUid);
      setTotalAmount(iAmount);
      setConfirmed(iConfirmedDate)

      if (voucherDiscount) {
        const iTotalAmount = iAmount + voucherDiscount;
        setTotalAmount(iTotalAmount);
      }
    }
  }, [paymentResult]);

  useEffect(() => {
    async function doFetch() {
      const user = await fetchUser({ variables: { uid: studentUserUid } })
        .then((result) => {
          const data = _.has(result, 'data') ? result.data : null;
          const details = _.has(data, 'getUser') ? data.getUser : null;

          return details;
        });

      const profile = _.has(user, 'userProfile') ? user.userProfile : null;
      const iFirstName = _.has(profile, 'firstName') ? profile.firstName : null;
      const iLastName = _.has(profile, 'lastName') ? profile.lastName : null;
      const iFullName = `${iFirstName} ${iLastName}`;

      setStudent(iFullName);
    }

    if (studentUserUid) { doFetch(); }
  }, [studentUserUid]);

  const [fetchSession] = useLazyQuery(getTimeslotSessionQuery);

  useEffect(() => {
    async function doFetch() {
      const session = await fetchSession({ variables: { timeslotUid } })
        .then((result) => {
          const data = _.has(result, 'data') ? result.data : null;
          const details = _.has(data, 'getTimeslotSession') ? data.getTimeslotSession : null;

          return details;
        });

      const iInterest = _.has(session, 'interest') ? session.interest : null;
      const start = _.has(session, 'startDate') ? moment(session.startDate) : null;
      const end = _.has(session, 'endDate') ? moment(session.endDate) : null;
      const duration = moment.duration(end.diff(start));
      const hours = duration.get('hours');
      const minutes = duration.get('minutes');
      const date = _.has(session, 'startDate') ? moment(session.startDate).format('lll') : null;
      const durationString = `${date} for ${hours ? `${hours} hrs` : ''} ${minutes ? `& ${minutes} mins` : ''}`;

      setDateDuration(durationString)
      setInterest(iInterest)
    }

    if (timeslotUid) { doFetch(); }
  }, [timeslotUid]);

  const onShow = useCallback(() => {
    setVisible(true);
  });

  const onClose = useCallback(() => {
    setVisible(false);
  });

  return (
    <>
      <Button variant="link" onClick={onShow}>PAYMENT</Button>
      <Modal
        show={visible}
        onHide={onClose}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Session Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4}>
              <DynamicAvatar userUid={studentUserUid} />
            </Col>
            <Col lg={8} style={{ padding: '20px 0' }}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('person-chalkboard')} />
                {' '}
                <small className="text-muted">{student}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('calendar')} />
                {' '}
                Payment UID#
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{paymentUid}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('calendar')} />
                {' '}
                Duration
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{dateDuration}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('calendar')} />
                {' '}
                Payment Confirmed Date
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{confirmed}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('chalkboard-user')} />
                {' '}
                Interest
              </p>
            </Col>
            <Col lg={8}>
              <p className="lead">
                <small className="text-muted">{interest}</small>
              </p>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <p className="lead">
                <FontAwesomeIcon icon={solid('money-bills')} />
                {' '}
                Total Amount
              </p>
            </Col>
            <Col lg={8}>
              <h3 className="text-info">
                <FontAwesomeIcon icon={solid('peso-sign')} />
                {' '}
                <small>{totalAmount && totalAmount.toFixed(2)}</small>
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>

          <Button
            variant="link"
            onClick={() => {
              navigate('/payments/invoice', { state: { paymentUid } });
            }}
          >
            View Invoice
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
