import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Row, Col, Button
} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styledComponents from 'styled-components';
import { ImageViewer, TutorRating } from '../../components';
import BookModal from './bookModal';
import BookingContext, { CalendarContext } from './booking.context';
// import { LoginContext } from '../login';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getElasticSearchQuery, saveElasticSearchMutation } from './gql';

moment.locale('en');
const localizer = momentLocalizer(moment);

const StyledDiv = styledComponents.div`
  width: 120px;
  &.custom-avatar {
    color: #fe9445;
    background-color: rgb(254 148 69 / 20%);
    border: 3px solid #fe9445;
    font-size: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 16px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    font-size: 36px;

    .avatar-text {
      color: #fe9445;
    }
  }
`;

const HomeCrumb = styledComponents(Button)`
  padding: unset;
  font-size: 12px;
  color: inherit;
`;

const ProfileDiv = styledComponents.div`
  margin: 21px 0px 0px 36px;
`;

const PriceRange = styledComponents.span`
  font-size: 24px;
  color: #fe9445;
`;

const PriceSession = styledComponents.span`
  font-size: 16px;
  color: #fe9445;
`;

export default function Index() {
  const navigate = useNavigate();
  const {
    tutorProfile, avatar, location, tutor, tutorUserUid,
  } = useContext(BookingContext);
  const [fullName, setFullName] = useState(null);
  const [initial, setInitial] = useState(null);
  const [tutorUid, setTutorUid] = useState(null);
  const [priceRange, setPriceRange] = useState(null);

  useEffect(() => {
    const uid = _.has(tutor, 'uid') ? tutor.uid : null;
    const price = _.has(tutor, 'priceRange') ? tutor.priceRange : null;
    const firstName = _.has(tutorProfile, 'firstName') ? tutorProfile.firstName : null;
    const middleInitial = _.has(tutorProfile, 'middleInitial') ? tutorProfile.middleInitial : null;
    const lastName = _.has(tutorProfile, 'lastName') ? tutorProfile.lastName : null;
    const consFullName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;

    setTutorUid(uid);
    setFullName(consFullName);
    setPriceRange(price);

    const iInitial = firstName && lastName ? `${_.toUpper(firstName.charAt(0))}${_.toUpper(lastName.charAt(0))}` : '';
    setInitial(iInitial);
  }, [
    tutor,
    tutorProfile,
    setFullName,
    setTutorUid,
  ]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <HomeCrumb variant="link" onClick={() => navigate('/')}>Home</HomeCrumb>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Booking</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Booking</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col xs={{ span: 10 }} className="booking-user-info">
                      {avatar ? (
                        <Link to={`/profile/${tutorUserUid}`}>
                          <ImageViewer
                            filePath={avatar}
                            roundedCircle
                            style={{ maxWidth: '120px' }}
                          />
                        </Link>
                      ) : (
                        <StyledDiv className="custom-avatar custom-avatar__large">
                          <Link to={`/profile/${tutorUserUid}`}>
                            <span className="avatar-text">{initial}</span>
                          </Link>
                        </StyledDiv>
                      )}

                      <ProfileDiv className="booking-info">
                        <h4><Link to={`/profile/${tutorUserUid}`}>{fullName}</Link></h4>
                        <TutorRating tutorUid={tutorUid} />
                        <p className="text-muted mb-0">
                          <i className="fas fa-map-marker-alt" />
                          {' '}
                          {location}
                        </p>
                      </ProfileDiv>
                    </Col>
                    <Col xs={{ span: 2 }} align="right">
                      <h2 className="lead">Price</h2>
                      <div className="mb-1">
                        <span className="text-lead">
                          <PriceRange>
                            ₱
                            {' '}
                            {priceRange}
                          </PriceRange>
                          <br />
                          <PriceSession>/ Session</PriceSession>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <Row>
                <Col className="mb-3">
                  <BookingCalendar />
                </Col>
              </Row>

              <Row>
                <Col className="mb-3 d-grid gap-2" lg={{ span: 2, offset: 10 }}>
                  <Button size="lg" variant="secondary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={solid('backward-step')} />
                    {' '}
                    Back
                  </Button>
                </Col>
              </Row>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function BookingCalendar() {
  const { tutor } = useContext(BookingContext);
  const [myEvents, setEvents] = useState([]);
  const [view, setView] = useState('week');
  const [forBooking, setForBooking] = useState(null);
  const { tutorUserUid: userUid } = useContext(BookingContext)

  useEffect(() => {
    if (tutor) {
      const tutorials = _.has(tutor, 'tutorials') ? tutor.tutorials : [];
      const days = 31;
      let current = moment();
      const listEvents = [];

      for (let i = 1; i <= days; i++) {
        const currentYear = current.get('year');
        const currentMonth = current.get('month');
        const currentDate = current.get('date');
        const currentDay = current.format('dddd');

        _.map(tutorials, (tutorial) => {
          const timeslots = _.has(tutorial, 'timeslots') ? tutorial.timeslots : [];
          const interest = _.has(tutorial, 'interest') ? tutorial.interest : null;
          const sessionType = _.has(tutorial, 'sessionType') ? tutorial.sessionType : null;
          const price = _.has(tutorial, 'price') ? tutorial.price : null;

          _.map(timeslots, (timeslot) => {
            const id = _.has(timeslot, 'id') ? timeslot.id : null;
            const uid = _.has(timeslot, 'uid') ? timeslot.uid : null;
            const day = _.has(timeslot, 'day') ? timeslot.day : null;
            const sameDay = _.toLower(currentDay) === _.toLower(day);
            let available = true;

            if (!sameDay) { return null; }

            const start = _.has(timeslot, 'start') ? timeslot.start : null;
            const startHrs = moment(`${start}`, 'HH:mm:ss').format('HH');
            const startMin = moment(`${start}`, 'HH:mm:ss').format('mm');
            const startSec = moment(`${start}`, 'HH:mm:ss').format('ss');
            const startDate = moment().set({
              year: currentYear,
              month: currentMonth,
              date: currentDate,
              hour: startHrs,
              minute: startMin,
              second: startSec,
            }).format();

            const end = _.has(timeslot, 'end') ? timeslot.end : null;
            const endHrs = moment(`${end}`, 'HH:mm:ss').format('HH');
            const endMin = moment(`${end}`, 'HH:mm:ss').format('mm');
            const endSec = moment(`${end}`, 'HH:mm:ss').format('ss');
            const endDate = moment().set({
              year: currentYear,
              month: currentMonth,
              date: currentDate,
              hour: endHrs,
              minute: endMin,
              second: endSec,
            }).format();

            if (sessionType === 'SINGLE') {
              const sessions = _.has(timeslot, 'sessions') ? timeslot.sessions : [];
              const curentStart = moment(startDate).format('lll');
              const currentEnd = moment(endDate).format('lll');

              _.map(sessions, (session) => {
                const sessionStart = moment(session.startDate).format('lll');
                const sessionEnd = moment(session.endDate).format('lll');

                if (curentStart === sessionStart && currentEnd === sessionEnd) {
                  available = false;
                }
              });
            }

            const event = {
              id,
              title: `${interest} (${sessionType} Session)`,
              start: new Date(startDate),
              end: new Date(endDate),
              timeslotUid: uid,
              sessionType,
              price,
              available,
              interest
            };

            // additional checking for start date
            const now = moment();
            // can only book 2hrs backwards
            const hrsBefore = moment(startDate).subtract(2, 'h');
            const isLapsed = now.isSameOrAfter(hrsBefore);

            if (!isLapsed) { listEvents.push(event); }

            return event;
          });
        });

        current = current.add(1, 'd');
      }

      _.compact(listEvents);
      setEvents(listEvents);
    }
  }, [tutor]);

  const [getElasticSearch] = useLazyQuery(getElasticSearchQuery)
  const [updateElasticSearch] = useMutation(saveElasticSearchMutation);

  const handleSelectEvent = useCallback((event) => {
    async function doProcess() {
      const interest = _.has(event, 'interest') ? event.interest : null
      const instance = await getElasticSearch({ variables: { userUid } })
        .then(({ data }) => _.has(data, 'getElasticSearch') ? data.getElasticSearch : null)
      const iDocument = _.has(instance, 'document') ? instance.document : null
      const iSearched = _.has(iDocument, 'searched') ? iDocument.searched : null
      const value = _.has(iSearched, interest) ? iSearched[interest] : 0

      const document = {
        searched: {
          ...iSearched,
          [interest]: value + 1
        }
      }

      // const variables = {
      //   referenceUid: userUid,
      //   type: 'TUTOR',
      //   document
      // }

      updateElasticSearch({
        variables: {
          referenceUid: userUid,
          type: 'TUTOR',
          document
        }
      })

      setForBooking({ ...event });
    }

    doProcess()
  }, [userUid]);

  const onView = useCallback((e) => {
    setView(e);
  });

  const contextPayload = useMemo(() => ({
    forBooking,
    setForBooking,
  }), [forBooking, setForBooking]);

  return (
    <CalendarContext.Provider value={contextPayload}>
      <Calendar
        localizer={localizer}
        events={myEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={['week', 'agenda']}
        view={view}
        step={30}
        selectable={false}
        onSelectEvent={handleSelectEvent}
        onView={onView}
      />

      <BookModal />
    </CalendarContext.Provider>
  );
}
