import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Alert, Button, Col, Image, Row,
} from 'react-bootstrap';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SearchContext from './search.context';
import { Pagination, TutorRating } from '../../components';
import { elasticSearchQuery, getTutorQuery, getUserQuery } from './gql';
import SearchFilter from './searchFilter';
import RegistryClient from '../../RegistryClient';
import FilterBadges from './filterBadges';
import { UploaderContext } from '../../modules/uploader';

const PROFILE_LINK = '/profile';

const StyledBody = styledComponents.div`
  padding: 1em 2em 1em 1em;
`;

const HomeCrumb = styledComponents(Button)`
  padding: unset;
  font-size: 12px;
  color: inherit;
`;

const PriceRange = styledComponents.span`
  font-size: 16px;
  color: #fe9445;
`;

const PriceSession = styledComponents.span`
  font-size: 14px;
  color: #fe9445;
`;

export default function Index() {
  const navigate = useNavigate();
  const {
    filters, setFilters,
  } = useContext(SearchContext);
  const [tutors, setTutors] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [totals, setTotals] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else if (page > 1) {
      const newOffset = (page - 1) * limit;
      setOffset(newOffset);
    }
  }, [page]);

  const { data: querySearch } = useQuery(elasticSearchQuery, {
    variables: {
      filters,
      type: 'TUTOR',
      offset,
      limit,
    },
  });

  useEffect(() => {
    const searchResult = _.has(querySearch, 'elasticSearch') ? querySearch.elasticSearch : null;
    const rows = _.has(searchResult, 'rows') ? searchResult.rows : [];
    const iCount = _.has(searchResult, 'count') ? searchResult.count : 0;

    setTotals(iCount);
    setTutors(rows);
  }, [querySearch]);

  const onSearch = useCallback((data) => {
    const { filters: iFilters } = data;
    const sessionType = _.has(iFilters, 'sessionType') ? iFilters.sessionType : null;
    if (sessionType === 'Select session') {
      Object.assign(iFilters, { sessionType: undefined });
    }

    setFilters(iFilters);
  });

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-8 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <HomeCrumb variant="link" onClick={() => navigate('/')}>Home</HomeCrumb>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Search</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">
                {totals}
                {' '}
                matches found
              </h2>
            </div>
            {/* <div className="col-md-4 col-12 d-md-block d-none">
              <div className="sort-by">
                <span className="sort-title">Sort by</span>
                <span className="sortby-fliter">
                  <select className="select">
                    <option>Select</option>
                    <option className="sorting">Rating</option>
                    <option className="sorting">Popular</option>
                    <option className="sorting">Latest</option>
                    <option className="sorting">Free</option>
                  </select>
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
          <SearchFilter onSearch={onSearch} />
        </div>

        <StyledBody className="col-md-12 col-lg-8 col-xl-9">
          <Row>
            <Col className="p-3" xs={{ span: 12 }}><FilterBadges /></Col>
            <Col xs={{ span: 12 }}>
              {!tutors.length && (
                <Alert variant="warning" style={{ margin: '16px 16px 0 0' }}>
                  <Alert.Heading>
                    <h3>
                      <i className="fa fa-exclamation-circle" aria-hidden="true" />
                      {' '}
                      No Result!
                    </h3>
                  </Alert.Heading>
                  <p>Empty result, please try to change your filter.</p>
                </Alert>
              )}
              {tutors.map((i) => <TutorItem {...i} />)}

              {totals > 0 && (
                <Pagination
                  pageSize={limit}
                  page={page}
                  totals={totals}
                  onChange={(value) => setPage(value)}
                />
              )}
            </Col>
          </Row>
        </StyledBody>
      </div>
    </>
  );
}

function TutorItem(payload) {
  const navigate = useNavigate();
  const userUid = _.has(payload, 'referenceUid') ? payload.referenceUid : null;
  const document = _.has(payload, 'document') ? payload.document : null;
  const tutorUid = _.has(document, 'tutorUid') ? document.tutorUid : null;

  const [avatar, setAvatar] = useState(null);
  const [initial, setInitial] = useState(null);
  const [location, setLocation] = useState(null);
  const [interests, setInterest] = useState(null);
  const [profileRoute, setProfileRoute] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [middleInitial, setMiddleInitial] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [priceRange, setPriceRange] = useState(null);
  const [path, setPath] = useState(null);
  const { getFileLink } = useContext(UploaderContext);

  const bookAppointment = useCallback(() => {
    navigate('/booking', { state: { tutorUid } });
  });

  const [fetchUser] = useLazyQuery(getUserQuery, { client: RegistryClient });
  const [getTutor] = useLazyQuery(getTutorQuery);

  useEffect(() => {
    async function doFetch() {
      return fetchUser({ variables: { uid: userUid } })
        .then(({ data }) => {
          const user = _.has(data, 'getUser') ? data.getUser : null;
          const iAvatar = _.has(user, 'avatar') ? user.avatar : null;
          const iAvatarStorage = _.has(iAvatar, 'storage') ? iAvatar.storage : null;
          const iPath = _.has(iAvatarStorage, 'path') ? iAvatarStorage.path : null;
          const profile = _.has(user, 'userProfile') ? user.userProfile : null;
          const iFirstName = _.has(profile, 'firstName') ? _.startCase(profile.firstName) : null;
          const iMiddleInitial = _.has(profile, 'middleInitial') ? profile.middleInitial : null;
          const iLastName = _.has(profile, 'lastName') ? _.startCase(profile.lastName) : null;

          const iInitial = iFirstName && iLastName ? `${_.toUpper(iFirstName.charAt(0))}${_.toUpper(iLastName.charAt(0))}` : '';

          const iAddress = _.has(profile, 'address') ? profile.address : null;
          const city = _.has(iAddress, 'city') ? iAddress.city : null;
          const countryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;

          setPath(iPath);
          setFirstName(iFirstName);
          setMiddleInitial(iMiddleInitial);
          setLastName(iLastName);
          setInitial(iInitial);
          setLocation(`${city}, ${countryCode}`);
        });
    }

    if (userUid) {
      doFetch();
    }
  }, [userUid]);

  useEffect(() => {
    async function doFetch() {
      return getTutor({ variables: { uid: tutorUid } })
        .then((result) => {
          const data = _.has(result, 'data') ? result.data : null;
          const tutor = _.has(data, 'getTutor') ? data.getTutor : null;
          const iPriceRange = _.has(tutor, 'priceRange') ? tutor.priceRange : null;

          setPriceRange(iPriceRange);
        });
    }

    if (tutorUid) {
      doFetch();
    }
  }, [tutorUid]);

  useEffect(() => {
    const tutorials = _.has(payload, 'tutorials') ? payload.tutorials : null;
    const iInterests = _.map(tutorials, (i) => i.interest);
    setInterest(_.toString(iInterests));
  }, [payload]);

  useEffect(() => {
    setProfileRoute(`${PROFILE_LINK}/${userUid}`);
  }, [userUid]);

  useEffect(() => {
    if (path) {
      getFileLink({ filePath: path }).then((uri) => {
        if (uri) {
          setAvatar(uri);
        }
      });
    }
  }, [path]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="mentor-widget">
          <div className="user-info-left">
            <div className="mentor-img">
              {avatar ? (
                <Link to={profileRoute}>
                  <object
                    className="img-fluid rounded-circle"
                    data={avatar}
                    type="image/png"
                  >
                    <Image src="/assets/img/user/user-empty.png" fluid roundedCircle />
                  </object>

                </Link>
              ) : (
                <StyledDiv className="custom-avatar custom-avatar__large">
                  <Link to={profileRoute}>
                    <span className="avatar-text">{initial}</span>
                  </Link>
                </StyledDiv>
              )}
            </div>
            <div className="user-info-cont">
              <h4 className="usr-name">
                <Link to={profileRoute}>
                  {firstName}
                  {' '}
                  {middleInitial}
                  {' '}
                  {lastName}
                </Link>
              </h4>
              <p className="mentor-type">{interests}</p>
              <TutorRating tutorUid={tutorUid} />
              <div className="mentor-details">
                <p className="user-location">
                  <i className="fas fa-map-marker-alt" />
                  {' '}
                  {location}
                </p>
              </div>
            </div>
          </div>
          <div className="user-info-right">
            <div className="user-infos">
              <ul>
                {/* <li>
                  <i className="far fa-comment" />
                  {' '}
                  0 Feedback
                </li> */}
                <li>
                  <span className="text-lead">
                    <PriceRange>
                      ₱
                      {' '}
                      {priceRange}
                    </PriceRange>
                    <PriceSession>/ Session</PriceSession>
                  </span>
                </li>
              </ul>
            </div>
            <div className="mentor-booking">
              <Button
                variant="secondary"
                onClick={bookAppointment}
              >
                <FontAwesomeIcon icon={solid('calendar')} />
                {' '}
                Book Appointment
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledDiv = styledComponents.div`
  &.custom-avatar {
    color: #fe9445;
    background-color: rgb(254 148 69 / 20%);
    border: 3px solid #fe9445;
    font-size: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
    overflow: hidden;

    .avatar-text {
      color: #fe9445;
    }
  }

  &.custom-avatar__large {
    width: 140px;
    height: 140px;
    font-size: 42px;
  }
`;
