import React, {
  useEffect, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import _ from 'lodash';

const faqsStudents = [
  {
    title: 'What is LearnLive! Tutorial Marketplace?',
    content: (
      <p>
        LearnLive! Tutorial Marketplace is a tutor booking platform.
        Tutors can offer their expertise whether academic, hobbies,
        or skills to interested individuals.
        Students can choose a wide selection of tutors based on their tutorial needs.
      </p>
    ),

  }, {
    title: 'What are your offerings?',
    content: <p>We offer one-on-one sessions, group tutorials, and expert classes.</p>,
  }, {
    title: 'How to register?',
    content: (
      <>
        <p>
          To sign up as a student, they must fill out an e-form on the
          platform to provide their personal identification details.
          For students under 18 years of age, they have to enter their guardian’s
          details and submit their guardian’s valid ID.
          They also must read and agree to the user agreement.
        </p>
        <p>
          An individual interested to teach in the platform has to sign up as a tutor,
          enter their details, read and agree to the user agreement, and submit requirements.
          They will also receive an email that confirms their registration on the platform.
        </p>
      </>
    ),
  }, {
    title: 'How to book a tutorial session?',
    content: (
      <p>
        Students can browse tutorial profiles on the platform
        and book the class with their desired interest and schedule.
        After payment, they may proceed with their booked session with their tutor.
      </p>
    ),
  }, {
    title: 'How can I pay for my tutorial sessions?',
    content: (
      <p>
        Once the students are settled with the class and schedule,
        it will proceed to the payment wherein they choose their preferred payment method,
        these are e-wallets (Gcash, Maya) and cards in partnership with Paymongo.
        Students need to input the necessary details to complete their payment.
      </p>
    ),
  }, {
    title: 'How can I rate my tutors?',
    content: (
      <p>After the class, students rate their tutors and give feedback about their sessions.</p>
    ),
  },
];

const faqsTeacher = [
  {
    title: 'How to register?',
    content: (
      <p>
        An individual interested to teach in the platform has to sign up as a tutor, enter his/her details, read and agree to the user agreement, and submit requirements.
        He/she will receive an email that confirms his/her registration on the platform.
      </p>
    ),
  },
  {
    title: 'What are your offerings?',
    content: (
      <p>We offer one-on-one sessions, group tutorials, and expert classes.</p>
    ),
  },
  {
    title: 'How to be a tutor in LearnLive?',
    content: (
      <p>
        To be a tutor, register on the platform and submit the necessary requirements.
        Once the admin reviewed and evaluated submitted personal information, documents, introductory video, and demo teaching video, the applicant will receive an email about the schedule of his/her interview.
        He/she will have to click the Calendly link provided then schedule his/her screening date and time and input his/her details.
        He/she will receive an email after passing the recruitment process and will then be able to create tutorial profile.
        He/she can start conducting classes once booked.
      </p>
    ),
  },
  {
    title: 'How will I receive booking from students?',
    content: (
      <p>
        A successful tutor applicant will receive an email that he/she passed the recruitment process. He/she can then create a tutorial profile and open his/her slots for booking classes.
      </p>
    ),
  }, {
    title: 'How will I receive ratings from students?',
    content: (
      <p>
        For every completed class, a tutor can give class notes regarding the session and receive a rating/feedback from his/her student/s.
      </p>
    ),
  }, {
    title: 'How can I be a Certified Vibal Tutor?',
    content: (
      <p>
        A tutor is only considered regular once he/she passes Vibal certification for tutors and
        has at least 20 bookings completed in 6 months.
        Should the tutor fail to maintain 20 bookings per month after 2 months of regularization,
        the commission for Vibal will revert to 25%.
      </p>
    ),
  },
];

export default function Index() {
  const { state } = useLocation();
  const roleCode = _.has(state, 'roleCode') ? state.roleCode : null;
  const [title, setTitle] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (roleCode === 'TUTOR') {
      setItems(faqsTeacher);
      setTitle('Frequently Ask Questions(For Tutor)');
    } else {
      setItems(faqsStudents);
      setTitle('Frequently Ask Questions(For Student)');
    }
  }, [roleCode, faqsStudents, faqsTeacher]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">FAQs</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">FAQs</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3 className="pb-3">{title}</h3>

              <Accordion flush>
                {items.map((item, key) => <FAQItem eventKey={key} {...item} />)}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FAQItem(payload) {
  const { eventKey, title, content } = payload;

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>{content}</Accordion.Body>
    </Accordion.Item>
  );
}
