import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import RegistryClient from '../../RegistryClient';
import LoginContext from '../../pages/login/login.context';
import { nav } from '../../constants';
import { Avatar, TutorRating } from '../../components';
import styledComponents from 'styled-components'
import { Button } from 'react-bootstrap';

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

const StyledButtonSideNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  border: unset;
  border-radius: unset;
  text-align: left;
`;

const getUserQuery = gql`
  query GetUser($uid: ID!) {
    getUser(uid: $uid) {
      id
      uid
      status
      progressPercentage
      userProfile {
        id
        uid
        userUid
        firstName
        middleInitial
        lastName
        age
        mobile
        birthDate
        gender
        address
        status
        __typename
      }
      avatar {
        id
        uid
        fileCategory
        storage
        __typename
      }
      __typename
    }
  }
`;

export default function Index({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { roleCode = 'DEFAULT' } = useContext(LoginContext);
  const [activeNav, setActiveNav] = useState(0)
  const [activeSubNav, setActiveSubNav] = useState(0);
  const pathname = _.has(location, 'pathname') ? location.pathname : null;
  const { sideNav } = nav;
  const found = _.find(sideNav, { roleCode });
  const navs = _.has(found, 'links') ? found.links : [];
  const splitted = _.split(pathname, '/');
  const breadLabel = _.startCase(_.toLower(_.last(splitted)));
  const [progress, setProgress] = useState(0);
  const [roleName, setRoleName] = useState(null)
  const [hasCompleteProfile, setHasCompleteProfile] = useState(false)

  const { userUid, instanceUid } = useContext(LoginContext);
  const [fullName, setFullName] = useState(null);

  const { data: getUserResult } = useQuery(getUserQuery, {
    client: RegistryClient,
    skip: !userUid,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(getUserResult, 'getUser') ? getUserResult.getUser : null;
    const profile = _.has(user, 'userProfile') ? user.userProfile : null;
    const firstName = _.has(profile, 'firstName') ? profile.firstName : null;
    const middleName = _.has(profile, 'middleName') ? profile.middleName : null;
    const lastName = _.has(profile, 'lastName') ? profile.lastName : null;
    const wholeName = `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
    const progress = _.has(user, 'progressPercentage') ? user.progressPercentage : 0;

    switch (roleCode) {
      case 'TUTOR':
        setRoleName('TUTOR')
        setHasCompleteProfile(true)
        break;
      case 'MARKETING_ADMIN': setRoleName('MARKETING ADMIN');
        break;
      case 'SUPPORT': setRoleName('SUPPORT');
        break;
      case 'PARTNER_MERCHANT': setRoleName('PARTNER MERCHANT');
        break;
      case 'SUPERADMIN': setRoleName('SUPERADMIN')
        break;
      default:
        setRoleName('STUDENT')
        setHasCompleteProfile(true)
    }

    setFullName(wholeName);
    setProgress(progress);
  }, [getUserResult, roleCode]);

  const onNavigate = ({ navKey, subKey, path }) => {
    if (navKey !== undefined) {
      setActiveNav(navKey);
    }

    if (subKey !== undefined) {
      setActiveSubNav(subKey);
    }

    navigate(path);
  }

  useEffect(() => {
    const keyString = _.findKey(navs, { path: pathname })
    const key = keyString && parseInt(keyString)

    setActiveNav(key)
  }, [pathname])

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">{breadLabel}</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">{breadLabel}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">

              <div className="profile-sidebar">
                <div className="user-widget">
                  <Avatar size='default' noClick />
                  {roleCode === 'TUTOR' && (<TutorRating tutorUid={instanceUid} />)}
                  <div className="user-info-cont">
                    <h4 className="usr-name">{fullName}</h4>
                    <p className="mentor-type">{roleName}</p>
                  </div>
                </div>
                {
                  hasCompleteProfile ? (
                    <div className="progress-bar-custom">
                      <h6>Complete your profiles</h6>
                      <div className="pro-progress">
                        <div
                          className="tooltip-toggle"
                          tabIndex="0"
                          style={{ width: `${progress}%` }}
                        />
                        <div className="tooltip">
                          {progress}
                          %
                        </div>
                      </div>
                    </div>
                  ) : ''
                }
                <div className="custom-sidebar-nav">
                  <ul>
                    {
                      _.map(navs, (nav, mainKey) => {
                        const label = _.has(nav, 'label') ? nav.label : null;
                        const path = _.has(nav, 'path') ? nav.path : null;
                        const icon = _.has(nav, 'icon') ? nav.icon : null;
                        const sub = _.has(nav, 'sub') ? nav.sub : [];

                        if (sub.length) {
                          return (
                            <li key={`sidenav-has-submenu-${mainKey}`} className={`has-submenu ${mainKey === activeNav ? 'active' : ''}`}>
                              {/* <a
                                href="#"
                                className={`${mainKey === activeNav ? 'active' : ''}`}
                                onClick={() => {
                                  onNavigate({ path, navKey: mainKey });
                                }}
                              >
                                <i className={`fas ${icon}`} />
                                {label}
                                {' '}
                                <span>
                                  <i className={`fas fa-chevron-${mainKey === activeNav ? 'down' : 'right'}`} />
                                </span>
                              </a> */}
                              <StyledButtonSideNav
                                variant='link'
                                className={`${mainKey === activeNav ? 'active' : ''}`}
                                onClick={() => {
                                  onNavigate({ path, navKey: mainKey });
                                }}
                              >
                                <i className={`fas ${icon}`} />
                                {label}
                                {' '}
                                <span>
                                  <i className={`fas fa-chevron-${mainKey === activeNav ? 'down' : 'right'}`} />
                                </span>
                              </StyledButtonSideNav>

                              <ul className="submenu" style={{ display: `${mainKey === activeNav ? '' : 'none'}` }}>
                                {
                                  _.map(sub, (i, sKey) => {
                                    const subLabel = _.has(i, 'label') ? i.label : null;
                                    const subPath = _.has(i, 'path') ? i.path : null;
                                    const subKey = `${mainKey}-${sKey}`;
                                    return (
                                      <li key={`sidenav-sub-${sKey}`} className={`${subKey === activeSubNav ? 'active' : ''}`}>
                                        {/* <a
                                          className={`${subKey === activeSubNav ? 'active' : ''}`}
                                          href="javascript:void(0)"
                                          onClick={() => onNavigate({
                                            path: subPath,
                                            navKey: mainKey,
                                            subKey,
                                          })}
                                        >
                                          {subLabel}
                                        </a> */}
                                        <StyledButtonSideNav
                                          variant='link'
                                          className={`${subKey === activeSubNav ? 'active' : ''}`}
                                          onClick={() => onNavigate({
                                            path: subPath,
                                            navKey: mainKey,
                                            subKey,
                                          })}
                                        >
                                          {subLabel}
                                        </StyledButtonSideNav>
                                      </li>
                                    );
                                  })
                                }
                              </ul>
                            </li>
                          );
                        }

                        return (
                          <li key={`sidenav-${mainKey}`} className={`${mainKey === activeNav ? 'active' : ''}`}>
                            {/* <a className={`${mainKey === activeNav ? 'active' : ''}`} onClick={() => onNavigate({ path, navKey: mainKey })}>
                              <i className={`fas ${icon}`} />
                              {label}
                              {' '}
                              <span><i className="fas fa-chevron-right" /></span>
                            </a> */}
                            <StyledButtonSideNav variant='link'
                              className={`${mainKey === activeNav ? 'active' : ''}`}
                              onClick={() => onNavigate({ path, navKey: mainKey })}
                            >
                              <i className={`fas ${icon}`} />
                              {label}
                              {' '}
                              <span><i className="fas fa-chevron-right" /></span>
                            </StyledButtonSideNav>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
