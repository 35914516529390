import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import axios from 'axios';
import RegistryClient from '../../../../RegistryClient';
import { deleteFileMutation, getUserFilesQuery, getUserQuery } from './gql';
import { LoginContext } from '../../../login';
import CertModalForm from './certModalForm';
import ImageViewer from '../../../../components/imageViewer';

const { REACT_APP_FILE_SERVICE } = process.env;
const UPLOAD_URL = `${REACT_APP_FILE_SERVICE}/delete-file`;
const FILE_CATEGORY = 'CERTIFICATES'

const deleteFile = (filePath) => new Promise((resolve, reject) => {
  axios.post(UPLOAD_URL, { filePath })
    .then(resolve).catch(reject);
});

export default function Index() {
  const { userUid } = useContext(LoginContext);
  const [canEdit, setCanEdit] = useState(true);
  const [certifications, setCertifications] = useState([]);

  const { data: userResult } = useQuery(getUserQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { uid: userUid },
  });

  useEffect(() => {
    const user = _.has(userResult, 'getUser') ? userResult.getUser : null
    const iUserStatus = _.has(user, 'status') ? user.status : null;

    if (iUserStatus === 'FOR_REVIEW') { setCanEdit(false); } else { setCanEdit(true); }
  }, [userResult]);

  const { data: filesResults, refetch } = useQuery(getUserFilesQuery, {
    skip: !userUid,
    client: RegistryClient,
    variables: { userUid, fileCategory: FILE_CATEGORY },
  });

  useEffect(() => {
    const iFiles = _.has(filesResults, 'getUserFiles') ? filesResults.getUserFiles : [];
    setCertifications(iFiles)
  }, [filesResults]);

  return (
    <div className="card col-10 me-auto ms-auto p-0">
      <div className="card-body custom-border-card pb-0">
        <div className="widget experience-widget mb-0">
          <h4 className="widget-title">
            Certifications
            {' '}
            {canEdit && <CertModalForm onCreate={refetch} />}
          </h4>
          <hr />
          {
            certifications && certifications.map(
              (cert) => <CertItem {...cert} onDelete={refetch} canEdit={canEdit} />,
            )
          }
        </div>
      </div>
    </div>
  );
}

function EmptyPlaceHolder() {
  return (
    <div>
      <p>
        Empty Result
        {' '}
        <br />
        <span className="text-muted">Please upload a file!</span>
      </p>
    </div>
  );
}

function CertItem(payload) {
  const { userUid } = useContext(LoginContext);
  const { uid, storage, canEdit, onDelete: onDeleted } = payload;
  const { description, path } = storage;

  const [mutateDelete] = useMutation(deleteFileMutation, {
    client: RegistryClient,
    onCompleted() {
      onDeleted()
    }
  });

  const onDelete = useCallback(async () => {
    await deleteFile(path).catch((err) => console.log({ err }));

    await mutateDelete({ variables: { uid, deletedBy: userUid } });
  }, [uid]);

  return (
    <StyledDiv>
      <Row>
        <Col lg={6}>
          <div className="timeline-content">
            <span>
              Desciption
              {' '}
              {
                canEdit && (
                  <Button variant="link" onClick={onDelete}>
                    <FontAwesomeIcon icon={solid('trash')} />
                  </Button>
                )
              }
            </span>
            <div className="row-result">{description}</div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="timeline-content">
            <span>Certificate</span>
            <div className="row-result">
              {
                path ? <ImageViewer filePath={path} thumbnail fluid /> : <EmptyPlaceHolder />
              }
            </div>
          </div>
        </Col>
      </Row>
    </StyledDiv>
  );
}

const StyledDiv = styledComponents.div`
  border-bottom: 1px solid;
  padding: 16px 0;
`;
