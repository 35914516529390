import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { getInterestQuery } from './gql';

export default function Index({ onSubmit }) {
  const [show, setShow] = useState(false);
  const {
    handleSubmit, formState, control, reset,
  } = useForm();
  const { errors } = formState;
  const [interests, setInterests] = useState([]);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);

  const submitForm = useCallback((data) => {
    const interestUid = _.has(data, 'interestUid') ? data.interestUid : null;
    const findInterest = _.find(interests, { uid: interestUid });
    const interest = _.has(findInterest, 'title') ? findInterest.title : null;

    onSubmit({ ...data, interest });
  });

  const triggerSubmit = () => {
    handleSubmit(submitForm)();
  };

  const [getInterest] = useLazyQuery(getInterestQuery);

  useEffect(() => {
    getInterest({ variables: { status: 'PUBLISHED' } })
      .then(({ data }) => {
        const getAllInterest = _.has(data, 'getAllInterest') ? data.getAllInterest : [];

        setInterests(getAllInterest);
      });
  }, [getInterest]);

  return (
    <>
      <Button className="edit-link" variant="link" onClick={handleShow}>
        <i className="fa fa-plus-circle" />
        {' '}
        Add Tutorial Profile
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Tutorial Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group className="form-group" controlId="slot.title">
              <Form.Label>Interest</Form.Label>
              <Controller
                name="interestUid"
                control={control}
                rules={{ required: 'Interest is required.' }}
                render={({ field }) => (
                  <Form.Select
                    autoFocus
                    isInvalid={!!_.has(errors, 'interestUid')}
                    {...field}
                  >
                    <option>Select Interest</option>
                    {
                      interests.map((i) => {
                        const uid = _.has(i, 'uid') ? i.uid : null;
                        const title = _.has(i, 'title') ? i.title : null;

                        return <option value={uid}>{title}</option>;
                      })
                    }
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'interestUid') ? errors.interestUid.message : 'Invalid interest.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" controlId="slot.title">
              <Form.Label>Session Type</Form.Label>
              <Controller
                name="sessionType"
                control={control}
                rules={{ required: 'Session type is required.' }}
                render={({ field }) => (
                  <Form.Select
                    autoFocus
                    isInvalid={!!_.has(errors, 'sessionType')}
                    {...field}
                  >
                    <option>Select Session Type</option>
                    <option value="SINGLE">One on One</option>
                    <option value="GROUP">Group</option>
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'sessionType') ? errors.sessionType.message : 'Invalid session tpye.'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" controlId="slot.title">
              <Form.Label>Price</Form.Label>
              <Controller
                name="price"
                control={control}
                rules={{
                  required: 'Price is required.',
                  pattern: {
                    value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                    message: 'Invalid price input.',
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    autoFocus
                    isInvalid={!!_.has(errors, 'price')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'price') ? errors.price.message : 'Invalid price.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={triggerSubmit}>Create</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
