import React from 'react';
import TutorsTableItem from './tutorTableItem';
import { LoadingSpinner } from '../../../components';

export default function tutorsTable(props) {
  const {
    columns, dataSource, loading,
  } = props;

  return (
    <div className="table-responsive">
      <table className="table table-hover table-center mb-0">
        <thead>
          <tr>
            { columns.length > 0 && columns.map(
              (column) => <th className={column.className} style={column.style}>{column.title}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          { loading
            && (
            <tr>
              <td className="text-center p-4" colSpan={columns && columns.length}>
                <LoadingSpinner loading={loading} />
              </td>
            </tr>
            )}

          {(!loading && dataSource.length === 0)
             && (
             <tr>
               <td colSpan={columns.length} className="text-muted text-center p-4">No Data</td>
             </tr>
             )}

          { dataSource && dataSource.length > 0 && dataSource.map((ds) => (
            <TutorsTableItem key={ds.id} tutorData={ds} />
          ))}

        </tbody>
      </table>
    </div>
  );
}
