/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_RECRUITMENT_STATUS } from './gql';
import LoginContext from '../../login/login.context';
import RegistryClient from '../../../RegistryClient';
import useNotification from '../../notification/useNotification';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { recruitmentStatusLabel } from '../../../constants';
import { AlertError } from '../../../components'

export default function updateStatusModal({
  recruitmentData, currentStatus,
}) {
  const { userUid } = useContext(LoginContext);
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const recruitmentUid = _.has(recruitmentData, 'uid') ? recruitmentData.uid : null;
  const user = _.has(recruitmentData, 'user') ? recruitmentData.user : null;
  // const studentUserUid = _.has(user, 'uid') ? user.uid : null;
  const userProfile = _.has(user, 'userProfile') ? user.userProfile : null;
  const firstName = _.has(userProfile, 'firstName') ? userProfile.firstName : null;
  const lastName = _.has(userProfile, 'lastName') ? userProfile.lastName : null;

  const { sendNotification } = useNotification({ userUid });
  const { doInsertAuditTrail } = useCreateAuditTrail();

  const {
    handleSubmit, formState, control, reset,
  } = useForm();

  const { errors } = formState;

  const [updateStatus] = useMutation(UPDATE_RECRUITMENT_STATUS, {
    client: RegistryClient,
    refetchQueries: 'GetPaginatedRecruitments',
  });

  // const { data: tutorData } = useQuery(GET_USER_TUTOR_DETAILS, {
  //   variables: {
  //     userUid: studentUserUid
  //   }
  // })

  // const tutorDetails = _.has(tutorData, 'getUserTutorDetails') ? tutorData.getUserTutorDetails : null
  // const tutorUid = _.has(tutorDetails, 'uid') ? tutorDetails.uid : null

  const getMessage = (status) => {
    switch (status) {
      case 'FOR_SCREENING':
        return {
          title: 'LearnLive Screening Details',
          message: `You are one step closer to being one of LearnLive tutors! 
          Your profile and submitted documents have been reviewed and you are now up for screening.`,
        };
      case 'FOR_ONBOARDING':
        return {
          title: 'LearnLive Onboarding Details',
          message: `Congratulations! You passed the screening.
          One last step and you are done. You will watch the onboarding video and you will answer questions after.`,
        };
      case 'ACCEPTED':
        return {
          title: 'Congrats and welcome to LearnLive!',
          message: 'Hooray! You are now a LearnLive tutor!',
        };
      case 'DECLINED':
        return {
          title: 'LearnLive Tutor Application Status',
          message: 'Thank you for your interest in LearnLive. Unfortunately, we won\'t be proceeding with your application at this time. We hope you consider applying again. Best of luck in your future endeavors.',
        };
      default:
        return {
          title: 'LearnLive Tutor Application Status',
          message: status,
        };
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setSaving(true)
    updateStatus({
      variables: {
        ...data,
        newStatus: data.status,
        updatedBy: userUid,
        recruitmentUid,
      },
    }).then(() => {
      const notif = getMessage(data.status);
      const tutorUserUid = _.has(user, 'uid') ? user.uid : null;
      // send notif
      sendNotification({
        title: notif.title,
        message: notif.message,
        userUids: [tutorUserUid],
      });

      // insert audit trail
      doInsertAuditTrail({
        action: 'UPDATE',
        changes: `Updated ${firstName} ${lastName}'s status from ${currentStatus} to ${data.status}`,
        module: 'Tutor Recruitment',
        details: { recruitmentUid },
      });

      setSaving(false)
      setShow(false);
      reset();
    }).catch((err) => {
      setSaving(false)
      setError(err.toString())
    });
  };

  const handleClose = () => {
    setSaving(false)
    setShow(false);
    reset();
  };

  return (
    <>
      <Button
        variant="primary"
        // variant="light"
        type="button"
        // className="btn bg-primary-light"
        onClick={() => setShow(true)}
      >
        Update Status
      </Button>
      {show && (
        <Modal
          show={show}
          onHide={() => handleClose()}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>Update Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {error && (
                <AlertError
                  error={error}
                  title="Failed to update status"
                  onClose={() => setError(null)}
                />
              )}
              <h3 className="mb-4">
                {firstName}
                {' '}
                {lastName}
                {' '}
              </h3>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>Select New Status</Form.Label>
                <Controller
                  name="status"
                  control={control}
                  rules={{
                    required: 'Status is required.',
                  }}
                  render={({ field }) => (
                    <Form.Select
                      autoFocus
                      isInvalid={!!_.has(errors, 'status')}
                      {...field}
                    >
                      <option value="" selected>All Status</option>
                      <option value="FOR_SCREENING">{recruitmentStatusLabel.FOR_SCREENING}</option>
                      <option value="FOR_ONBOARDING">{recruitmentStatusLabel.FOR_ONBOARDING}</option>
                      <option value="ACCEPTED">{recruitmentStatusLabel.ACCEPTED}</option>
                      <option value="DECLINED">{recruitmentStatusLabel.DECLINED}</option>
                    </Form.Select>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'status') ? errors.status.message : 'Invalid status.'}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>Remarks</Form.Label>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...field}
                    />
                  )}
                />
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleClose()} disabled={saving}>Cancel</Button>
              {' '}
              <Button type="submit" variant="primary" disabled={saving}>{!saving ? 'Save' : 'Saving'}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
}
