/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button, Modal, Row, Col, Alert, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Controller, useForm } from 'react-hook-form';
import RescheduleContext, { CalendarContext } from './reschedule.context';
import LoginContext from '../login/login.context';
import LoginModal from '../login/loginModal';
import { isEnrolledQuery, rescheduleMutation, rescheduleTutorMutation } from './gql';
import useNotification from '../notification/useNotification';
import useCreateAuditTrail from '../auditTrail/useCreateAuditTrail';

export default function Index() {
  const { forBooking, setForBooking } = useContext(CalendarContext);
  const { sessionUid, tutorProfile, currentStartDate } = useContext(RescheduleContext);
  const [visible, setVisible] = useState(false);
  const [timeslotUid, setTimeSlotUid] = useState(null);
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState(0);
  const [sessionType, setSessionType] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [canBook, setCanBook] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const { userUid, roleCode, instanceUid } = useContext(LoginContext);
  const navigate = useNavigate();
  const [enrolled, setEnrolled] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sendNotification } = useNotification({ userUid });
  const [tutorName, setTutorName] = useState(null);
  const formMethods = useForm();
  const {
    handleSubmit, formState, control
  } = formMethods;
  const { errors } = formState;

  const { doInsertAuditTrail } = useCreateAuditTrail();

  useEffect(() => {
    if (tutorProfile) {
      const firstName = _.has(tutorProfile, 'firstName') ? tutorProfile.firstName : null;
      const middleInitial = _.has(tutorProfile, 'middleInitial') ? tutorProfile.middleInitial : null;
      const lastName = _.has(tutorProfile, 'lastName') ? tutorProfile.lastName : null;

      setTutorName(`${firstName} ${middleInitial} ${lastName}`);
    }
  }, [tutorProfile]);

  useEffect(() => {
    if (forBooking) { setVisible(true); } else { setVisible(false); }
    const available = _.has(forBooking, 'available') ? forBooking.available : false;

    setTimeSlotUid(_.has(forBooking, 'timeslotUid') && forBooking.timeslotUid);
    setTitle(_.has(forBooking, 'title') && forBooking.title);
    setPrice(_.has(forBooking, 'price') && forBooking.price);
    setSessionType(_.has(forBooking, 'sessionType') && forBooking.sessionType);

    const start = _.has(forBooking, 'start') ? forBooking.start : null;
    const end = _.has(forBooking, 'end') ? forBooking.end : null;
    const dateRangeString = `${moment(start).format('lll')} - ${moment(end).format('lll')}`;

    setStartDate(start);
    setEndDate(end);
    setDateRange(dateRangeString);

    setCanBook(false);
    setIsAvailable(available);
    if (available) {
      setCanBook(true);
    }

    if (userUid) { setAuthenticated(true); } else { setAuthenticated(false); }
  }, [forBooking, userUid]);

  const handleClose = () => {
    setForBooking(null);
  };

  const [reschedMutation] = useMutation(rescheduleMutation, {
    onCompleted: () => {
      const newStartDate = startDate;

      doInsertAuditTrail({
        action: 'RESCHEDULE',
        changes: `Updated ${title} session's schedule from ${currentStartDate} to ${newStartDate}`,
        module: 'Calendar',
      });

      sendNotification({
        userUids: [userUid],
        title: 'Session Rescheduled',
        message: `Your class with ${tutorName} on ${currentStartDate} is rescheduled on ${newStartDate}`,
      });

      navigate('/student/calendar');
    },
  });

  const [reschedTutorMutation] = useMutation(rescheduleTutorMutation, {
    onCompleted: (result) => {
      const { rescheduleTutorSession: data } = result;
      const { enrollees } = data;
      const newStartDate = startDate;

      doInsertAuditTrail({
        action: 'RESCHEDULE',
        changes: `Rescheduled ${title} session's from ${currentStartDate} to ${newStartDate}`,
        module: 'Calendar',
      });

      sendNotification({
        userUids: [userUid],
        title: 'Session Rescheduled',
        message: `Your session on ${currentStartDate} has been rescheduled on ${newStartDate}`,
      });

      _.map(enrollees, (row) => {
        const rowUserUid = _.has(row, 'createdBy') ? row.createdBy : null;

        sendNotification({
          userUids: [rowUserUid],
          title: 'Session Rescheduled',
          message: `Your class with ${tutorName} on ${currentStartDate} is rescheduled on ${newStartDate}`,
        });
      });

      navigate('/tutor/calendar');
    },
  });

  const [checkEnrolled, { loading: loadingCheckEnrolled }] = useLazyQuery(isEnrolledQuery);

  useEffect(() => {
    async function doCheck() {
      setLoading(true);

      await checkEnrolled({
        variables: {
          instanceUid,
          timeslotUid,
          startDate,
          endDate,
        },
      }).then(({ data }) => {
        const alreadyEnrolled = _.has(data, 'isStudentEnrolled') ? data.isStudentEnrolled : null;

        if (alreadyEnrolled) { setCanBook(false); }

        setEnrolled(alreadyEnrolled);
      });

      setLoading(false);
    }

    if (instanceUid && timeslotUid) {
      doCheck();
    }
  }, [checkEnrolled, timeslotUid, instanceUid, loadingCheckEnrolled, setLoading]);

  const proceedReschedule = useCallback((values) => {
    async function doProcess() {
      setLoading(true);
      const tuteeUid = instanceUid;
      const updatedBy = userUid;

      if (roleCode === 'TUTEE') {
        const remark = _.has(values, 'remark') ? values.remark : null;

        await reschedMutation({
          variables: {
            sessionUid,
            tuteeUid,
            timeslotUid,
            remark,
            startDate,
            endDate,
            updatedBy,
          },
        });
      } else if (roleCode === 'TUTOR') {
        await reschedTutorMutation({
          variables: {
            sessionUid,
            timeslotUid,
            startDate,
            endDate,
            updatedBy,
          },
        });
      }

      setLoading(false);
    }

    doProcess();
  }, [
    timeslotUid,
    title, price,
    sessionType,
    dateRange,
    startDate,
    endDate,
    tutorProfile,
    instanceUid,
    userUid,
    roleCode,
  ]);

  const triggerSubmit = useCallback(() => {
    handleSubmit(proceedReschedule)();
  });

  return (
    <Modal
      show={visible}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Session Reschedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {enrolled && (
          <Row>
            <Col lg={12}>
              <Alert>
                <Alert.Heading>
                  <FontAwesomeIcon icon={solid('exclamation-circle')} />
                  {' '}
                  Booked!
                </Alert.Heading>
                <p>You've already booked this timeslot</p>
              </Alert>
            </Col>
          </Row>
        )}
        {!isAvailable && (
          <Row>
            <Col lg={12}>
              <Alert variant="danger">
                <Alert.Heading>
                  <FontAwesomeIcon icon={solid('exclamation-circle')} />
                  {' '}
                  Date Unavailable
                </Alert.Heading>
                <p>We are really sorry but this schedule has been taken.</p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={3}>
            <p className="lead">Interest</p>
          </Col>
          <Col lg={9}>
            <p className="lead">
              <FontAwesomeIcon icon={solid('person-chalkboard')} />
              {' '}
              <small className="text-muted">{title}</small>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <p className="lead">Session</p>
          </Col>
          <Col lg={9}>
            <p className="lead">
              <FontAwesomeIcon icon={solid('user-group')} />
              {' '}
              <small className="text-muted">{sessionType}</small>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <p className="lead">Timeslot</p>
          </Col>
          <Col lg={9}>
            <p className="lead">
              <i className="far fa-calendar-alt" />
              {' '}
              <small className="text-muted">{dateRange}</small>
            </p>
          </Col>
        </Row>

        {
          roleCode === 'TUTEE' && (
            <Row>
              <Col lg={12}>
                <Form noValidate>
                  <Form.Group className="form-group" controlId="resched.remark">
                    <Form.Label>Reason</Form.Label>
                    <Controller
                      name="remark"
                      control={control}
                      rules={{ required: 'Remark is required.' }}
                      render={({ field }) => (
                        <Form.Control
                          autoFocus
                          isInvalid={!!_.has(errors, 'remark')}
                          as="textarea"
                          rows={4}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {_.has(errors, 'remark') ? errors.remark.message : 'Invalid remark.'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {
          authenticated ? (
            <Button variant="primary" onClick={triggerSubmit} disabled={!canBook || loading}>
              {loading ? 'Loading...' : 'Confirm Reschedule'}
            </Button>
          ) : (
            <LoginModal />
          )
        }
      </Modal.Footer>
    </Modal>
  );
}
