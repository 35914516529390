/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Page, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  rowItem: {
    marginBottom: '16pt',
  },
  itemTitle: {
    fontWeight: 700,
    fontSize: '12pt',
  },
  itemValue: {
    fontWeight: 400,
    fontSize: '16pt',
  },
  netTitle: {
    fontWeight: 700,
    fontSize: '14pt',
  },
  netValue: {
    fontWeight: 600,
    fontSize: '19pt',
    color: '#FE9445',
  },
  hr: {
    borderBottom: '1pt',
    borderColor: '#000000',
    width: '100%',
    marginBottom: '16pt',
  },

  peso: {
    content: "20B1"
  }
});

export default function Index(payload) {
  const tutorPayslip = _.has(payload, 'tutorPayslip') ? payload.tutorPayslip : null;
  const [tutor, setTutor] = useState(null);
  const [payPeriod, setPayPeriod] = useState(null);
  const [subTotalGrossPay, setSubTotalGrossPay] = useState(null);
  const [totalGrossPay, setTotalGrossPay] = useState(null);
  const [totalIncentives, setTotalIncentives] = useState(null);
  const [totalDeductions, setTotalDeductions] = useState(null);
  const [totalWithholding, setTotalWithholding] = useState(null);
  const [totalFees, setTotalFees] = useState(null);
  const [totalNetPay, setTotalNetPay] = useState(null);
  const [vat, setVat] = useState(null);
  const [vatPercent, setVatPercent] = useState(null);

  useEffect(() => {
    const tutorPayroll = _.has(tutorPayslip, 'tutorPayroll') ? tutorPayslip.tutorPayroll : null;
    const iTutor = _.has(tutorPayroll, 'tutor') ? tutorPayroll.tutor : null;
    const iPayroll = _.has(tutorPayroll, 'payroll') ? tutorPayroll.payroll : null;
    const iStartDate = _.has(iPayroll, 'startDate') ? iPayroll.startDate : null;
    const iEndDate = _.has(iPayroll, 'endDate') ? iPayroll.endDate : null;
    const iPayPeriod = `${moment(iStartDate).format('ll')} to ${moment(iEndDate).format('ll')}`;
    const iSubTotalGrossPay = _.has(tutorPayslip, 'subTotalGrossPay') ? tutorPayslip.subTotalGrossPay : null;
    const iTotalGrossPay = _.has(tutorPayslip, 'totalGrossPay') ? tutorPayslip.totalGrossPay : null;
    const iTotalIncentives = _.has(tutorPayslip, 'totalIncentives') ? tutorPayslip.totalIncentives : null;
    const iTotalNetPay = _.has(tutorPayslip, 'totalNetPay') ? tutorPayslip.totalNetPay : null;
    const iTotalWithholding = _.has(tutorPayslip, 'totalWithholding') ? tutorPayslip.totalWithholding : null;
    const iTotalDeductions = _.has(tutorPayslip, 'totalDeductions') ? tutorPayslip.totalDeductions : null;
    const iTotalFees = _.has(tutorPayslip, 'totalFees') ? tutorPayslip.totalFees : null;
    const iVat = _.has(tutorPayroll, 'vat') ? tutorPayroll.vat : null;
    const iVatPercent = _.has(tutorPayroll, 'vatPercent') ? parseFloat(tutorPayroll.vatPercent) : null;

    setTutor(iTutor);
    setPayPeriod(iPayPeriod);
    setTotalGrossPay(iTotalGrossPay && iTotalGrossPay.toFixed(2));
    setTotalIncentives(iTotalIncentives && iTotalIncentives.toFixed(2));
    setTotalDeductions(iTotalDeductions && iTotalDeductions.toFixed(2));
    setTotalWithholding(iTotalWithholding && iTotalWithholding.toFixed(2));
    setTotalFees(iTotalFees && iTotalFees.toFixed(2));
    setTotalNetPay(iTotalNetPay && iTotalNetPay.toFixed(2));
    setSubTotalGrossPay(iSubTotalGrossPay && iSubTotalGrossPay.toFixed(2));
    setVat(iVat && iVat.toFixed(2))
    setVatPercent(iVatPercent)
  }, [tutorPayslip]);

  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <RowItem title="Tutor" value={tutor} />
          <RowItem title="Pay Period" value={payPeriod} />
          <View style={styles.hr} />

          <RowItem title="Subtotal Gross Pay" value={subTotalGrossPay} />
          <RowItem title={`VAT ${vatPercent}%`} value={`${vat && '-' + vat}`} />
          <RowItem title="Total Incentives" value={`${totalIncentives && '+' + totalIncentives}`} />
          <RowItem title="Total Deductions" value={`${totalDeductions && '-' + totalDeductions}`} />
          <RowItem title="Total Learnlive Fees" value={`${totalFees && '-' + totalFees}`} />
          <View style={styles.hr} />

          <RowItem title="Total Gross Pay" value={totalGrossPay} />
          <RowItem title="Total Withholding Tax" value={`${totalWithholding && '-' + totalWithholding}`} />
          <View style={styles.hr} />

          <NetPay title="Total Net Pay" value={totalNetPay} />
        </View>

      </Page>
    </Document>
  );
}

function RowItem({ title, value }) {
  return (
    <View style={styles.rowItem}>
      <Text style={styles.itemTitle}>{title}</Text>
      <Text style={styles.itemValue}>{value}</Text>
    </View>
  );
}

function NetPay({ title, value }) {
  return (
    <View style={styles.rowItem}>
      <Text style={styles.netTitle}>{title}</Text>
      <Text style={styles.netValue}>{value}</Text>
    </View>
  );
}
