/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ChargeContext from './charge.context';

export default function Index() {
  const navigate = useNavigate();
  const {
    paymentUid,
    tutorName,
    startDate,
  } = useContext(ChargeContext);

  const handleViewInvoice = useCallback(() => {
    navigate('/payments/invoice', { state: { paymentUid }, replace: true });
  }, [paymentUid]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Booking</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Booking</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fas fa-check" />
                    <h3>Appointment booked Successful!</h3>

                    <p>
                      Appointment booked with
                      {' '}
                      <b>{tutorName}</b>
                      {' '}
                      on
                      {' '}
                      <b>{startDate && moment(startDate).format('lll')}</b>
                    </p>

                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary view-inv-btn"
                      onClick={handleViewInvoice}
                    >
                      View Invoice
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
