/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import {
  Button, Modal, Card, Form,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Ratings } from '../../../components';

export default function Index(payload) {
  const [visible, setVisible] = useState(false);
  const { rating, feedback, interest } = payload;

  const { control, reset } = useForm({
    defaultValues: { feedback, ratings: rating },
  });

  const onClose = useCallback(() => {
    setVisible(false);
    reset();
  });

  const onShow = useCallback(() => {
    setVisible(true);
  });

  return (
    <>
      <Button
        className="view"
        variant="link"
        size="sm"
        onClick={onShow}
      >
        <FontAwesomeIcon icon={solid('eye')} />
        {' '}
        View
      </Button>

      <Modal
        show={visible}
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Student Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Title>
                <p className="lead text-info">
                  <FontAwesomeIcon icon={solid('chalkboard')} />
                  {' '}
                  {interest}
                </p>
              </Card.Title>

              <Form noValidate>
                <Form.Group className="form-group" controlId="rate.feedback">
                  <Form.Label>Feedback</Form.Label>
                  <Controller
                    name="feedback"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        as="textarea"
                        rows={4}
                        {...field}
                        disabled
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group className="form-group" controlId="rate.ratings">
                  <Form.Label className="form-control-label">Ratings</Form.Label>
                  <Controller
                    name="ratings"
                    control={control}
                    rules={{ required: 'Ratings is required.' }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        as={(item) => <Ratings {...item} size="xl" readonly />}
                      />
                    )}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
