import React from 'react';
import { DashboardTemplate } from '../../../template/components';
import Wallet from './wallet';

export default function Index() {
  return (
    <DashboardTemplate>
      <Wallet />
    </DashboardTemplate>
  );
}
