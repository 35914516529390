import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import InterestList from './list';
import FormModal from './formModal';
import _ from 'lodash'
import { AlertError } from '../../../../components'

export default function Index() {
  const [searchText, setSearchText] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(null)

  const onClickAdd = () => {
    setShow(true);
  };

  const handleClickEdit = (interestData) => {
    setIsEdit(true);
    setSelectedInterest(interestData);
    setShow(true);
  };

  const debounceSearch = _.debounce((value) => setSearchText(value), 500) 

  const onSearch = (value) => {
    debounceSearch(value)
  };

  return (
    <div>
      <Row className="mb-4 justify-content-end gap-2">
        <Col lg={3} className="">
          <div className="input-group">
            <input
              type="text"
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Search Interest"
              className="form-control"
            />
            <Button
              type="button"
              className="btn btn-primary"
              onClick={(e) => e.preventDefault()}
            >
              <i className="fa fa-search" />

            </Button>
          </div>
        </Col>
        <Col lg={3} className=" d-grid">
          <Button onClick={() => onClickAdd()} type="button">
            <i className="fa fa-plus" />
            {' '}
            Add Interest
          </Button>
        </Col>
      </Row>

      {error && <AlertError t
        error={error.title}
        title={error.message}
        onClose={() => setError(null)}
      />}

      <div className="card">
        <div className="card-body">
          <InterestList
            searchText={searchText}
            onClickEdit={(value) => handleClickEdit(value)}
            onError={({ title, message }) => setError({ title, message}) }
            onSuccess={() => setError(null)}
          />
        </div>
      </div>
      { show && (
        <FormModal
          isEdit={isEdit}
          initialValues={selectedInterest}
          show={show}
          onHide={() => {
            setShow(false);
            setIsEdit(false);
            setSelectedInterest(null);
          }}
        />
      )}
    </div>
  );
}
