import React from 'react';
import {
  BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, YAxis, Brush,
} from 'recharts';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { LoadingSpinner } from '../../../components';
import { GET_OVERALL_RATE_ATTENDANCE } from './gql';

export default function AttendanceBarchart() {
  const { data, loading } = useQuery(GET_OVERALL_RATE_ATTENDANCE);

  const result = _.has(data, 'getAttendanceOverallRate') ? data.getAttendanceOverallRate : null;
  const parsedData = result && result.length > 0 ? result.map((item) => {
    const tutor = _.has(item, 'tutor') ? item.tutor : null;
    const others = _.has(tutor, 'others') ? tutor.others : null;
    const firstName = _.has(others, 'firstName') ? others.firstName : null;
    const lastName = _.has(others, 'lastName') ? others.lastName : null;
    const attendance = _.has(item, 'attendance') ? item.attendance : null;
    const presentCount = _.has(attendance, 'presentCount') ? attendance.presentCount : null;
    const noShowCount = _.has(attendance, 'noShowCount') ? attendance.noShowCount : null;
    const lateCount = _.has(attendance, 'lateCount') ? attendance.lateCount : null;
    const present = _.has(attendance, 'present') ? attendance.present : null;
    const noShow = _.has(attendance, 'noShow') ? attendance.noShow : null;
    const late = _.has(attendance, 'late') ? attendance.late : null;
    const total = _.has(attendance, 'total') ? attendance.total : null;

    return {
      name: `${firstName} ${lastName}`,
      presentCount,
      noShowCount,
      lateCount,
      present,
      noShow,
      late,
      total,
    };
  }) : [];

  return (
    <div style={{ width: '100%', height: 400 }} className="d-flex m-auto">
      { !loading
        ? (
          <ResponsiveContainer width="100%"  height={400}>

            <BarChart width={150} height={40} data={parsedData}>
              <XAxis dataKey="name" />
              <YAxis dataKey="total" domain={[0, 100]} hide/>
              <Tooltip />
              <Bar barSize={40} dataKey="present" unit="%" name="Present" stackId="attStatus" fill="#ffc658" />
              <Bar barSize={40} dataKey="noShow" unit="%" name="No Show" stackId="attStatus" fill="#82ca9d" />
              <Bar barSize={40} dataKey="late" unit="%" name="Late" stackId="attStatus" fill="#8884d8" />
              <Brush dataKey="name" height={30} stroke="#8884d8" />
            </BarChart>

          </ResponsiveContainer>
        )
        : <div className="m-auto"><LoadingSpinner /></div>}
    </div>
  );
}
