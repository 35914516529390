import { gql } from '@apollo/client';

export const GET_PAGINATED_RECRUITMENTS = gql`
query GetPaginatedRecruitments($offset: Int, $limit: Int, $searchText: String $filter: RecruitmentFilter) {
    getPaginatedRecruitments(offset: $offset, limit: $limit, searchText: $searchText filter: $filter) {
      count
      rows {
        id
        uid
        status
        userUid
        createdAt
        createdBy
        updatedAt
        updatedBy
        user {
          id
          uid
          username
          email
          roleCode
          agreedPolicyTerm
          status
          userProfile {
            id
            uid
            userUid
            firstName
            middleInitial
            lastName
            age
            mobile
            birthDate
            gender
            address
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          avatar {
            id
            uid
            userUid
            fileCategory
            storage
          }
          qualification {
            id
            uid
            userUid
            about
            educations
            certifications
            introVideo
            govId
            nbi
            demoTeaching
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        logs {
          id
          uid
          recruitmentUid
          remarks
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`;

export const GET_PAGINATED_RECRUITMENT_LOGS = gql`
query GetRecruitmentLogs($offset: Int $limit: Int $recruitmentUid: String!) {
  getPaginatedRecruitmentLogs(offset: $offset limit: $limit recruitmentUid: $recruitmentUid) {
    count
    rows {
      id
      uid
      recruitmentUid
      remarks
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
`;

export const SAVE_RECRUITMENT_SETTINGS = gql`
mutation SaveRecruitmentSettings($userUid: String!, $coolOffPeriod: String, $coolOffPeriodType: String) {
  saveRecruitmentSettings(userUid: $userUid, coolOffPeriod: $coolOffPeriod, coolOffPeriodType: $coolOffPeriodType) 
  {
    id
    uid
    name
    value
    module
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const GET_RECRUITMENT_SETTINGS = gql`
query GetRecruitmentSettings {
  getRecruitmentSettings {
    id
    uid
    name
    value
    module
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;

export const UPDATE_RECRUITMENT_STATUS = gql`
mutation UpdateRecruitmentStatus($recruitmentUid: String!, $newStatus: String!, $updatedBy: String!, $remarks: String) {
  updateRecruitmentStatus(recruitmentUid: $recruitmentUid, newStatus: $newStatus, updatedBy: $updatedBy, remarks: $remarks) {
    id
    uid
    status
    userUid
    details
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;


export const CHANGE_TUTOR_STATUS = gql`
mutation ChangeTutorStatus($uid: ID! $status: String!) {
  changeTutorStatus(uid: $uid, status: $status) {
      id
      uid
      userUid
      status
      others
      priceRange
      rating

      createdAt
      createdBy
      updatedAt
      updatedBy
  }
}`

export const GET_USER_TUTOR_DETAILS = gql`
query GetUserTutorDetails($userUid: ID!) {
  getUserTutorDetails(userUid: $userUid) {
      id
      uid
      userUid
      status
      others
      priceRange

      createdAt
      createdBy
      updatedAt
      updatedBy
  }
}
`