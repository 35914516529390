import { useMutation } from '@apollo/client';
import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import ModalConfirm from '../../../components/modalConfirm';
import { UPDATE_USER_STATUS, sendSystemEmailMutation } from './gql';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import NotificationClient from '../../../NotificationClient';
import RegistryClient from '../../../RegistryClient';
import LoginContext from '../../login/login.context';


export default function updateStatusModal({ userUid, status, firstName, lastName, email, userRole }) {

  const { userUid: currentUserUid } = useContext(LoginContext);
  const [saving, setSaving] = useState(false)

  const { doInsertAuditTrail } = useCreateAuditTrail();
  const [sendEmail] = useMutation(sendSystemEmailMutation, {
    client: NotificationClient,
  });

  const [updateStatus] = useMutation(UPDATE_USER_STATUS, {
    client: RegistryClient,
  });

  const sendEmailNotif = ({
    email, status, firstName, lastName,
  }) => {
    const customerCareEmail = process.env.REACT_APP_CUSTOMERCARE;

    if (status !== 'ACTIVE') {
      const variables = {
        to: email,
        subject: 'Your account has been deactivated',
        html: `
        <p>Dear ${firstName} ${lastName},</p>
        <p>This is to inform you that your LearnLive account has been deactivated.</p>
        <p>Please contact the administrator for any concerns and reactivation of your account.</p>
        <p>Please email us at ${customerCareEmail}</p>
        <br/>
        <p>Thank you and have a good day!</p>
        <br/>
        <p>LearnLive Team 
        `,
      };

      sendEmail({ variables });
    } else {
      const variables = {
        to: email,
        subject: 'Welcome back to LearnLive!',
        html: `
        <p>Hello ${firstName} ${lastName},</p>
        <p>Good Day! <p/>
        <p>Your LearnLive account has been reactivated. You may now access it in learnlive.ph to continue your tutorial journey. </p>
        <p>Let  us know if you have any concerns regarding this.</p>
        <br/>
        <p>Email us at  ${customerCareEmail}. Thank you and glad to have you back here at LearnLive!</p>
        <br/>
        <p>LearnLive Team 
        `,
      };

      sendEmail({ variables });
    }
  };

  const handleSubmit = () => {
    setSaving(true)
    updateStatus({
      variables: {
        uid: userUid,
        updatedBy: currentUserUid,
        status: (status === 'ACTIVE') ? 'INACTIVE' : 'ACTIVE',
      },
    }).then(() => {
      doInsertAuditTrail({
        action: 'UPDATE',
        changes: status === 'ACTIVE' ? `Activated ${firstName} ${lastName}` : `Deactivated ${firstName} ${lastName}`,
        module: 'User Management',
      });
      sendEmailNotif({
        status: (status === 'ACTIVE') ? 'INACTIVE' : 'ACTIVE',
        firstName,
        lastName,
        email,
      });
      setSaving(false)

    })
      .catch(err => {
        // todo: set error here
        setSaving(false)
      })
  };

  return (
    <ModalConfirm
      message={(
        <span>
          {`Are you sure you want to ${status === 'ACTIVE' ? 'deactivate ' : 'activate '}`}
          <b>
            {firstName}
            {' '}
            {lastName}
          </b>
          {' '}
          ?
        </span>
      )}
      onOk={handleSubmit}
    >
      {({ onClick }) => (
        <>
          {status === 'ACTIVE' && (
            <Button
              disabled={saving}
              variant="secondary"
              onClick={onClick}
            >
              {saving ? 'loading...' : 'Deactivate'}
            </Button>
          )}

          {status === 'INACTIVE' && (
            <Button
              variant="primary"
              onClick={onClick}
              disabled={saving}
            >
              {saving ? 'loading...' : 'Activate'}
            </Button>
          )}
        </>
      )}
    </ModalConfirm>
  );
}
