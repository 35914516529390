import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import ls from 'local-storage';
import _ from 'lodash';

const uri = `${process.env.REACT_APP_PAYMENT_API}/graphql`;
const tokenManager = ls.get('tokenManager');
const idToken = _.has(tokenManager, 'idToken') ? tokenManager.idToken : null;

export const cache = new InMemoryCache({
  cacheRedirects: {
    // avoid requesting the same data that already in the client that has different key
    Query: {
      // getAvpFile: (_, args) => toIdValue(cache.config.dataIdFromObject({ __typename: 'AVPFile', uid: args.uid })),
      // getTutorialVideo: (_, args) => toIdValue(cache.config.dataIdFromObject({ __typename: 'TutorialVideo', uid: args.uid })),
    },
  },
});

const headers = {
  authorization: idToken ? `Bearer ${idToken}` : '',
};

// eslint-disable-next-line new-cap
const link = new createUploadLink({ uri, headers });

const client = new ApolloClient({
  cache,
  link,
});

export default client;
