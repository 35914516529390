import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { recruitmentStatusLabel } from '../../../constants'
import {
  GET_LAST_ACTIVITY, GET_TUTOR_RATING,
  GET_TUTOR_REVIEW_COUNT,
  GET_USER,
} from './gql';
import AuditrTrailClient from '../../../AuditTrailClient';
import UpdateStatusModal from './updateStatusModal';
import UserImage from '../../../components/userImage';
import RegistryClient from '../../../RegistryClient';


const APPCODE = process.env.REACT_APP_CODE || 'LEARNLIVE_TUTORIAL';
const TUTOR = 'TUTOR'

export default function tutorTableItem({ tutorData, onUpdateStatus }) {
  const tutorUid = _.has(tutorData, 'uid') ? tutorData.uid : null;
  const tutorUserUid = _.has(tutorData, 'userUid') ? tutorData.userUid : null;
  // const tutorStatus = _.has(tutorData, 'status') ? tutorData.status : null;
  const others = _.has(tutorData, 'others') ? tutorData.others : null;
  const email = _.has(others, 'email') ? others.email : null;
  const firstName = _.has(others, 'firstName') ? others.firstName : null;
  const lastName = _.has(others, 'lastName') ? others.lastName : null;

  const avatar = _.has(tutorData, 'avatar') ? tutorData.avatar : null;
  const avatarStorage = _.has(avatar, 'storage') ? avatar.storage : null;

  const avatarPath = _.has(avatarStorage, 'path') ? avatarStorage.path : null;

  // activity
  // const createdAt = _.has(tutorData, 'createdAt') ? tutorData.createdAt : null;

  const { data: userData } = useQuery(GET_USER, {
    client: RegistryClient,
    variables: {
      uid: tutorUserUid
    }
  })

  const userDetails = _.has(userData, 'getUser') ? userData.getUser : null;
  const userStatus = _.has(userDetails, 'status') ? userDetails.status : null

  const { data } = useQuery(GET_LAST_ACTIVITY, {
    client: AuditrTrailClient,
    variables: {
      userUid: tutorUserUid,
      appCode: APPCODE,
      module: 'Sessions',
    },
  });

  const lastActivityResult = _.has(data, 'getLastActivity') ? data.getLastActivity : null;
  const lastActivityChanges = _.has(lastActivityResult, 'changes') ? lastActivityResult.changes : null;
  // const lastActivityDetails = _.has(lastActivityResult, 'details') ? lastActivityResult.details : null;
  const lastActivityCreatedAt = _.has(lastActivityResult, 'createdAt') ? lastActivityResult.createdAt : null

  return (
    <tr>
      <td>
        <h2 className="table-avatar">
          <Link to={`/profile/${tutorUserUid}`} className="avatar avatar-sm me-2">
            <UserImage filePath={avatarPath} />

          </Link>
          <Link to={`/profile/${tutorUserUid}`}>
            {firstName}
            {' '}
            {lastName}
          </Link>
        </h2>
      </td>
      <td width="15%">
        {lastActivityChanges}
      </td>
      <td>
        {moment(lastActivityCreatedAt).isValid() && moment(lastActivityCreatedAt).format('LLL')}
      </td>
      <td>
        {tutorUid ? <OverallRating tutorUid={tutorUid} /> : '--'}
      </td>
      <td>
        <span className={userStatus === 'ACTIVE' ? 'text-success' : 'text-secondary'}>
          {recruitmentStatusLabel[userStatus]}
        </span>
      </td>
      <td>
        <UpdateStatusModal
          userUid={tutorUserUid}
          status={userStatus}
          firstName={firstName}
          lastName={lastName}
          email={email}
          userRole={TUTOR}
        />
      </td>
    </tr>
  );
}

function OverallRating({ tutorUid }) {
  const { data } = useQuery(GET_TUTOR_RATING, {
    variables: {
      tutorUid,
    },
  });

  const { data: reviewData } = useQuery(GET_TUTOR_REVIEW_COUNT, {
    variables: { tutorUid },
  });
  const rating = _.has(data, 'getTutorRating') ? data.getTutorRating : 0;
  const reviewCount = _.has(reviewData, 'getTutorReviewCount') ? reviewData.getTutorReviewCount : 0;
  return (
    <span>
      {rating}
      <i className="fa fa-star text-primary" />
      {' '}
      /
      {' '}
      {reviewCount}
      <i className="fa fa-comment text-primary" />
    </span>
  );
}