import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AboutTutor from '../personalProfile/about';
import Qualifications from './qualifications';
import ForReviewModal from './forReviewModal';
import UserFiles from './userFiles';
import Certifications from './certifications';
import Others from './others';

export { default as Settings } from './settings';

export default function Index() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="4">
          <ForReviewModal />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <AboutTutor />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Qualifications />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Certifications />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Others />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <UserFiles />
        </Col>
      </Row>
    </Container>
  );
}
