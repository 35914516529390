/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SAVE_DEFAULT_COMMISSION, GET_DEFAULT_COMMISSION } from './gql';
import LoginContext from '../../login/login.context';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { AlertError } from '../../../components'


export default function defaultCommissionModal({ show, onHide }) {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const {
    handleSubmit, control, formState, watch, setValue, resetField,
  } = useForm();
  const { errors } = formState;

  const [saveCommission] = useMutation(SAVE_DEFAULT_COMMISSION, {
    refetchQueries: ['GetPaginatedCommissions']
  });

  const [loadCommission, { loading, data: commissionData }] = useLazyQuery(GET_DEFAULT_COMMISSION, {
    variables: {
      roleCode: watch('roleCode'),
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'roleCode') {
        if (value.roleCode !== 'ALL') {
          loadCommission();
        } else {
          resetField('percentageRate');
          resetField('effectivityDate');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const commissionResult = _.has(commissionData, 'getDefaultCommission') ? commissionData.getDefaultCommission : null;
    const percentage = _.has(commissionResult, 'percentage') ? commissionResult.percentage : 0;
    const effectivityDate = _.has(commissionResult, 'effectivityDate') ? commissionResult.effectivityDate : null;

    if (commissionResult) {
      setValue('percentageRate', percentage);
      setValue('effectivityDate', effectivityDate ? new Date(effectivityDate) : null);
    }
  }, [loading, commissionData]);

  const onSubmit = (data, e) => {
    setSaving(true)
    setError(null)
    e.preventDefault();

    saveCommission({
      variables: {
        ...data,
        percentage: parseFloat(data.percentageRate),
        updatedBy: userUid,
      },
    }).then(() => {
      setSaving(false)
      // audit trail
      doInsertAuditTrail({
        action: 'CREATE',
        changes: `Created default Learnlive Fees for ${data.roleCode}`,
        module: 'Learnlive Fees',
      });
      onHide(false);
    })
    .catch(err => {
      setSaving(false)
      setError(err.toString())
    })
  };

  return (
    <Modal show={show} onHide={() => onHide()}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>
            Set Default Commission Rate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {error && <AlertError 
                title='Failed to save'
                error={error}
                onClose={() => setError(null)}
            />}
          <Form.Group>
            <Form.Label>Select Role</Form.Label>
            <Controller
              name="roleCode"
              control={control}
              rules={{
                required: 'Role is required.',
                validate: (value) => !!value.trim() || 'Role cannot be empty.',
              }}
              render={({ field }) => (
                <Form.Select
                  autoFocus
                  isInvalid={!!_.has(errors, 'roleCode')}
                  {...field}
                >
                  <option value="ALL">Select role</option>
                  <option value="TUTOR">Freelance Tutor</option>
                  <option value="PARTNER_MERCHANT">Partner Merchant</option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'roleCode') ? errors.roleCode.message : 'Invalid role.'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Percentage rate</Form.Label>
            <Controller
              name="percentageRate"
              control={control}
              rules={{
                required: 'Percentage rate is required.',
              }}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  autoFocus
                  isInvalid={!!_.has(errors, 'percentageRate')}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {_.has(errors, 'percentageRate') ? errors.percentageRate.message : 'Invalid Percentage rate.'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Effectivity Date</Form.Label>
            <Controller
              name="effectivityDate"
              control={control}
              rules={{ required: 'Effectivity Date is required.' }}
              render={({ field }) => (
                <DatePicker
                  isClearable
                  customInput={(
                    <Form.Control
                      isInvalid={!!_.has(errors, 'effectivityDate')}
                      feedback={errors.effectivityDate}
                    />
                  )}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  dateFormat="MMMM d, yyyy"
                  minDate={addDays(new Date(), 14)}
                  {...field}
                />
              )}
            />
            <p className="text-danger mt-1" style={{ fontSize: '.875em' }}>
              {_.has(errors, 'effectivityDate') ? errors.effectivityDate.message : ''}
            </p>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={saving} onClick={() => onHide()}>Cancel</Button>
          <Button variant="primary"disabled={saving} type="submit">{saving ? 'Saving...' : 'Save'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
