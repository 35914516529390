import { useMutation } from '@apollo/client';
import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import LoginContext from '../../login/login.context';
import PaymentClient from '../../../PaymentClient';
import { UPDATE_VOUCHER_STATUS, DELETE_VOUCHER } from './gql';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import ModalConfirm from '../../../components/modalConfirm';

const DELETED = 'DELETED';

export default function publishModal({ data, onError, onSuccess }) {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();
  const [saving, setSaving] = useState(false)

  const uid = _.has(data, 'uid') ? data.uid : null;
  const status = _.has(data, 'status') ? data.status : null;
  const totalUsed = _.has(data, 'totalUsed') ? data.totalUsed : 0;

  const [updateStatus] = useMutation(UPDATE_VOUCHER_STATUS, {
    client: PaymentClient,
  });

  const [deleteVoucher] = useMutation(DELETE_VOUCHER, {
    client: PaymentClient,
    refetchQueries: ['GetPaginatedVouchers'],
  });

  const handleOk = () => {
    setSaving(true)
    if (status === 'DRAFT' && totalUsed === 0) {
      deleteVoucher({
        variables: {
          uid,
          deletedBy: userUid,
        },
      }).then(() => {
        setSaving(false)
        if(onSuccess) {
          onSuccess()
        }
        // audit trail
        doInsertAuditTrail({
          action: 'DELETE',
          changes: `Deleted ${data.code} voucher`,
          module: 'Voucher Code',
        });
      })
      .catch(err => {
        setSaving(false)
        if(onError) {
          onError({
            title: 'Failed to delete voucher',
            message: err.toString()
          })
        }
      })
    } else {
      updateStatus({
        variables: {
          uid,
          status: DELETED,
          updatedBy: userUid,
        },
      }).then(() => {
        setSaving(false)
        if(onSuccess) {
          onSuccess()
        }
        // audit trail
        doInsertAuditTrail({
          action: 'DELETE',
          changes: `Deleted ${data.code} voucher`,
          module: 'Voucher Code',
        });
      })
      .catch(err => {
        setSaving(false)
        if(onError) {
          onError({
            title: 'Failed to delete voucher',
            message: err.toString()
          })
        }
      })
    }
  };

  return (
    <ModalConfirm
      onOk={() => handleOk()}
      message={(
        <p>
          Are you sure you want to delete
          {' '}
          <b>{data.code}</b>
          ?
        </p>
        )}
    >
      {({ onClick }) => (
        <Button
          disabled={saving}
          title="Delete"
          variant="danger"
          onClick={onClick}
        >
          {!saving ? 'Delete': 'Deleting'}
        </Button>
      )}
    </ModalConfirm>
  );
}
