/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ChargeContext from './charge.context';

export default function Index() {
  const navigate = useNavigate();
  const {
    paymentUid,
    amount,
    topUpAt,
  } = useContext(ChargeContext);

  const backToWallet = useCallback(() => {
    navigate('/payments', { replace: true });
  }, [paymentUid]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cash In</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Cash In</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fas fa-check" />
                    <h3>Wallet Cash In Successful!</h3>

                    <p>
                      You have top up an amount of
                      {' '}
                      <b>{amount}</b>
                      {' '}
                      on
                      {' '}
                      <b>{moment(topUpAt).format('lll')}</b>
                    </p>
                    <p className="text-primary">
                      Transaction UID:
                      {' '}
                      {paymentUid}
                    </p>

                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary view-inv-btn"
                      onClick={backToWallet}
                    >
                      Wallet
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
