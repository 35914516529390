/* eslint-disable react/no-unescaped-entities */
import React, {
  useCallback, useContext, useState,
} from 'react';
import {
  Alert, Button, Form, Modal,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMutation } from '@apollo/client';
import { changeTutorStatusMutation } from './gql';
import { LoginContext } from '../../login';

import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';

export default function Index() {
  const [show, setShow] = useState(false);
  const {
    handleSubmit, formState, control, reset,
  } = useForm();
  const { errors } = formState;
  const { instanceUid } = useContext(LoginContext);

  const { doInsertAuditTrail, userFullName } = useCreateAuditTrail();

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);

  const [changeStatus] = useMutation(changeTutorStatusMutation, {
    onCompleted: () => {
      doInsertAuditTrail({
        action: 'UPDATE',
        changes: `${userFullName} showed his/her profile`,
        module: 'My Profile',
      });

      handleClose();
    },
  });

  const submitForm = useCallback(() => {
    changeStatus({
      variables: {
        uid: instanceUid,
        status: 'ACTIVE',
      },
    });
  });

  const triggerSubmit = () => {
    handleSubmit(submitForm)();
  };

  const isYes = ((value) => {
    const isValid = _.toLower(value) === 'yes';

    if (!isValid) { return 'Please reply with "YES"'; }

    return isValid;
  });

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        <FontAwesomeIcon icon={solid('user-gear')} />
        {' '}
        Show Profile
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Show Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            <Alert.Heading>
              <FontAwesomeIcon icon={solid('exclamation-triangle')} />
              {' '}
              Warning!
            </Alert.Heading>
            <p>
              Be aware that confirming will show your profile and can
              be searchable by the students.
            </p>
          </Alert>
          <Form noValidate>
            <Form.Group className="form-group" controlId="hide.confirm">
              <Form.Label>Input "YES" to confirm</Form.Label>
              <Controller
                name="confirm"
                control={control}
                rules={{
                  required: 'Confirmation is required.',
                  validate: {
                    isYes,
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    autoFocus
                    isInvalid={!!_.has(errors, 'confirm')}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {_.has(errors, 'confirm') ? errors.confirm.message : 'Invalid confirm.'}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={triggerSubmit}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
