import React, {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Session from './session';
import { getAttendanceQuery, getSessionQuery, getUserQuery } from './gql';
import { LoginContext } from '../login';
import SessionContext from './sessions.context';
import RegistryClient from '../../RegistryClient';

export default function Index() {
  const navigate = useNavigate();
  const loc = useLocation();
  const state = _.has(loc, 'state') ? loc.state : null;
  const sessionUid = _.has(state, 'sessionUid') ? state.sessionUid : null;
  const { instanceUid, roleCode } = useContext(LoginContext);
  const [tutorName, setTutorName] = useState(null);
  const [tutorInitial, setTutorInitial] = useState(null);
  const [sessionStatus, setSessionStatus] = useState(null);
  const [interest, setInterest] = useState(null);
  const [sessionType, setSessionType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [link, setLink] = useState(null);
  const [attendanceUid, setAttendanceUid] = useState(null);
  const [attendanceStatus, setAttendanceStatus] = useState(null);
  const [canJoin, setCanJoin] = useState(false);
  const [location, setLocation] = useState(null);
  const [tutorUid, setTutorUid] = useState(null);
  const [tutorUserUid, setTutorUserUid] = useState(null);
  const [tutorRoleCode, setTutorRoleCode] = useState(null);

  useEffect(() => {
    if (!sessionUid) {
      if (roleCode === 'TUTOR') {
        navigate('/tutor');
      } else if (roleCode === 'TUTEE') {
        navigate('/student');
      }
    }
  }, [sessionUid]);

  const { data: sessionResult } = useQuery(getSessionQuery, {
    skip: !sessionUid,
    variables: { uid: sessionUid },
  });

  const [fetchUser] = useLazyQuery(getUserQuery, { client: RegistryClient });

  const { data: attendanceResult, refetch: refetchAttendance } = useQuery(getAttendanceQuery, {
    skip: !instanceUid || !sessionUid,
    variables: { sessionUid, instanceUid },
  });

  useEffect(() => {
    const attendance = _.has(attendanceResult, 'getAttendance') ? attendanceResult.getAttendance : null;
    const uid = _.has(attendance, 'uid') ? attendance.uid : null;
    const attStatus = _.has(attendance, 'status') ? attendance.status : null;

    setAttendanceUid(uid);
    setAttendanceStatus(attStatus);
  }, [attendanceResult]);

  useEffect(() => {
    const session = _.has(sessionResult, 'getSession') ? sessionResult.getSession : null;
    const rslot = _.has(session, 'timeslot') ? session.timeslot : null;
    const rProfile = _.has(rslot, 'tutorialProfile') ? rslot.tutorialProfile : null;
    const rTutor = _.has(rProfile, 'tutor') ? rProfile.tutor : null;
    const rTutorUid = _.has(rTutor, 'uid') ? rTutor.uid : null;
    const rOthers = _.has(rTutor, 'others') ? rTutor.others : null;
    const rFirstName = _.has(rOthers, 'firstName') ? _.startCase(rOthers.firstName) : null;
    const rInitial = _.has(rOthers, 'middleInitial') ? _.startCase(rOthers.middleInitial) : null;
    const rLastName = _.has(rOthers, 'lastName') ? _.startCase(rOthers.lastName) : null;
    const rFullName = `${rFirstName || ''} ${rInitial || ''} ${rLastName || ''} `;
    const rTutorInitial = `${rFirstName && rFirstName.charAt(0)}${rLastName && rLastName.charAt(0)}`;
    const rstatus = _.has(session, 'status') ? session.status : null;
    const iTutorUserUid = _.has(rTutor, 'userUid') ? rTutor.userUid : null;

    const rInterest = _.has(rProfile, 'interest') ? rProfile.interest : null;
    const rSessionType = _.has(rProfile, 'sessionType') ? rProfile.sessionType : null;
    const rStartDate = _.has(session, 'startDate') ? session.startDate : null;
    const rEndDate = _.has(session, 'endDate') ? session.endDate : null;
    const rLink = _.has(session, 'link') ? session.link : null;
    const rCanJoin = _.has(session, 'canJoin') ? session.canJoin : null;

    setTutorUid(rTutorUid);
    setTutorName(rFullName);
    setTutorInitial(rTutorInitial);
    setSessionStatus(rstatus);
    setInterest(rInterest);
    setSessionType(rSessionType);
    setStartDate(rStartDate);
    setEndDate(rEndDate);
    setLink(rLink);
    setCanJoin(rCanJoin);

    if (iTutorUserUid) {
      fetchUser({ variables: { uid: iTutorUserUid } })
        .then(({ data: userResult }) => {
          const user = _.has(userResult, 'getUser') ? userResult.getUser : null;
          const userUid = _.has(user, 'uid') ? user.uid : null;
          setTutorUserUid(userUid);

          const iTutorRoleCode = _.has(user, 'roleCode') ? user.roleCode : null;
          setTutorRoleCode(iTutorRoleCode);

          const profile = _.has(user, 'userProfile') ? user.userProfile : null;
          const iAddress = _.has(profile, 'address') ? profile.address : null;
          const city = _.has(iAddress, 'city') ? iAddress.city : null;
          const countryCode = _.has(iAddress, 'countryCode') ? iAddress.countryCode : null;
          setLocation(`${city}, ${countryCode}`);
        });
    }
  }, [sessionResult]);

  const contextPayload = useMemo(() => ({
    tutorName,
    tutorInitial,
    sessionStatus,
    interest,
    sessionType,
    startDate,
    endDate,
    link,
    sessionUid,
    attendanceStatus,
    attendanceUid,
    canJoin,
    location,
    refetchAttendance,
    tutorUid,
    tutorUserUid,
    tutorRoleCode,
  }), [
    tutorName,
    tutorInitial,
    sessionStatus,
    interest,
    sessionType,
    startDate,
    endDate,
    link,
    sessionUid,
    attendanceStatus,
    attendanceUid,
    canJoin,
    location,
    refetchAttendance,
    tutorUid,
    tutorUserUid,
    tutorRoleCode,
  ]);

  return (
    <div className="content">
      <div className="container-fluid">
        <SessionContext.Provider value={contextPayload}>
          <Session />
        </SessionContext.Provider>
      </div>
    </div>
  );
}
