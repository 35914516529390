import { gql } from '@apollo/client';

export const GET_USER_COMMISSION = gql`
query GetUserCommission($userUid: String!, $roleCode: String!) {
    getUserCommission(userUid: $userUid, roleCode: $roleCode) {
      id
      uid
      userUid
      roleCode
      percentage
      type
      effectivityDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
    }
  }
`;

export const GET_DEFAULT_COMMISSION = gql`
query GetDefaultCommission($roleCode: String!) {
    getDefaultCommission(roleCode: $roleCode) {
      id
      uid
      userUid
      roleCode
      percentage
      type
      effectivityDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
    }
  }
`;

export const SAVE_DEFAULT_COMMISSION = gql`
mutation SaveDefaultCommission($updatedBy: String!, $roleCode: String!, $effectivityDate: String!, $percentage: Float!) {
    saveDefaultCommission(updatedBy: $updatedBy, roleCode: $roleCode, effectivityDate: $effectivityDate, percentage: $percentage) {
      id
      uid
      userUid
      roleCode
      percentage
      type
      effectivityDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
    }
  }
`;

export const SAVE_USER_COMMISSION = gql`
mutation SaveUserCommission($userUid: String!, $updatedBy: String!, $roleCode: String!, $effectivityDate: String!, $percentage: Float!) {
    saveUserCommission(userUid: $userUid, updatedBy: $updatedBy, roleCode: $roleCode, effectivityDate: $effectivityDate, percentage: $percentage) {
      id
      userUid
      roleCode
      percentage
      type
      effectivityDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
    }
  }
`;

export const GET_PAGINATED_COMMISSIONS = gql`
query GetPaginatedCommissions($offset: Int!, $limit: Int, $filter: CommissionFilter) {
  getPaginatedCommissions(offset: $offset, limit: $limit, filter: $filter) {
    count
    rows {
      id
      uid
      userUid
      roleCode
      percentage
      type
      effectivityDate
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
    }
  }
}
`;

export const UPDATE_COMMISSIONS = gql`
mutation UpdateCommission($uid: String! $updatedBy: String!, $roleCode: String!, $effectivityDate: String!, $percentage: Float! $userUid: String) {
  updateCommission(uid: $uid updatedBy: $updatedBy, roleCode: $roleCode, effectivityDate: $effectivityDate, percentage: $percentage userUid: $userUid) {
    id
    uid
    userUid
    roleCode
    percentage
    type
    effectivityDate
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
  }
}
`;
