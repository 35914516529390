import { gql } from '@apollo/client';

export const GET_ALL_INTEREST = gql`
query GetAllInterest($status: String) {
    getAllInterest(status: $status) {
      id
      uid
      title
      description
      status
      participantsLimit
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const GET_PAGINATED_INTEREST = gql`
query GetPaginatedInterest($status: String, $offset: Int, $limit: Int, $searchText: String) {
    getPaginatedInterest(status: $status, offset: $offset, limit: $limit, searchText: $searchText) {
      count
      rows {
        id
        uid
        title
        description
        status
        participantsLimit
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const GET_INTEREST_BY_TITLE = gql`
query GetInterestByTitle($title: String! $uid: String) {
  getInterestByTitle(title: $title uid: $uid) {
    id
    uid
    title
    description
    status
    participantsLimit
  }
}
`;

export const ADD_INTEREST = gql`
mutation AddInterest($title: String!, $createdBy: String!, $description: String, $status: String $participantsLimit: Int) {
    addInterest(title: $title, createdBy: $createdBy, description: $description, status: $status participantsLimit: $participantsLimit) {
      id
      uid
      title
      description
      status
      participantsLimit
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const UPDATE_INTEREST_STATUS = gql`
mutation UpdateInterestStatus($uid: String!, $updatedBy: String!) {
    updateInterestStatus(uid: $uid, updatedBy: $updatedBy) {
      id
      uid
      title
      description
      status
      participantsLimit
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const EDIT_INTEREST = gql`
mutation EditInterest($uid: String!, $title: String!, $updatedBy: String!, $description: String $participantsLimit: Int) {
    editInterest(uid: $uid, title: $title, updatedBy: $updatedBy, description: $description participantsLimit: $participantsLimit) {
      id
      uid
      title
      description
      status
      participantsLimit
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_INTEREST = gql`
mutation DeleteInterest($uid: String!, $deletedBy: String!) {
    deleteInterest(uid: $uid, deletedBy: $deletedBy)
  }
`;
