import { gql } from '@apollo/client';

export const GET_PAGINATED_AUDIT_TRAIL = gql`
query Query($offset: Int!, $limit: Int!, $searchText: String, $filter: AuditTrailFilter) {
    getPaginatedAuditTrail(offset: $offset, limit: $limit, searchText: $searchText, filter: $filter) {
      count
      rows {
        id
        uid
        action
        appCode
        module
        details
        changes
        userFullName
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const CREATE_AUDIT_TRAIL = gql`
mutation CreateAuditTrail($appCode: String!, $action: String!, $userUid: String!, $userFullName: String!, $changes: String!, $module: String, $details: JSON) {
    createAuditTrail(appCode: $appCode, action: $action, userUid: $userUid, userFullName: $userFullName, changes: $changes, module: $module, details: $details) {
      id
      uid
      action
      appCode
      module
      details
      changes
      userFullName
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const INSERT_VIEW_PROFILE = gql`
mutation InsertViewProfile($appCode: String!, $changes: String!, $userUid: String!, $userFullName: String!, $profileUserUid: String!, $module: String) {
  insertViewProfile(appCode: $appCode, changes: $changes, userUid: $userUid, userFullName: $userFullName, profileUserUid: $profileUserUid, module: $module) {
    id
    uid
    action
    appCode
    module
    details
    changes
    userFullName
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
`;
