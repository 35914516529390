import { gql } from '@apollo/client';

export const GET_TUTOR_AVERAGE_RATING = gql`
query GetTutorAverageRating {
    getTutorAverageRating {
      id
      uid
      userUid
      status
      others
      priceRange
      rating
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const GET_PAGINATED_TUTOR_RATINGS = gql`
query GetPaginatedTutorRatings($offset: Int!, $limit: Int, $tutorUid: String) {
  getPaginatedTutorRatings(offset: $offset, limit: $limit, tutorUid: $tutorUid) {
    count
    rows {
      id
      uid
      sessionUid
      tutorUid
      review
      ratings
      document
      createdAt
      createdBy
      updatedAt
      updatedBy
      tutor {
        id
        uid
        userUid
        status
        others
        priceRange
        rating
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
}
`;

export const GET_TUTORS_STUDENTS_COUNT = gql`
query GetTutorsStudentsCount {
  getTutorsStudentsCount {
    tutor {
      id
      uid
      userUid
      status
      others
      priceRange
      rating
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    studentCounts
  }
}
`;

export const GET_TUTORS_TEACHERING_HOURS = gql`
query GetTutorsTeacheringHours {
  getTutorsTeacheringHours {
    tutor {
      id
      uid
      userUid
      status
      others
      priceRange
      rating
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    totalHours
  }
}
`;

export const GET_OVERALL_RATE_ATTENDANCE = gql`
query Query {
  getAttendanceOverallRate
}
`;
