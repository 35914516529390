import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useQuery } from '@apollo/client';
import { DynamicAvatar, TutorRating } from '../../components';
import RescheduleModal from './rescheduleModal';
import RescheduleContext, { CalendarContext } from './reschedule.context';
import { LoginContext } from '../login';
import { getSessionCalendarSlotsQuery } from './gql';
import styledComponents from 'styled-components'

const StyledButtonNav = styledComponents(Button)`
  padding: unset;
  font-size: inherit;
  margin: unset;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  border: unset;
  border-radius: unset;
`;

moment.locale('en');
const localizer = momentLocalizer(moment);

export default function Index() {
  const navigate = useNavigate();
  const {
    tutorProfile, location, tutor,
  } = useContext(RescheduleContext);
  const [fullName, setFullName] = useState(null);
  const [tutorUid, setTutorUid] = useState(null);
  const { userUid, roleCode } = useContext(LoginContext);

  useEffect(() => {
    const uid = _.has(tutor, 'uid') ? tutor.uid : null;
    const firstName = _.has(tutorProfile, 'firstName') ? tutorProfile.firstName : null;
    const middleInitial = _.has(tutorProfile, 'middleInitial') ? tutorProfile.middleInitial : null;
    const lastName = _.has(tutorProfile, 'lastName') ? tutorProfile.lastName : null;
    const consFullName = `${firstName || ''} ${middleInitial || ''} ${lastName || ''}`;

    setTutorUid(uid);
    setFullName(consFullName);
  }, [
    tutor,
    tutorProfile,
    setFullName,
    setTutorUid,
  ]);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a> */}
                    <StyledButtonNav variant='button' onClick={() => navigate('/')}>Home</StyledButtonNav>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Reschedule Booking</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Reschedule Booking</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col lg={{ span: 1 }}>
                      <DynamicAvatar userUid={userUid} roleCode={roleCode} size="large" />
                    </Col>
                    <Col lg={{ span: 2 }}>
                      <div className="booking-info m-2">
                        <h4><a href="profile.html">{fullName}</a></h4>
                        <TutorRating tutorUid={tutorUid} />
                        <p className="text-muted mb-0">
                          <i className="fas fa-map-marker-alt" />
                          {' '}
                          {location}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <Row>
                <Col className="mb-3">
                  <BookingCalendar />
                </Col>
              </Row>

              <Row>
                <Col className="mb-3 d-grid gap-2" lg={{ span: 2, offset: 10 }}>
                  <Button size="lg" variant="primary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={solid('backward-step')} />
                    {' '}
                    Back
                  </Button>
                </Col>
              </Row>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function BookingCalendar() {
  const { sessionUid, setLoading, loading } = useContext(RescheduleContext);
  const [myEvents, setEvents] = useState([]);
  const [view, setView] = useState('week');
  const [forBooking, setForBooking] = useState(null);

  const {
    data: calendarSlotsResult,
    loading: calendarLoading,
  } = useQuery(getSessionCalendarSlotsQuery, {
    skip: !sessionUid,
    variables: { uid: sessionUid },
    pollInterval: 5000,
  });

  useEffect(() => {
    if (calendarSlotsResult) {
      const rows = _.has(calendarSlotsResult, 'getSessionCalendarSlots') ? calendarSlotsResult.getSessionCalendarSlots : [];
      const listEvents = [];

      _.map(rows, (row, key) => {
        const timeslotUid = _.has(row, 'timeslotUid') ? row.timeslotUid : null;
        const interest = _.has(row, 'interest') ? row.interest : null;
        const startDate = _.has(row, 'start') ? row.start : null;
        const endDate = _.has(row, 'end') ? row.end : null;
        const sessionType = _.has(row, 'sessionType') ? row.sessionType : null;
        const available = _.has(row, 'available') ? row.available : null;

        const event = {
          id: key,
          title: interest,
          start: new Date(startDate),
          end: new Date(endDate),
          timeslotUid,
          sessionType,
          // price,
          available,
        };

        listEvents.push(event);
      });

      _.compact(listEvents);
      setEvents(listEvents);
    }
  }, [calendarSlotsResult]);

  useEffect(() => {
    if (calendarLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [setLoading, calendarLoading, loading]);

  const handleSelectEvent = useCallback((event) => {
    setForBooking({ ...event });
  }, []);

  const onView = useCallback((e) => {
    setView(e);
  });

  const contextPayload = useMemo(() => ({
    forBooking,
    setForBooking,
  }), [forBooking, setForBooking]);

  return (
    <CalendarContext.Provider value={contextPayload}>
      <Calendar
        localizer={localizer}
        events={myEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={['week', 'agenda']}
        view={view}
        step={30}
        selectable={false}
        onSelectEvent={handleSelectEvent}
        onView={onView}
      />

      <RescheduleModal />
    </CalendarContext.Provider>
  );
}
