import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import LiveSessionCard from './liveSessionCard';
import UpcomingSessionCards from './upcomingSessionCard';
import WalletWidget from '../../payments/wallet/walletWidget';
import AttendanceWidget from '../../attendance/attendanceWidget';
import RecommendedTutors from './recommendedTutors'

export default function Index() {
  return (
    <Row>
      <Col lg={{ span: 12 }}>
        <WalletWidget />
      </Col>

      <Col lg={{ span: 12 }}>
        <Card>
          <Card.Body>
            <Card.Title>Sessions</Card.Title>
            <Row>
              <Col lg={12}><LiveSessionCard /></Col>
              <Col lg={12}><UpcomingSessionCards /></Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col lg={{ span: 12 }}>
        <Card>
          <Card.Body>
            <Card.Title>Top 5 Recommended Tutors</Card.Title>
            <Row>
              <Col lg={12}><RecommendedTutors /></Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col lg={{ span: 6 }}>
        <AttendanceWidget />
      </Col>

    </Row>

  );
}
