/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ChargeContext from './charge.context';

export default function Index() {
  const navigate = useNavigate();
  const { error } = useContext(ChargeContext);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cash In</li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Cash In</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">

              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i style={{ background: 'red', borderColor: 'red' }}><FontAwesomeIcon icon={solid('xmark')} /></i>
                    <h3>Charging payment error!</h3>
                    <p className="text-danger">{error}</p>
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary view-inv-btn"
                      onClick={() => navigate('/payments', { replace: true })}
                    >
                      Wallet
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
