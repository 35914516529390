import React, { useContext } from 'react';
import {
  Form, Modal, Button, Row, Col,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { CREATE_VOUCHER, UPDATE_VOUCHER, GET_VOUCHER_BY_CODE } from './gql';
import PaymentClient from '../../../PaymentClient';
import LoginContext from '../../login/login.context';
import useCreateAuditTrail from '../../auditTrail/useCreateAuditTrail';
import { useState } from 'react';
import { AlertError } from '../../../components'

const getVoucherByCode = ({ code, uid }) => PaymentClient.query({
  query: GET_VOUCHER_BY_CODE,
  fetchPolicy: 'network-only',
  variables: { code, uid },
}).then((result) => {
  const dataResult = _.has(result, 'data') ? result.data : null;

  const voucherData = _.has(dataResult, 'getVoucherByCode') ? dataResult.getVoucherByCode : null;
  return voucherData;
}).catch((error) => error);

const insertAuditTrail = (doInsertAuditTrail, newValue, oldValue, name) => {
  doInsertAuditTrail({
    action: 'UPDATE',
    changes: `Updated ${name} from ${oldValue} to ${newValue}`,
    module: 'Voucher Code',
  });
};

const logChanges = (oldValues, newValues, doInsertAuditTrail) => {
  const oldCode = _.has(oldValues, 'code') ? oldValues.code : null;
  const oldDescription = _.has(oldValues, 'description') ? oldValues.description : null;
  const oldRequirement = _.has(oldValues, 'requirement') ? oldValues.requirement : null;
  const oldAmount = _.has(oldValues, 'amount') ? oldValues.amount : null;
  const oldStartDate = _.has(oldValues, 'startDate') ? oldValues.startDate : 0;
  const oldEndDate = _.has(oldValues, 'endDate') ? oldValues.endDate : 0;

  const newCode = _.has(newValues, 'code') ? newValues.code : null;
  const newDescription = _.has(newValues, 'description') ? newValues.description : null;
  const newRequirement = _.has(newValues, 'requirement') ? newValues.requirement : null;
  const newAmount = _.has(newValues, 'amount') ? newValues.amount : null;
  const newStartDate = _.has(newValues, 'startDate') ? newValues.startDate : 0;
  const newEndDate = _.has(newValues, 'endDate') ? newValues.endDate : 0;

  if (oldCode !== newCode) {
    insertAuditTrail(doInsertAuditTrail, newCode, oldCode, 'code');
  }

  if (oldDescription !== newDescription) {
    insertAuditTrail(doInsertAuditTrail, newDescription, oldDescription, 'description');
  }

  if (oldRequirement !== newRequirement) {
    insertAuditTrail(doInsertAuditTrail, newRequirement, oldRequirement, 'requirement');
  }

  if (oldAmount !== newAmount) {
    insertAuditTrail(doInsertAuditTrail, newAmount, oldAmount, 'amount discount');
  }

  if (oldStartDate !== newStartDate) {
    insertAuditTrail(doInsertAuditTrail, newStartDate, oldStartDate, 'start date');
  }

  if (oldEndDate !== newEndDate) {
    insertAuditTrail(doInsertAuditTrail, newEndDate, oldEndDate, 'end date');
  }
};

export default function voucherModal({
  show, onHide, isEdit, initialValues,
}) {
  const { userUid } = useContext(LoginContext);
  const { doInsertAuditTrail } = useCreateAuditTrail();
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const code = _.has(initialValues, 'code') ? initialValues.code : null;
  const description = _.has(initialValues, 'description') ? initialValues.description : null;
  const requirement = _.has(initialValues, 'requirement') ? initialValues.requirement : 1;
  const amount = _.has(initialValues, 'amount') ? initialValues.amount : 1;
  const startDate = _.has(initialValues, 'startDate') ? initialValues.startDate : 0;
  const endDate = _.has(initialValues, 'endDate') ? initialValues.endDate : 0;

  const {
    handleSubmit, reset, formState, control, watch,
  } = useForm({
    defaultValues: {
      code,
      description,
      requirementCount: requirement,
      amount,
      startDate: isEdit && startDate && new Date(startDate),
      endDate: isEdit && endDate && new Date(endDate),
    },
  });

  const { errors } = formState;

  const [createVoucher] = useMutation(CREATE_VOUCHER, {
    client: PaymentClient,
    refetchQueries: ['GetPaginatedVouchers'],
  });

  const [updateVoucher] = useMutation(UPDATE_VOUCHER, {
    client: PaymentClient,
    refetchQueries: ['GetPaginatedVouchers'],
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setSaving(true)
    if (!isEdit) {
      createVoucher({
        variables: {
          ...data,
          amount: parseFloat(data.amount),
          requirement: parseInt(data.requirementCount, 10),
          createdBy: userUid,
        },
      })
        .then(() => {
          // audit trail
          doInsertAuditTrail({
            action: 'CREATE',
            changes: `Created ${data.code} voucher`,
            module: 'Voucher Code',
          });
          setSaving(false)
          reset();
          onHide();
        })
        .catch((err) => {
          setSaving(false)
          setError({
            title: 'Failed to create voucher',
            message: err.toString()
          })
        });
    } else {
      updateVoucher({
        variables: {
          ...data,
          amount: parseFloat(data.amount),
          requirement: parseInt(data.requirementCount, 10),
          updatedBy: userUid,
          uid: _.has(initialValues, 'uid') ? initialValues.uid : null,
        },
      })
        .then(() => {
          // audit trail
          const newValues = {
            ...data,
            amount: parseFloat(data.amount),
            requirement: parseInt(data.requirementCount, 10),
          };
          logChanges(initialValues, newValues, doInsertAuditTrail);
          
          setSaving(false)
          reset();
          onHide();
        })
        .catch((err) => {
          setSaving(false)
          setError({
            title: 'Failed to update voucher',
            message: err.toString()
          })        
        });
    }
  };

  const handleClose = () => {
    onHide();
    reset();
  };

  const uniqueCode = async (value) => {
    const voucherData = await getVoucherByCode({
      code: value,
      uid: _.has(initialValues, 'uid') ? initialValues.uid : null,
    });
    const voucherCode = _.has(voucherData, 'code') ? voucherData.code : null;
    return voucherCode !== value || 'Voucher code already exists.';
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>{`${isEdit ? 'Edit' : 'Create'}  Voucher Code`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {error && (
          <AlertError
            error={error.title}
            title={error.message}
            onClose={() => setError(null)}
          />
          ) }

          <Row>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>Voucher Code</Form.Label>
                <Controller
                  name="code"
                  control={control}
                  rules={{
                    required: 'Voucher code is required.',
                    validate: {
                      whiteSpace: (value) => !!value.trim() || 'Voucher code cannot be empty.',
                      uniqueName: (value) => uniqueCode(value),
                      maxLength: (value) => value.length <= 50 || 'Voucher code character limit 50.',
                      pattern: (value) => {
                        const pattern = /^[A-Za-z0-9]*$/;
                        return pattern.test(value) || 'Voucher code can only contain letters and numbers only.';
                      },
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control
                      autoFocus
                      isInvalid={!!_.has(errors, 'code')}
                      {...field}
                      onChange={(e) => {
                        const { value } = e.target;
                        const trimmedValue = value.replace(/\s/g, '');
                        field.onChange(trimmedValue);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === ' ' || e.code === 'Space') {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'code') ? errors.code.message : 'Invalid status.'}
                </Form.Control.Feedback>
              </Form.Group>

            </Col>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>Description</Form.Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>Amount Discount</Form.Label>
                <Controller
                defaultValue={1}
                  name="amount"
                  rules={{ required: 'Amount Discount is required.' }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="number"
                      min={1}
                      autoFocus
                      isInvalid={!!_.has(errors, 'amount')}
                      {...field}
                      defaultValue={1}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {_.has(errors, 'amount') ? errors.amount.message : 'Invalid amount.'}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>
                  Requirement
                  {' '}
                  <span className="text-muted">
                    (ex. Only for the first 100 users.)
                  </span>
                </Form.Label>
                <Controller
                  name="requirementCount"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="number"
                      min={1}
                      {...field}
                      defaultValue={1}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>Start Date</Form.Label>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{ required: 'Start date is required.' }}
                  render={({ field }) => (
                    <ReactDatePicker
                      customInput={(
                        <Form.Control
                          isInvalid={!!_.has(errors, 'startDate')}
                        />
                      )}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="MMMM d, yyyy"
                      {...field}
                      minDate={new Date()}
                      selectsStart
                      startDate={field.value}
                      endDate={watch('endDate')}
                      maxDate={watch('endDate')}
                    />
                  )}
                />
                <p className="text-danger mt-1" style={{ fontSize: '.875em' }}>
                  {_.has(errors, 'startDate') ? errors.startDate.message : ''}
                </p>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="form-group" controlId="acceptTutor.controlInput1">
                <Form.Label>End Date</Form.Label>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{ required: 'End Date is required.' }}
                  render={({ field }) => (
                    <ReactDatePicker
                      customInput={(
                        <Form.Control
                          isInvalid={!!_.has(errors, 'endDate')}
                        />
                      )}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="MMMM d, yyyy"
                      {...field}
                      selectsEnd
                      startDate={watch('startDate')}
                      endDate={field.value}
                      minDate={watch('startDate')}
                    />
                  )}
                />
                <p className="text-danger mt-1" style={{ fontSize: '.875em' }}>
                  {_.has(errors, 'endDate') ? errors.endDate.message : ''}
                </p>
              </Form.Group>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>Cancel</Button>
          {' '}
          <Button type="submit" variant="primary" disabled={saving}>{!saving ? 'Save' : 'Saving'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
