import React, { useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { CustomTable } from '../../../components';
import UserFullname from './userFullname';
import PaymentClient from '../../../PaymentClient';
import { GET_VOUCHER_USERS } from './gql';

export default function voucherUsers({ voucherCode }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const { data, loading, error } = useQuery(GET_VOUCHER_USERS, {
    client: PaymentClient,
    variables: {
      code: voucherCode,
    },
  });

  const dataResult = _.has(data, 'getVoucherUsers') ? data.getVoucherUsers : [];

  const columns = [
    {
      title: 'Full Name',
      dataKey: 'name',
      render: (text, record) => <UserFullname userUid={record} />,
    },
  ];

  if (error) {
    return <div><p className="text-center text-danger">Something went wrong!</p></div>;
  }

  return (
    <CustomTable
      loading={loading}
      pagination={false}
      columns={columns}
      page={page}
      pageSize={pageSize}
      dataValues={dataResult}
      onPageChange={(nPage) => setPage(nPage)}
    />
  );
}
