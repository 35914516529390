import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { GET_PAGINATED_INTEREST } from './gql';
import { CustomTable } from '../../../../components';
import UpdateStatusModal from './updateStatusModal';
import DeleteModal from './deleteModal';
// import { onError } from 'apollo-link-error';

export default function interestList({ searchText, onClickEdit, onError, onSuccess }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [status] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  const { data, loading } = useQuery(GET_PAGINATED_INTEREST, {
    variables: {
      status,
      offset: pageSize ? pageSize * (page - 1) : 0,
      limit: pageSize,
      searchText,
    },
  });

  const result = _.has(data, 'getPaginatedInterest') ? data.getPaginatedInterest : null;
  const dataResult = _.has(result, 'rows') ? result.rows : [];
  const rowCount = _.has(result, 'count') ? result.count : 0;

  const columns = [
    {
      title: 'TITLE',
      dataKey: 'title',
    },
    {
      title: 'PARTICIPANTS LIMIT',
      dataKey: 'participantsLimit',
    },
    {
      title: 'STATUS',
      dataKey: 'status',
    },
    {
      title: 'ACTION',
      dataKey: 'uid',
      render: (text, record) => {
        const uid = _.has(record, 'uid') ? record.uid : null;
        const title = _.has(record, 'title') ? record.title : null;
        const iStatus = _.has(record, 'status') ? record.status : null;
        return (
          <div className="d-flex gap-2">
            <UpdateStatusModal
              status={iStatus}
              uid={uid}
              title={title}
              onError={onError}
              onSuccess={onSuccess}
            />
            {' '}
            <Button
              variant="success"
              onClick={() => onClickEdit(record)}
            >
              Edit
            </Button>
            {' '}
            <DeleteModal uid={uid} title={title} onError={onError} onSuccess={onSuccess} />
          </div>
        );
      },
    },
  ];

  return (
    <CustomTable
      loading={loading}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totals={rowCount}
      dataValues={dataResult}
      onPageChange={(nPage) => setPage(nPage)}
    />
  );
}
